import React from "react";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import { Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Asynchronous(props) {
  const opt = props.options;
  const val = props.val;
  const setFieldValue = props.onChange;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const getOptionSelected = props.getOptionSelected;
  const getOptionLabel = props.getOptionLabel;

  React.useEffect(() => {
    let active = true;
    setOptions(opt);
    return () => {
      active = false;
    };
  }, []);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Tooltip title={val ? val.name : ""}>
      <Autocomplete
        multiple={props.multiple != null ? props.multiple : true}
        disabled={props.disabled != null ? props.disabled : false}
        id={props.id}
        name={props.name}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disableCloseOnSelect
        value={val}
        size="medium"
        disableClearable={true}
        onChange={(event, newValue) => {
          if (props.name === "certifications") {
            setFieldValue("certifications", newValue);
          } else if (props.name === "education") {
            setFieldValue("education", newValue);
          } else if (props.name === "training") {
            setFieldValue("training", newValue);
          } else if (props.name === "license") {
            setFieldValue("license", newValue);
          } else if (props.name === "secondaryDepartments") {
            setFieldValue("secondaryDepartments", newValue);
          } else if (
            props.name === "skills" ||
            props.name === "employeesToSelect"
          ) {
            props.onChange(newValue);
          } else if (props.name.includes("shiftType")) {
            if (props.name.includes("trainings")) {
              props.onChange(newValue, val, props.trainingRequirementId);
              props.setFieldValue(props.name, newValue);
            } else {
              props.onChange(newValue);
              setFieldValue(props.name, newValue);
            }
          } else {
            if (props.name.includes("trainings")) {
              props.onChange(newValue, val, props.trainingRequirementId);
              props.setFieldValue(props.name, newValue);
            } else {
              setFieldValue(props.name, newValue);
            }
          }
          props.autoClose && setOpen(false);
        }}
        isOptionEqualToValue={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={props.options}
        getOptionDisabled={(option) => option.name === "none"}
        placeholder={props.placeholder}
        renderOption={(p, option, { selected }) => (
          <li {...p}>
            {props.showCheckBox === true && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={props.checkedOnLoad === true ? true : selected}
              />
            )}
            {props.name.includes("shiftType") ? option.type : option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={props.placeholder}
          />
        )}
      />
    </Tooltip>
  );
}
