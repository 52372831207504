import React, { forwardRef } from "react";
import {
  Grid,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckBoxFilter from "./CheckBoxFilter";
import RadioButtonFilter from "./RadioButtonFilter";
import MultiSelect from "../general/MultiSelectSkills";
import { applyFilters, baseFilterObj } from "./filter/FilterEvents";

const MuiAccordion = withStyles({
  root: {
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(Accordion);

function defaultFilters(employees, jobTypes, availabilityType, nonLeaveType) {
  let filters = baseFilterObj();

  filters.requestsFilters = [
    ...filters.requestsFilters,
    ...availabilityType,
    ...nonLeaveType,
  ];
  filters.jobTypeFilters = [...filters.jobTypeFilters, ...jobTypes];
  filters.employeeFilters = [...filters.employeeFilters, ...employees];

  return filters;
}

const FilterMenu = forwardRef(
  (
    {
      jobTypes,
      shiftNames,
      availabilityTypeNames,
      nonLeaveTypeNames,
      officeFloatStatus,
      allOffices,
      employeeResources,
      employeeNames,
      allEmployeeNames,
      SetEmployeeNames,
      setFilterList,
      setAnchorEl,
      setCheckBox,
      checkBox,
      handleCheckBoxToggle,
      filterByEmployees,
      selectedEmployees,
      SetSelectedEmployees,
      handleRadioToggle,
    },
    ref,
  ) => {
    //On rerender, revert to what is in local storage filters
    // With the exception of employeeNames, it is based on what is selected from the search dropdown
    let shiftFilterList = baseFilterObj();
    shiftFilterList.requestsFilters = [
      ...shiftFilterList.requestsFilters,
      ...availabilityTypeNames,
      ...nonLeaveTypeNames,
    ];
    shiftFilterList.jobTypeFilters = [
      ...shiftFilterList.jobTypeFilters,
      ...jobTypes,
    ];
    shiftFilterList.employeeFilters = [
      ...shiftFilterList.employeeFilters,
      ...employeeNames,
    ];

    const handleResetAll = () => {
      let resetFilters = defaultFilters(
        allEmployeeNames,
        jobTypes,
        availabilityTypeNames,
        nonLeaveTypeNames,
      );
      SetEmployeeNames(allEmployeeNames);
      setCheckBox(resetFilters);
      setFilterList(resetFilters);

      if (ref.current) {
        ref.current.eventSettings.dataSource.length = 0;
        ref.current.addEvent(Object.values(ref.current.allScheduleDataObject));
        ref.current.resourceCollection[2].dataSource.length = 0;
        ref.current.addResource(
          resetFilters.employeeFilters.slice(1).reverse(),
          "Employees",
        );
      } else {
        console.log("NO REF AVAILABLE YET");
      }
      SetSelectedEmployees([]);
      setAnchorEl(null);
    };

    //query for the filtered events only after clicking apply
    const handleFilterApply = () => {
      setFilterList(checkBox);
      if (ref.current) {
        const displayedEvents = applyFilters(
          checkBox,
          Object.values(ref.current.allScheduleDataObject),
        );
        ref.current.eventSettings.dataSource.length = 0;
        ref.current.addEvent(displayedEvents);
        const newEmployeeResources = [];
        for (let employee of checkBox.employeeFilters) {
          if (employee.name !== "All Employees" && employee.checked) {
            newEmployeeResources.push(employee);
          }
        }
        ref.current.resourceCollection[2].dataSource.length = 0;
        ref.current.addResource(newEmployeeResources.reverse(), "Employees");
      } else {
        console.log("NO REF AVAILABLE YET");
      }

      setAnchorEl(null);
    };
    return (
      <Grid container direction="column" style={{ width: 300 }}>
        <Grid item align="right">
          <Button
            color="primary"
            onClick={handleResetAll}
            style={{ marginBottom: 8 }}
            id="resetAllFilters"
          >
            Reset All Filters
          </Button>
          <Button
            color="primary"
            onClick={handleFilterApply}
            style={{ marginBottom: 8 }}
            id="applyFilters"
          >
            Apply Filters
          </Button>
        </Grid>
        <Grid item container component={MuiAccordion}>
          <Grid
            item
            container
            component={AccordionSummary}
            alignItems="center"
            justifyContent="space-between"
            expandIcon={<ExpandMoreIcon />}
            style={{ height: 60, marginTop: -15 }}
          >
            <Grid item xs={6} style={{ paddingTop: 12, paddingBottom: 12 }}>
              <Typography variant="subtitle1">Events to View</Typography>
            </Grid>
          </Grid>
          <RadioButtonFilter
            filterTypes={shiftFilterList.shiftsFilters}
            filterString={"shiftsFilters"}
            checkBox={checkBox.shiftsFilters}
            handleRadioToggle={handleRadioToggle}
          />
        </Grid>
        <Grid item container component={MuiAccordion}>
          <Grid
            item
            container
            component={AccordionSummary}
            alignItems="center"
            justifyContent="space-between"
            expandIcon={<ExpandMoreIcon />}
            style={{ height: 60, marginTop: -15 }}
          >
            <Grid item xs={10} style={{ paddingTop: 12, paddingBottom: 12 }}>
              <Typography variant="subtitle1">Employees</Typography>
            </Grid>
          </Grid>
          <Grid item container component={AccordionDetails} direction="column">
            <Grid>
              <MultiSelect
                options={[...jobTypes]}
                name="skills"
                id="skills"
                placeholder="Enter Skill"
                onChange={filterByEmployees}
                val={selectedEmployees}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                autoClose={true}
              />
              <CheckBoxFilter
                filterTypes={shiftFilterList.employeeFilters}
                checkBox={checkBox.employeeFilters}
                handleCheckBoxToggle={handleCheckBoxToggle}
                filterString={"employeeFilters"}
                allString={"All Employees"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container component={MuiAccordion}>
          <Grid
            item
            container
            component={AccordionSummary}
            alignItems="center"
            justifyContent="space-between"
            expandIcon={<ExpandMoreIcon />}
            style={{ height: 60, marginTop: -15 }}
          >
            <Grid item xs={6} style={{ paddingTop: 12, paddingBottom: 12 }}>
              <Typography variant="subtitle1">Skills</Typography>
            </Grid>
          </Grid>
          <Grid item container component={AccordionDetails} direction="column">
            <Grid>
              <CheckBoxFilter
                filterTypes={shiftFilterList.jobTypeFilters}
                filterString={"jobTypeFilters"}
                allString={"All Skills"}
                checkBox={checkBox.jobTypeFilters}
                handleCheckBoxToggle={handleCheckBoxToggle}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container component={MuiAccordion}>
          <Grid
            item
            container
            component={AccordionSummary}
            alignItems="center"
            justifyContent="space-between"
            expandIcon={<ExpandMoreIcon />}
            style={{ height: 60, marginTop: -15 }}
          >
            <Grid item xs={10} style={{ paddingTop: 12, paddingBottom: 12 }}>
              <Typography variant="subtitle1">Non-Schedule Events</Typography>
            </Grid>
          </Grid>
          <Grid item container component={AccordionDetails} direction="column">
            <Grid>
              <CheckBoxFilter
                filterTypes={shiftFilterList.requestsFilters}
                checkBox={checkBox.requestsFilters}
                handleCheckBoxToggle={handleCheckBoxToggle}
                filterString={"requestsFilters"}
                allString={"All Requests"}
                nonScheduleEvents={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  },
);

export default FilterMenu;
