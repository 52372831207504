import { useState } from "react";
import { IconButton, Select, MenuItem, Menu, Typography } from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { identify } from "logrocket";

const OfficeContext = (props) => {
  const {
    loading,
    selectedOfficeId,
    allOffices,
    setSelectedOffice,
    primaryAssignment,
    secondaryOffices,
    selectedOffice,
    setSelectedOfficeId,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOfficeClick = (id) => {
    setSelectedOfficeId(id);
    let officeSelected = allOffices.data.offices.find((o) => o.id === id);
    setSelectedOffice(officeSelected);
    handleClose();
  };

  return (
    <div style={{ backgroundColor: "#E6E6E6", borderRadius: 15, padding: 5 }}>
      {loading ||
      allOffices.loading ||
      (!selectedOffice && !secondaryOffices) ? (
        <div>Loading offices...</div>
      ) : window.VIEW_ALL_OFFICES === false ||
        window.VIEW_ALL_OFFICES === "false" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {selectedOffice && (
            <Typography
              variant="body1"
              style={{ marginRight: 10, fontWeight: "bold" }}
            >
              {selectedOffice.name}
            </Typography>
          )}
          <IconButton
            sx={{ color: "#FFFFFF" }}
            onClick={handleClick}
            style={{ backgroundColor: "#f15a25" }}
          >
            <ListAltIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
              position: "absolute",
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {allOffices.data.offices &&
              allOffices.data.offices.length > 0 &&
              allOffices.data.offices
                .filter(
                  (e) =>
                    e.id === primaryAssignment.office.id ||
                    secondaryOffices.map((o) => o.office.id).includes(e.id),
                )
                .map((e, index) => (
                  <MenuItem
                    value={e.id}
                    key={index}
                    onClick={() => handleOfficeClick(e.id)}
                  >
                    {e.name}
                  </MenuItem>
                ))}
          </Menu>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          {selectedOffice && (
            <Typography
              variant="body1"
              style={{ marginRight: 10, fontWeight: "bold" }}
            >
              {selectedOffice.name}
            </Typography>
          )}
          <IconButton onClick={handleClick}>
            <ListAltIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {allOffices.data.offices &&
              allOffices.data.offices.length > 0 &&
              allOffices.data.offices
                .filter((e) => e.parent == null)
                .map((e, index) => (
                  <MenuItem
                    value={e.id}
                    key={index}
                    onClick={() => handleOfficeClick(e.id)}
                  >
                    {e.name}
                  </MenuItem>
                ))}
          </Menu>
        </div>
      )}
    </div>
  );
};
export default OfficeContext;
