import React from "react";
import { Grid, CssBaseline, Link } from "@mui/material";

const ErrorPage = () => {
  return (
    <Grid container>
      <CssBaseline />
      <Grid item xs={12} style={{ marginTop: "15vh" }}>
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            <h1>A major error occurred</h1>
            <p>
              Please make a ticket saying you reached the root Error Boundary.
              You can do so by visiting our{" "}
              <Link
                href="https://dualitysys.atlassian.net/servicedesk/customer/portal/1"
                target="_blank"
                rel="noopener"
              >
                support portal
              </Link>
              .
            </p>
            <p>You can also consider refreshing and trying again</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
