import React from "react";
import { Typography, Grid, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { userVar } from "../../cache";

const useStyles = makeStyles((theme) => ({
  highlightedEmployee: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  coloredAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  greyAvatar: {
    backgroundColor: "white",
    height: 27,
    width: 27,
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.secondary.main,
  },
  subtitleBlack: {
    fontSize: 12,
    color: theme.palette.text.primary,
  },
}));
/*employees with their First and last name initial is returned
optional job_title */

const EventParticipant = (props) => {
  const classes = useStyles();

  const {
    participant,
    showAvatar,
    selected,
    showJobTitle,
    singleLine,
    widthMatters,
    color,
    addOption,
    notifyDevelopers,
    environment,
    displayLastNameFirst,
  } = props;

  const user = userVar();

  const firstInitial = participant.firstName ? participant.firstName[0] : "";
  const lastInitial = participant.lastName ? participant.lastName[0] : "";
  const initials = firstInitial + lastInitial;
  const fullName =
    displayLastNameFirst && displayLastNameFirst === true
      ? participant.lastName + " " + participant.firstName
      : participant.firstName + " " + participant.lastName;

  let matchingAssignment;
  let jobTitle =
    participant.skills &&
    participant.skills.length > 0 &&
    participant.skills.find((e) => e.variety === "JOB_TYPE")
      ? participant.skills.length > 0 &&
        participant.skills.find((e) => e.variety === "JOB_TYPE")
      : "";

  // let jobTitle = matchingAssignment ? matchingAssignment.role.name : '';
  // jobTitle = jobTitle.slice(0, 1).toUpperCase() + jobTitle.slice(1).toLowerCase();

  if (!addOption) {
    if (showAvatar && showJobTitle && widthMatters && color) {
      return (
        <Grid item container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <Avatar
              className={color ? classes.coloredAvatar : classes.greyAvatar}
            >
              {initials}
            </Avatar>
          </Grid>
          <Grid item style={{ width: 175 }}>
            <Typography
              className={selected ? classes.highlightedEmployee : null}
              noWrap
            >
              {fullName}
            </Typography>
            <Typography className={classes.subtitleBlack}>
              {jobTitle}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (showAvatar && showJobTitle && !widthMatters) {
      return (
        <Grid item container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <Avatar
              className={color ? classes.coloredAvatar : classes.greyAvatar}
            >
              {initials}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography
              className={selected ? classes.highlightedEmployee : null}
            >
              {fullName}
            </Typography>
            <Typography className={classes.subtitleBlack}>
              {jobTitle}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (showAvatar && !showJobTitle && color) {
      return (
        <Grid item container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <Avatar className={classes.coloredAvatar}>{initials}</Avatar>
          </Grid>
          <Grid item style={{ width: 175 }}>
            <Typography
              className={selected ? classes.highlightedEmployee : null}
              noWrap
            >
              {fullName}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (showAvatar && showJobTitle && !color) {
      return (
        <Grid item container direction="row" spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Avatar className={classes.greyAvatar}>{initials}</Avatar>
          </Grid>
          <Grid item style={{ width: 100 }} xs={9}>
            <Typography
              className={selected ? classes.highlightedEmployee : null}
              noWrap
            >
              {fullName}
            </Typography>
            <Typography className={classes.subtitleBlack}>
              {jobTitle}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (!showAvatar && showJobTitle && singleLine) {
      return (
        <Grid item style={{ width: 255 }}>
          <Typography noWrap>
            {fullName} {jobTitle ? " - " + jobTitle : null}
          </Typography>
        </Grid>
      );
    } else if (!showAvatar && showJobTitle && !singleLine) {
      return (
        <Grid item style={{ width: 255 }}>
          <Typography
            className={selected ? classes.highlightedEmployee : null}
            noWrap
          >
            {fullName}
          </Typography>
          <Typography className={classes.subtitleBlack}>{jobTitle}</Typography>
        </Grid>
      );
    } else if (!widthMatters) {
      return (
        <Grid item style={{ width: 230 }}>
          <Typography
            className={selected ? classes.highlightedEmployee : null}
            noWrap
          >
            {fullName}
          </Typography>
        </Grid>
      );
    } else if (widthMatters) {
      return (
        <Grid item style={{ width: 130 }}>
          <Typography
            className={selected ? classes.highlightedEmployee : null}
            noWrap
          >
            {fullName}
          </Typography>
        </Grid>
      );
    } else {
      return <Typography>Loading...</Typography>;
    }
  } else {
    return null;
  }
};

EventParticipant.propTypes = {
  participant: PropTypes.object,
  showAvatar: PropTypes.bool,
  selected: PropTypes.bool,
  showJobTitle: PropTypes.bool,
  singleLine: PropTypes.bool,
  widthMatters: PropTypes.bool,
  color: PropTypes.bool,
  addOption: PropTypes.bool,
};

EventParticipant.defaultProps = {
  showAvatar: false,
  selected: false,
  showJobTitle: false,
  singleLine: false,
  widthMatters: true,
  color: true,
  addOption: false,
};

export default EventParticipant;
