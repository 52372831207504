import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

import { userVar } from "../../cache";

const Greeting = () => {
  const user = userVar();
  const location = useLocation();

  const messages = {
    "/": "Your office at a glance",
    "/Schedule": "Juggle shifts here",
    "/Preferences": "Night owl or early bird? Make it happen here",
    "/Requests": "Vacation dreams and swapping schemes",
    "/CreateSchedule": "Big breath, the next schedule starts here",
    "/ManageTeam": "Build & manage the dream team",
    "/ManageMentees": "Shape the future with mentorship magic",
    "/ImportData": "Turn chaos into harmony",
    "/Admin": "Wild world of workspaces",
    "/StaffingCoverage": "No shift left behind",
    "/StaffAvailability": "Who's ready to rock and roll?",
    "/ScheduleQualityAnalytics": "Teeny tiny report card",
    "/PreferenceAnalytics": "Ooh what's popular?",
    "/SkillSetAnalytics": "Look at all these great people doing great things",
    "/TimeOffAnalytics": "Mapping out the great escapes",
  };

  const currentMessage = messages[location.pathname] || "It's good to see you!";

  return (
    <Grid container>
      <Grid item xs={12}>
        {location.pathname === "/" && (
          <Typography component="h1" variant="h2" color="#00000">
            Welcome back, {user?.name ? user?.name : ""}!
          </Typography>
        )}
      </Grid>

      {/*<Grid item xs={12}>*/}
      {/*  <Typography component="h1" variant="h4" color="secondary">*/}
      {/*    {currentMessage}*/}
      {/*  </Typography>*/}
      {/*</Grid>*/}
    </Grid>
  );
};

export default Greeting;
