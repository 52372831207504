import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  MenuItem,
  InputLabel,
  Select,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const ImportPastShifts = (props) => {
  const { closeDialog } = props;
  const headerData = [["Description", "Start", "End", "Subject", "location"]];
  return (
    <div>
      <Grid item container direction="column" spacing={2}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignContent="flex-start"
        >
          <Grid item container justifyContent="flex-end" xs={2}>
            <Grid item>
              <IconButton color="secondary" onClick={closeDialog} size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item>
            <Box m={2} p={2}>
              <Button
                color="secondary"
                //data={headerData}
                target="_blank"
                variant="outlined"
              >
                Download CSV Format
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ImportPastShifts;
