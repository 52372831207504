import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  InputLabel,
  IconButton,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DateFnsUtils from "@date-io/date-fns";
import CloseIcon from "@mui/icons-material/Close";
import { format, sub } from "date-fns";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_SHIFT_ASSIGNMENT, EMPLOYEE_CALL_IN } from "../../api/gqlQueries";
import { userVar, selectedDateVar } from "../../cache";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}
const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 138,
    maxWidth: 225,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  spaceBelow: {
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.primary.main,
  },
  textInput: {
    width: 375,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    marginTop: -4,
    paddingLeft: 14,
    paddingRight: 14,
  },
}));

// users use this form to indicate that they wont be available on a particular date after the schedule period is generated

const EmpCallInForm = (props) => {
  const classes = useStyles();

  const {
    closeDialog,
    userEvents,
    notifyDevelopers,
    environment,
    // refetch
  } = props;

  const user = userVar();
  const date = selectedDateVar();

  const userScheduleDates = userEvents.map((event) =>
    format(new Date(event.start), "MM/dd/yyyy"),
  );
  const [currentDate, setCurrentDate] = useState(new Date(date));
  const [note, setNote] = useState("");
  const [shiftAssignmentId, setShiftAssignmentId] = useState("");
  const [shiftId, setShiftId] = useState("");
  const [error, setError] = useState("");
  const [dateError, setDateError] = useState("");

  const [getShiftAssignmentId] = useLazyQuery(GET_SHIFT_ASSIGNMENT, {
    onCompleted(data) {
      setShiftAssignmentId(data.shiftAssignments[0].id);
    },
    onError(error) {
      console.log(error);
      toastShow(
        "Unable to complete request. Please check date and try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_SHIFT_ASSIGNMENT lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [callIn] = useMutation(EMPLOYEE_CALL_IN, {
    onCompleted() {
      toastShow("Employee Call In Successfully Created", "Success");
      closeDialog();
    },
    onError(error) {
      console.log(error);
      toastShow(
        "Error with request. Please ensure you chose the correct date and try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on EMPLOYEE_CALL_IN lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    const newDate = new Date(date);
    if (newDate < sub(new Date(), { days: 1 })) {
      setDateError("Date should not be before today");
      return;
    }
    setDateError("");
    const shift = userEvents.find(
      (event) =>
        new Date(event.start).toDateString() === newDate.toDateString(),
    );
    const shiftId = shift.eventId;
    setShiftId(shiftId);
    getShiftAssignmentId({
      variables: {
        employeeId: parseInt(user.id),
        shiftId: parseInt(shiftId),
      },
    });
  }, []);

  const handleSubmit = () => {
    //mutation to change isArchived value on shift to true to indicate employee called off
    callIn({
      variables: {
        id: parseInt(shiftAssignmentId),
        note: note,
      },
    });
  };

  const handleDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      if (date < sub(new Date(), { days: 1 })) {
        setCurrentDate(date);
        setDateError("Date should not be before today");
        return;
      } else {
        setCurrentDate(date);
        const shift = userEvents.find(
          (event) =>
            new Date(event.start).toDateString() === date.toDateString(),
        );
        if (shift) {
          setDateError("");
          const shiftId = shift.eventId;
          setShiftId(shiftId);
          //query to get shift assignment ID every time date changes so value is stored for submit
          getShiftAssignmentId({
            variables: {
              employeeId: parseInt(user.id),
              shiftId: parseInt(shiftId),
            },
          });
        } else {
          setDateError("You must choose a date that you are working");
        }
      }
    } else {
      setDateError("Invalid date");
    }
  };

  const checkInvalid = (date) => {
    const formatted = format(date, "MM/dd/yyyy");
    return !userScheduleDates.includes(formatted);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid
        container
        component="form"
        direction="column"
        alignItems="flex-start"
        justifyContent="center"
        spacing={3}
      >
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Typography variant="h3">Call In</Typography>
          </Grid>
          <Grid item>
            <IconButton color="secondary" onClick={closeDialog} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <InputLabel htmlFor="current-date" shrink={false}>
            <Typography variant="h6">Date To Call In</Typography>
          </InputLabel>
          <DatePicker
            disableToolbar
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            id="current-date"
            shouldDisableDate={checkInvalid}
            value={currentDate}
            onChange={handleDateChange}
            className={classes.input}
            renderInput={(props) => <TextField {...props} />}
          />
          {dateError && (
            <Typography variant="body2" className={classes.dateError}>
              {dateError}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <InputLabel htmlFor="current-date" shrink={false}>
            <Typography variant="h6">Note to Manager</Typography>
          </InputLabel>
          <TextField
            variant="outlined"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className={classes.textInput}
          />
        </Grid>
        <Grid item>
          {error && <Typography className={classes.error}>{error}</Typography>}
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          style={{ zIndex: 4, position: "absolute", bottom: 25, right: 30 }}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={Boolean(error || dateError)}
            >
              Call in
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default EmpCallInForm;
