import { useState } from "react";
import {
  Button,
  Popover,
  List,
  ListItem,
  Link,
  IconButton,
  Badge,
  Container,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsDropdown from "../notifications/NotificationsDropdown";

const NotificationPopover = (props) => {
  const { notificationCount, userDetails } = props;

  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  return (
    <>
      <IconButton
        color="#000000"
        onClick={(e) => setNotificationsAnchorEl(e.currentTarget)}
        size="large"
      >
        <Badge
          badgeContent={notificationCount}
          data-testid="notificationsNumbers"
          color="primary"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={notificationsAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={Boolean(notificationsAnchorEl)}
        onClose={() => setNotificationsAnchorEl(null)}
      >
        <Container
          style={{ padding: 10, overflow: "hidden" }}
          data-testid="notificationsPopOver"
        >
          <NotificationsDropdown
            closePopover={() => setNotificationsAnchorEl(null)}
            userId={userDetails.id}
            data-testid="notificationsList"
          />
        </Container>
      </Popover>
    </>
  );
};

export default NotificationPopover;
