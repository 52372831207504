import { useState } from "react";
import { Button, Popover, List, ListItem, Container } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { handleLogout } from "./LogOutDialog";
import { Link } from "react-router-dom";
import { useApolloClient } from "@apollo/client";

const UserIconInfo = (props) => {
  const client = useApolloClient();
  const { userDetails } = props;

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);

  return (
    <>
      <Button onClick={(e) => setProfileAnchorEl(e.currentTarget)}>
        {userDetails.firstName && userDetails.lastName ? (
          <Avatar
            data-testid="userInitialsProfileIcon"
            style={{ backgroundColor: "#f15a25" }}
          >
            {userDetails.firstName[0] + userDetails.lastName[0]}
          </Avatar>
        ) : (
          <Avatar data-testid="userInitialsEmptyProfileIcon">
            <AccountCircleIcon style={{ fontSize: 40 }} />
          </Avatar>
        )}
      </Button>
      <Popover
        anchorEl={profileAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={Boolean(profileAnchorEl)}
        onClose={() => setProfileAnchorEl(null)}
      >
        <List>
          <ListItem
            component={Link}
            to="/Profile"
            data-testid="myProfile"
            onClick={() => setProfileAnchorEl(null)}
          >
            My Profile
          </ListItem>
          <ListItem
            component={Link}
            to="/UpdatePassword"
            data-testid="updatePassword"
          >
            Update Password
          </ListItem>
          <ListItem
            component={Link}
            to="/"
            onClick={() => handleLogout(client)}
            data-testid="logOut"
          >
            Logout
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default UserIconInfo;
