import React from "react";
import { useQuery } from "@apollo/client";
import { GET_ONLY_USER_SHIFTS } from "../../api/gqlQueries";
import { CircularProgress, div, Typography, Box } from "@mui/material";
import { userVar } from "../../cache";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

const NextShiftDetails = () => {
  const history = useHistory();
  const user = userVar();
  const today = format(new Date(), "yyyy-MM-dd") + "T00:00:00";

  const shifts = useQuery(GET_ONLY_USER_SHIFTS, {
    variables: {
      employeeId: parseInt(user.id),
      rangeStart: today,
    },
  });

  if (shifts.loading) {
    return <CircularProgress color="primary" />;
  } else {
    const allShifts =
      shifts.data.userShifts &&
      shifts.data.userShifts.length > 0 &&
      shifts.data.userShifts.map((e) => e.shift);
    allShifts &&
      allShifts.length > 0 &&
      allShifts.sort((a, b) => new Date(a.start) - new Date(b.start));
    let nextShift = allShifts && allShifts.length > 0 && allShifts[0];
    return (
      <div>
        <div
          onClick={() =>
            (window.SHOW_CALENDAR_ON_LOAD === true ||
              window.SHOW_CALENDAR_ON_LOAD === "true") &&
            user.isEmployee === true
              ? history.push("/")
              : history.push("/Schedule")
          }
          style={{ cursor: "pointer" }}
        >
          <Box m={2}>
            <Typography variant="h3">
              {nextShift
                ? "NEXT SHIFT: " + format(new Date(nextShift.start), "EEEE")
                : "No Shifts for this User"}
            </Typography>
          </Box>
          <div style={{ marginTop: 20 }}>
            {" "}
            <Typography variant="h6">
              {!allShifts
                ? ""
                : allShifts &&
                  allShifts.length > 0 &&
                  format(new Date(allShifts[0].start), "HH:mm") +
                    " - " +
                    format(new Date(allShifts[0].end), "HH:mm")}
            </Typography>
          </div>
          <div>
            {nextShift &&
              nextShift.employeeSet &&
              nextShift.employeeSet.map((e, index) => (
                <React.Fragment key={index}>
                  <Typography variant="body1">
                    {e.firstName + " " + e.lastName}{" "}
                  </Typography>
                  <Typography style={{ fontSize: 10, fontStyle: "italics" }}>
                    {e.skills && e.skills.map((s) => s.name).join(",")}
                  </Typography>
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
    );
  }
};

export default NextShiftDetails;
