import React, { useEffect, useState } from "react";
import ParticipantDelete from "./ParticipantDelete";
import EventParticipant from "./EventParticipant";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  MenuItem,
  InputLabel,
  Select,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import StarRateIcon from "@mui/icons-material/StarRate";
import {
  MANAGER_ADD_EMPLOYEE_TO_SHIFT,
  GET_ALL_USERS,
  // GET_SHIFT_ASSIGNMENTS_BY_SHIFT,
  // MANAGER_REMOVE_EMPLOYEE_FROM_SHIFT,
  ADD_AND_DROP_OPTIONS_FOR_SHIFT,
  BATCH_CREATE_RESCHEDULE_OPTION,
  EXECUTE_OPTION,
} from "../../api/gqlQueries";
import { format, isAfter, isBefore, isEqual } from "date-fns";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { userVar } from "../../cache";
import RescheduleOptionsKey from "../rescheduling/RescheduleOptionsKey";
import differenceInHours from "date-fns/differenceInHours";

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 273,
    textOverflow: "ellipsis",
  },
  helpfulTip: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.primary.main,
    marginTop: -15,
  },
  button: {
    width: 75,
  },
  icon: {
    padding: 0,
    marginLeft: -7,
    marginTop: 2,
  },
}));

const MangAddEmployees = (props) => {
  const classes = useStyles();

  const {
    procedureRequirement,
    availableShifts,
    constraints,
    gaps,
    closeDialog,
    setToast,
    setShowToast,
    setErrorToast,
    setShowErrorToast,
    refetch,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
  } = props;

  const user = userVar();
  const officeId = user.office.id;

  let exactShiftMatch;
  let gapsToFill;
  if (gaps && gaps.length > 0) {
    gapsToFill = [];
    gaps.forEach((gap) => {
      const matchingTime = gapsToFill.find(
        (gapToFill) =>
          isEqual(gapToFill.ShiftStart, gap.start) &&
          isEqual(gapToFill.ShiftEnd, gap.end),
      );

      if (matchingTime) {
        matchingTime.skills.push({
          ...gap.skill,
          value: gap.value,
        });
      } else {
        gapsToFill.push({
          ShiftStart: gap.start,
          ShiftEnd: gap.end,
          skills: [
            {
              ...gap.skill,
              value: gap.value,
            },
          ],
        });
      }
    });
  } else {
    exactShiftMatch = findExact(constraints[0]);
    gapsToFill = constraints;
  }

  const formattedConstraintsWithShifts = gapsToFill.map((constraint) => {
    const exact = findExact(constraint);

    if (exact) {
      return {
        ...constraint,
        availableShifts: [exact],
      };
    } else {
      const shiftsToFill = availableShifts.filter(
        (shift) =>
          ((isAfter(shift.start, constraint.ShiftStart) ||
            isEqual(shift.start, constraint.ShiftStart)) &&
            isBefore(shift.start, constraint.ShiftEnd)) ||
          (isAfter(shift.end, constraint.ShiftStart) &&
            (isBefore(shift.end, constraint.ShiftEnd) ||
              isEqual(shift.end, constraint.ShiftEnd))),
      );

      const singleLongerShift = shiftsToFill.find((shift) => {
        const shiftLength = differenceInHours(shift.end, shift.start);
        const constraintLength = differenceInHours(
          constraint.ShiftEnd,
          constraint.ShiftStart,
        );

        return (
          (isBefore(shift.start, constraint.ShiftStart) ||
            isEqual(shift.start, constraint.ShiftStart)) &&
          shiftLength > constraintLength
        );
      });

      return {
        ...constraint,
        availableShifts: singleLongerShift ? [singleLongerShift] : shiftsToFill,
      };
    }
  });

  const shiftEvent = availableShifts[0];

  let currentShiftId;
  currentShiftId = shiftEvent && shiftEvent.id;

  const [selectedShift, setSelectedShift] = useState(0);
  const [currentShiftEmployees, setCurrentShiftEmployees] = useState([]);
  const [selectedEmployeeToAdd, setSelectedEmployeeToAdd] = useState("");
  const [employeesToAdd, setEmployeesToAdd] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [addOptions, setAddOptions] = useState([]);

  const { loading, error, data } = useQuery(GET_ALL_USERS, {
    variables: {
      id: parseInt(officeId),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_ALL_USERS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [getRescheduleOptions] = useLazyQuery(ADD_AND_DROP_OPTIONS_FOR_SHIFT, {
    onCompleted(data) {
      console.log(data);
      const addOptions =
        data.rescheduleAdds.length > 0
          ? data.rescheduleAdds
              .map((add) => {
                const employeeIdsAndBenefits = add.rescheduleindividualSet.map(
                  (employee) => {
                    return {
                      employeeId: employee.employee.id,
                      benefit: employee.benefit,
                      cost: employee.cost,
                    };
                  },
                );
                return employeeIdsAndBenefits;
              })
              .flat()
          : [];
      setAddOptions(addOptions);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ADD_AND_DROP_OPTIONS_FOR_SHIFT lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchCreateOptions, { data: batchCreateOptionsData }] = useMutation(
    BATCH_CREATE_RESCHEDULE_OPTION,
    {
      onCompleted(d) {
        refetch();
      },
    },
    {
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage("Error Edit Shift." + error);
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_CREATE_RESCHEDULE_OPTION Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  useEffect(() => {
    if (exactShiftMatch) {
      getRescheduleOptions({
        variables: {
          shifts: [parseInt(exactShiftMatch.id)],
        },
      });
    }
  }, []);

  const [executeOption] = useMutation(EXECUTE_OPTION, {
    onCompleted(data) {
      console.log(data);
      refetch();
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on EXECUTE_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  function findExact(constraint) {
    availableShifts.find(
      (shift) =>
        isEqual(shift.start, constraint.ShiftStart) &&
        isEqual(shift.end, constraint.ShiftEnd),
    );
  }

  let allEmployees;
  if (!loading && !error) {
    allEmployees = data.offices[0].employeeSet;
  }

  const renderNewAdditions = () => {
    if (employeesToAdd) {
      return employeesToAdd.map((employee) => (
        <Grid
          item
          container
          key={employee.id}
          xs={5}
          alignItems="center"
          spacing={1}
          style={{ margin: 5, marginRight: 25 }}
        >
          <Grid item xs={2}></Grid>
          <ParticipantDelete
            key={employee.employeeId}
            participant={employee}
            newAddition={true}
            employeesToAdd={employeesToAdd}
            setEmployeesToAdd={setEmployeesToAdd}
            currentShiftEmployees={currentShiftEmployees}
            setCurrentShiftEmployees={setCurrentShiftEmployees}
            officeId={officeId}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setSnackBarMessage}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
          />
        </Grid>
      ));
    }
  };

  const renderEmployeeOptions = (employees) => {
    if (employees && employees.length > 0) {
      let skillsToFilterBy = [];
      formattedConstraintsWithShifts[0].skills.map((skill) =>
        skillsToFilterBy.push(skill.name),
      );
      let adds = [];
      let others = [];

      employees
        .filter((emp) =>
          emp.skills.find((e) => skillsToFilterBy.includes(e.name)),
        )
        .map((employee) => {
          const add = addOptions.find(
            (option) => option.employeeId === employee.id,
          );
          if (add) {
            adds = [...adds, { ...add, ...employee }];
          } else {
            others = [...others, employee];
          }
        });

      adds.sort((a, b) => b.benefit - a.benefit);

      adds = adds.map((employee) => {
        return (
          <MenuItem key={employee.id} value={employee.id}>
            <EventParticipant
              participant={employee}
              showAvatar={false}
              showJobTitle={true}
              singleLine={true}
              addOption={true}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
            {employee.benefit <= -1 && (
              <StarRateIcon className={classes.icon} />
            )}
            {employee.benefit < 1 && employee.benefit > -1 && (
              <>
                <StarRateIcon className={classes.icon} />
                <StarRateIcon className={classes.icon} />
              </>
            )}
            {employee.benefit >= 1 && (
              <>
                <StarRateIcon className={classes.icon} />
                <StarRateIcon className={classes.icon} />
                <StarRateIcon className={classes.icon} />
              </>
            )}
          </MenuItem>
        );
      });

      others = others.map((employee) => {
        return (
          <MenuItem key={employee.id} value={employee.id}>
            <EventParticipant
              participant={employee}
              showAvatar={false}
              showJobTitle={true}
              singleLine={true}
              addOption={true}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </MenuItem>
        );
      });
      return [...adds, ...others];
    } else {
      return (
        <MenuItem value={-1}>
          <Typography>No Eligible Employees</Typography>
        </MenuItem>
      );
    }
  };

  const handleAddEmployee = (e) => {
    setSelectedEmployeeToAdd(e.target.value);
  };

  const temporaryAdd = () => {
    if (selectedEmployeeToAdd) {
      const employeeToAdd = allEmployees.find(
        (user) => user.id === selectedEmployeeToAdd,
      );
      setEmployeesToAdd([...employeesToAdd, employeeToAdd]);
      setCurrentShiftEmployees([...currentShiftEmployees, employeeToAdd]);
      setSelectedEmployeeToAdd("");
      setErrorMessage("");
    } else {
      setErrorMessage("Must select employee to add");
    }
  };

  //ability to add note to the shift changes to go to notification
  const handleSubmit = () => {
    if (selectedEmployeeToAdd) {
      setErrorMessage("Click Add button to save selected employee to shift");
      return;
    } else {
      let optionsToExecute = [];

      if (employeesToAdd.length > 0) {
        let manualAdds = [];

        employeesToAdd.forEach((employee) => {
          //find if employee selected was an algorithm-generated option
          const existingOption = addOptions.find(
            (option) => option.employeeId === employee.id,
          );
          if (existingOption) {
            optionsToExecute.push(existingOption.optionId);
          } else {
            manualAdds.push({
              optionType: "ADD",
              status: "APPROVED",
              individuals: [
                {
                  employee: parseInt(employee.id),
                  cost: 0,
                  benefit: 0,
                  actions: [
                    {
                      actionType: "ADD",
                      shift: parseInt(currentShiftId),
                    },
                  ],
                },
              ],
              userGenerated: true,
            });
          }
        });
        // create a reschedule option with action type ADD to add employees to any shift
        if (manualAdds.length > 0) {
          batchCreateOptions({
            variables: { options: manualAdds },
          });
        }
      }

      //execute options that were selected
      if (optionsToExecute.length > 0) {
        optionsToExecute.forEach((optionId) => {
          executeOption({
            variables: {
              option: parseInt(optionId),
            },
          });
        });
      }
      setToast("Manager Edit Shift");
      setShowToast(true);
      closeDialog();
    }
  };

  const handleShiftChange = (e) => {
    const index = e.target.value;
    // const selected = includedShifts.find(shift => (
    //   shift.title === title
    // ));
    setSelectedShift(index);
    const shift = gapsToFill[index];
    const exact = findExact(shift);
    if (exact) {
      getRescheduleOptions({
        variables: {
          shifts: [parseInt(exact.id)],
        },
      });
    }
  };

  const eligibleToSave = Boolean(employeesToAdd.length > 0);

  return (
    <>
      <Grid item container>
        <Grid item container justifyContent="space-between" xs={12}>
          <Grid item>
            <Typography variant="h3">
              Add Employees to {procedureRequirement.name}
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" xs={2}>
            <Grid item>
              <IconButton color="secondary" onClick={closeDialog} size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container alignItems="flex-start">
          <Grid item container direction="column" spacing={2} xs={5}>
            <Grid item>
              <Typography variant="h6">
                {shiftEvent &&
                  format(new Date(shiftEvent.start), "dd MMM yyyy")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" style={{ marginBottom: 8 }}>
                Shift Time:
              </Typography>
              <Select
                variant="outlined"
                name="selectedShift"
                value={selectedShift}
                onChange={handleShiftChange}
                className={classes.select}
              >
                {gapsToFill.map((shift, index) => (
                  <MenuItem key={index} value={index}>
                    {format(shift.ShiftStart, "HH:mm")}
                    {" - "}
                    {format(shift.ShiftEnd, "HH:mm")}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <Typography variant="h6" style={{ marginBottom: 8 }}>
                Skills Needed:
              </Typography>
              <Typography>
                {gapsToFill[selectedShift].skills
                  .map((skill) => `${skill.name}: ${skill.value}`)
                  .join(", ")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            // style={{ marginTop: -100 }}
            xs={7}
          >
            <RescheduleOptionsKey type="add" />
          </Grid>
        </Grid>

        {/* {employeesToAdd.length > 0 &&
        <Grid item>
          <Typography variant="h6">Employees Scheduled:</Typography>
        </Grid>
      } */}
        <Grid
          item
          container
          justifyContent="space-between"
          alignContent="flex-start"
          wrap="wrap"
          style={{ marginTop: 16, height: 300, overflowY: "auto" }}
        >
          {/* {renderParticipants()} */}
          {renderNewAdditions()}
        </Grid>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item>
            <InputLabel id="select-employee-1-label">
              <Typography variant="h6">Add Employee:</Typography>
            </InputLabel>
          </Grid>
          <Grid item>
            <Select
              labelId="select-employee-1-label"
              id="select-employee-1"
              variant="outlined"
              value={selectedEmployeeToAdd}
              onChange={handleAddEmployee}
              className={classes.select}
            >
              {renderEmployeeOptions(allEmployees)}
            </Select>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={temporaryAdd}
              disabled={!selectedEmployeeToAdd}
            >
              Add
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              className={classes.button}
              onClick={() => setSelectedEmployeeToAdd("")}
              disabled={!selectedEmployeeToAdd}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        {errorMessage && (
          <Grid item style={{ marginTop: 16 }}>
            <Typography className={classes.error}>{errorMessage}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="flex-end"
        justifyContent="flex-end"
        spacing={1}
        style={{ marginTop: 15 }}
      >
        <Grid item>
          <Typography variant="body2" className={classes.helpfulTip}>
            Save to activate shift changes
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmit}
            disabled={!eligibleToSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default MangAddEmployees;
