import React, { useEffect, useState } from "react";
import "../../AllStyles/Calendar.css";
import {
  Typography,
  IconButton,
  Button,
  Paper,
  Grid,
  Tooltip,
  CircularProgress,
  List,
  ListItem,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  format,
  isAfter,
  isSameDay,
  isWeekend,
  add,
  getDay,
  sub,
  startOfDay,
  endOfDay,
} from "date-fns";
import WeekLabel from "./WeekLabel";
import Label from "./Label";
import { useQuery } from "@apollo/client";
import { userVar, selectedDateVar, eventsDatesVar } from "../../cache";
import Roles from "../../Roles/roles";
import {
  ScheduleComponent,
  Day,
  Week,
  Month,
  Agenda,
  Inject,
  ViewDirective,
  ViewsDirective,
  TimelineViews,
  ResourcesDirective,
  ResourceDirective,
  Print,
  ICalendarExport,
  ExcelExport,
  TimelineMonth,
} from "@syncfusion/ej2-react-schedule";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { GET_EMPLOYEE_SKILL } from "../../api/gqlQueries";
import WarningIcon from "@mui/icons-material/Warning";
import AlertOutline from "mdi-material-ui/AlertOutline";
import CloseIcon from "@mui/icons-material/Close";
import ShiftSlack from "../slacks/ShiftSlack";
import EditIcon from "@mui/icons-material/Edit";
import { compareAsc } from "date-fns";
import { useField } from "formik";
import ProcedureColors from "../../helpers/ProcedureColors.json";
import {
  PagerComponent,
  PagerDropDown,
  Pager,
  Page,
} from "@syncfusion/ej2-react-grids";
import { DataManager, JsonAdaptor } from "@syncfusion/ej2-data";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  showSpinner,
  hideSpinner,
  createSpinner,
} from "@syncfusion/ej2-popups";

const useStyles = makeStyles(() => ({
  today: {
    //background: "#F15A25",
    borderRadius: 30,
    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 1,
    paddingBottom: 1,
  },
  tooltip: {
    minWidth: 600,
    backgroundColor: "rgba(57, 57, 60, 0.95)",
  },
  weekend: {
    backgroundColor: "#D3D3D3",
  },
}));

const Scheduler = React.forwardRef((props, ref) => {
  const {
    events,
    draftStart,
    draftEnd,
    openStart,
    openEnd,
    employeeResources,
    missionResources,
    officeResources,
    view,
    setView,
    toggleEditRequest,
    toggleCallInForm,
    toggleShiftSwitchForm,
    issueDates,
    slackIssues,
    handleEditClick,
    handleEditProcedureReq,
    setShiftToChange,
    allowCallIns,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
    getFutureShifts,
    itemsPerPageVal,
    handleChangeView,
    officeProcedures,
    addDatestoTheListOfDates,
    getShiftsForAnyDateRange,
  } = props;

  const classes = useStyles();
  const eventsDates = eventsDatesVar();
  const user = userVar();
  let flag = false;
  const selectedDate = selectedDateVar();
  const managerAccess =
    user.role === Roles.MANAGER ||
    user.role === Roles.SCHEDULER ||
    user.role === Roles.ADMIN;

  employeeResources.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  function clickMissionTimeline() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_4");
      button && button.click();
    }, 300);
  }
  function clickEmployeeTimeline() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_5");
      button && button.click();
    }, 300);
  }

  function clickMonthView() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_6");
      button && button.click();
    }, 300);
  }

  function clickDayView() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_7");
      button && button.click();
    }, 300);
  }

  const [currentView, SetCurrentView] = useState("TimelineDay");

  useEffect(() => {
    handleChangeView(currentView);
  }, [currentView]);

  function changeView(props) {
    if (props.action === "view") {
      SetCurrentView(props.currentView);
      handleChangeView(props.currentView);
      if (props.currentView === "TimelineDay") {
        clickMissionTimeline();
      } else if (props.currentView === "TimelineWeek") {
        clickEmployeeTimeline();
      } else if (props.currentView === "Day") {
        clickDayView();
      } else if (props.currentView === "Month") {
        clickMonthView();
      }
    } else if (props.action === "date") {
      selectedDateVar(props.currentDate);
      const oneweekfromnow = add(new Date(props.currentDate), { weeks: 1 });
      const currentDate = sub(new Date(props.currentDate), { days: 1 });

      let eventforViewEnd =
        events &&
        events.length > 0 &&
        events.find(
          (e) =>
            format(new Date(e.start), "yyyy-MM-dd") ===
            format(new Date(oneweekfromnow), "yyyy-MM-dd"),
        );
      let eventforViewStart =
        events &&
        events.length > 0 &&
        events.find(
          (e) =>
            format(new Date(e.start), "yyyy-MM-dd") ===
            format(new Date(props.currentDate), "yyyy-MM-dd"),
        );
      if (!eventforViewEnd || !eventforViewStart) {
        let variables = {
          rangeStart: currentDate.toISOString(),
          rangeEnd: oneweekfromnow.toISOString(),
          office: parseInt(user.office.id),
        };
        getFutureShifts({ variables: variables });
      }
    }
  }

  const monthEventTemplate = (props) => {
    let procedureColor =
      window.COLORCODE_PROCEDURE_EVENTS === true &&
      ProcedureColors.procedureTypes.find(
        (e) => props.missionId && e.name === props.missionId,
      )
        ? ProcedureColors.procedureTypes.find(
            (e) => props.missionId && e.name === props.missionId,
          ).color
        : "none";
    return (
      <div className="templatewrap">
        <Label
          data={props}
          issueDates={issueDates}
          procedureColor={procedureColor}
        />
      </div>
    );
  };

  const weekMissionTemplate = (props) => {
    let skills =
      props.skills && props.skills.length > 0 && props.skills.map((e) => e);
    let procedureColor =
      window.COLORCODE_PROCEDURE_EVENTS === true &&
      ProcedureColors.procedureTypes.find(
        (e) => props.missionId && e.name === props.missionId,
      )
        ? ProcedureColors.procedureTypes.find(
            (e) => props.missionId && e.name === props.missionId,
          ).color
        : "none";
    return (
      <div className="templatewrap">
        <WeekLabel
          data={props}
          skills={skills}
          procedureColor={procedureColor}
        />
      </div>
    );
  };

  function callShiftAssignmentsForSelectedView() {
    let currentViewDates = document
      .querySelector(".e-schedule")
      .ej2_instances[0].getCurrentViewDates();
    const firstDayOfCurrentView = startOfDay(currentViewDates[0]);
    const lastDayOfCurrentView = endOfDay(
      currentViewDates[currentViewDates.length - 1],
    );
    if (
      !eventsDates.find(
        (e) =>
          e.rangeStart === format(firstDayOfCurrentView, "yyyy-MM-dd") &&
          e.rangeEnd === format(lastDayOfCurrentView, "yyyy-MM-dd"),
      )
    ) {
      let variables =
        window.DISPLAY_ALL_OFFICES_EVENTS === true ||
        window.DISPLAY_ALL_OFFICES_EVENTS === "true"
          ? {
              rangeStart: firstDayOfCurrentView.toISOString(),
              rangeEnd: lastDayOfCurrentView.toISOString(),
            }
          : {
              rangeStart: firstDayOfCurrentView.toISOString(),
              rangeEnd: lastDayOfCurrentView.toISOString(),
              office: parseInt(user.office.id),
            };
      getShiftsForAnyDateRange({ variables: variables });
      addDatestoTheListOfDates(firstDayOfCurrentView, lastDayOfCurrentView);
    }
  }

  const onActionBegin = (args) => {
    const { event } = args;
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.showSpinner();
  };

  const actionComplete = (args) => {
    if (
      args.requestType === "dateNavigate" ||
      args.requestType === "viewNavigate"
    ) {
      callShiftAssignmentsForSelectedView();
    }
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.hideSpinner();
  };

  function onSlide(args) {
    let page = args.currentPage;
    pageChange(page);
  }

  function pageChange(page) {
    let prevPage = page - 1;
    let startIndexOfCurrentPage = parseInt(prevPage) * 10 + 1;
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    let dm1 = missionResources;
    let dm2 = employeeResources;
    let curMisResData = dm1.slice(
      parseInt(startIndexOfCurrentPage) - 1,
      parseInt(startIndexOfCurrentPage) + 10,
    );
    let CurEmpResData = dm2.slice(
      parseInt(startIndexOfCurrentPage) - 1,
      parseInt(startIndexOfCurrentPage) + 10,
    );
    scheduleObj.resources[0].dataSource = curMisResData;
    scheduleObj.resources[1].dataSource = CurEmpResData;
    scheduleObj.dataBind();
  }

  const weekEmployeeTemplate = (props) => {
    return (
      <div className="template-wrap">
        <Typography variant="body1">
          {props.type && props.type != ""
            ? props.type.name.toUpperCase()
            : props.eventTitle}
        </Typography>
      </div>
    );
  };

  const dayViewTemplate = (props) => {
    if (props.missionId === "GHOC") {
      return (
        <div className="templatewrap" style={{ backgroundColor: props.color }}>
          {/* <Typography style={{ fontWeight: "bold" }}>
            {props.missionId}
          </Typography> */}
          <Typography>{props.eventTitle}</Typography>
          <br />
          {props.participants.length > 0 &&
            props.participants.map((participant) => (
              <React.Fragment key={participant.id}>
                <Typography>
                  {participant.firstName} {participant.lastName}
                </Typography>
                <Typography variant="body2">
                  {participant.skillSet &&
                    participant.skillSet.map((e) => e.name).join(", ")}
                </Typography>
                <br />
              </React.Fragment>
            ))}
        </div>
      );
    } else if (props.participants && props.participants.length > 0) {
      return (
        <div className="templatewrap">
          <Typography style={{ fontWeight: "bold" }}>
            {props.missionId}
          </Typography>
          <Typography>{props.eventTitle}</Typography>
          <br />
          {props.participants.map((participant) => (
            <React.Fragment key={participant.id}>
              <Typography>
                {participant.firstName} {participant.lastName}
              </Typography>
              <Typography variant="body2">
                {participant.skillSet &&
                  participant.skillSet.length > 0 &&
                  participant.skillSet.map((e) => e.name).join(", ")}
              </Typography>
              <br />
            </React.Fragment>
          ))}
        </div>
      );
    } else if (props.type === "timeOff" || props.type === "softRequest") {
      return (
        <div className="templatewrap">
          <Typography style={{ fontWeight: "bold" }}>
            {props.eventTitle}
          </Typography>
          {props.comment ? <Typography>{props.comment}</Typography> : null}
          <br />
        </div>
      );
    } else {
      const startTime = format(props.start, "HH:mm");
      const endTime = format(props.end, "HH:mm");
      const title = `${startTime} - ${endTime}`;
      return (
        <div className="templatewrap">
          <Typography style={{ fontWeight: "bold" }}>
            {props.missionId}
          </Typography>
          <Typography>{title}</Typography>
          <br />
          <Typography>{props.eventTitle}</Typography>
        </div>
      );
    }
  };

  const missionresourceHeaderTemplate = (props) => {
    return (
      <div className="template-wrap">
        <Typography style={{ fontSize: 15, fontWeight: "bold" }}>
          {props.resourceData.name}
        </Typography>
      </div>
    );
  };

  const empresourceHeaderTemplate = (props) => {
    let displayAlerts =
      window.DISPLAY_NO_OF_ALERTS === true ||
      window.DISPLAY_NO_OF_ALERTS === "true"
        ? true
        : false;
    let uniqueProcedures = [];
    let noOfAlerts =
      officeProcedures &&
      officeProcedures.length > 0 &&
      officeProcedures.find((o) => o.name === props.resourceData.office);
    noOfAlerts &&
      noOfAlerts.procedurerequirementSet.map(
        (e) =>
          !uniqueProcedures.includes(e.procedureType.name) &&
          uniqueProcedures.push(e.procedureType.name),
      );
    return (
      <div className="template-wrap">
        <Grid container spacing={1}>
          <Grid item xs={5} style={{ textAlign: "left" }}>
            <Typography variant="h5">{props.resourceData.office}</Typography>
            {displayAlerts ? (
              <Typography style={{ fontSize: 10 }}>
                Alerts: {uniqueProcedures.length}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6} style={{ textAlign: "left" }}>
            <Typography variant="h5">{props.resourceData.name}</Typography>
            <Typography style={{ fontSize: 10 }}>
              {props.resourceData.skills.map((e) => e).join(",")}
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </div>
    );
  };

  const cellHeaderTemplate = (props) => {
    const today = isSameDay(new Date(), props.date);
    const formatted = format(props.date, "MM/dd/yyyy");
    const slackIssueDate = issueDates.includes(formatted);
    let issuesForDate;
    let under;
    let over;
    if (slackIssueDate) {
      issuesForDate = slackIssues.filter((slack) => {
        const startDate = new Date(slack.interval.start);
        return isSameDay(startDate, props.date);
      });
      under = issuesForDate.find(
        (issue) => issue.lower > issue.assigned || issue.slack < 0,
      );
      over = issuesForDate.find(
        (issue) => issue.upper < issue.assigned || issue.slack > 0,
      );
    }

    const draft =
      (new Date(props.date) > new Date(draftStart) ||
        isSameDay(new Date(props.date), new Date(draftStart))) &&
      (new Date(props.date) < new Date(draftEnd) ||
        isSameDay(new Date(props.date), new Date(draftEnd)));

    const open =
      (new Date(props.date) > new Date(openStart) ||
        isSameDay(new Date(props.date), new Date(openStart))) &&
      (new Date(props.date) < new Date(openEnd) ||
        isSameDay(new Date(props.date), new Date(openEnd)));

    const weekend = isWeekend(new Date(props.date));
    return (
      <Grid container justifyContent="space-between">
        <Grid item xs={10}>
          <Typography
            variant="body2"
            className={today ? classes.today : weekend ? classes.weekend : null}
          >
            {format(props.date, "dd,E")}
          </Typography>
        </Grid>
        {view === "TimelineDay" && (
          <Grid item xs={2}>
            {slackIssueDate && (
              <Tooltip
                title={
                  <ShiftSlack startDate={props.date} slacks={issuesForDate} />
                }
                placement="left"
                arrow
                enterDelay={500}
                enterNextDelay={500}
                classes={{ tooltip: classes.tooltip }}
              >
                <span>
                  {under && (
                    <WarningIcon
                      style={{
                        fontSize: 16,
                        marginBottom: -3,
                        color: "black",
                      }}
                    />
                  )}
                  {over && !under && (
                    <AlertOutline
                      style={{
                        fontSize: 16,
                        marginBottom: -3,
                        color: "black",
                      }}
                    />
                  )}
                </span>
              </Tooltip>
            )}
          </Grid>
        )}
        <Grid item xs={12} justifyContent="center">
          {draft && (
            <Typography variant="body2" style={{ color: "#8CADE1" }}>
              Draft
            </Typography>
          )}
          {open && (
            <Typography variant="body2" style={{ color: "#8CADE1" }}>
              Open
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  const closeQuickInfo = () => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.closeQuickInfoPopup();
  };

  const quickInfoHeader = (props) => {
    const personal = props.employeeIds?.includes(user.id);
    return (
      <div>
        <Grid container justifyContent="flex-end">
          <Grid item style={{ marginTop: 8, marginRight: 8 }}>
            {managerAccess && !props.type ? (
              <Tooltip title="Add/Remove Employees from Shift">
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => handleEditClick(props)}
                >
                  <EditIcon style={{ color: "#000", fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            ) : null}
            <IconButton
              aria-label="close"
              color="secondary"
              size="small"
              onClick={closeQuickInfo}
            >
              <CloseIcon style={{ color: "#000", fontSize: 18 }} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          {props.type === "timeOff" ? (
            <Grid item xs={12}>
              <Typography
                variant="h4"
                style={{ marginLeft: 8, marginBottom: 8 }}
              >
                {props.eventTitle}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography variant="h4" style={{ marginLeft: 8 }}>
                {props.eventTitle}
              </Typography>
            </Grid>
          )}
          {props.type !== "timeOff" ? (
            props.tasks && props.tasks.length === 0 ? null : (
              <Grid item container xs={12} justifyContent="flex-end">
                {allowCallIns && (
                  <Grid item>
                    <Button
                      color="primary"
                      disabled={!personal}
                      onClick={() => {
                        setShiftToChange(props.eventId);
                        toggleCallInForm();
                      }}
                    >
                      Call In
                    </Button>
                  </Grid>
                )}
                {props.procedureName &&
                  props.procedureName != "" &&
                  new Date(props.start) >= new Date() && (
                    <Grid item>
                      <Button
                        color="primary"
                        onClick={() => {
                          handleEditProcedureReq(props);
                        }}
                        style={{ marginLeft: 4, marginRight: 8 }}
                      >
                        Edit Procedure Requirements
                      </Button>
                    </Grid>
                  )}

                <Grid item>
                  <Button
                    color="primary"
                    disabled={managerAccess ? false : !personal}
                    onClick={() => {
                      setShiftToChange(props.eventId);
                      toggleShiftSwitchForm();
                    }}
                    style={{ marginLeft: 4, marginRight: 8 }}
                  >
                    Switch Shifts
                  </Button>
                </Grid>
              </Grid>
            )
          ) : null}
        </Grid>
      </div>
    );
  };

  const handleCellClick = (args) => {
    const { startTime } = args;
    selectedDateVar(startTime);
    // SetDateSelected(startTime);
    // toggleTimeOffForm();
    // if (isAfter(startTime, new Date(scheduleEndDate)) &&
    //   !offRequestDates.includes(format(startTime, 'MM/dd/yyyy'))
    // ) {
    //   setAnchorEl(element);
    // }
  };

  const handleEventClick = (args) => {
    const { event } = args;
    event && selectedDateVar(event.start);
    if (
      (event.type || event.category) &&
      (event.type === "softRequest" || event.category === "personal")
    ) {
      toggleEditRequest(event.type, event.eventId);
    }
  };

  const onPopupOpen = (args) => {
    const { data } = args;

    if (args.element.className.includes("e-more-popup-wrapper")) {
      args.cancel = false;
    } else if (
      !data.eventId ||
      data.type === "softRequest" ||
      data.category === "personal"
    ) {
      args.cancel = true;
    }
  };

  const onDragStop = (args) => {
    const { event } = args;
  };

  const onRenderCell = (args) => {
    if (
      args.elementType === "workCells" ||
      args.elementType === "resourceHeader"
    ) {
      if (args.groupIndex % 2 == 0) {
        args.element.style.backgroundColor = "#F5F5F5";
      }
    }
  };

  const onEventRendered = (args) => {
    args.element.style.backgroundColor = args.data.color;
  };

  let missionViewInterval = parseInt(
    window.DISPLAY_INTERVAL_FOR_MISSION_TIMELINE,
  );
  let empviewInterval = parseInt(window.DISPLAY_INTERVAL_FOR_EMPLOYEE_TIMELINE);
  let displayMonthView =
    window.DISPLAY_MONTH_VIEW === true || window.DISPLAY_MONTH_VIEW === "true";
  let displayDayView =
    window.DISPLAY_DAY_VIEW === true || window.DISPLAY_DAY_VIEW === "true";

  let dataManager = new DataManager({
    json: events,
    adaptor: new JsonAdaptor(),
  });

  return (
    <div id="schedule">
      <Paper>
        <ScheduleComponent
          data-testid="missionSchedulerComponent"
          selectedDate={selectedDate}
          currentView={currentView}
          height="600"
          delayUpdate={true}
          rowAutoHeight={true}
          showWeekend={true}
          actionComplete={actionComplete}
          eventSettings={{
            dataSource: dataManager,
            fields: {
              id: "id",
              subject: { name: "eventTitle" },
              startTime: { name: "start" },
              endTime: { name: "end" },
              isAllDay: { name: "isAllDay" },
              description: { name: "comment" },
            },
          }}
          eventRendered={onEventRendered}
          cssClass="missionCalendar print excel-export calendar-component"
          ref={ref}
          allowDragAndDrop={false}
          workHours={{ start: "00:00", end: "23:59" }}
          showTimeIndicator={false}
          navigating={changeView}
          enableTooltip={true}
          cellClick={handleCellClick}
          eventClick={handleEventClick}
          popupOpen={onPopupOpen}
          actionBegin={onActionBegin}
          dragStop={onDragStop}
          dateHeaderTemplate={cellHeaderTemplate}
          quickInfoTemplates={{
            header: quickInfoHeader,
          }}
          renderCell={onRenderCell}
        >
          <ViewsDirective>
            <ViewDirective
              option="TimelineDay"
              displayName="Mission Timeline"
              group={{ resources: ["Missions"] }}
              timeScale={
                window.DISPLAY_TIME_SCALE_ON_CALENDAR === "true" ||
                window.DISPLAY_TIME_SCALE_ON_CALENDAR === true
                  ? { enable: true, interval: 240 }
                  : { enable: false }
              }
              eventTemplate={weekMissionTemplate}
              interval={missionViewInterval}
              resourceHeaderTemplate={missionresourceHeaderTemplate}
            />
            <ViewDirective
              option="TimelineWeek"
              displayName="Employees Timeline"
              // group={{ resources: ['Offices', 'Employees'] }}
              group={{ resources: ["Employees"] }}
              timeScale={{ enable: false }}
              eventTemplate={weekEmployeeTemplate}
              interval={empviewInterval}
              //cellHeaderTemplate={monthCellHeaderTemplate}
              resourceHeaderTemplate={empresourceHeaderTemplate}
            />
            {displayMonthView === true && (
              <ViewDirective
                option="Month"
                group={{ byDate: true, allowGroupEdit: true }}
                eventTemplate={monthEventTemplate}
                cellHeaderTemplate={cellHeaderTemplate}
              />
            )}
            {displayDayView === true && (
              <ViewDirective option="Day" eventTemplate={dayViewTemplate} />
            )}
            <ViewDirective option="Print" />
          </ViewsDirective>
          <ResourcesDirective>
            <ResourceDirective
              field="missionId"
              title="mission"
              name="Missions"
              textField="name"
              idField="id"
              colorField="color"
              allowMultiple={true}
              dataSource={missionResources}
            ></ResourceDirective>
            <ResourceDirective
              field="employeeIds"
              title="employee"
              name="Employees"
              textField="name"
              idField="id"
              colorField="color"
              allowMultiple={true}
              dataSource={employeeResources}
            ></ResourceDirective>
            <ResourceDirective
              field="office"
              title="office"
              name="Offices"
              colorField="color"
              dataSource={officeResources}
              textField="name"
              idField="id"
            ></ResourceDirective>
          </ResourcesDirective>
          <Inject
            services={[
              Day,
              Week,
              TimelineViews,
              TimelineMonth,
              Month,
              Agenda,
              ICalendarExport,
              ExcelExport,
              Print,
            ]}
          />
        </ScheduleComponent>
        {/* <PagerComponent
          totalRecordsCount={(parseInt(employeeResources.length))}
          pageSize={parseInt(pageSize)}
          pageSizes={true}
          dropDownChanged={pagerDropdownChanged}
          //dropDownChanged={(e) => SetPageSize(e.target.value)}
          click={onSlide}>
          <Inject services={[Pager, PagerDropDown, Page,pageSizes]} />
        </PagerComponent> */}
      </Paper>
    </div>
  );
});

function areEqual(prevProps, nextProps) {
  return (
    prevProps.events.length === nextProps.events.length &&
    prevProps.employeeResources.length === nextProps.employeeResources.length &&
    prevProps.missionResources.length === nextProps.missionResources.length
  );
}

export default React.memo(Scheduler, areEqual);
