import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  CssBaseline,
  InputAdornment,
  IconButton,
  Paper,
  InputLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorIcon from "@mui/icons-material/Error";
import { isLoggedInVar, userVar, CognitoUserVar } from "../../cache";
import { useHistory } from "react-router-dom";
import { updatePassword, getCurrentUser } from "aws-amplify/auth";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import PasswordStrengthIndicator from "./PasswordStrengthIndicator";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "160px",
    display: "flex",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "450px",
    margin: "auto",
    paddingTop: "50px",
    paddingBottom: "50px",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: "40px",
    height: "40px",
    marginTop: "30px",
    marginBottom: "20px",
  },
  inputWidth: {
    width: "100%",
  },
}));

const validationSchema = Yup.object({
  oldPassword: Yup.string().required("Required"),
  newPassword: Yup.string()
    .required("Required")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

const UpdatePassword = ({ handleSubmit }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <main variant="body">
        <Grid className={classes.root}>
          <CssBaseline />
          <Grid className={classes.paper} component={Paper}>
            <Formik
              initialValues={{
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values.oldPassword, values.newPassword);
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column" spacing={3}>
                    <Grid item align="center">
                      <Typography
                        variant="h3"
                        data-testid="update-password-title"
                      >
                        Change Password
                      </Typography>
                    </Grid>
                    <Grid item>
                      <InputLabel
                        htmlFor="update-oldpassword-textfield"
                        data-testid="update-password-oldpassword"
                      >
                        Current Password*
                      </InputLabel>

                      <TextField
                        id="update-oldpassword-textfield"
                        variant="outlined"
                        data-testid="update-oldpassword-textfield"
                        type={showPassword ? "text" : "password"}
                        name="oldPassword"
                        onChange={handleChange}
                        value={values.oldPassword}
                        placeholder="Old Password"
                        className={classes.inputWidth}
                        error={!errors.oldPassword === null}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                size="large"
                              >
                                {errors.oldPassword != null ? (
                                  <ErrorIcon color="primary" />
                                ) : showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          "data-testid": "old-password-input", // Add data-testid here for the input element
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <InputLabel
                        htmlFor="update-newpassword-textfield"
                        data-testid="update-password-newpassword"
                      >
                        New Password*
                      </InputLabel>
                      <TextField
                        id="update-newpassword-textfield"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        data-testid="update-newpassword-textfield"
                        name="newPassword"
                        onChange={handleChange}
                        value={values.newPassword}
                        placeholder="New Password"
                        className={classes.inputWidth}
                        error={!errors.newPassword === null}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                size="large"
                              >
                                {errors.newPassword != null ? (
                                  <ErrorIcon color="primary" />
                                ) : showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          "data-testid": "new-password-input", // Add data-testid here for the input element
                        }}
                      />
                      <Grid>
                        <PasswordStrengthIndicator
                          password={values.newPassword}
                        />
                      </Grid>
                    </Grid>

                    <Grid item>
                      <InputLabel
                        htmlFor="update-confirmpassword-textfield"
                        data-testid="update-password-confirmpassword"
                      >
                        Confirm Password*
                      </InputLabel>
                      <TextField
                        id="update-confirmpassword-textfield"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        data-testid="update-confirmpassword-textfield"
                        name="confirmPassword"
                        onChange={handleChange}
                        value={values.confirmPassword}
                        placeholder="Confirm Password"
                        className={classes.inputWidth}
                        error={!errors.confirmPassword === null}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                size="large"
                              >
                                {errors.confirmPassword != null ? (
                                  <ErrorIcon color="primary" />
                                ) : showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          "data-testid": "confirm-password-input", // Add data-testid here for the input element
                        }}
                      />
                      <Typography color="primary" variant="subtitle2">
                        {errors.confirmPassword}
                      </Typography>
                    </Grid>

                    <Grid item align="center" mt={8}>
                      <Button
                        data-testid="update-password-submit"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Update Password
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </main>
    </>
  );
};

export default UpdatePassword;
