import React from "react";
import {
  Grid,
  AccordionDetails,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

const RadioButtonFilter = ({
  filterTypes,
  filterString,
  checkBox,
  handleRadioToggle,
}) => {
  return (
    <>
      <Grid item container component={AccordionDetails} direction="column">
        <Grid
          item
          component={RadioGroup}
          style={{ marginTop: -5, marginBottom: -5 }}
        >
          {filterTypes.map((filterType, index) => (
            <FormControlLabel
              label={filterType.name}
              control={
                <Radio
                  key={index}
                  checked={
                    checkBox.find((obj) => obj.name === filterType.name)
                      .checked === true
                  }
                  onChange={(e) => {
                    filterType.checked = e.target.checked;
                    handleRadioToggle(filterType, filterString);
                  }}
                  style={{ marginTop: -5, marginBottom: -5, marginLeft: 10 }}
                />
              }
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default RadioButtonFilter;
