import { useCallback, useEffect, useRef, useState } from "react";

function dispatchStorageEvent(key, newValue) {
  window.dispatchEvent(new StorageEvent("storage", { key, newValue }));
}

export const setSessionStorageItem = (key, value) => {
  const stringifiedValue = JSON.stringify(value);
  window.sessionStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
};

const removeSessionStorageItem = (key) => {
  window.sessionStorage.removeItem(key);
  dispatchStorageEvent(key, null);
};

export const getSessionStorageItem = (key, defaultValue) => {
  if (typeof window !== undefined) {
    const saved = JSON.parse(sessionStorage.getItem(key));
    return saved || defaultValue;
  }
};

const useSessionStorageSubscribe = (callback) => {
  window.addEventListener("storage", callback);
  return () => window.removeEventListener("storage", callback);
};

const getSessionStorageServerSnapshot = () => {
  throw Error("useSessionStorage is a client-only hook");
};

export function useStatelessSessionStorage(key, initialValue) {
  /**
   * Synchronizes a value that is not needed for rendering with session storage.
   *
   * A hook that tracks a value that does not cause rerenders (stateless), a function to
   * update the value, and a mechanism to synchronise the value with session storage.
   *
   * @param {String} key          Key to look up the value in session storage.
   * @param {String} initialValue Value to use if the session storage is empty.
   *
   * @returns {[Any, Object]} Current value and a function to set/update the current value.
   */
  const val = useRef(getSessionStorageItem(key, initialValue));

  // const getSnapshot = () => getSessionStorageItem(key);

  // const store = React.useSyncExternalStore(
  //   useSessionStorageSubscribe,
  //   getSnapshot,
  //   getSessionStorageServerSnapshot
  // );

  const setState = (v) => {
    try {
      const nextState =
        typeof v === "function"
          ? v(getSessionStorageItem(key, initialValue))
          : v;

      if (nextState === undefined || nextState === null) {
        removeSessionStorageItem(key);
      } else {
        setSessionStorageItem(key, nextState);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    if (
      getSessionStorageItem(key) === null &&
      typeof initialValue !== "undefined"
    ) {
      setSessionStorageItem(key, initialValue);
    }
  }, [key, initialValue]);

  return [val.current, setState];
}

export function useStatefulSessionStorage(key, initialValue) {
  /**
   * Synchronizes a value that is needed for rendering with session storage.
   *
   * A hook that tracks a value that does causes rerenders (useState), a function to
   * update the value, and a mechanism to synchronise the value with session storage.
   *
   * @param {String} key          Key to look up the value in session storage.
   * @param {String} initialValue Value to use if the session storage is empty.
   *
   * @returns {[Any, Object]} Current value and a function to set/update the current value.
   */
  const [val, setValue] = useState(() =>
    getSessionStorageItem(key, initialValue),
  );

  useEffect(() => {
    setSessionStorageItem(key, val);
  }, [key, val]);

  return [val, setValue];
}

const setLocalStorageItem = (key, value) => {
  const stringifiedValue = JSON.stringify(value);
  window.localStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
};

const removeLocalStorageItem = (key, value) => {
  window.localStorage.removeItem(key);
  dispatchStorageEvent(key, null);
};

export const getLocalStorageItem = (key, defaultValue) => {
  if (typeof window !== "undefined") {
    const saved = JSON.parse(localStorage.getItem(key));
    return saved || defaultValue;
  }
};

export function useStatelessLocalStorage(key, initialValue) {
  /**
   * Synchronizes a value that is not needed for rendering with local storage.
   *
   * A hook that tracks a value that does not cause rerenders (stateless), a function to
   * update the value, and a mechanism to synchronise the value with local storage.
   *
   * @param {String} key          Key to look up the value in local storage.
   * @param {String} initialValue Value to use if the local storage is empty.
   *
   * @returns {[Any, Object]} Current value and a function to set/update the current value.
   */
  const val = useRef(getLocalStorageItem(key, initialValue));

  // const getSnapshot = () => getLocalStorageItem(key);

  // const store = React.useSyncExternalStore(
  //   useLocalStorageSubscribe,
  //   getSnapshot,
  //   getLocalStorageServerSnapshot
  // );

  const setState = (v) => {
    try {
      const nextState =
        typeof v === "function" ? v(getLocalStorageItem(key, initialValue)) : v;

      if (nextState === undefined || nextState === null) {
        removeLocalStorageItem(key);
      } else {
        setLocalStorageItem(key, nextState);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    if (
      getLocalStorageItem(key) === null &&
      typeof initialValue !== "undefined"
    ) {
      setLocalStorageItem(key, initialValue);
    }
  }, [key, initialValue]);

  return [val.current, setState];
}

export function useStatefulLocalStorage(key, initialValue) {
  /**
   * Synchronizes a value that is needed for rendering with local storage.
   *
   * A hook that tracks a value that causes rerenders (useState), a function to
   * update the value, and a mechanism to synchronise the value with local storage.
   *
   * @param {String} key          Key to look up the value in local storage.
   * @param {String} initialValue Value to use if the local storage is empty.
   *
   * @returns {[Any, Object]} Current value and a function to set/update the current value.
   */
  const [val, setValue] = useState(() =>
    getLocalStorageItem(key, initialValue),
  );

  useEffect(() => {
    setLocalStorageItem(key, val);
  }, [key, val]);

  return [val, setValue];
}
