export function filterDuplicates(events) {
  /**
   * Takes the array of calendar event objects, and returns an array where each event can occur only once
   *
   * @param {Array} events array of calendar objects, all objects must have a `id` attribute
   *
   * @return {Array} eventsSet array of calendar objects that does not have duplicate events
   *
   */
  const eventsLookup = {};
  const eventsSet = [];
  for (var event of events) {
    if (!eventsLookup[event.id]) {
      eventsLookup[event.id] = true;
      eventsSet.push(event);
    }
  }
  return eventsSet;
}
