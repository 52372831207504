import React, { useState, forwardRef } from "react";
import {
  Typography,
  Grid,
  IconButton,
  FormControlLabel,
  Switch,
  Button,
  InputLabel,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, gql } from "@apollo/client";
import { CREATE_SOFT_REQUEST } from "../../api/gqlQueries";
import { userVar, selectedDateVar } from "../../cache";
import { updateSoftRequest } from "./UpdateEvents";
import { toIsoDate, getLocalIsoOffset } from "../../helpers/formatTime";
import { DateTimePaginator } from "../../helpers/DateTimePaginator";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}
const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 138,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.primary.main,
  },
}));

/* Soft time off requests are added prior to generating a schedule to indicate that a particular date is not preferred to work.
This component is a soft time off form.
A user can add priority to a soft request
*/

const SoftTimeOff = forwardRef((props, ref) => {
  const classes = useStyles();

  const {
    closeDialog,
    scheduleEndDate,
    notifyDevelopers,
    environment,
    selectedOffice,
  } = props;

  const user = userVar();
  const date = selectedDateVar();

  const minimumDate = new Date(scheduleEndDate);
  const [priorityChecked, setPriorityChecked] = useState(false);
  const [dateForRequest, setDateForRequest] = useState(
    date < minimumDate ? minimumDate : date,
  );
  const [error, setError] = useState("");

  const [updatePreference] = useMutation(CREATE_SOFT_REQUEST, {
    update(cache, { data: { createOffPreference } }) {
      if (createOffPreference.result.__typename !== "OffPreferenceNode") {
        return;
      }
      const paginator = new DateTimePaginator(1); // TODO: using in multiple spots need the same constructor
      const element = createOffPreference.result;
      const left = element.date + "T00:00:00";
      const start = new Date(left + getLocalIsoOffset());
      const dates = paginator.getQueryDateRanges(start, start);
      const variables = {
        employeeId: user.id,
        pageEndDate: toIsoDate(dates[0][1]),
        pageStartDate: toIsoDate(dates[0][0]),
      };
      const strVariables = JSON.stringify(variables);
      cache.modify({
        fields: {
          offPreferences: (existing, { storeFieldName }) => {
            if (storeFieldName.includes(strVariables)) {
              const newSoftTimeOffRef = cache.writeFragment({
                data: element,
                fragment: gql`
                  fragment NewOffPreference on OffPreferenceNode {
                    id
                    date
                    value
                    employee {
                      id
                    }
                  }
                `,
              });
              return [...existing, newSoftTimeOffRef];
            } else {
              return existing;
            }
          },
        },
      });
    },
    onCompleted(d) {
      const typename = d.createOffPreference.result.__typename;
      const result = d.createOffPreference.result;
      if (typename === "OffPreferenceNode") {
        // we need to have updateSoftRequest when triggered from schedule but not from requests
        if (ref) {
          updateSoftRequest(ref.current, result, null, selectedOffice.name);
        }
        toastShow("Your day off preference has been submitted.", "Success");
        closeDialog();
      } else if (typename === "ModelValidationError") {
        toastShow(result.message, "Error");
      } else {
        toastShow(result.message, "Error");
        closeDialog();
      }
    },
    onError(error) {
      toastShow(
        "Unable to create the request. If the issue persists, please create a help ticket.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_SOFT_REQUEST Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleSubmit = () => {
    // the soft request is a preference. Like all other preferences we give a value to the soft request. If priority flag is on the value is 10.
    const newRequest = {
      employee: parseInt(user.id),
      date: toIsoDate(dateForRequest),
      value: priorityChecked ? 10.0 : 5.0,
    };
    updatePreference({ variables: { ...newRequest } });
  };

  const handleUpdateDate = (date) => {
    setDateForRequest(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="h3">Soft Time Off</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              color="secondary"
              size="small"
              onClick={closeDialog}
              data-testid="closeSoftTimeOffFormShift"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            data-testid="prioritySwitchSoftTimeOffFormShift"
            control={
              <Switch
                checked={priorityChecked}
                onChange={(e) => setPriorityChecked(e.target.checked)}
                name="priorityChecked"
                color={priorityChecked ? "primary" : "secondary"}
              />
            }
            label="Priority Request"
          ></FormControlLabel>
        </Grid>
        <Grid item>
          <InputLabel htmlFor="date-for-request">
            <Typography variant="h6">Date:</Typography>
          </InputLabel>
          <DatePicker
            disableToolbar
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            id="date-for-request"
            value={dateForRequest}
            onChange={handleUpdateDate}
            className={classes.input}
            renderInput={(props) => <TextField {...props} />}
            data-testid="dateSoftTimeOffFormShift"
          />
        </Grid>
        <Grid item style={{ height: 40, marginTop: -15, marginBottom: 10 }}>
          {error && <Typography className={classes.error}>{error}</Typography>}
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              data-testid="saveSoftTimeOffFormShift"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
});

export default SoftTimeOff;
