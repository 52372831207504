import { format, add, isSameDay, isEqual } from "date-fns";
import OfficeColors from "./OfficeColors.json";

//If an event has a tasks attribute, you must go through these tasks
//to find the appropriate shift ID. If it does not have tasks, the
//eventId will be the shift ID

export const orderShifts = (shifts) => {
  const shiftNames = [];
  const missionNames = [];
  if (shifts.length > 0) {
    let formatted = [];
    shifts.forEach((shift) => {
      const start = new Date(shift.start);
      const end = new Date(shift.end);
      const formattedStart = format(start, "HH:mm");
      const formattedEnd = format(end, "HH:mm");
      const title = `${formattedStart} - ${formattedEnd}`;
      const color = OfficeColors.offices.find(
        (e) => e.name === shift.officeName,
      )
        ? OfficeColors.offices.find((e) => e.name === shift.officeName).color
        : "none";

      if (
        !missionNames.includes(shift.procedureType) &&
        shift.procedureType != null
      ) {
        missionNames.push(shift.procedureType);
      }

      if (
        !shiftNames.includes(shift.procedureType) &&
        shift.procedureType != null
      ) {
        shiftNames.push(shift.procedureType);
      }

      if (!shiftNames.includes(title)) {
        shiftNames.push(title);
      }

      const participants =
        shift.shiftassignmentSet &&
        shift.shiftassignmentSet.map((shiftAssignment) => {
          return {
            ...shiftAssignment,
            shiftAssignmentId: shiftAssignment.id,
          };
        });
      const participantIds =
        participants.length > 0 &&
        participants.map((participant) => participant.employeeId);
      const displayTitle =
        window.DISPLAY_TIME_ON_TITLE === true ||
        window.DISPLAY_TIME_ON_TITLE === "true"
          ? true
          : false;
      const displayOnlyProcedureType =
        window.DISPLAY_ONLY_PROCEDURE_TYPE === true ||
        window.DISPLAY_ONLY_PROCEDURE_TYPE === "true"
          ? true
          : false;
      if (shift.shiftassignmentSet && shift.shiftassignmentSet.length > 0) {
        let newEvent = {
          eventId: shift.id,
          start: start,
          end: end,
          participants: [...participants],
          eventTitle:
            displayOnlyProcedureType === true
              ? shift.procedureType != "" && shift.procedureType != null
                ? displayTitle === true
                  ? shift.procedureType + " " + title
                  : shift.procedureType
                : title
              : shift.procedureName != null || shift.procedureName != ""
                ? displayTitle === true
                  ? shift.procedureName + " " + title
                  : shift.procedureName
                : shift.procedureType != "" && shift.procedureType != null
                  ? displayTitle === true
                    ? shift.procedureType + " " + title
                    : shift.procedureType
                  : title,
          employeeIds: [...participantIds],
          missionId: shift.procedureType,
          procedureName: shift.procedureName,
          procedureId: shift.procedureId,
          calendars: ["Month", "Day", "TimelineWeek"],
          office: shift.officeName,
          culminatedIds: [shift.id],
          isAllDay: true,
          color: color,
          formattedStart: formattedStart,
          formattedEnd: formattedEnd,
        };

        formatted.push(newEvent);
        participants.forEach((participant) => {
          formatted.push({
            eventId: shift.id,
            start: start,
            end: end,
            participants: [...participants],
            employeeIds: [participant.employeeId],
            eventTitle: `${participant.lastName} ${participant.firstName}`,
            missionId: shift.procedureType,
            procedureId: shift.procedureId,
            calendars: ["Month", "Day", "TimelineDay"],
            procedureName: shift.procedureName,
            skills: participant.skillSet.map((e) => e.name),
            office: shift.officeName,
            culminatedIds: [shift.id],
            color: color,
            formattedStart: formattedStart,
            formattedEnd: formattedEnd,
          });
        });
      } else {
        formatted.push({
          eventId: shift.id,
          start: start,
          end: end,
          participants: [],
          employeeIds: [],
          eventTitle:
            displayOnlyProcedureType === true
              ? shift.procedureType != "" && shift.procedureType != null
                ? displayTitle === true
                  ? shift.procedureType + " " + title
                  : shift.procedureType
                : title
              : shift.procedureName != null || shift.procedureName != ""
                ? displayTitle === true
                  ? shift.procedureName + " " + title
                  : shift.procedureName
                : shift.procedureType != "" && shift.procedureType != null
                  ? displayTitle === true
                    ? shift.procedureType + " " + title
                    : shift.procedureType
                  : title,
          missionId: shift.procedureType,
          procedureName: shift.procedureName,
          procedureId: shift.procedureId,
          calendars: ["Month", "Day", "TimelineDay"],
          office: shift.officeName,
          culminatedIds: [shift.id],
          color: color,
          formattedStart: formattedStart,
          formattedEnd: formattedEnd,
        });
      }
    });
    // formatted = formatted.filter((event) => event !== null);
    let missionEvents = formatted.filter((e) =>
      e.calendars.includes("TimelineDay"),
    );
    formatted.sort((a, b) => a.start - b.start);
    formatted.sort((a, b) => a.eventId - b.eventId);
    shiftNames.sort();
    missionNames.sort();
    missionEvents = aggregateTasks(missionEvents);
    let monthEvents = formatted.filter(
      (e) => !e.calendars.includes("TimelineDay"),
    );
    formatted = [...missionEvents, ...monthEvents];
    return { formatted, shiftNames, missionNames };
  } else {
    return {
      formatted: [],
      shiftNames: [],
      missionNames: [],
    };
  }
};

export const aggregateTasks = (tasks) => {
  let aggregated = [];
  tasks.sort((a, b) => new Date(a.start) - new Date(b.start));
  const displayTitle =
    window.DISPLAY_TIME_ON_TITLE === true ||
    window.DISPLAY_TIME_ON_TITLE === "true"
      ? true
      : false;
  const displayOnlyProcedureType =
    window.DISPLAY_ONLY_PROCEDURE_TYPE === true ||
    window.DISPLAY_ONLY_PROCEDURE_TYPE === "true"
      ? true
      : false;
  tasks.forEach((task) => {
    const taskStart = new Date(task.start);
    const taskEnd = new Date(task.end);
    const priorTask = aggregated.find(
      (aggregatedTask) =>
        isEqual(aggregatedTask.end, taskStart) &&
        aggregatedTask.participants &&
        aggregatedTask.participants.length === 0 &&
        aggregatedTask.procedureId &&
        task.procedureId &&
        parseInt(aggregatedTask.procedureId) === parseInt(task.procedureId),
    );
    const taskWithSameTimes = aggregated.find(
      (aggregatedTask) =>
        isEqual(aggregatedTask.start, taskStart) &&
        isEqual(aggregatedTask.end, taskEnd) &&
        aggregatedTask.participants &&
        aggregatedTask.participants.length === 0 &&
        parseInt(aggregatedTask.procedureId) === parseInt(task.procedureId),
    );
    if (priorTask) {
      priorTask.end = taskEnd;
      priorTask.culminatedIds = [...priorTask.culminatedIds, task.eventId];
      priorTask.eventTitle =
        displayOnlyProcedureType === true
          ? priorTask.procedureType != "" && priorTask.procedureType != null
            ? displayTitle === true
              ? priorTask.procedureType +
                " " +
                format(priorTask.start, "HH:mm") +
                "-" +
                format(task.end, "HH:mm")
              : priorTask.procedureType
            : format(priorTask.start, "HH:mm") + "-" + format(task.end, "HH:mm")
          : priorTask.procedureName != null || priorTask.procedureName != ""
            ? displayTitle === true
              ? priorTask.procedureName +
                " " +
                format(priorTask.start, "HH:mm") +
                "-" +
                format(task.end, "HH:mm")
              : priorTask.procedureName
            : priorTask.procedureType != "" && priorTask.procedureType != null
              ? displayTitle === true
                ? priorTask.procedureType +
                  " " +
                  format(priorTask.start, "HH:mm") +
                  "-" +
                  format(task.end, "HH:mm")
                : priorTask.procedureType
              : format(priorTask.start, "HH:mm") +
                "-" +
                format(task.end, "HH:mm");
    } else if (taskWithSameTimes) {
      taskWithSameTimes.culminatedIds = [
        ...taskWithSameTimes.culminatedIds,
        task.eventId,
      ];
    } else {
      aggregated.push({
        ...task,
        start: taskStart,
        end: taskEnd,
      });
    }
  });
  aggregated.sort((a, b) => a.start - b.start);
  return aggregated;
};

export const findGaps = (constraints, tasks) => {
  let gaps = [];
  const needsToBeScheduled = constraints.filter((constraint) => {
    const constraintStart = new Date(constraint.start);
    const constraintEnd = new Date(constraint.end);

    const tasksInConstraintTime = tasks.filter((task) => {
      const taskStart = new Date(task.start);
      const taskEnd = new Date(task.end);
      return (
        (isEqual(constraintStart, taskStart) ||
          (constraintStart < taskStart && constraintEnd > taskStart) ||
          isEqual(constraintEnd, taskEnd) ||
          (constraintStart < taskEnd && constraintEnd > taskEnd)) &&
        task.shiftAssignment.employee.skills.find(
          (skill) => skill.name === constraint.employeeCapability.name,
        )
      );
    });

    if (tasksInConstraintTime.length > 1) {
      const aggregatedTasks = aggregateTasks(tasksInConstraintTime);

      if (aggregatedTasks.length > 1) {
        for (let i = 0; i < aggregatedTasks.length; i++) {
          let start;
          let end;
          if (new Date(aggregatedTasks[i].start) > constraintStart) {
            start = constraintStart;
            end = new Date(aggregatedTasks[i].start);
          } else if (
            i === aggregatedTasks.length - 1 &&
            new Date(aggregatedTasks[i].end) < constraintEnd
          ) {
            start = new Date(aggregatedTasks[i].end);
            end = constraintEnd;
          } else if (i !== aggregatedTasks.length - 1) {
            start = new Date(aggregatedTasks[i].end);
            end = new Date(aggregatedTasks[i + 1].start);
          }
          gaps.push({
            skill: constraint.employeeCapability,
            start: start,
            end: end,
            value: constraint.value,
          });
        }
      } else if (aggregatedTasks.length === 1) {
        if (aggregatedTasks[0].start > constraintStart) {
          gaps.push({
            skill: constraint.employeeCapability,
            start: constraintStart,
            end: aggregatedTasks[0].start,
            value: constraint.value,
          });
        }
        if (aggregatedTasks[0].end < constraintEnd) {
          gaps.push({
            skill: constraint.employeeCapability,
            start: aggregatedTasks[0].end,
            end: constraintEnd,
            value: constraint.value,
          });
        }
      }
      const coversConstraint = aggregatedTasks.find(
        (aggregatedTask) =>
          (isEqual(constraintStart, aggregatedTask.start) ||
            constraintStart > aggregatedTask.start) &&
          (isEqual(constraintEnd, aggregatedTask.end) ||
            constraintEnd < aggregatedTask.end),
      );

      return !coversConstraint;
    } else if (tasksInConstraintTime.length === 1) {
      const taskStart = new Date(tasksInConstraintTime[0].start);
      const taskEnd = new Date(tasksInConstraintTime[0].end);

      if (taskStart > constraintStart) {
        gaps.push({
          skill: constraint.employeeCapability,
          start: constraintStart,
          end: taskStart,
          value: constraint.value,
        });
      }

      if (taskEnd < constraintEnd) {
        gaps.push({
          skill: constraint.employeeCapability,
          start: taskEnd,
          end: constraintEnd,
          value: constraint.value,
        });
      }

      return !(
        isEqual(constraintStart, taskStart) && isEqual(constraintEnd, taskEnd)
      );
    } else {
      gaps.push({
        skill: constraint.employeeCapability,
        start: constraintStart,
        end: constraintEnd,
        value: constraint.value,
      });
      return true;
    }
  });
  return { needsToBeScheduled, gaps };
};

// export const formatMissions = (procedureRequirements, resources) => {
//   const currentMissionResources = [...resources];
//   const currentMissionEvents = [];
//   // const currentMissionEvents = [...events];
//   let i = 0;
//   const missionTypes = [];

//   procedureRequirements.forEach((procedureReq) => {
//     const { procedure, procedureemployeeconstraintSet } = procedureReq;

//     if (procedure && procedure.taskSet) {
//       const nonArchived = procedure.taskSet.filter(
//         (task) =>
//           task.shiftAssignment && task.shiftAssignment.isArchived === false
//       );

//       if (nonArchived.length > 0) {
//         const gapData = findGaps(procedureemployeeconstraintSet, nonArchived);
//         const needsToBeScheduled = gapData.needsToBeScheduled;
//         const gaps = gapData.gaps;

//         if (needsToBeScheduled && needsToBeScheduled.length > 0) {
//           currentMissionEvents.push({
//             eventId: procedure.id,
//             procedureRequirementId: procedureReq.id,
//             eventTitle: procedureReq.name,
//             start: new Date(procedure.start),
//             end: new Date(procedure.end),
//             missionId: procedureReq.name,
//             employeeIds: [],
//             participants: [],
//             calendars: ["month"],
//             tasks: [],
//             gaps: gaps,
//             notes: procedure.notes,
//           });
//         }

//         currentMissionEvents.push({
//           eventId: procedure.id,
//           eventTitle: procedureReq.name,
//           start: new Date(procedure.start),
//           end: new Date(procedure.end),
//           missionId: procedureReq.name,
//           employeeIds: nonArchived.map(
//             (task) => task.shiftAssignment.employee.id
//           ),
//           participants: nonArchived.map(
//             (task) => task.shiftAssignment.employee
//           ),
//           calendars: ["mission", "month"],
//           tasks: nonArchived,
//           constraints: procedureemployeeconstraintSet,
//           notes: procedure.notes,
//         });

//         nonArchived.forEach((task) => {
//           currentMissionEvents.push({
//             eventId: task.shiftAssignment.shift.id,
//             eventTitle: `${task.shiftAssignment.employee.firstName} ${task.shiftAssignment.employee.lastName}`,
//             start: new Date(task.start),
//             end: new Date(task.end),
//             missionId: procedureReq.name,
//             employeeIds: [task.shiftAssignment.employee.employeeId],
//             participants: [task.shiftAssignment.employee],
//             calendars: ["mission", "employee"],
//             constraints: procedureemployeeconstraintSet,
//           });

//           const shiftExists = currentMissionEvents.find(
//             (event) =>
//               isEqual(event.start, new Date(task.start)) &&
//               isEqual(event.end, new Date(task.end)) &&
//               event.missionId === procedureReq.name &&
//               event.tasks &&
//               event.calendars.includes("day")
//           );
//           if (shiftExists) {
//             shiftExists.tasks = [...shiftExists.tasks, task];
//             shiftExists.employeeIds = [
//               ...shiftExists.employeeIds,
//               task.shiftAssignment.employee.id,
//             ];
//             shiftExists.participants = [
//               ...shiftExists.participants,
//               task.shiftAssignment.employee,
//             ];
//           } else {
//             currentMissionEvents.push({
//               eventId: procedure.id,
//               eventTitle:
//                 format(new Date(task.start), "HH:mm") +
//                 " - " +
//                 format(new Date(task.end), "HH:mm"),
//               start: new Date(task.start),
//               end: new Date(task.end),
//               missionId: procedureReq.name,
//               employeeIds: [task.shiftAssignment.employee.id],
//               participants: [task.shiftAssignment.employee],
//               tasks: [task],
//               calendars: ["day"],
//               constraints: procedureemployeeconstraintSet,
//             });
//           }
//         });
//       } else {
//         currentMissionEvents.push({
//           eventId: procedure.id,
//           procedureRequirementId: procedureReq.id,
//           eventTitle: procedureReq.name,
//           start: new Date(procedure.start),
//           end: new Date(procedure.end),
//           missionId: procedureReq.name,
//           employeeIds: [],
//           participants: [],
//           calendars: ["month"],
//           tasks: [],
//         });
//       }
//     }
//   });
//   return { currentMissionEvents };
// };

export const formatSoftRequests = (requests, userId) => {
  return requests.map((request) => {
    const highPriority = request.value > 5;
    return {
      eventId: request.id,
      eventTitle: "Soft Time Off",
      start: new Date(`${request.date}T08:00:00`),
      end: new Date(`${request.date}T09:00:00`),
      type: "softRequest",
      highPriority,
      employeeIds: [userId],
      isAllDay: true,
    };
  });
};

export const formatTimeOff = (requests, userId) => {
  return requests.map((request) => {
    const name = request.employee.firstName + " " + request.employee.lastName;
    const personal = parseInt(request.employee.id) === parseInt(userId);

    const newRequest = {
      eventId: request.id,
      eventTitle: `${name} - ${request.type.name}`,
      type: request.type,
      status: request.approvedby ? "approved" : "pending",
      category: personal ? "personal" : "employee",
      employeeIds: [request.employee.id],
      approvedby: request.approvedby,
      workHours: request.workHours,
      typeId: request.type.id,
      comment: request.comment,
      deniedText: request.deniedText,
      createdAt: request.createdAt,
    };

    if (request.startTime && request.endTime) {
      newRequest.start = new Date(`${request.firstday} ${request.startTime}`);
      newRequest.end = new Date(`${request.lastday} ${request.endTime}`);
      newRequest.isAllDay = false;
    } else {
      newRequest.start = new Date(`${request.firstday}T08:00:00`);
      newRequest.end = new Date(`${request.lastday}T08:00:00`);
      newRequest.isAllDay = true;
    }
    return newRequest;
  });
};
