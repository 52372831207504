import React from "react";
import { CircularProgress } from "@mui/material";
import Calendar from "./Calendar";
import { userVar } from "../../cache";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import {
  ALL_SQL_SHIFT_ASSIGNMENTS,
  EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
  NOTIFY_DEVELOPERS,
  GET_OFFICE_PERIOD_STATUS,
} from "../../api/gqlQueries";
import { useData } from "./FetchData";
import { DateTimePaginator } from "../../helpers/DateTimePaginator";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

function Landing({ selectedOffice, allOffices }) {
  const user = userVar();
  const environment = process.env.NODE_ENV;
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const client = useApolloClient();
  const scheduleQuery =
    window.DISPLAY_ALL_OFFICE_EVENTS === "true"
      ? ALL_SQL_SHIFT_ASSIGNMENTS
      : EFFICIENT_SQL_SHIFT_ASSIGNMENTS;
  const paginator = new DateTimePaginator(1);
  const getDataFunction = useData(
    client,
    scheduleQuery,
    paginator,
    selectedOffice.id,
    user.id,
  );

  const { loading, data, refetch } = useQuery(GET_OFFICE_PERIOD_STATUS, {
    variables: { officeId: parseInt(selectedOffice.id) },
    onError(error) {
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_OFFICE_PERIOD_STATUS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  if (loading) {
    return <CircularProgress />;
  } else {
    return (
      <>
        <Calendar
          data-testid="LandingGrid"
          schedulePeriods={data.getOfficePeriodStatus}
          refetchSchedulePeriods={refetch}
          notifyDevelopers={notifyDevelopers}
          environment={environment}
          queryHandler={getDataFunction}
          client={client}
          selectedOffice={selectedOffice}
          allOffices={allOffices}
        />
      </>
    );
  }
}

export default Landing;
