import {
  ColumnDirective,
  ColumnsDirective,
  ExcelExport,
  GridComponent,
  Filter,
  Inject,
  Page,
  Sort,
  CommandColumn,
  Toolbar,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import "../../AllStyles/Grid.css";
import { getComponent, L10n } from "@syncfusion/ej2-base";
import { extractHeader } from "../Analytics/ScheduleSummaryReport";

const useStyles = makeStyles((theme) => ({
  underText: {
    fontWeight: "bold",
  },
  timeText: {
    paddingRight: 0,
  },
  underTimeText: {
    fontWeight: "bold",
    paddingRight: 0,
  },
  table: {
    minWidth: "auto",
  },
  icon: {
    padding: 0,
    marginRight: 5,
  },
}));

const EmployeeStint = ({ tableData }) => {
  const toolbarOptions = ["Search", "Print", "ExcelExport"];
  const searchOptions = {
    operator: "contains",
    key: "",
  };

  useEffect(() => {
    let gridObj = getComponent(
      document.getElementById("stint-data-grid"),
      "grid",
    );
    if (gridObj) {
      gridObj.enablePersistence = false;
      gridObj.searchSettings.key = "";
      window.localStorage.setItem("gridGrid", "");
      window.localStorage.setItem("gridstint-data-grid", "");
    }
  }, []);

  L10n.load({
    "en-US": {
      grid: {
        EmptyRecord: "No records found.",
        enablePersistence: false,
        clearFilters: true,
      },
    },
  });

  const extractColumns = (data) => {
    let columns = new Set();
    data.forEach((entry) => {
      columns.add("employee.firstName");
      columns.add("employee.lastName");
      Object.keys(entry.stints).forEach((key) => {
        columns.add(`stints.${key}.value`);
      });
    });
    return Array.from(columns);
  };

  const columns = extractColumns(tableData);

  return (
    <div style={{ margin: 10, padding: 20 }}>
      <h2 style={{ textAlign: "center", marginBottom: "10px" }}>Stints</h2>
      <GridComponent
        id="stint-data-grid"
        name="data-grid-stint-report"
        dataSource={tableData}
        pageSettings={{
          pageSize: 10,
          pageSizes: true,
        }}
        allowPaging={true}
        allowSorting={true}
        enableHover={true}
        height="auto"
        allowExcelExport={true}
        enablePersistence={true}
        showColumnChooser={true}
        toolbar={toolbarOptions}
        searchSettings={searchOptions}
      >
        <ColumnsDirective>
          {columns?.map((col) => (
            <ColumnDirective
              key={col}
              field={col}
              headerText={extractHeader(col)}
              textAlign="Center"
            />
          ))}
        </ColumnsDirective>
        <Inject
          services={[
            Sort,
            Filter,
            CommandColumn,
            Toolbar,
            ExcelExport,
            ColumnChooser,
            Page,
          ]}
        />
      </GridComponent>
    </div>
  );
};

export default EmployeeStint;
