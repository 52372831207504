import { useEffect, useState, forwardRef } from "react";
import FilterMenu from "./FilterMenu";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Grid,
  Popover,
  Paper,
  Button,
  Badge,
  CircularProgress,
} from "@mui/material";
import { userVar } from "../../cache";
import Roles from "../../Roles/roles";
import { useStatefulLocalStorage } from "../../hooks/storage/useStorage";
import { baseFilterObj } from "./filter/FilterEvents";

const Filter = forwardRef((props, ref) => {
  const {
    jobTypes,
    shiftNames,
    availabilityTypes,
    officeFloatStatus,
    allOffices,
    employeeResources,
    selectedOffice,
  } = props;
  const user = userVar();
  const [anchorEl, setAnchorEl] = useState(null); //being set on anchorEl prop for the Popover component. TODO: check MUI docs

  const userRole = user.role;

  const availabilityTypeNames = availabilityTypes
    .filter((e) => e.off === true)
    .map((obj) => ({ ...obj, checked: true }));

  const nonLeaveTypeNames = availabilityTypes
    .filter((e) => e.off === false)
    .map((obj) => ({ ...obj, checked: true }));

  const jobTypeNames = jobTypes.map((obj) => ({
    ...obj,
    id: obj.skillId,
    checked: true,
  }));

  // Keeps track of what is entered in multiselect search text box
  const [selectedEmployees, SetSelectedEmployees] = useState([]);

  let allEmployeeNames = [
    ...employeeResources.map((obj) => ({
      ...obj,
      checked: true,
    })),
  ];

  // Keeps track of which subset of employees to display when the search is active
  const [employeeNames, SetEmployeeNames] = useState(allEmployeeNames);
  const filterByEmployees = (e) => {
    SetSelectedEmployees(e);
    if (e.length > 0) {
      let selectedSkills = e.filter((s) => s.skillId).map((s) => s.name);
      let filteredEmployees = [
        ...allEmployeeNames.filter(
          (employee) =>
            employee.skills &&
            employee.skills.length > 0 &&
            employee.skills.find((s) => selectedSkills.includes(s.name)),
        ),
      ];
      SetEmployeeNames(filteredEmployees);
      setCheckBox((prevCheckBox) => {
        // Update the checkbox state)
        const updatedCheckBox = {
          ...prevCheckBox,
          employeeFilters: prevCheckBox["employeeFilters"].map((filterObj) => ({
            ...filterObj,
            checked: filterObj.skills
              ? filterObj.skills.some((skill) =>
                  selectedSkills.includes(skill.name),
                )
              : false,
          })),
        };
        return updatedCheckBox;
      });
    } else {
      SetEmployeeNames(allEmployeeNames);
      setCheckBox((prevCheckBox) => {
        // Update the checkbox state
        const updatedCheckBox = {
          ...prevCheckBox,
          employeeFilters: prevCheckBox["employeeFilters"].map((filterObj) => ({
            ...filterObj,
            checked: false,
          })),
        };
        return updatedCheckBox;
      });
    }
  };

  //Set initial filter values
  let shiftFilterList = baseFilterObj();
  shiftFilterList.requestsFilters = [
    ...shiftFilterList.requestsFilters,
    ...availabilityTypeNames,
    ...nonLeaveTypeNames,
  ];
  shiftFilterList.jobTypeFilters = [
    ...shiftFilterList.jobTypeFilters,
    ...jobTypeNames,
  ];
  shiftFilterList.employeeFilters = [
    ...shiftFilterList.employeeFilters,
    ...employeeNames,
  ];

  const missionfilterList = {
    allFilters: ["All Shifts", "All Missions", "All Job Type"],
    shiftsFilters: ["All Shifts", "Personal", "Not Scheduled"],
    missionFilters: ["All Missions"],
    requestsFilters: [],
    jobTypeFilters: ["All Job Type"],
  };

  //Filter Variable to store for persistence
  const [filterList, setFilterList] = useStatefulLocalStorage(
    `filter:${selectedOffice.name}:v0`,
    shiftFilterList,
  );
  let updateRequired = false;
  if (!filterList["shiftsFilters"]) {
    filterList.shiftsFilters = shiftFilterList.shiftsFilters;
    updateRequired = true;
  }
  if (!filterList["employeeFilters"]) {
    filterList.employeeFilters = shiftFilterList.employeeFilters;
    updateRequired = true;
  }
  if (!filterList["jobTypeFilters"]) {
    filterList.jobTypeFilters = shiftFilterList.jobTypeFilters;
    updateRequired = true;
  }
  if (!filterList["requestsFilters"]) {
    filterList.requestsFilters = shiftFilterList.requestsFilters;
    updateRequired = true;
  }
  if (updateRequired) {
    setFilterList(filterList);
  }
  //state variable to keep track of checked boxes in filterMenu
  const [checkBox, setCheckBox] = useState(
    filterList ? filterList : shiftFilterList,
  );

  const handleCheckBoxToggle = (filterListToChange, filterString) => {
    setCheckBox((prevCheckBox) => {
      // Check if the clicked filter is not "All"
      const isNotAllFilter = !filterListToChange.name.includes("All");

      // Update the checkbox state
      const updatedCheckBox = {
        ...prevCheckBox,
        [filterString]: prevCheckBox[filterString].map((filterObj) => {
          if (filterObj.name === filterListToChange.name) {
            if (filterObj.name === "Approved" || filterObj.name === "Pending") {
              if (
                filterListToChange.checked === false &&
                checkBox[filterString].find(
                  (e) =>
                    (e.name === "Approved" || e.name === "Pending") &&
                    e.checked === false,
                )
              ) {
                prevCheckBox[filterString]
                  .filter((e) => e.id || e.name === "Non-Leave")
                  .map((e) => (e.checked = false));
              } else {
                prevCheckBox[filterString]
                  .filter((e) => e.id || e.name === "Non-Leave")
                  .map((e) => (e.checked = true));
              }
            }
            if (filterObj.name === "Non-Leave") {
              prevCheckBox[filterString]
                .filter((e) => e.off === false)
                .map((e) => (e.checked = !filterObj.checked));
            }
            return {
              ...filterObj,
              checked: !filterObj.checked,
            };
          }
          return filterObj;
        }),
      };

      // If the clicked filter is not "All " , set All filter checked property to false, otherwise set all check property to the "All" status
      if (isNotAllFilter) {
        const allSkillsFilterIndex = updatedCheckBox[filterString].findIndex(
          (filterObj) => filterObj.name.includes("All"),
        );
        if (allSkillsFilterIndex !== -1) {
          updatedCheckBox[filterString][allSkillsFilterIndex].checked = false;
        }
      }
      if (!isNotAllFilter) {
        prevCheckBox[filterString].map(
          (e) => (e.checked = filterListToChange.checked),
        );
      }
      return updatedCheckBox;
    });
  };

  const handleRadioToggle = (filterListToChange, filterString) => {
    setCheckBox((prevCheckBox) => {
      // Update the checkbox state
      const updatedCheckBox = {
        ...prevCheckBox,
        [filterString]: prevCheckBox[filterString].map((filterObj) => {
          if (filterObj.name === filterListToChange.name) {
            return {
              ...filterObj,
              checked: filterListToChange.checked,
            };
          } else {
            return {
              ...filterObj,
              checked: !filterListToChange.checked,
            };
          }
        }),
      };

      return updatedCheckBox;
    });
  };

  if (
    !filterList ||
    !jobTypeNames ||
    !availabilityTypeNames ||
    !nonLeaveTypeNames ||
    !employeeNames
  ) {
    return <CircularProgress />;
  } else {
    return (
      <Grid container direction="row" spacing={2}>
        <Grid>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
              setCheckBox(filterList);
            }}
            style={{ overflow: "auto" }}
          >
            <Paper style={{ padding: 10 }}>
              {
                <FilterMenu
                  data-testid="FilterMenu"
                  jobTypes={jobTypeNames}
                  shiftNames={[]}
                  officeFloatStatus={officeFloatStatus}
                  allOffices={allOffices}
                  allEmployeeNames={allEmployeeNames}
                  employeeResources={employeeResources}
                  employeeNames={employeeNames}
                  SetEmployeeNames={SetEmployeeNames}
                  filterByEmployees={filterByEmployees}
                  selectedEmployees={selectedEmployees}
                  SetSelectedEmployees={SetSelectedEmployees}
                  setFilterList={setFilterList}
                  ref={ref}
                  setAnchorEl={setAnchorEl}
                  checkBox={checkBox}
                  setCheckBox={setCheckBox}
                  availabilityTypeNames={availabilityTypeNames}
                  nonLeaveTypeNames={nonLeaveTypeNames}
                  handleCheckBoxToggle={handleCheckBoxToggle}
                  handleRadioToggle={handleRadioToggle}
                />
              }
            </Paper>
          </Popover>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            data-testid="FilterMenuIcon"
          >
            <Badge variant="dot" color="primary" style={{ marginRight: 5 }}>
              <TuneIcon />
            </Badge>
            Filter
          </Button>
        </Grid>
      </Grid>
    );
  }
});

export default Filter;
