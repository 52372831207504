import { forwardRef, useState } from "react";
import { Button, Dialog, DialogContent } from "@mui/material";
import SoftTimeOff from "./SoftTimeOff";

const AddSoftTimeOffRequest = forwardRef((props, ref) => {
  const {
    scheduleEndDate,
    maxGreyout,
    schedulePeriodWeeks,
    notifyDevelopers,
    selectedOffice,
  } = props;

  const [softRequestForm, setSoftRequestForm] = useState(false);
  const toggleSoftRequestForm = () => setSoftRequestForm(!softRequestForm);
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={toggleSoftRequestForm}
        data-testid="addSoftRequest"
      >
        Add Soft Request
      </Button>
      <Dialog open={softRequestForm} fullWidth maxWidth="xs">
        <DialogContent
          style={{ padding: 30 }}
          data-testid="ShiftBasedSoftRequestForm"
        >
          <SoftTimeOff
            closeDialog={toggleSoftRequestForm}
            scheduleEndDate={scheduleEndDate}
            ref={ref}
            maxGreyout={maxGreyout}
            schedulePeriodWeeks={schedulePeriodWeeks}
            notifyDevelopers={notifyDevelopers}
            selectedOffice={selectedOffice}
          />
        </DialogContent>
      </Dialog>
    </>
  );
});

export default AddSoftTimeOffRequest;
