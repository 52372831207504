import React, { useEffect, useState } from "react";
import { Grid, Typography, IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { format, sub, add, isEqual } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";
import StarRateIcon from "@mui/icons-material/StarRate";
import { useLazyQuery } from "@apollo/client";
import {
  GET_SHIFTS_WITH_RANGE,
  ADD_OPTIONS_FOR_SHIFT,
} from "../../api/gqlQueries";
import { userVar } from "../../cache";
import RescheduleOptionsKey from "./RescheduleOptionsKey";

const useStyles = makeStyles((theme) => ({
  icon: {
    padding: 0,
    marginLeft: -7,
  },
}));

const AddEmployeesForm = ({ selectedNotification, closeDialog }) => {
  const classes = useStyles();

  const user = userVar();

  const [shift, setShift] = useState();
  const [addOptions, setAddOptions] = useState([]);

  const [getShifts, { called, loading, data }] = useLazyQuery(
    GET_SHIFTS_WITH_RANGE,
    {
      onError(error) {
        console.log(error);
      },
    },
  );

  const [
    getAddOptions,
    { called: addCalled, loading: addLoading, data: addData },
  ] = useLazyQuery(ADD_OPTIONS_FOR_SHIFT, {
    onCompleted(data) {
      console.log(data);
      const addOptions =
        data.rescheduleAdds.length > 0
          ? [...data.rescheduleAdds]
          : // .map(add => {
            //     const employeeIdsAndBenefits = add.rescheduleindividualSet.map(employee => {
            //         return {
            //             employee: employee.employee,
            //             benefit: employee.benefit,
            //             cost: employee.cost
            //         }
            //     });
            //     return employeeIdsAndBenefits;
            // }).flat()
            [];
      setAddOptions(addOptions);
    },
    onError(error) {
      console.log(error);
    },
  });

  const getDateFromDescription = (description) => {
    const words = description.split(" ");
    let dates = [];
    words.forEach((word) => {
      const possibleDate = word.includes("-");
      const possibleTime = word.includes(":");
      if (possibleTime) {
        let time = word.slice(0, 14);
        dates.push(time);
      }
      possibleDate && dates.push(word);
      // const date = possibleDate ? new Date(word) : null;
      // console.log(word)
      // const timestamp = Date.parse(date);
      // if (!isNaN(timestamp)) {
      //     dates.push(date);
      // }
    });
    dates.join(" ");
    // dates.sort((a, b) => a - b);
    return new Date(dates);
  };

  useEffect(() => {
    if (selectedNotification) {
      const start = sub(
        getDateFromDescription(selectedNotification.description),
        { days: 1 },
      );
      const end = add(start, { days: 2 });
      getShifts({
        variables: {
          officeId: parseInt(user.office.id),
          rangeStart: start.toISOString(),
          rangeEnd: end.toISOString(),
        },
      });
    }
  }, []);

  useEffect(() => {
    if (data) {
      const dateToMatch = getDateFromDescription(
        selectedNotification.description,
      );
      const matching = data.shifts.find((shift) =>
        isEqual(new Date(shift.start), dateToMatch),
      );

      if (matching) {
        setShift(matching);
        getAddOptions({ variables: { shifts: [parseInt(matching.id)] } });
      }
    }
  }, [data]);

  if (addData && shift) {
    const formattedDate = format(new Date(shift.start), "dd MMM yyyy");
    const formattedTime = `${format(new Date(shift.start), "HH:mm")} - ${format(
      new Date(shift.end),
      "HH:mm",
    )}`;

    const renderAddOptions = () => {
      addOptions.sort(
        (a, b) =>
          b.rescheduleindividualSet[0].benefit -
          a.rescheduleindividualSet[0].benefit,
      );

      return addOptions.map((option) => {
        const benefit = option.rescheduleindividualSet[0].benefit;
        return (
          <Grid
            item
            key={option.id}
            container
            spacing={1}
            alignContent="center"
            style={{ paddingLeft: 24 }}
          >
            {/* <Grid item>
                            <Checkbox />
                        </Grid> */}
            <Grid item>
              <Typography>
                {option.rescheduleindividualSet[0].employee.firstName +
                  " " +
                  option.rescheduleindividualSet[0].employee.lastName}
              </Typography>
            </Grid>
            <Grid item>
              {(benefit || benefit === 0) && benefit >= 1 && (
                <>
                  <StarRateIcon className={classes.icon} />
                  <StarRateIcon className={classes.icon} />
                  <StarRateIcon className={classes.icon} />
                </>
              )}
              {(benefit || benefit === 0) && benefit < 1 && benefit > -1 && (
                <>
                  <StarRateIcon className={classes.icon} />
                  <StarRateIcon className={classes.icon} />
                </>
              )}
              {(benefit || benefit === 0) && benefit <= -1 && (
                <StarRateIcon className={classes.icon} />
              )}
            </Grid>
          </Grid>
        );
      });
    };

    return (
      <Grid container direction="column" spacing={2}>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Typography variant="h3">Add Options</Typography>
          </Grid>
          <Grid item>
            <IconButton color="secondary" onClick={closeDialog} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <RescheduleOptionsKey type="add" />
        </Grid>
        <Grid item>
          <Typography>
            The following are the best options for
            <b>
              {" "}
              {formattedDate} {formattedTime}
            </b>{" "}
            in order by benefit.
            {/* Select who you would like to ask to pick up this shift. */}
          </Typography>
        </Grid>
        {addOptions.length > 0 ? (
          <>
            {renderAddOptions()}
            {/* <Grid item container justify='flex-end'>
                            <Grid item>
                                <Button variant='contained' color='primary' onClick={() => closeDialog()}>
                                    Send Request
                                </Button>
                            </Grid>
                        </Grid> */}
          </>
        ) : (
          <Grid item>
            <Typography>There are no options for this shift.</Typography>
          </Grid>
        )}
      </Grid>
    );
  } else {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Typography variant="h3">Add Options</Typography>
          </Grid>
          <Grid item>
            <IconButton color="secondary" onClick={closeDialog} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {(loading || addLoading) && (
          <Grid item>
            <CircularProgress />
          </Grid>
        )}
        {called && !loading && addCalled && !addLoading && (
          <Grid item>
            <Typography>We could not find any options for you.</Typography>
          </Grid>
        )}
      </Grid>
    );
  }
};

export default AddEmployeesForm;
