import React, { useEffect, useState } from "react";
import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelsDirective,
} from "@syncfusion/ej2-react-layouts";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  ColumnSeries,
  DataLabel,
} from "@syncfusion/ej2-react-charts";
import { useTheme } from "@mui/styles";
import {
  CircularProgress,
  Grid,
  Typography,
  Box,
  Button,
  Dialog,
  DialogContent,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  NOTIFY_DEVELOPERS,
  UTILIZATION_ANALYTICS,
  SCHEDULE_QUALITY_ANALYTICS,
  INTERVAL_SLACKS,
  MOST_RECENT_CENSUS,
  FIND_SKILL_TYPE,
  SCHEDULE_FITNESS,
  GET_OFFICE_PERIOD_STATUS,
} from "../../api/gqlQueries";
import { userVar, appsettingsVar } from "../../cache";
import { add, format } from "date-fns";
import "../../AllStyles/DashboardLayout.css";
import NextShiftDetails from "./NextShiftDetails";
import MonthOverview from "./MonthOverview";
import UpcomingTasks from "./UpcomingTasks";
import ScheduleInfo from "./ScheduleInfo";
import PreferenceFeedback from "./PreferenceFeedback";
import CensusDataForm from "../shiftBasedCalendar/CensusDataForm";
import DashboardFooter from "./DashboardFooter";
import { scheduleQualityGrade } from "../../helpers/DashboardHelpers";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";

let toastObj;
function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

const useStyles = makeStyles((theme) => ({
  panelStyle: {
    boxSizing: "border-box",
  },
  mainDiv: {
    width: "100%",
    marginBottom: 20,
  },
  box: {
    width: "25%",
    textAlign: "center",
    boxSizing: "border-box",
    borderRadius: 4,
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace("#", "");
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

const ManagerDashboard = ({ selectedOffice }) => {
  const classes = useStyles();
  const theme = useTheme();

  const user = userVar();
  const today = format(new Date(), "yyyy-MM-dd") + "T00:00:00";

  const environment = process.env.NODE_ENV;

  const [showcensus, SetShowCensus] = useState(false);

  const toggleCensusform = () => SetShowCensus(!showcensus);
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.error(error);
    },
  });
  const utilizationAnalytics = useQuery(UTILIZATION_ANALYTICS, {
    variables: {
      office: parseInt(selectedOffice.id),
    },
    onError(err) {
      console.error(err);
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UTILIZATION_ANALYTICS Query. Environment: " +
            environment +
            ". Graphql " +
            err,
        },
      });
    },
  });

  const scheduleQualityAnalytics = useQuery(SCHEDULE_QUALITY_ANALYTICS, {
    variables: {
      office: parseInt(selectedOffice.id),
    },
    onError(err) {
      console.error(err);
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on SCHEDULE_QUALITY_ANALYTICS Query. Environment: " +
            environment +
            ". Graphql " +
            err,
        },
      });
    },
  });

  let rangeEnd = null;

  const schedulePeriods = useQuery(GET_OFFICE_PERIOD_STATUS, {
    variables: {
      officeId: parseInt(selectedOffice.id),
    },
    onCompleted(d) {
      let currentSchedulePeriod =
        d.getOfficePeriodStatus.currentSchedulePeriodId;
      currentSchedulePeriod &&
        scheduleFitnesses({
          variables: {
            schedulePeriod: parseInt(currentSchedulePeriod),
            office: parseInt(selectedOffice.id),
          },
        });
      rangeEnd =
        d.getOfficePeriodStatus &&
        d.getOfficePeriodStatus.managerDraftRange &&
        d.getOfficePeriodStatus.managerDraftRange.end
          ? d.getOfficePeriodStatus.managerDraftRange.end
          : d.getOfficePeriodStatus &&
              d.getOfficePeriodStatus.managerDraftRange &&
              d.getOfficePeriodStatus.releasedRange.start
            ? d.getOfficePeriodStatus.releasedRange.start
            : null;
    },
  });

  const [
    intervalSlacks,
    { data: intervalSlacksData, loading: intervalSlacksLoading },
  ] = useLazyQuery(INTERVAL_SLACKS, {
    onError(err) {
      console.error(err);
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on SCHEDULE_QUALITY_ANALYTICS Query. Environment: " +
            environment +
            ". Graphql " +
            err,
        },
      });
    },
  });

  useEffect(() => {
    if (rangeEnd) {
      intervalSlacks({
        variables: {
          office: parseInt(selectedOffice.id),
          issuesOnly: true,
          rangeStart: today,
          rangeEnd: rangeEnd,
        },
      });
    }
  }, [rangeEnd]);

  const censusData = useQuery(MOST_RECENT_CENSUS, {
    variables: {
      office: parseInt(selectedOffice.id),
      range: 5,
    },
    onError(err) {
      console.error(err);
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on MOST_RECENT_CENSUS Query. Environment: " +
            environment +
            ". Graphql " +
            err,
        },
      });
    },
  });

  const skillSet = useQuery(FIND_SKILL_TYPE, {
    variables: {
      officeId: parseInt(selectedOffice.id),
    },
    onError(err) {
      toastShow("Graphql " + err, "Error");

      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            " Graphql " +
            err,
        },
      });
    },
  });

  const [scheduleFitnesses, { data: scheduleFitnessData }] = useLazyQuery(
    SCHEDULE_FITNESS,
    {
      onError(err) {
        toastShow("Graphql " + err, "Error");
        notifyDevelopers({
          variables: {
            message:
              "Error on SCHEDULE_FITNESS Query. Environment: " +
              environment +
              " Graphql " +
              err,
          },
        });
      },
    },
  );

  const lighterPrimaryColor = convertHexToRGBA(appsettingsVar().color, 40);

  //all employees utilization values
  let allUtilization =
    utilizationAnalytics?.data?.utilizationAnalytics?.length > 0 &&
    utilizationAnalytics.data.utilizationAnalytics
      .filter((e) => new Date(e.scheduleEnd) > new Date())
      .map((e) => parseFloat(e.utilization));

  const patientLevelDataGraph =
    censusData.data &&
    censusData.data.censusTrend &&
    censusData.data.censusTrend.length > 0 &&
    censusData.data.censusTrend.map((e) => {
      return {
        x: format(new Date(e.createdAt), "MM-dd-yyyy"),
        y: parseInt(e.count),
      };
    });

  const leastCapacity =
    utilizationAnalytics.data &&
    utilizationAnalytics.data.utilizationAnalytics
      .filter((e) => e.skill != null)
      .reduce((a, b) => (a = a.utilization > b.utilization ? a : b), 0);

  const leastCapacitySkillSet =
    skillSet.data &&
    skillSet.data.skills.find(
      (e) => parseInt(e.id) === parseInt(leastCapacity?.skill),
    )?.name;

  const mostCapacity =
    utilizationAnalytics.data &&
    utilizationAnalytics.data.utilizationAnalytics
      .filter((e) => e.skill != null)
      .reduce((a, b) => (a = a.utilization < b.utilization ? a : b), 0);

  const mostCapacitySkillSet =
    skillSet.data &&
    skillSet.data.skills.find(
      (e) => parseInt(e.id) === parseInt(mostCapacity?.skill),
    )?.name;

  const one = () => {
    return (
      <div
        style={{ height: "100%", width: "100%" }}
        data-testid="managernextShiftDetailsPane"
      >
        <span id="close" className="e-close-icon e-clear-icon" />
        <NextShiftDetails />
      </div>
    );
  };

  const two = () => {
    let empScheduleFitnesses =
      scheduleFitnessData?.employeeFitnesses.length > 0 &&
      scheduleFitnessData.employeeFitnesses.reduce(
        (a, b) => (a = a.score < b.score ? a : b),
      );

    let averagescheduleQualityForUnit =
      scheduleQualityAnalytics?.data?.scheduleQualityAnalytics &&
      (
        (scheduleQualityAnalytics.data.scheduleQualityAnalytics
          .filter((e) => new Date(e.scheduleEnd) > new Date())
          .map((e) => parseFloat(e.score))
          .reduce((a, b) => a + b, 0) /
          scheduleQualityAnalytics.data.scheduleQualityAnalytics.filter(
            (e) => new Date(e.scheduleEnd) > new Date(),
          ).length) *
        100
      ).toFixed(2);
    let colorandgrade =
      averagescheduleQualityForUnit > 0
        ? scheduleQualityGrade(averagescheduleQualityForUnit)
        : null;

    return (
      <>
        <Grid container spacing={2} data-testid="managerscheduleQualityPane">
          <span id="close" className="e-close-icon e-clear-icon" />
          <Grid item container>
            <Grid item xs={12}>
              {" "}
              <Box m={2}>
                {" "}
                <Typography variant="h3">UNIT STAFFING HEALTH</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-evenly"
            style={{
              marginBottom: 10,
            }}
          >
            <Grid item xs={1}></Grid>
            <Grid
              item
              xs={2}
              style={{
                border: "1px solid" + appsettingsVar().color,
                background: lighterPrimaryColor,
                borderRadius: 4,
              }}
            >
              {" "}
              <Tooltip title="Shortages in the next Week">
                <Typography variant="body1">Imminent Shortages </Typography>
              </Tooltip>
              {intervalSlacksData ? (
                <Typography variant="body1">
                  {intervalSlacksData.intervalSlacks &&
                    intervalSlacksData.intervalSlacks.length > 0 &&
                    intervalSlacksData.intervalSlacks.filter(
                      (e) =>
                        new Date(e.interval.start) > new Date() &&
                        new Date(e.interval.start) <
                          add(new Date(), { days: 7 }),
                    ).length}
                </Typography>
              ) : (
                "0"
              )}
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                border: "1px solid" + appsettingsVar().color,
                background: lighterPrimaryColor,
                borderRadius: 4,
              }}
            >
              <Typography variant="body1">Next Shortage</Typography>
              {intervalSlacksData ? (
                <Typography variant="body1">
                  {" "}
                  {intervalSlacksData.intervalSlacks &&
                    intervalSlacksData.intervalSlacks.length > 0 &&
                    format(
                      new Date(
                        intervalSlacksData.intervalSlacks[0].interval.start,
                      ),
                      "MM-dd-yyyy",
                    )}
                </Typography>
              ) : (
                <Typography variant="body1">N/A</Typography>
              )}
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                border: "1px solid" + appsettingsVar().color,
                background: lighterPrimaryColor,
                borderRadius: 4,
              }}
            >
              <Tooltip title="Shortages in the next Month">
                <Typography variant="body1"> Total Shortages</Typography>
              </Tooltip>
              {intervalSlacksData ? (
                <Typography variant="body1">
                  {intervalSlacksData.intervalSlacks &&
                    intervalSlacksData.intervalSlacks.length}
                </Typography>
              ) : (
                "0"
              )}
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                border: "1px solid" + appsettingsVar().color,
                background: lighterPrimaryColor,
                borderRadius: 4,
              }}
            >
              <Typography variant="body1">Utilization</Typography>
              {utilizationAnalytics.loading ? (
                <CircularProgress />
              ) : (
                <Typography variant="body1">
                  {allUtilization.length > 0
                    ? (
                        (allUtilization.reduce((a, b) => a + b, 0) /
                          allUtilization.length) *
                        100
                      ).toFixed(2)
                    : "N/A"}
                </Typography>
              )}
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          <Grid item container justifyContent="space-evenly">
            <Grid item xs={1}></Grid>
            {colorandgrade ? (
              <Grid
                item
                xs={2}
                style={{
                  border: "1px solid" + appsettingsVar().color,
                  borderRadius: 4,
                  background: colorandgrade
                    ? colorandgrade.color
                    : lighterPrimaryColor,
                }}
              >
                <Tooltip title="“Average schedule quality of all employees from the most recently created schedule”">
                  <Typography variant="body1"> Schedule Quality</Typography>
                </Tooltip>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {colorandgrade && colorandgrade.grade}
                </Typography>
              </Grid>
            ) : (
              <Grid
                item
                xs={2}
                style={{
                  border: "1px solid" + appsettingsVar().color,
                  borderRadius: 4,
                  background: colorandgrade
                    ? colorandgrade.color
                    : lighterPrimaryColor,
                }}
              >
                <Tooltip title="“Average schedule quality of all employees from the most recently created schedule”">
                  <Typography variant="body1"> Schedule Quality</Typography>
                </Tooltip>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {colorandgrade ? colorandgrade.grade : "N/A"}
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={2}
              style={{
                border: "1px solid" + appsettingsVar().color,
                background: lighterPrimaryColor,
                borderRadius: 4,
              }}
            >
              <Typography variant="body1">Least Capacity</Typography>
              <Typography variant="body1">
                {leastCapacitySkillSet ? leastCapacitySkillSet : "N/A"}
              </Typography>
            </Grid>{" "}
            <Grid
              item
              xs={2}
              style={{
                border: "1px solid" + appsettingsVar().color,
                background: lighterPrimaryColor,
                borderRadius: 4,
              }}
            >
              <Typography variant="body1">Most Capacity</Typography>
              <Typography variant="body1">
                {mostCapacitySkillSet ? mostCapacitySkillSet : "N/A"}
              </Typography>
            </Grid>{" "}
            <Grid
              item
              xs={2}
              style={{
                border: "1px solid" + appsettingsVar().color,
                background: empScheduleFitnesses
                  ? scheduleQualityGrade(empScheduleFitnesses.score?.toFixed(2))
                      .color
                  : lighterPrimaryColor,
                borderRadius: 4,
              }}
            >
              <Typography variant="body1">Worst Schedule</Typography>
              <Typography variant="subtitle2">
                {" "}
                Schedule Quality :{" "}
                {empScheduleFitnesses
                  ? scheduleQualityGrade(empScheduleFitnesses.score?.toFixed(2))
                      .grade
                  : "N/A"}
              </Typography>
              <Typography variant="subtitle2">
                {" "}
                Skills:{" "}
                {empScheduleFitnesses?.employee?.skills?.length > 0
                  ? empScheduleFitnesses.employee.skills
                      .map((e) => e.name)
                      .join(",")
                  : "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const three = () => {
    if (schedulePeriods.loading) {
      return <CircularProgress />;
    } else {
      return (
        <div
          style={{ height: "100%", width: "100%" }}
          data-testid="managerupcomingTasksPane"
        >
          <span id="close" className="e-close-icon e-clear-icon" />
          <UpcomingTasks
            schedulePeriods={schedulePeriods}
            lightBackground={lighterPrimaryColor}
            borderColor={theme.palette.primary.main}
            selectedOffice={selectedOffice}
          />
        </div>
      );
    }
  };

  const four = () => {
    return (
      <div
        style={{ height: "100%", width: "100%" }}
        data-testid="managermonthOverviewPane"
      >
        <span id="close" className="e-close-icon e-clear-icon" />
        <MonthOverview />
      </div>
    );
  };

  const five = () => {
    return (
      <div
        style={{ height: "100%", width: "100%" }}
        data-testid="managerunitCensusPane"
      >
        <span id="close" className="e-close-icon e-clear-icon" />
        <div>
          {" "}
          <Box m={2}>
            <Typography variant="h3">UNIT CENSUS </Typography>{" "}
            <Button color="primary" onClick={() => SetShowCensus(!showcensus)}>
              Add Unit Census
            </Button>
          </Box>
          <div className="control-pane">
            <div className="control-section">
              <ChartComponent
                id="charts"
                style={{ textAlign: "center", margin: "auto" }}
                height="50%"
                primaryXAxis={{
                  valueType: "Category",
                  interval: 1,
                  majordivLines: { width: 0 },
                }}
                primaryYAxis={{
                  lineStyle: { width: 0 },
                  labelStyle: {
                    color: "#000",
                    fontWeight: 400,
                    fontSize: 15,
                  },
                  minimum: 0,
                  interval: 5,
                  majorGridLines: { width: 0 },
                }}
                chartArea={{ border: { width: 0 } }}
                tooltip={{ enable: true }}
                palettes={[appsettingsVar().color]}
              >
                <Inject
                  services={[ColumnSeries, Legend, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={patientLevelDataGraph}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="No of Patients recorded for Date"
                    marker={{
                      dataLabel: {
                        visible: true,
                        font: { fontWeight: "600", color: "#ffffff" },
                      },
                    }}
                  ></SeriesDirective>
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const six = () => {
    if (schedulePeriods.loading) {
      return <CircularProgress />;
    } else {
      return (
        <div
          style={{ height: "110%", width: "100%" }}
          data-testid="managerScheduleInfoPane"
        >
          <span id="close" className="e-close-icon e-clear-icon" />
          <ScheduleInfo
            schedulePeriods={schedulePeriods}
            lightBackground={lighterPrimaryColor}
            borderColor={theme.palette.primary.main}
            selectedOffice={selectedOffice}
          />
        </div>
      );
    }
  };

  const seven = () => {
    if (schedulePeriods.loading) {
      return <CircularProgress />;
    } else {
      return (
        <div
          style={{ height: "100%", width: "100%" }}
          data-testid="managerpreferenceFeedbackPane"
        >
          <span id="close" className="e-close-icon e-clear-icon" />
          <PreferenceFeedback
            schedulePeriods={schedulePeriods}
            lightBackground={lighterPrimaryColor}
            borderColor={theme.palette.primary.main}
            selectedOffice={selectedOffice}
          />
        </div>
      );
    }
  };

  return (
    <div
      id="defaultTarget"
      className="dashboardParent"
      style={{ marginTop: 20, textAlign: "center" }}
    >
      <DashboardLayoutComponent
        id="defaultLayout"
        columns={5}
        // cellSpacing={cellSpacing}
        allowResizing={false}
        cellAspectRatio={100 / 115}
        // mediaQuery={mediaQuery}
      >
        <PanelsDirective>
          <PanelDirective
            row={0}
            col={0}
            sizeX={1}
            sizeY={1}
            content={one}
            cssClass={classes.panelStyle}
          ></PanelDirective>
          <PanelDirective
            row={0}
            col={1}
            sizeX={3}
            sizeY={1}
            content={two}
            cssClass={classes.panelStyle}
          ></PanelDirective>
          <PanelDirective
            row={0}
            col={4}
            sizeX={1}
            sizeY={1}
            content={three}
            cssClass={classes.panelStyle}
          ></PanelDirective>
          <PanelDirective
            row={1}
            col={0}
            sizeX={1}
            sizeY={1}
            content={four}
            cssClass={classes.panelStyle}
          ></PanelDirective>
          <PanelDirective
            row={1}
            col={1}
            sizeX={2}
            sizeY={1}
            content={five}
            cssClass={classes.panelStyle}
          ></PanelDirective>
          <PanelDirective
            row={1}
            col={3}
            sizeX={1}
            sizeY={1}
            content={six}
            cssClass={classes.panelStyle}
          ></PanelDirective>
          <PanelDirective
            row={1}
            col={4}
            sizeX={1}
            sizeY={1}
            content={seven}
            cssClass={classes.panelStyle}
          ></PanelDirective>
        </PanelsDirective>
      </DashboardLayoutComponent>
      <div>
        <DashboardFooter selectedOffice={selectedOffice} />
      </div>
      <Dialog open={showcensus} fullWidth maxWidth="sm">
        <DialogContent
          style={{
            padding: 30,
            overflowX: "hidden",
            overflowY: "auto",
            height: 675,
            position: "relative",
          }}
        >
          <CensusDataForm
            closeDialog={toggleCensusform}
            selectedDate={new Date()}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ManagerDashboard;
