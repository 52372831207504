const roles = {
  ADMIN: "ADMIN" || "Admin",
  MANAGER: "MANAGER" || "Manager",
  LEAD: "MANAGER" || "Manager",
  EMPLOYEE: "EMPLOYEE" || "Employee",
  CNO: "Executive",
  EXECUTIVE: "Executive",
  SCHEDULER: "SCHEDULER" || "Scheduler",
};

export default roles;
