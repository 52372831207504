import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  InputLabel,
  CircularProgress,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DateFnsUtils from "@date-io/date-fns";
import { findEmployeesToSwitch } from "../../helpers/shiftSwitch";
// import EmpSelectEmployeesForm from "./EmpSelectEmployeesForm";
import EmpSelectEmployeesandDates from "./EmpSelectEmployeesandDates";
import { add, format } from "date-fns";
import {
  GET_SHIFT_ASSIGNMENT,
  // GET_SHIFT_ASSIGNMENTS_BY_SHIFT,
  // CREATE_SHIFT_SWITCH,
  RESCHEDULE_SWAP_OPTIONS,
  ACCEPT_OPTION,
  CREATE_RESCHEDULE_OPTION,
} from "../../api/gqlQueries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { userVar } from "../../cache";
import isSameDay from "date-fns/isSameDay";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}
const useStyles = makeStyles((theme) => ({
  input: {
    width: 225,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  spaceBelow: {
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.primary.main,
  },
  coloredText: {
    color: "#8CADE1",
    fontWeight: 700,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    paddingLeft: 14,
    paddingRight: 14,
  },
  select: {
    width: 273,
    marginTop: theme.spacing(1),
  },
}));

const MissionEmpShiftSwitchRequestForm = (props) => {
  const classes = useStyles();

  const {
    allEvents,
    userEvents,
    closeDialog,
    date,
    shiftSwitchBuffer,
    notifyDevelopers,
    environment,
  } = props;

  const user = userVar();

  const userJobTypes = user.skills
    .filter((skill) => skill.variety === "JOB_TYPE")
    .map((skill) => skill.name);

  const allShiftEvents = allEvents;
  const userShiftEvents = userEvents;

  const minimumDate = add(new Date(), { days: shiftSwitchBuffer });

  const [acceptOption] = useMutation(ACCEPT_OPTION, {
    onCompleted(data) {
      console.log(data);
      toastShow("Employee Shift Switch Successful", "Success");
      closeDialog();
    },
    onError(error) {
      console.log(error);
      toastShow(
        "Unable to create shift switch request. Please check details and try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ACCEPT_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [createSwapOption] = useMutation(CREATE_RESCHEDULE_OPTION, {
    onCompleted(data) {
      console.log(data);
      acceptOption({
        variables: {
          acceptor: parseInt(user.id),
          option: parseInt(data.createRescheduleOption.option.id),
        },
      });
    },
    onError(error) {
      console.log(error);
      toastShow(
        "Unable to create shift switch request. Please check details and try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_RESCHEDULE_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [
    rescheduleSwapOptions,
    { called: rescheduleCalled, loading: rescheduleLoading },
  ] = useLazyQuery(RESCHEDULE_SWAP_OPTIONS, {
    onCompleted(data) {
      console.log(data);
      if (data.rescheduleSwaps.length > 0) {
        const swapsWithOptionStatus = data.rescheduleSwaps.filter((swap) => {
          const past = swap.rescheduleindividualSet[0].rescheduleactionSet.find(
            (action) => new Date(action.shift.start) < minimumDate,
          );

          return swap.status === "OPTION" && !past;
        });
        setRescheduleOptionIds([]);
        setRescheduleSwapAddOptions(swapsWithOptionStatus);
        setManualEntry(false);
      } else {
        setRescheduleSwapAddOptions([]);
      }
    },
    onError(error) {
      console.log(error);
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on RESCHEDULE_SWAP_OPTIONS lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [manualEntry, setManualEntry] = useState(false);
  const [error, setError] = useState("");
  const [desiredDateError, setDesiredDateError] = useState("");
  const [currentDateError, setCurrentDateError] = useState("");
  const [currentDate, setCurrentDate] = useState();
  const [desiredDate, setDesiredDate] = useState();
  const [multipleShifts, setMultipleShifts] = useState([]);

  const [currentDateShiftAssignmentId, setCurrentDateShiftAssignmentId] =
    useState("");
  const [currentDateEmployees, setCurrentDateEmployees] = useState([]);
  const [desiredDateEmployees, setDesiredDateEmployees] = useState([]);
  const [eligibleEmployees, setEligibleEmployees] = useState([]);
  const [employeeToNotify, setEmployeeToNotify] = useState("select");

  const [rescheduleSwapAddOptions, setRescheduleSwapAddOptions] = useState([]);
  const [rescheduleOptionIds, setRescheduleOptionIds] = useState([]);

  const toggleManualEntry = () => setManualEntry(!manualEntry);

  const formattedUserScheduleDates = userEvents.map((event) =>
    format(new Date(event.start), "MM/dd/yyyy"),
  );

  const getParticipants = (date) => {
    const eventsOnDate = allShiftEvents.filter((event) =>
      isSameDay(event.start, date),
    );

    return eventsOnDate
      .map((event) => {
        if (event.tasks) {
          return event.tasks.map((task) => {
            return {
              ...task.shiftAssignment.employee,
              shift: {
                start: task.shiftAssignment.shift.start,
                end: task.shiftAssignment.shift.end,
                id: task.shiftAssignment.shift.id,
              },
            };
          });
        } else {
          return event.participants.map((participant) => {
            return {
              ...participant,
              shift: {
                start: event.start,
                end: event.end,
                id: event.eventId,
              },
            };
          });
        }
      })
      .flat();
  };

  useEffect(() => {
    if (currentDateShiftAssignmentId) {
      rescheduleSwapOptions({
        variables: {
          shiftAssignments: parseInt(currentDateShiftAssignmentId),
        },
      });
    }
  }, [currentDateShiftAssignmentId]);

  useEffect(() => {
    //grab date that is passed in from user selection on calendar
    const selectedDate =
      date && new Date(date) > minimumDate ? new Date(date) : minimumDate;
    selectedDate.setHours(0, 0, 0, 0);

    //find first user event and first nonUser event
    const userUpcomingEvents = userShiftEvents.filter(
      (event) =>
        event.start > selectedDate || isSameDay(event.start, selectedDate),
    );
    userUpcomingEvents.sort((a, b) => a.start - b.start);
    const userFirstEvent = userUpcomingEvents[0];

    if (userFirstEvent) {
      const sameDayShifts = userShiftEvents.filter((event) =>
        isSameDay(event.start, userFirstEvent.start),
      );

      let shiftAssignments = [];
      sameDayShifts.forEach((shift) => {
        const shiftAssignmentsForShift = getShiftAssignments(shift);

        shiftAssignments = [...shiftAssignments, ...shiftAssignmentsForShift];
      });
      shiftAssignments.length > 0
        ? setCurrentDateShiftAssignmentId(shiftAssignments[0].id)
        : setCurrentDateShiftAssignmentId("");
      setMultipleShifts(shiftAssignments);
    }

    const nonUserFirstEvent = allShiftEvents.filter(
      (event) =>
        !formattedUserScheduleDates.includes(
          format(new Date(event.start), "MM/dd/yyyy"),
        ) && event.start > minimumDate,
    )[0];

    //find employees eligible to make switch based on events
    if (userFirstEvent && nonUserFirstEvent) {
      const firstDateParticipants = getParticipants(userFirstEvent.start);
      const secondDateParticipants = getParticipants(nonUserFirstEvent.start);

      const eligibleEmployees = findEmployeesToSwitch(
        firstDateParticipants,
        secondDateParticipants,
        userJobTypes,
      );

      setCurrentDate(userFirstEvent.start);
      setDesiredDate(nonUserFirstEvent.start);

      //set state with all starting data
      setCurrentDateEmployees(firstDateParticipants);
      setDesiredDateEmployees(secondDateParticipants);
      setEligibleEmployees(eligibleEmployees);
    } else {
      toastShow("Non Eligible Shift Switch", "Error");
      closeDialog();
      return;
    }
  }, []);

  const getShiftAssignments = (shift) => {
    if (shift.tasks) {
      let shiftAssignments = [];

      const matches = shift.tasks.filter(
        (task) =>
          parseInt(task.shiftAssignment.employee.id) === parseInt(user.id),
      );

      if (matches.length > 0) {
        shiftAssignments = matches.map((match) => match.shiftAssignment);
      }
      return shiftAssignments;
    } else {
      let shiftAssignments = [];
      let matchingAssignment = shift.participants.find(
        (participant) => parseInt(participant.id) === parseInt(user.id),
      );
      if (matchingAssignment) {
        const newAssignment = {
          id: matchingAssignment.shiftAssignmentId,
          employee: {
            id: matchingAssignment.id,
            firstName: matchingAssignment.firstName,
            lastName: matchingAssignment.lastName,
          },
          shift: {
            id: shift.eventId,
            start: shift.start,
            end: shift.end,
          },
        };

        shiftAssignments = [...shiftAssignments, newAssignment];
      }

      return shiftAssignments;
    }
  };

  const handleCurrentDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      setCurrentDate(date);
      const sameDayShifts = userShiftEvents.filter((event) =>
        isSameDay(event.start, date),
      );

      if (sameDayShifts.length > 0) {
        setCurrentDateError("");
        let shiftAssignments = [];
        sameDayShifts.forEach((shift) => {
          const shiftAssignmentsForShift = getShiftAssignments(shift);

          shiftAssignments = [...shiftAssignments, ...shiftAssignmentsForShift];
        });

        shiftAssignments.length > 0
          ? setCurrentDateShiftAssignmentId(shiftAssignments[0].id)
          : setCurrentDateShiftAssignmentId("");
        setMultipleShifts(shiftAssignments);

        const newCurrentDateEmployees = getParticipants(sameDayShifts[0].start);

        setCurrentDateEmployees(newCurrentDateEmployees);
        const newEligible = findEmployeesToSwitch(
          newCurrentDateEmployees,
          desiredDateEmployees,
          userJobTypes,
        );

        setEmployeeToNotify("select");
        setEligibleEmployees(newEligible);
        setCurrentDateError("");
        setError("");
      } else {
        setCurrentDateError("You must choose a date that you are working");
        setEmployeeToNotify("select");
        setRescheduleOptionIds([]);
        setRescheduleSwapAddOptions([]);
      }
    }
  };

  const handleDesiredDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      if (!checkSecondDateInvalid(date)) {
        setDesiredDate(date);
        setEmployeeToNotify("select");

        const newEmployees = getParticipants(date);
        setDesiredDateEmployees(newEmployees);
        const newEligible = findEmployeesToSwitch(
          currentDateEmployees,
          newEmployees,
          userJobTypes,
        );
        setEligibleEmployees(newEligible);
        setError("");
        setDesiredDateError("");
      } else {
        setDesiredDateEmployees([]);
        setEligibleEmployees([]);
        setEmployeeToNotify("select");
        setDesiredDateError(
          "You must choose a desired date that you are not working",
        );
        setDesiredDate(date);
      }
    }
  };

  const handleSubmit = () => {
    if (rescheduleOptionIds.length > 0) {
      rescheduleOptionIds.forEach((id) => {
        acceptOption({
          variables: {
            acceptor: parseInt(user.id),
            option: parseInt(id),
          },
        });
      });
    } else if (employeeToNotify !== "select") {
      //manually create swap options
      const userShift = userShiftEvents.find((event) =>
        isSameDay(event.start, currentDate),
      );
      let userShiftId;
      if (userShift.tasks) {
        const matchingTask = userShift.tasks.find(
          (task) =>
            parseInt(task.shiftAssignment.employee.id) === parseInt(user.id),
        );
        userShiftId = matchingTask.shiftAssignment.shift.id;
      } else {
        userShiftId = userShift.eventId;
      }

      const employee = eligibleEmployees.find(
        (employee) => parseInt(employee.id) === parseInt(employeeToNotify),
      );

      if (userShift && employee) {
        const individuals = [
          {
            employee: parseInt(employee.id),
            cost: 0,
            benefit: 0,
            actions: [
              {
                actionType: "ADD",
                shift: userShiftId,
              },
              {
                actionType: "DROP",
                shift: employee.shift.id,
              },
            ],
          },
          {
            employee: parseInt(user.id),
            cost: 0,
            benefit: 0,
            actions: [
              {
                actionType: "DROP",
                shift: userShiftId,
              },
              {
                actionType: "ADD",
                shift: employee.shift.id,
              },
            ],
          },
        ];

        createSwapOption({
          variables: {
            optionType: "SWAP",
            individuals: individuals,
            userGenerated: true,
          },
        });
      }
    } else {
      setError("You must choose an employee to notify");
    }
  };

  const checkFirstDateInvalid = (date) => {
    const formatted = format(date, "MM/dd/yyyy");
    return !formattedUserScheduleDates.includes(formatted);
  };

  const checkSecondDateInvalid = (date) => {
    const formatted = format(date, "MM/dd/yyyy");
    return formattedUserScheduleDates.includes(formatted);
  };

  const eligibleToSave = Boolean(
    (rescheduleOptionIds.length > 0 || employeeToNotify !== "select") &&
      currentDate > minimumDate,
  );

  const eligibleEmployeesPresent = eligibleEmployees.length > 0;
  const rescheduleSwapOptionsPresent = rescheduleSwapAddOptions.length > 0;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div>
        <Grid item style={{ marginTop: 15 }}>
          <Typography variant="h6">Dates to Switch</Typography>
        </Grid>
        <Grid item container justifyContent="flex-start" spacing={2}>
          <Grid item>
            <InputLabel htmlFor="current-date">
              <Typography variant="h6">From</Typography>
            </InputLabel>
            <DatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              id="current-date"
              shouldDisableDate={checkFirstDateInvalid}
              value={currentDate || date}
              onChange={handleCurrentDateChange}
              minDate={new Date()}
              className={classes.input}
              renderInput={(props) => <TextField {...props} />}
            />
            {currentDateError && (
              <Typography variant="body2" className={classes.dateError}>
                {currentDateError}
              </Typography>
            )}
          </Grid>
          <Grid item>
            {multipleShifts.length > 0 && (
              <>
                <Typography variant="h6">Select Shift:</Typography>
                <Select
                  id="shift"
                  name="shift"
                  variant="outlined"
                  value={currentDateShiftAssignmentId}
                  className={classes.input}
                  disabled={multipleShifts.length === 1}
                  onChange={(e) =>
                    setCurrentDateShiftAssignmentId(e.target.value)
                  }
                >
                  {multipleShifts.map((shift) => (
                    <MenuItem key={shift.id} value={shift.id}>
                      {format(new Date(shift.shift.start), "HH:mm")}
                      {" - "}
                      {format(new Date(shift.shift.end), "HH:mm")}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </Grid>
        </Grid>
        {manualEntry && (
          <Grid item>
            <InputLabel htmlFor="desired-date">
              <Typography variant="h6">To</Typography>
            </InputLabel>
            <DatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              id="desired-date"
              shouldDisableDate={checkSecondDateInvalid}
              value={desiredDate}
              onChange={handleDesiredDateChange}
              minDate={minimumDate}
              className={classes.input}
              renderInput={(props) => <TextField {...props} />}
            />
            {desiredDateError && (
              <Typography variant="body2" className={classes.dateError}>
                {desiredDateError}
              </Typography>
            )}
          </Grid>
        )}
        <Grid item style={{ marginTop: 15 }}>
          {(rescheduleSwapOptionsPresent ||
            (manualEntry && eligibleEmployeesPresent)) && (
            <Typography className={classes.spaceBelow}>
              Select the employees you would like to send the request to:
            </Typography>
          )}

          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justifyContent="space-evenly"
            spacing={1}
          >
            {error && (
              <Grid item>
                <Typography className={classes.error}>{error}</Typography>
              </Grid>
            )}
            {rescheduleCalled && rescheduleLoading && <CircularProgress />}
            {
              manualEntry && (
                <Select
                  id="select-employee"
                  variant="outlined"
                  value={employeeToNotify}
                  onChange={(e) => setEmployeeToNotify(e.target.value)}
                  className={classes.select}
                >
                  <MenuItem value="select">Select Employee</MenuItem>
                  {eligibleEmployees.length > 0 ? (
                    eligibleEmployees.map((employee, index) => (
                      <MenuItem
                        key={`${employee.lastName}-${index}`}
                        value={employee.id}
                      >
                        <Typography>
                          {employee.firstName + " " + employee.lastName}
                          <span style={{ fontSize: 15, fontWeight: 500 }}>
                            {format(
                              new Date(employee.shift.start),
                              " dd MMM ",
                            ) +
                              format(new Date(employee.shift.start), "HH:mm") +
                              "-" +
                              format(new Date(employee.shift.end), "HH:mm")}
                          </span>
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={"none"}>
                      <Typography>No Eligible Employees</Typography>
                    </MenuItem>
                  )}
                </Select>
              )
              // <EmpSelectEmployeesForm
              //   employees={eligibleEmployees}
              //   setEmployees={setEmployeesToNotify}
              //   setError={setError}
              // />
            }
            {rescheduleSwapOptionsPresent && !manualEntry && (
              <EmpSelectEmployeesandDates
                rescheduleOptions={rescheduleSwapAddOptions}
                setRescheduleOptionIds={setRescheduleOptionIds}
                setError={setError}
              />
            )}
            {!rescheduleSwapOptionsPresent && !eligibleEmployeesPresent && (
              <Grid item>
                <Typography>
                  There are no shift switch options available. Please try a
                  different date.
                </Typography>
              </Grid>
            )}
            {!rescheduleSwapOptionsPresent && !manualEntry && (
              <Grid item>
                <Typography>
                  We could not find any algorithm-generated shift switch options
                  for this date.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          style={{
            marginTop: 20,
            zIndex: 4,
            position: "absolute",
            bottom: 25,
          }}
        >
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={toggleManualEntry}
              // disabled={
              //   !rescheduleSwapOptionsPresent &&
              //   !eligibleEmployeesPresent
              // }
            >
              {manualEntry ? "View Generated Options" : "View Manual Options"}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!eligibleToSave}
              style={{ width: 169, height: 39, marginRight: 60 }}
            >
              Switch Shifts
            </Button>
          </Grid>
        </Grid>
      </div>
    </LocalizationProvider>
  );
};

export default MissionEmpShiftSwitchRequestForm;
