import React, { useState, forwardRef, ForwardedRef } from "react";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import { ScheduleComponent, setTime } from "@syncfusion/ej2-react-schedule";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import { add, format } from "date-fns";
import {
  CREATE_EMPLOYEE_TIME_OFF,
  NOTIFY_DEVELOPERS,
  SLACK_FRAGMENT,
  EMPLOYEE_TIME_OFF_FRAGMENT,
  MANAGER_CREATE_EMPLOYEE_TIME_OFF,
  BATCH_MANAGER_CREATE_EMPLOYEE_TIME_OFF,
  GET_TIMEZONES,
} from "../../api/gqlQueries";
import { useMutation, useQuery } from "@apollo/client";
import { selectedDateVar, userVar } from "../../cache";
import Roles from "../../Roles/roles";
import EmployeeSearchBox from "../general/EmployeeSearchBox";
import { updateScheduleFromOption, updateTimeOffEvent } from "./UpdateEvents";
import { combineDateAndTime } from "../../helpers/formatTime";
import { DateTimePaginator } from "../../helpers/DateTimePaginator";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import { getSlackTransformer } from "./Transformer";
import { AvailabilityTypeNode, SelectedOffice, User } from "../../types";
import SearchDropdown from "../../helpers/SearchDropdown";
let toastObj;

function toastShow(content: string, type: string) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

const useStyles = makeStyles<Theme>((theme) => ({
  input: {
    minWidth: 138,
    maxWidth: 225,
  },
  employeeInput: {
    width: 225,
  },
  wideInput: {
    width: 575,
  },
  textField: {
    width: 50,
  },
  timeField: {
    width: 120,
  },
  error: {
    color: theme.palette.primary.main,
  },
  helperText: {
    color: theme.palette.text.secondary,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    marginTop: -4,
    paddingLeft: 14,
    paddingRight: 14,
  },
}));

/* Add time off on a given date
   start date and end date can span over a few days.
   user can select a part of the day or user can set the event to an all day event.
   user can delete their time off requests
*/

interface TimeOffRequestProps {
  closeDialog: () => void;
  scheduleEndDate: Date;
  invalidDates: string[];
  employees: any[];
  refetch: () => void;
  environment: string;
  SetAllDateTimeOffs: () => void;
  timeOffRequestsRefetch: () => void;
  availabilityTypes: AvailabilityTypeNode[];
  selectedOffice: SelectedOffice;
}

interface Office {
  id: string;
  name: string;
}

interface ShiftNode {
  id: string;
  start: string;
  end: string;
  procedure: string;
  office: Office;
}

interface IntervalNode {
  id: string;
  start: string;
  end: string;
  shifts: ShiftNode[];
}

interface SkillNode {
  id: string;
  name: string;
}

interface SlackNode {
  id: string;
  required: number;
  slack: number;
  numAssigned: number;
  interval: IntervalNode;
  skill: SkillNode;
}

interface SlackEvent {
  [date: string]: {
    [id: string]: SlackNode;
  };
}

interface CalendarRef extends ScheduleComponent {
  slackEvents: SlackEvent;
  // Include any other properties or methods for the ref object if needed
}

interface TimeZoneObject {
  label: string;
}

const TimeOffRequestForm = forwardRef(
  (props: TimeOffRequestProps, ref: ForwardedRef<CalendarRef>) => {
    const classes = useStyles();
    const {
      closeDialog,
      scheduleEndDate,
      invalidDates,
      employees,
      refetch,
      environment,
      SetAllDateTimeOffs,
      timeOffRequestsRefetch,
      availabilityTypes,
      selectedOffice,
    } = props;

    const user: User = userVar();
    const date = selectedDateVar();

    const managerAccess =
      user.role === Roles.MANAGER ||
      user.role === Roles.SCHEDULER ||
      user.role === Roles.ADMIN;

    const [employee, setEmployee] = useState(
      managerAccess ? null : employees.find((e) => e.id === user.id),
    );
    const [startDate, setStartDate] = useState(date);
    const [endDate, setEndDate] = useState(date);
    const [isAllDay, setIsAllDay] = useState(true);
    const [startTime, setStartTime] = useState<string | undefined>("00:00");
    const [endTime, setEndTime] = useState<string | undefined>("00:00");
    const [workHourInput, setWorkHourInput] = useState<string>("0");
    const [type, setType] = useState<string>("0");
    const [timezone, setTimezone] = useState<string>(selectedOffice.timezone);
    const [comment, setComment] = useState("");
    const [error, setError] = useState("");
    const [endError, setEndError] = useState("");
    const [startError, setStartError] = useState("");
    const minimumDate = managerAccess
      ? new Date()
      : scheduleEndDate
        ? add(new Date(scheduleEndDate), { days: 1 })
        : new Date();

    const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
      onError(error) {
        console.log(error);
      },
    });

    const tzResults = useQuery(GET_TIMEZONES, {
      onCompleted(d) {
        const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (d.getTimezones.includes(userTz)) {
          setTimezone(userTz);
        } else if (d.getTimezones.includes(selectedOffice.timezone)) {
          setTimezone(selectedOffice.timezone);
        } else {
          setTimezone("Etc/UTC");
        }
      },
    });

    const [managerCreateEmployeeTimeOff] = useMutation(
      MANAGER_CREATE_EMPLOYEE_TIME_OFF,
      {
        update(cache, { data: { managerInitiateTimeOffV2 } }) {
          if (
            managerInitiateTimeOffV2.result.__typename ===
            "ManagerCreateEmployeeTimeOffResult"
          ) {
            const paginator = new DateTimePaginator(1); // TODO: using in multiple spots need the same constructor
            const element = managerInitiateTimeOffV2.result.employeeTimeOff;
            const start = new Date(element.start);
            const end = new Date(element.end);
            const dates = paginator.getQueryDateRanges(start, end);
            dates.forEach((dateArray) => {
              const variables = {
                end: dateArray[1].toISOString(),
                officeId: selectedOffice.id,
                start: dateArray[0].toISOString(),
                status: ["APPROVED", "REQUESTED"],
              };
              const strVariables = JSON.stringify(variables);
              cache.modify({
                fields: {
                  employeeTimeOff: (existing, { storeFieldName }) => {
                    if (storeFieldName.includes(strVariables)) {
                      const newTimeOffRef = cache.writeFragment({
                        data: element,
                        fragment: EMPLOYEE_TIME_OFF_FRAGMENT,
                      });
                      return [...existing, newTimeOffRef];
                    } else {
                      return existing;
                    }
                  },
                },
              });
            });

            const newSlacks = getSlackTransformer(
              -1,
              -1,
            )(managerInitiateTimeOffV2.result.slacks);

            if (ref && "current" in ref && ref.current) {
              ref.current.slackEvents = {
                ...ref.current.slackEvents,
                ...newSlacks,
              };
            }

            interface SlackElement {
              interval: {
                start: string;
                end: string;
              };
            }

            managerInitiateTimeOffV2.result.slacks.forEach(
              (element: SlackElement) => {
                const start = new Date(element.interval.start);
                const end = new Date(element.interval.end);
                const dates = paginator.getQueryDateRanges(start, end);
                dates.forEach((date) => {
                  const variables = {
                    issuesOnly: true,
                    office: selectedOffice.id,
                    pageEnd: date[1].toISOString(),
                    pageStart: date[0].toISOString(),
                  };
                  const strVariables = JSON.stringify(variables);
                  cache.modify({
                    fields: {
                      intervalSlacks: (existing, { storeFieldName }) => {
                        /**
                         * Modifier function to update the cached query `intervalSlacks`.
                         *
                         * Queries may be in the cache multiple times if they were called with
                         * different arguments. This callback function will be called on each of
                         * those cached fields so we check whether the variables we are interested
                         * in is contained in the `storeFieldName` which is the full key that includes
                         * the serialized variables. N.B. checking if a string includes a substring
                         * means that the order of the variables and the type (int vs string) matter.
                         * https://www.apollographql.com/docs/react/caching/cache-interaction#examples
                         * https://www.apollographql.com/docs/react/api/cache/InMemoryCache#modify
                         *
                         * @param {SlackNode[]} existing the array of object currently in the cache.
                         * @param {String} storeFieldName the serialized full key of the field including variable arguments
                         *
                         * @returns {SlackNode[]} the new object appended to existing or the existing array.
                         */
                        if (storeFieldName.includes(strVariables)) {
                          const newSlackRef = cache.writeFragment({
                            data: element,
                            fragment: SLACK_FRAGMENT,
                          });
                          return [...existing, newSlackRef];
                        } else {
                          return existing;
                        }
                      },
                    },
                  });
                });
              },
            );
          }
        },
        onCompleted(data) {
          if (
            data.managerInitiateTimeOffV2.result.__typename ===
            "ManagerCreateEmployeeTimeOffResult"
          ) {
            if (ref && "current" in ref && ref.current) {
              updateTimeOffEvent(
                ref.current,
                data.managerInitiateTimeOffV2.result.employeeTimeOff,
                null,
                selectedOffice.name,
              );
              updateScheduleFromOption(
                ref.current,
                data.managerInitiateTimeOffV2.result.impactedShifts,
                selectedOffice.name,
              );
              ref.current.refreshTemplates("dateHeaderTemplate");
            }

            toastShow("New Time Off Request created", "Success");
            closeDialog();
          } else {
            toastShow(data.managerInitiateTimeOffV2.result.message, "Error");
          }
        },
        onError(error) {
          console.log(error);
          toastShow(
            "Unable to create new time off request. Please check dates and try again.",
            "Error",
          );
          notifyDevelopers({
            variables: {
              message:
                "Error on MANAGER_CREATE_EMPLOYEE_TIME_OFF Mutation. Environment: " +
                environment +
                ". Graphql " +
                error,
            },
          });
        },
      },
    );

    //TO DO, update cache for batch mutation
    const [batchManagerEmployeeCreateTimeOff] = useMutation(
      BATCH_MANAGER_CREATE_EMPLOYEE_TIME_OFF,
      {
        onCompleted(data) {
          if (ref && "current" in ref && ref.current) {
            updateTimeOffEvent(
              ref.current,
              data.batchManagerInitiateTimeOffv2.result.tors,
              null,
              selectedOffice.name,
            );
          }
          // TODO: data will be an array of requests as opposed to an object
          toastShow("New Time Off Request created", "Success");
          closeDialog();
        },
        onError(error) {
          console.log(error);
          toastShow(
            "Unable to create new time off request. Please check dates and try again.",
            "Error",
          );
          notifyDevelopers({
            variables: {
              message:
                "Error on BATCH_MANAGER_CREATE_EMPLOYEE_TIME_OFF Mutation. Environment: " +
                environment +
                ". Graphql " +
                error,
            },
          });
        },
      },
    );

    const [createEmployeeTimeOff] = useMutation(CREATE_EMPLOYEE_TIME_OFF, {
      update(cache, { data: { createEmployeeTimeOff } }) {
        if (
          createEmployeeTimeOff.result.__typename ===
          "CreateEmployeeTimeOffResult"
        ) {
          const paginator = new DateTimePaginator(1); // TODO: using in multiple spots need the same constructor
          const element = createEmployeeTimeOff.result.employeeTimeOff;
          const start = new Date(element.start);
          const end = new Date(element.end);
          const dates = paginator.getQueryDateRanges(start, end);
          dates.forEach((dateArray) => {
            const variables = {
              end: dateArray[1].toISOString(),
              officeId: selectedOffice.id,
              start: dateArray[0].toISOString(),
              status: ["APPROVED", "REQUESTED"],
            };
            const strVariables = JSON.stringify(variables);
            cache.modify({
              fields: {
                employeeTimeOff: (existing, { storeFieldName }) => {
                  if (storeFieldName.includes(strVariables)) {
                    const newTimeOffRef = cache.writeFragment({
                      data: element,
                      fragment: EMPLOYEE_TIME_OFF_FRAGMENT,
                    });
                    return [...existing, newTimeOffRef];
                  } else {
                    return existing;
                  }
                },
              },
            });
          });
        }
      },
      onCompleted(data) {
        if (
          data.createEmployeeTimeOff.result.__typename ===
          "CreateEmployeeTimeOffResult"
        ) {
          if (ref && "current" in ref && ref.current) {
            updateTimeOffEvent(
              ref.current,
              data.createEmployeeTimeOff.result.employeeTimeOff,
              null,
              selectedOffice.name,
            );
          }

          toastShow("New Time Off Request created", "Success");
          closeDialog();
        } else {
          toastShow(data.createEmployeeTimeOff.result.message, "Error");
        }
      },
      onError(error) {
        console.log(error);
        toastShow(
          "Unable to create new time off request. Please check dates and try again. Type of request is required.",
          "Error",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on CREATE_EMPLOYEE_TIME_OFF Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    });

    type Event = {
      typeId: string | null;
      workHours: number;
      comment: string;
      timeZone: string;
      employee: number | undefined;
      startDate: string;
      endDate: string;
      startTime: string | undefined;
      endTime: string | undefined;
      employeeId: number | undefined;
      managerId: number | undefined;
    };

    const handleSubmit = () => {
      // set timezone in precedence user tz, office tz, default to GMT
      // let timezone = "";
      // const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // if (userTz) {
      //   timezone = userTz;
      // } else if (selectedOffice.timezone) {
      //   timezone = selectedOffice.timezone;
      // } else {
      //   timezone = "Etc/GMT";
      // }

      const formattedStartDate = startDate.toISOString().split("T")[0];
      const formattedEndDate = endDate.toISOString().split("T")[0];

      let newEvent: Event = {
        typeId: type,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        startTime: isAllDay ? undefined : startTime,
        endTime: isAllDay ? undefined : endTime,
        workHours: parseInt(workHourInput),
        comment: comment,
        timeZone: timezone,
        employee: undefined,
        employeeId: undefined,
        managerId: undefined,
      };

      if (managerAccess) {
        newEvent.employeeId = parseInt(employee.id);
        newEvent.managerId = parseInt(user.id);
        managerCreateEmployeeTimeOff({ variables: { input: { ...newEvent } } });
      } else {
        newEvent.employee = parseInt(user.id);
        createEmployeeTimeOff({ variables: { ...newEvent } });
      }
    };

    //check to see if user already has a request scheduled that date
    const checkInvalid = (date: Date) => {
      if (employee !== user.id) {
        return false;
      } else {
        const formatted = format(date, "MM/dd/yyyy");
        return invalidDates.includes(formatted);
      }
    };

    const eligibleToSave = Boolean(
      !checkInvalid(startDate) &&
        !checkInvalid(endDate) &&
        !(endDate < startDate) &&
        type != "0" &&
        employee,
    );

    const handleUpdateStart = (date: Date | null) => {
      if (date && !isNaN(date.getTime())) {
        const invalid = checkInvalid(date);
        if (!invalid || employee !== user.id) {
          setStartDate(date);
          setStartError("");
          if (date > endDate) {
            setEndDate(date);
          }
        } else {
          setStartError(
            "You must choose a date that does not already have a request",
          );
        }
      }
    };

    const handleUpdateEnd = (date: Date | null) => {
      if (date && !isNaN(date.getTime())) {
        const invalid = checkInvalid(date);
        if (!invalid || employee !== user.id) {
          setEndDate(date);
          setEndError("");
        } else {
          setEndError(
            "You must choose a date that does not already have a request",
          );
        }
      }
    };

    const handleWorkHoursChange = (event: any) => {
      setWorkHourInput(event.target.value);
    };

    //if isAllDay is true, set start and end times to empty strings
    //if isAllDay is false, set end date to equal start date
    const handleIsAllDayChange = (e: any) => {
      setIsAllDay(e.target.checked);
      e.target.checked && setStartTime("00:00");
      e.target.checked && setEndTime("00:00");
    };

    if (tzResults.loading) {
      return <CircularProgress color="primary" />;
    } else {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid
            container
            direction="column"
            spacing={2}
            data-testid="time-off-request-form"
          >
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography variant="h3">Add Calendar Event</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="close"
                  color="secondary"
                  size="small"
                  onClick={closeDialog}
                  data-testid="closeTimeOffFormShift"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAllDay}
                    onChange={handleIsAllDayChange}
                    name="isAllDay"
                    color={isAllDay ? "primary" : "secondary"}
                    data-testid="allDaySwitchTimeOffFormShift"
                  />
                }
                label="All Day"
              />
            </Grid>
            {managerAccess && (
              <Grid item>
                <EmployeeSearchBox
                  options={employees}
                  name="employeesToSelect"
                  id="employeesToSelect"
                  placeholder="Filter by Employees"
                  setEmployee={setEmployee}
                  val={employee}
                  datatestid="searchEmployeeTimeOffFormShift"
                />
              </Grid>
            )}
            <Grid item container justifyContent="flex-start" spacing={2}>
              <Grid item>
                <InputLabel htmlFor="start-date">
                  <Typography variant="h6">Start Date:</Typography>
                </InputLabel>
                <div data-testid="startDateTimeOffFormShift">
                  <DatePicker
                    inputFormat="MM/dd/yyyy"
                    shouldDisableDate={checkInvalid}
                    minDate={minimumDate}
                    value={startDate}
                    onChange={handleUpdateStart}
                    className={classes.input}
                    renderInput={(props) => {
                      return (
                        <TextField
                          {...props}
                          variant="outlined"
                          id="start-date"
                        />
                      );
                    }}
                  />
                </div>

                {startError && (
                  <Typography variant="body2" className={classes.dateError}>
                    {startError}
                  </Typography>
                )}
              </Grid>
              {!isAllDay && (
                <>
                  <Grid item>
                    <InputLabel htmlFor="start-time">
                      <Typography variant="h6">Start Time:</Typography>
                    </InputLabel>
                    <TextField
                      id={"start-time"}
                      name={"startTime"}
                      variant="outlined"
                      value={startTime}
                      // does this logic work properly? can't a end time be less than a start time
                      // if the end date is after the start date?
                      onChange={(e) => {
                        setStartTime(e.target.value);
                      }}
                      type="time"
                      disabled={isAllDay}
                      className={classes.timeField}
                      data-testid="startTimeTimeOffFormShift"
                    />
                  </Grid>
                </>
              )}
              {/* <Grid item>
                <InputLabel htmlFor="end-date">
                  <Typography variant="h6">End Date:</Typography>
                </InputLabel>
                <div data-testid="endDateTimeOffFormShift">
                  {" "}
                  <DatePicker
                    inputFormat="MM/dd/yyyy"
                    shouldDisableDate={checkInvalid}
                    minDate={startDate}
                    value={endDate}
                    onChange={handleUpdateEnd}
                    className={classes.input}
                    renderInput={(props) => {
                      return (
                        <TextField
                          {...props}
                          variant="outlined"
                          id="end-date"
                        />
                      );
                    }}
                  />
                </div>

                {endError && (
                  <Typography variant="body2" className={classes.dateError}>
                    {endError}
                  </Typography>
                )}
              </Grid> */}
            </Grid>

            <Grid item container justifyContent="flext-start" spacing={2}>
              <Grid item>
                <InputLabel htmlFor="end-date">
                  <Typography variant="h6">End Date:</Typography>
                </InputLabel>
                <div data-testid="endDateTimeOffFormShift">
                  {" "}
                  <DatePicker
                    inputFormat="MM/dd/yyyy"
                    shouldDisableDate={checkInvalid}
                    minDate={startDate}
                    value={endDate}
                    onChange={handleUpdateEnd}
                    className={classes.input}
                    renderInput={(props) => {
                      return (
                        <TextField
                          {...props}
                          variant="outlined"
                          id="end-date"
                        />
                      );
                    }}
                  />
                </div>

                {endError && (
                  <Typography variant="body2" className={classes.dateError}>
                    {endError}
                  </Typography>
                )}
              </Grid>
              {!isAllDay && (
                <>
                  {" "}
                  <Grid item>
                    <InputLabel htmlFor="end-time">
                      <Typography variant="h6">End Time:</Typography>
                    </InputLabel>
                    <TextField
                      id={"end-time"}
                      name={"endTime"}
                      variant="outlined"
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                      type="time"
                      disabled={isAllDay}
                      className={classes.timeField}
                      data-testid="endTimeTimeOffFormShift"
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Grid item container spacing={2} alignItems="center">
              <Grid item>
                <InputLabel htmlFor="timezone">
                  <Typography variant="h6">Timezone:</Typography>
                </InputLabel>
                <SearchDropdown
                  options={tzResults.data.getTimezones.map(
                    (timezone: string) => ({
                      label: timezone,
                    }),
                  )}
                  value={timezone ? timezone : null}
                  placeholderText="Select a Timezone"
                  onValueChange={(selectedValue: TimeZoneObject) => {
                    return setTimezone(selectedValue?.label);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={2} alignItems="center">
              <Grid item>
                <InputLabel htmlFor="type">
                  <Typography variant="h6">Type of Request:</Typography>
                </InputLabel>
              </Grid>
              <Grid item>
                <Select
                  id="type"
                  name="type"
                  variant="outlined"
                  type="number"
                  value={type}
                  className={classes.input}
                  onChange={(e) => setType(e.target.value)}
                  data-testid="requestsTypeTimeOffFormShift"
                >
                  {availabilityTypes && availabilityTypes.length > 0
                    ? availabilityTypes.map((type) => (
                        <MenuItem key={type.name + type.id} value={type.id}>
                          {type.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </Grid>
            </Grid>
            <Grid item container spacing={2} alignItems="center">
              <Grid item>
                <InputLabel htmlFor="work-hours">
                  <Typography variant="h6">Hours Used:</Typography>
                </InputLabel>
              </Grid>
              <Grid item>
                <TextField
                  id="work-hours"
                  name="work-hours"
                  variant="outlined"
                  type="number"
                  value={workHourInput}
                  onChange={handleWorkHoursChange}
                  data-testid="workhoursTimeOffFormShift"
                />
              </Grid>
            </Grid>
            <Grid item>
              <InputLabel htmlFor="comment">
                <Typography variant="h6">Comment:</Typography>
              </InputLabel>
              <TextField
                id="comment"
                name="comment"
                variant="outlined"
                value={comment}
                className={classes.wideInput}
                onChange={(e) => setComment(e.target.value)}
                data-testid="commentTimeOffFormShift"
              />
            </Grid>
            <Grid item>
              {error && (
                <Typography className={classes.error}>{error}</Typography>
              )}
            </Grid>
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!eligibleToSave}
                  data-testid="saveTimeOffFormShift"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </LocalizationProvider>
      );
    }
  },
);

export default TimeOffRequestForm;
