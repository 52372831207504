import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { userVar } from "../../cache";
import MultiSelect from "../general/MultiSelectSkills";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EmployeeGrid = (props) => {
  const { onTreeDragStop, onItemDrag, employeeResources, skills } = props;

  const user = userVar();

  const treeTemplate = (props) => {
    return (
      <div id="waiting">
        <div id="waitlist">
          <div id="waitdetails">
            <b>{props.name}</b>
            <div style={{ fontSize: 10, color: "#DODODO" }}>
              {props.skills &&
                props.skills.length > 0 &&
                props.skills.map((e) => e.name).join(", ")}{" "}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [selectedSkills, SetSelectedSkills] = useState([]);
  const [fields, SetFields] = useState({
    dataSource: employeeResources
      ? user.isEmployee === true
        ? [employeeResources.find((e) => e.id === user.id)]
        : employeeResources
      : [],
    id: "id",
    subject: { name: "eventTitle" },
  });

  useEffect(() => {
    setTimeout(() => {
      SetFields({
        dataSource:
          user.isEmployee === true
            ? [employeeResources.find((e) => e.id === user.id)]
            : employeeResources,
        id: "id",
        subject: { name: "subject" },
      });
    }, 100);
  }, [
    (employeeResources && employeeResources.length) || selectedSkills.length,
  ]);

  const filterBySkillsandEmployees = (e) => {
    SetSelectedSkills(e);
    if (e.length <= 0) {
      SetFields({
        dataSource:
          user.isEmployee === true
            ? [employeeResources.find((e) => e.id === user.id)]
            : employeeResources,
        id: "id",
        subject: { name: "subject" },
      });
    } else {
      let skillNames = e.map((s) => s.name);
      SetFields({
        dataSource: employeeResources
          .filter((employee) => {
            let skillpresent =
              employee.skills &&
              employee.skills.length > 0 &&
              employee.skills
                .map((skill) => {
                  if (skillNames.includes(skill.name)) {
                    return true;
                  }
                })
                .filter(Boolean);
            let emppresent = e.find((emp) => emp.id === employee.id);
            if (emppresent) {
              return employee;
            }
            if (skillpresent && skillpresent.includes(true)) {
              return employee;
            }
          })
          .filter(Boolean),
        id: "id",
        subject: { name: "subject" },
      });
    }
  };

  return (
    <Grid
      item
      xs={2}
      style={{ height: window.innerHeight - 100, overflow: "scroll" }}
      data-testid="employeeListForManualScheduling"
    >
      <div className="treeview-container">
        <div className="title-container">
          <div data-testid="employeeorskillSearch">
            <MultiSelect
              options={
                user.isEmployee
                  ? [employeeResources.find((x) => x.id === user.id)]
                  : [...skills, ...employeeResources]
              }
              name="skills"
              id="skills"
              placeholder="Enter Skills or Employee"
              onChange={filterBySkillsandEmployees}
              val={selectedSkills}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              autoClose={true}
            />
          </div>
          <TreeViewComponent
            id="e-treeview"
            cssClass="treeview-external-drag"
            dragArea=".drag-sample-wrapper"
            nodeTemplate={treeTemplate}
            fields={fields}
            nodeDragStop={onTreeDragStop}
            nodeDragging={onItemDrag}
            allowDragAndDrop={true}
          />
        </div>
      </div>
    </Grid>
  );
};

export default EmployeeGrid;
