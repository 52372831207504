import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  Alert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Reset_Password } from "../../api/gqlQueries";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { NOTIFY_DEVELOPERS } from "../../api/gqlQueries";
import { resetPassword } from "aws-amplify/auth";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    marginTop: "200px",
  },
}));

const validationSchema = Yup.object({
  email: Yup.string().required("Required").email(),
});

function ForgotPassword() {
  let history = useHistory();
  const classes = useStyles();
  const environment = process.env.NODE_ENV;
  const [emailToSend, setEmailToSend] = useState("");
  const [loader, setloader] = useState(false);

  // const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
  //   onError(error) {
  //     console.log(error);
  //   },
  // });

  async function handleResetPassword(username) {
    try {
      await resetPassword({ username }).then(() => {
        history.push("/VerifyCode");
        toastShow(
          "A verification code has been sent to your email.",
          "Success",
        );
      });
    } catch (error) {
      toastShow(
        "We were unable to handle your request. Please refresh to try again.",
        "Error",
      );
    }
  }

  const handleemailsend = (email) => {
    const url =
      "https://amitjoxyq4.execute-api.us-east-1.amazonaws.com/Prod/sendpasswordreset";
    const data = {
      email: email,
      backend: window.location.origin,
    };
    axios({
      method: "post",
      url: url,
      data: data,
    })
      .then(function () {
        toastShow("Password Reset Email Sent", "Success");
      })
      .catch(function () {
        toastShow(
          "Something went wrong.Could not send password reset email",
          "Error",
        );
      });
  };

  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
          handleResetPassword(values.email);
        }}
      >
        {({ handleSubmit, values, errors, setFieldValue, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="row"
              className={classes.root}
              spacing={4}
            >
              <Grid item xs={6}>
                <Typography variant="h3">Forgot Password</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  component={Link}
                  to="/"
                >
                  <IconButton color="primary" size="large">
                    <HomeIcon />
                  </IconButton>
                  Back to Login
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="email"
                  name="email"
                  style={{ width: "50%" }}
                  value={values.email}
                  onChange={(e) => {
                    setEmailToSend(e.target.value);
                    setFieldValue("email", e.target.value);
                  }}
                  data-testid="userEmailForgotPassword"
                  variant="outlined"
                  placeholder="Enter Login Email"
                />
                {loader && <CircularProgress color="primary" />}
                <Typography
                  color="primary"
                  data-testid="emailErrorForgotPassword"
                >
                  {errors.email}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  data-testid="forgotPasswordSubmit"
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
}

export default ForgotPassword;
