import React, { useState } from "react";
import { Grid, Button, Popover, MenuItem } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";

const Download = () => {
  const [openDownload, setopenDownload] = useState(null);
  const openD = Boolean(openDownload);
  const anchorId = openD ? "download-popover" : undefined;

  const ExportToICS = () => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.exportToICalendar();
  };
  const ExportToExcel = () => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.exportToExcel();
  };

  return (
    <>
      <Grid item>
        <Button
          color="secondary"
          aria-describedby={anchorId}
          onClick={(event) => setopenDownload(event.currentTarget)}
          data-testid="DownloadEvents"
        >
          <GetAppIcon style={{ marginRight: 5 }} /> Download
        </Button>
      </Grid>
      <Grid>
        <Popover
          id={anchorId}
          anchorEl={openDownload}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          open={openD}
          onClose={() => setopenDownload(null)}
          style={{ overflow: "auto" }}
        >
          <MenuItem
            color="secondary"
            target="_blank"
            onClick={ExportToICS}
            style={{ marginTop: -8 }}
            data-testid="exportToCalendar"
          >
            Export to my Calendar
          </MenuItem>
          <MenuItem
            color="secondary"
            target="_blank"
            onClick={ExportToExcel}
            style={{ marginTop: -8 }}
            data-testid="exportToExcel"
          >
            Export to Excel
          </MenuItem>
        </Popover>
      </Grid>
    </>
  );
};

export default Download;
