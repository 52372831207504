import React, { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { add, format } from "date-fns";
import {
  RESCHEDULE_SWAP_OPTIONS,
  ACCEPT_OPTION,
  FORCE_OPTIONS_FOR_EMPLOYEE,
  CREATE_RESCHEDULE_OPTION,
  GET_OFFICE_SCHEDULE_STATUS,
} from "../../api/gqlQueries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { userVar, forceOptionsExecution } from "../../cache";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import EmpSelectEmployeesandDates from "./EmpSelectEmployeesandDates";
import RescheduleOptionsKey from "../rescheduling/RescheduleOptionsKey";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}
const useStyles = makeStyles((theme) => ({
  spaceBelow: {
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.primary.main,
  },
  coloredText: {
    color: "#8CADE1",
    fontWeight: 700,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    paddingLeft: 14,
    paddingRight: 14,
  },
  select: {
    width: 300,
  },
}));

const EmpShiftSwitchRequestForm = forwardRef((props, ref) => {
  const classes = useStyles();

  const {
    closeDialog,
    shiftSwitchBuffer,
    notifyDevelopers,
    environment,
    selectedOffice,
    selectedShift,
  } = props;
  const user = userVar();

  //get user's skills
  const userJobTypes = new Set(
    selectedShift.employee.skillSet.map((skill) => skill.name),
  );

  // get minimum Date for which the employee can create a shift swicth. This is an office setting
  const minimumDate = add(new Date(), { days: shiftSwitchBuffer });

  const [acceptOption] = useMutation(ACCEPT_OPTION, {
    onCompleted(data) {
      toastShow("Employee Shift Switch successful", "Success");
      closeDialog();
    },
    onError(error) {
      console.log(error);
      toastShow(
        "Unable to create shift switch request. Please check details and try again.",
        "Error",
      );

      notifyDevelopers({
        variables: {
          message:
            "Error on ACCEPT_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [forceOptionsFlag, setForceOptionsFlag] = useState(false);

  const officeScheduleStatus = useQuery(GET_OFFICE_SCHEDULE_STATUS, {
    variables: {
      id: parseInt(selectedOffice.id),
    },
    onCompleted(d) {
      console.log(d);
    },
    onError(err) {
      console.log(err.message);
    },
  });

  const [createSwapOption] = useMutation(CREATE_RESCHEDULE_OPTION, {
    onCompleted(data) {
      acceptOption({
        variables: {
          acceptor: parseInt(user.id),
          option: parseInt(data.createRescheduleOption.option.id),
        },
      });
    },
    onError(error) {
      toastShow(
        "Unable to create shift switch request. Please check details and try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_RESCHEDULE_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [rescheduleSwapOptions, { loading: rescheduleSwapOptionsLoading }] =
    useLazyQuery(RESCHEDULE_SWAP_OPTIONS, {
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        if (data.rescheduleSwaps.length > 0) {
          const swapsWithOptionStatus = data.rescheduleSwaps.filter((swap) => {
            const past =
              swap.rescheduleindividualSet[0].rescheduleactionSet.find(
                (action) => new Date(action.shift.start) < minimumDate,
              );

            return swap.status === "OPTION" && !past;
          });

          if (forceOptionsFlag === true) {
            toastShow(
              "Our Algorithm has generated some swap options for " +
                selectedShift.employee.firstName +
                " " +
                selectedShift.employee.lastName +
                " on " +
                format(selectedShift.start, " dd-MMM-yyyy."),
              "Success",
            );
          }

          setRescheduleOptionIds([]);
          setRescheduleSwapAddOptions(swapsWithOptionStatus);
        } else {
          if (forceOptionsFlag === true) {
            toastShow(
              "Our Algorithm could not generate options for you. Please try manual shift swap.",
            );
          }
          setRescheduleSwapAddOptions([]);
        }

        officeScheduleStatus.stopPolling();
        setForceOptionsFlag(false);
        forceOptionsExecution({ started: false });
      },
      onError(error) {
        console.log(error);
        toastShow(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
          "Error",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on RESCHEDULE_SWAP_OPTIONS lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    });

  useEffect(() => {
    rescheduleSwapOptions({
      variables: {
        shiftAssignments: parseInt(selectedShift.employee.shiftAssignmentId),
      },
    });
  }, []);

  useEffect(() => {
    if (officeScheduleStatus.data) {
      if (officeScheduleStatus.data.offices[0].schedulingStatus === "READING") {
        setForceOptionsFlag(true);
      }
      if (
        officeScheduleStatus.data.offices[0].schedulingStatus === "COMPLETE" &&
        forceOptionsFlag === true
      ) {
        rescheduleSwapOptions({
          variables: {
            shiftAssignments: parseInt(
              selectedShift.employee.shiftAssignmentId,
            ),
          },
        });
      }
    }
  }, [officeScheduleStatus.data]);

  const [forceOptions] = useMutation(FORCE_OPTIONS_FOR_EMPLOYEE, {
    onCompleted(data) {
      if (data.forceOptions.successful) {
        officeScheduleStatus.startPolling(5000);
        toastShow(
          "Our Algorithm is generating some options and we will have them for you momentarily.",
          "Success",
        );
      }
    },
    onError(error) {
      console.log(error);
      toastShow(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on FORCE_OPTIONS_FOR_EMPLOYEE Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [error, setError] = useState("");
  const currentDate = selectedShift.start;
  const currentDateSelectedEmployee = selectedShift.employee.id;
  const [eligibleEmployees, setEligibleEmployees] = useState([]);
  const [employeeToNotify, setEmployeeToNotify] = useState("select");

  const [rescheduleSwapAddOptions, setRescheduleSwapAddOptions] = useState([]);
  const [rescheduleOptionIds, setRescheduleOptionIds] = useState([]);

  const handleSubmit = () => {
    if (rescheduleOptionIds.length > 0) {
      rescheduleOptionIds.forEach((id) => {
        acceptOption({
          variables: {
            acceptor: parseInt(user.id),
            option: parseInt(id),
          },
        });
      });
    } else if (employeeToNotify !== "select") {
      //manually create swap options
      const individuals = [
        {
          employee: parseInt(employeeToNotify),
          cost: 0,
          benefit: 0,
          actions: [
            {
              actionType: "ADD",
              shift: selectedShift.shiftId,
            },
            {
              actionType: "DROP",
              shift: shift.shiftId,
            },
          ],
        },
        {
          employee: parseInt(user.id),
          cost: 0,
          benefit: 0,
          actions: [
            {
              actionType: "DROP",
              shift: selectedShift.shiftId,
            },
            {
              actionType: "ADD",
              shift: shift.shiftId,
            },
          ],
        },
      ];

      createSwapOption({
        variables: {
          optionType: "SWAP",
          individuals: individuals,
          userGenerated: true,
        },
      });
    } else {
      setError("You must choose an employee to notify");
    }
  };

  const eligibleToSave = Boolean(
    (rescheduleOptionIds.length > 0 || employeeToNotify !== "select") &&
      currentDate > minimumDate,
  );

  const eligibleEmployeesPresent =
    eligibleEmployees && eligibleEmployees.length > 0;

  const rescheduleSwapOptionsPresent =
    rescheduleSwapAddOptions.filter(
      (option) => option.rescheduleindividualSet.length >= 1,
    ).length > 0;

  if (rescheduleSwapOptionsLoading) {
    return <CircularProgress />;
  } else {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid>
          <Grid container direction="row">
            <Grid item>
              <Typography variant="h4">Current Shift Information</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={6} style={{ marginTop: 16 }}>
              <Typography variant="h6">Employee Name</Typography>
              <Typography variant="body1">
                {selectedShift.employee.firstName +
                  " " +
                  selectedShift.employee.lastName}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 16 }}>
              <Typography variant="h6">Shift time and date</Typography>
              <Typography variant="body1">
                {format(selectedShift.start, "MMMM dd yyyy hh:mm a") +
                  " to " +
                  format(selectedShift.end, "MMMM dd yyyy hh:mm a")}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 16 }}>
              <Typography variant="h6">Location</Typography>
              <Typography variant="body1">
                {selectedShift.officeName}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 16 }}>
              <Typography variant="h6">Skill</Typography>
              <Typography variant="body1">
                {userJobTypes.map((e) => e).join(", ")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 16 }}>
            <Typography variant="h6">Dates to Switch</Typography>
          </Grid>
          <Grid container>
            <Grid
              item
              container
              direction="column"
              alignItems="flex-start"
              justifyContent="space-evenly"
              spacing={1}
            >
              {rescheduleSwapOptionsPresent && (
                <Grid item>
                  <Typography
                    className={classes.error}
                    data-testid="errorEmpShiftSwitchForm"
                  >
                    {error}
                  </Typography>
                </Grid>
              )}
              {(rescheduleSwapOptionsPresent || eligibleEmployeesPresent) && (
                <Grid item container justifyContent="flex-start">
                  <RescheduleOptionsKey type="switch" />
                </Grid>
              )}
              {(rescheduleSwapOptionsPresent || eligibleEmployeesPresent) && (
                <Typography className={classes.spaceBelow}>
                  Select the employees you would like to send the request to:
                </Typography>
              )}
              {rescheduleSwapOptionsPresent && (
                <Grid
                  container
                  style={{
                    marginTop: 16,
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  <EmpSelectEmployeesandDates
                    rescheduleOptions={rescheduleSwapAddOptions}
                    setRescheduleOptionIds={setRescheduleOptionIds}
                    setError={setError}
                    currentDateSelectedEmployee={currentDateSelectedEmployee}
                  />
                </Grid>
              )}
              {!rescheduleSwapOptionsPresent && (
                <Grid item>
                  <Typography>
                    We could not find any algorithm-generated shift switch
                    options for this date. Press the button below to generate
                    options for this date.
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      forceOptions({
                        variables: {
                          employee: parseInt(selectedShift.employee.id),
                          shifts: [selectedShift.shiftId],
                        },
                      });
                      forceOptionsExecution({
                        started: true,
                      });
                    }}
                    data-testid="forceOptionsMangShiftSwitch"
                    disabled={
                      forceOptionsExecution().started === true ? true : false
                    }
                  >
                    {forceOptionsExecution().started === true && (
                      <CircularProgress />
                    )}
                    Request Additional Options
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!eligibleToSave}
              style={{
                width: 169,
                height: 39,
                margin: 20,
              }}
              data-testid="submitShiftSwitchForm"
            >
              Switch Shifts
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    );
  }
});

export default EmpShiftSwitchRequestForm;
