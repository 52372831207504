import {
  ColumnDirective,
  ColumnsDirective,
  ExcelExport,
  GridComponent,
  Filter,
  Inject,
  Page,
  Sort,
  CommandColumn,
  Toolbar,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import "../../AllStyles/Grid.css";
import { getComponent, L10n } from "@syncfusion/ej2-base";

const useStyles = makeStyles((theme) => ({
  underText: {
    fontWeight: "bold",
  },
  timeText: {
    paddingRight: 0,
  },
  underTimeText: {
    fontWeight: "bold",
    paddingRight: 0,
  },
  table: {
    minWidth: "auto",
  },
  icon: {
    padding: 0,
    marginRight: 5,
  },
}));

const PayrollReportTable = ({ payrollData }) => {
  const toolbarOptions = ["Search", "Print", "ExcelExport"];
  const searchOptions = {
    operator: "contains",
    key: "",
  };

  useEffect(() => {
    let gridObj = getComponent(
      document.getElementById("payroll-data-grid"),
      "grid",
    );
    if (gridObj) {
      gridObj.enablePersistence = false;
      gridObj.searchSettings.key = "";
      window.localStorage.setItem("gridGrid", "");
      window.localStorage.setItem("payroll-griddata-grid", "");
    }
  }, []);

  L10n.load({
    "en-US": {
      grid: {
        EmptyRecord: "No records found.",
        enablePersistence: false,
        clearFilters: true,
      },
    },
  });
  return (
    <div>
      <GridComponent
        id="payroll-data-grid"
        name="data-grid-payroll-report"
        dataSource={payrollData}
        pageSettings={{
          pageSize: 10,
          pageSizes: true,
        }}
        allowSorting={true}
        enableHover={true}
        rowHeight={35}
        height="auto"
        allowExcelExport={true}
        enablePersistence={true}
        showColumnChooser={true}
        toolbar={toolbarOptions}
        searchSettings={searchOptions}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="employee.id"
            textAlign="Center"
            visible={false}
            isPrimaryKey={true}
            showInColumnChooser={false}
          ></ColumnDirective>
          <ColumnDirective
            field="employee.lastName"
            headerText="Last Name"
            textAlign="Center"
          ></ColumnDirective>
          <ColumnDirective
            field="employee.firstName"
            headerText="First Name"
            textAlign="Center"
          ></ColumnDirective>
          <ColumnDirective
            field="workHours"
            headerText="Hours Worked"
            textAlign="Center"
          ></ColumnDirective>
          <ColumnDirective
            field="workCredit"
            headerText="Hours Credited"
            textAlign="Center"
          ></ColumnDirective>
          <ColumnDirective
            field="ptoCredit"
            headerText="Hours on PTO"
            textAlign="Center"
          ></ColumnDirective>
          <ColumnDirective
            field="totalHours"
            headerText="Total Hours"
            textAlign="Center"
          ></ColumnDirective>
        </ColumnsDirective>
        <Inject
          services={[
            Sort,
            Filter,
            CommandColumn,
            Toolbar,
            ExcelExport,
            ColumnChooser,
            Page,
          ]}
        />
      </GridComponent>
    </div>
  );
};

export default PayrollReportTable;
