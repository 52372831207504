import React, { useState } from "react";
import { GET_SCHEDULE_PERIODS, NOTIFY_DEVELOPERS } from "../../api/gqlQueries";
import { useQuery, useMutation } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { Grid, CircularProgress, Box, Select, MenuItem } from "@mui/material";
import { format } from "date-fns";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

const useStyles = makeStyles(() => ({
  select: {
    minWidth: 225,
  },
}));

const SchedulePeriodPicker = ({
  selectedOffice,
  setSchedulePeriod,
  schedulePeriod,
}) => {
  const classes = useStyles();
  const [allSchedulePeriods, setAllSchedulePeriods] = useState([]);
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });
  const periodData = useQuery(GET_SCHEDULE_PERIODS, {
    variables: {
      officeId: selectedOffice.id,
    },
    onCompleted(d) {
      setAllSchedulePeriods(d.schedulePeriods);
    },
    onError(error) {
      console.log(error);
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again. " +
          error.message,
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_SCHEDULE_PERIODS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  if (periodData.loading) {
    return <CircularProgress />;
  }
  return (
    <Grid item xs={2}>
      <Box m={2}>
        {allSchedulePeriods ? (
          <Select
            variant="outlined"
            value={schedulePeriod ? schedulePeriod : 0}
            onChange={(e) => {
              setSchedulePeriod(e.target.value);
            }}
            className={classes.select}
          >
            {" "}
            {!schedulePeriod && (
              <MenuItem key="0" value="0" disabled={true}>
                Please select a schedule period.
              </MenuItem>
            )}
            {allSchedulePeriods.map((e) => (
              <MenuItem key={e.id} value={e.id}>
                {format(new Date(`${e.start}T08:00:00`), "dd MMM yyyy") +
                  " to " +
                  format(new Date(`${e.end}T08:00:00`), "dd MMM yyyy")}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Select variant="outlined" className={classes.select}>
            <MenuItem>Error retreiving schedule periods</MenuItem>
          </Select>
        )}
      </Box>
    </Grid>
  );
};

export default SchedulePeriodPicker;
