import React from "react";
import { Grid, Typography, Checkbox } from "@mui/material";

const CheckBoxFilter = ({
  filterTypes,
  filterString,
  allString,
  checkBox,
  handleCheckBoxToggle,
  nonScheduleEvents,
}) => {
  return (
    <>
      {filterTypes.map((filterType, index) => (
        <Grid
          item
          container
          alignItems="center"
          key={index}
          spacing={filterString === "employeeFilters" ? 2 : 0}
          style={{
            marginTop: -5,
            marginBottom: -5,
            marginLeft:
              nonScheduleEvents === true && filterType.id ? 25 : "none",
          }}
        >
          <Grid item>
            <Checkbox
              checked={
                checkBox.find((obj) => obj.name === allString).checked ==
                  true ||
                checkBox.find((obj) => obj.name === filterType.name).checked ==
                  true
              }
              onChange={(e) => {
                filterType.checked = e.target.checked;
                handleCheckBoxToggle(filterType, filterString);
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body1">{filterType.name}</Typography>
            {filterString === "employeeFilters" && (
              <Typography style={{ fontSize: 10, color: "#DODODO" }}>
                {filterType.skills &&
                  filterType.skills.map((e) => e.name).join(", ")}
              </Typography>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default CheckBoxFilter;
