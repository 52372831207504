import React, { useState } from "react";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import { makeStyles } from "@mui/styles";
import { GET_PAYROLL_REPORT } from "../../api/gqlQueries";
import { useQuery } from "@apollo/client";
import { CircularProgress, Typography, TextField } from "@mui/material";
import PayrollReportTable from "../reportTables/PayrollReportTable";
import { Grid, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { add } from "date-fns";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

const useStyles = makeStyles(() => ({
  filterInput: {
    marginTop: 18,
  },
}));

export const transformPayrollData = (payrollData) => {
  const transformedData = [];
  for (let entry of payrollData) {
    const transformedEntry = {
      __typename: entry.__typename,
      totalHours: Math.round((entry.totalHours + Number.EPSILON) * 100) / 100,
      workHours: Math.round((entry.workHours + Number.EPSILON) * 100) / 100,
      ptoCredit: Math.round((entry.ptoCredit + Number.EPSILON) * 100) / 100,
      workCredit: Math.round((entry.workCredit + Number.EPSILON) * 100) / 100,
      employee: entry.employee,
    };
    transformedData.push(transformedEntry);
  }
  return transformedData;
};
const PayrollReportPage = ({ selectedOffice }) => {
  //   const [dateRange, setDateRange] = useState({});
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(add(new Date(), { days: 7 }));
  const [displayReport, setDisplayReport] = useState(false);
  const [tempStart, setTempStart] = useState(new Date());
  const [tempEnd, setTempEnd] = useState(add(new Date(), { days: 7 }));

  function runReport() {
    setStart(tempStart);
    setEnd(tempEnd);
    if (tempStart < tempEnd) {
      setDisplayReport(true);
    } else {
      setDisplayReport(false);
      toastShow(
        "Please enter a Start Date that is less than the End Date.",
        "Warning",
      );
    }
  }

  const clearReport = () => {
    setDisplayReport(false);
  };
  return (
    <div>
      <Grid container direction="row" spacing={4} alignItems="center">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item xs={12} style={{ marginTop: 30, marginBottom: 20 }}>
            <Typography variant="h3">Payroll Report</Typography>
          </Grid>
          <Grid xs={2} item>
            <Typography variant="h5">Select Start Date</Typography>
            <DatePicker
              inputVariant="outlined"
              name="Start"
              format="MM/dd/yyyy"
              value={tempStart}
              onChange={(date) => {
                setTempStart(date);
              }}
              renderInput={(props) => {
                return <TextField {...props} />;
              }}
            />
          </Grid>
          <Grid xs={2} item>
            <Typography variant="h5">Select End Date</Typography>
            <DatePicker
              inputVariant="outlined"
              name="End"
              format="MM/dd/yyyy"
              value={tempEnd}
              onChange={(date) => {
                setTempEnd(date);
              }}
              renderInput={(props) => {
                return <TextField {...props} />;
              }}
            />
          </Grid>
          <Grid item xs={2} style={{ marginTop: 15 }}>
            <Button
              style={{ marginRight: 15 }}
              id="run-button-id"
              color="primary"
              variant="contained"
              onClick={runReport}
            >
              Run Report
            </Button>
            <Button
              id="clear-button-id"
              color="primary"
              variant="contained"
              onClick={clearReport}
            >
              Clear
            </Button>
          </Grid>
        </LocalizationProvider>
      </Grid>
      <Grid style={{ marginTop: 15, marginBottom: 15 }}>
        {displayReport && (
          <PayrollReport
            selectedOfficeId={selectedOffice.id}
            start={start.toISOString()}
            end={end.toISOString()}
          />
        )}
      </Grid>
    </div>
  );
};
const PayrollReport = ({ selectedOfficeId, start, end }) => {
  const [payrollData, setPayrollData] = useState([]);
  const getPayrollReport = useQuery(GET_PAYROLL_REPORT, {
    variables: {
      start: start,
      end: end,
      officeId: selectedOfficeId,
    },
    onCompleted(d) {
      setPayrollData(d.payrollReport);
    },
  });

  if (getPayrollReport.loading) {
    return <CircularProgress />;
  } else {
    const transformedData = transformPayrollData(payrollData);
    return (
      <div>
        <PayrollReportTable payrollData={transformedData} />
      </div>
    );
  }
};

export default PayrollReportPage;
