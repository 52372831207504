export const scheduleQualityGrade = (value) => {
  let val = parseFloat(value);
  if (val > 90) {
    return { grade: "A", color: "#90EE90" };
  } else if (val >= 80 && val < 90) {
    return { grade: "B", color: "#ffffe0" };
  } else if (val >= 60 && val < 80) {
    return { grade: "C", color: "#F7A400" };
  } else if (val < 60) {
    return { grade: "D", color: "#FF7F7F" };
  }
};
