import React from "react";
import { Snackbar, Alert } from "@mui/material";

const ErrorSnackBar = (props) => {
  console.log(props.message);
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={20000}
      onClose={props.close}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={props.close} severity="error">
        {props.message}
      </Alert>
    </Snackbar>
  );
};
export default ErrorSnackBar;
