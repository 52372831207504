/**
 * Used to ensure we query the same date ranges in order to maximize local cache hits.
 *
 * Apollo uses the query name and query arguments as the cache key, so the
 * `DateTimePaginator` class is used to keep track of which date ranges to query so
 * that we maximize the chances that a query with the same date range has already been
 * made and we can use the local cache.
 */
import { startOfToday, add } from "date-fns";

export class DateTimePaginator {
  constructor(queryWeeks, baseDate = null) {
    this.queryWeeks = queryWeeks;
    this.baseDate = baseDate || startOfToday();
  }

  getQueryDateRanges(viewStart, viewEnd) {
    /**
     * Get date ranges to query given a view time range of interest.
     *
     * Given a time range of interest (`viewStart` to `viewEnd`), returns an array of
     * Date ranges (an array of length 2) that can be used to query data and restrict
     * the results to the given date range.
     *
     * @param {Date} viewStart the start of the view range of interest
     * @param {Date} viewEnd the end of the view range of interest
     *
     * @return {array} of arrays where the nested arrays have exactly 2 Date objects
     */
    if (viewEnd < viewStart) {
      const oldEnd = viewEnd;
      viewEnd = viewStart;
      viewStart = oldEnd;
    } else if (viewStart === viewEnd) {
      viewEnd = add(viewEnd, { days: 1 });
    }
    let current = new Date(this.baseDate);
    const out = Array();
    while (current > viewStart) {
      const start = add(current, { weeks: -this.queryWeeks });
      if (start < viewEnd) {
        out.push(Array(start, current));
      }
      current = new Date(start);
    }
    current = new Date(this.baseDate);
    while (current < viewEnd) {
      const end = add(current, { weeks: this.queryWeeks });
      if (end > viewStart) {
        out.push(Array(current, end));
      }
      current = new Date(end);
    }
    return out;
  }
}
