import React, { useState } from "react";
import {
  OFFICE_SCHDULING_INFO_MANAGER,
  GET_OFFICE_PERIOD_STATUS,
} from "../../api/gqlQueries";
import {
  CircularProgress,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useQuery, useLazyQuery } from "@apollo/client";
import { userVar } from "../../cache";
import { differenceInCalendarDays } from "date-fns";

const DashboardFooter = ({ selectedOffice }) => {
  const user = userVar();
  const [officeSchedulingInfoManagers] = useLazyQuery(
    OFFICE_SCHDULING_INFO_MANAGER,
    {
      onCompleted(d) {
        SetOfficeScheduleInfo(d.officeSummary);
      },
    },
  );
  const [officeScheduleInfo, SetOfficeScheduleInfo] = useState();
  const SchedulePeriods = useQuery(GET_OFFICE_PERIOD_STATUS, {
    variables: {
      officeId: parseInt(selectedOffice.id),
    },
    onCompleted(d) {
      let currentSchedulePeriod =
        d.getOfficePeriodStatus.currentSchedulePeriodId;

      currentSchedulePeriod &&
        officeSchedulingInfoManagers({
          variables: {
            schedulePeriod: parseInt(currentSchedulePeriod),
          },
        });
    },
  });

  if (SchedulePeriods.loading) {
    return <CircularProgress color="primary" />;
  } else {
    let mostRecentSchedule =
      SchedulePeriods.data.getOfficePeriodStatus &&
      SchedulePeriods.data.getOfficePeriodStatus.nextPeriodToCreateStart
        ? SchedulePeriods.data.getOfficePeriodStatus.nextPeriodToCreateStart
        : new Date();

    let daysScheduled = differenceInCalendarDays(
      new Date(mostRecentSchedule),
      new Date(),
    );

    return (
      <div
        style={{
          display: "inline-block",
          fontWeight: 400,
          fontSize: 14,
          margin: 0,
          padding: 0,
        }}
      >
        <Table style={{ margin: 0, padding: 0 }}>
          <TableBody>
            <TableRow>
              <TableCell style={{ border: "none" }}>
                Days Scheduled{" "}
                {daysScheduled && daysScheduled > 0
                  ? daysScheduled + " days"
                  : "0 days"}{" "}
                {" | "}
              </TableCell>
              <TableCell style={{ border: "none" }}>
                Scheduling Time{" "}
                {officeScheduleInfo &&
                officeScheduleInfo.length > 0 &&
                officeScheduleInfo[0].schedulingTime
                  ? officeScheduleInfo[0].schedulingTime + "min"
                  : "0 min"}
                {" | "}
              </TableCell>
              <TableCell style={{ border: "none" }}>
                Schedules Considered for {selectedOffice.name}{" "}
                {officeScheduleInfo &&
                officeScheduleInfo.length > 0 &&
                officeScheduleInfo[0].rostersConsidered
                  ? officeScheduleInfo[0].rostersConsidered
                  : 0}{" "}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
};

export default DashboardFooter;
