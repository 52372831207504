import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

/**
 *
 * @param options: List of objects which need a label key for display
 * @param placeholderText: String to display on input when no selection is made
 * @param onValueChange: Callback function to send selected value to parent component. Return value needs optional chain ?. to handle null
 * @param value: value passed from parent component to use as autocomplete value
 */

interface SearchDropdownProps {
  options: any[];
  placeholderText: string;
  onValueChange: (value: any) => void;
  value: any;
}
const SearchDropdown = ({
  options = [],
  placeholderText = "Search",
  onValueChange,
  value = null,
}: SearchDropdownProps) => {
  return (
    <Autocomplete
      value={value}
      options={options}
      data-testid="search-dropdown"
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholderText}
          InputProps={{
            ...params.InputProps,
            inputProps: {
              ...params.inputProps,
              "data-testid": "search-dropdown-input",
            },
          }}
        />
      )}
      renderOption={(p, option) => (
        <li data-testid={`option-${option.label}`} {...p}>
          {option?.label}
        </li>
      )}
      onChange={(event, value) => {
        onValueChange(value);
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  );
};

export default SearchDropdown;
