import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  TextField,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Snackbar,
  Dialog,
  DialogContent,
  Alert,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { DateTimePicker } from "@mui/x-date-pickers";
import DateFnsUtils from "@date-io/date-fns";
import { userVar, selectedDateVar } from "../../cache";
import {
  FIND_SKILL_TYPE,
  CREATE_PROCEDURE_EMPLOYEE_CONSTRAINTS,
  UPDATE_PROCEDURE,
  BATCH_UPDATE_PROCEDURE_REQUIREMENTS,
  UPDATE_PROCEDURE_EMPLOYEE_CONSTRAINTS,
  BATCH_DELETE_PROCEDURE_EMPLOYEE_CONSTRAINTS,
  CANCEL_PROCEDURE_EMPLOYEE_CONSTRAINTS,
  GET_OFFICE_TIMEZONE,
  CANCEL_PROCEDURE,
  PROCEDURE_EMPLOYEE_CONSTRAINTS,
  CREATE_BOOKED_PROCEDURES,
  PROCEDURE_TYPES,
  GET_ALL_CHILD_OFFICES_NAMES,
  PROCEDURE_DETAILS,
  CREATE_CONSTRAINT,
  CREATE_PROCEDURE_TYPE,
  PROCEDURE_EMPLOYEE_PACKAGES,
  ALL_OFFICES,
} from "../../api/gqlQueries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  differenceInHours,
  format,
  isEqual,
  addHours,
  add,
  sub,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
} from "date-fns";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import MultiSelect from "../general/MultiSelectSkills";
import moment from "moment-timezone";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 170,
    maxWidth: 275,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  spaceBelow: {
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.primary.main,
  },
  coloredText: {
    color: "#8CADE1",
    fontWeight: 700,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    paddingLeft: 14,
    paddingRight: 14,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "5px",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
  },
}))(TableCell);

const validationSchema = Yup.object({
  startDate: Yup.date().required("Required"),
  endDate: Yup.date().required("Required"),
  duration: Yup.number().required("Required"),
  procedureType: Yup.string().required("Required"),
  procedureName: Yup.string().required("Required"),
  shifts: Yup.array().of(
    Yup.object().shape({
      skills: Yup.string().required("Required"),
      ShiftStart: Yup.string().required("Required"),
      ShiftEnd: Yup.string().required("Required"),
      value: Yup.string().required("Required"),
    }),
  ),
  office: Yup.string().required("Required"),
});

const ProcedureRequirementForm = (props) => {
  const classes = useStyles();
  const {
    closeDialog,
    procedureRequirement,
    setToast,
    setShowToast,
    setAddEmployees,
    setConstraints,
    refetch,
    scheduleEndDate,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
    getVariables,
    events,
    setScheduleData,
    getShiftsForAnyDateRange,
    getSlackIssues,
  } = props;

  const user = userVar();
  const selectedDate = selectedDateVar();
  const selectedDateEndDate = addHours(selectedDateVar(), 4);

  const [vals, SetVals] = useState([]);
  const [showAddToast, setShowAddToast] = useState(false);
  const [showProcedureAddToast, setShowProcedureAddToast] = useState(false);
  const [procedureId, setProcedureId] = useState("");
  const [showNewProcedureTypeInput, SetShowNewProcedureTypeInput] =
    useState(false);
  const [showNewProcedureTypeDialog, SetShowNewProcedureTypeDialog] =
    useState(false);
  const [procedureEndDate, SetProcedureEndDate] = useState(null);
  const getSkills = useQuery(FIND_SKILL_TYPE, {
    variables: {
      office: parseInt(user.office.id),
    },
  });

  const getTimezone = useQuery(GET_OFFICE_TIMEZONE, {
    variables: {
      office: parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_OFFICE_TIMEZONE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const procedureTypes = useQuery(PROCEDURE_TYPES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on PROCEDURE_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [procedureEmployeeTemplatesData, SetProcedureEmployeeTemplatesData] =
    useState([]);

  const [procedureEmployeePackages] = useLazyQuery(
    PROCEDURE_EMPLOYEE_PACKAGES,
    {
      onCompleted(data) {
        SetProcedureEmployeeTemplatesData(data.procedureEmployeePackages);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on PROCEDURE_EMPLOYEE_PACKAGES Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  const [
    procedureRequirementDetails,
    { data: procedureRequirementDetailsData },
  ] = useLazyQuery(PROCEDURE_DETAILS, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on PROCEDURE_DETAILS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    if (procedureRequirement && procedureRequirement.eventId) {
      procedureRequirementDetails({
        variables: {
          id: parseInt(procedureRequirement.eventId),
        },
      });
    }
  }, [procedureRequirementDetailsData]);

  const childOffices = useQuery(GET_ALL_CHILD_OFFICES_NAMES, {
    variables: {
      parent: parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on PROCEDURE_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const allOfficesOfThisLocation = useQuery(ALL_OFFICES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on PROCEDURE_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [createProcedureRequirements] = useMutation(CREATE_BOOKED_PROCEDURES, {
    onCompleted(d) {
      let firstDayOfCurrentView = sub(new Date(selectedDate), { weeks: 1 });
      firstDayOfCurrentView = startOfDay(startOfWeek(firstDayOfCurrentView));
      let lastDayOfCurrentView = endOfDay(
        add(new Date(selectedDate), { weeks: 1 }),
      );
      let variables =
        window.DISPLAY_ALL_OFFICES_EVENTS === true ||
        window.DISPLAY_ALL_OFFICES_EVENTS === "true"
          ? {
              rangeStart: firstDayOfCurrentView.toISOString(),
              rangeEnd: lastDayOfCurrentView.toISOString(),
            }
          : {
              rangeStart: firstDayOfCurrentView.toISOString(),
              rangeEnd: lastDayOfCurrentView.toISOString(),
              office: parseInt(user.office.id),
            };
      getShiftsForAnyDateRange({ variables: variables });
      setShowProcedureAddToast(true);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_BOOKED_PROCEDURES Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [cancelProcedure] = useMutation(CANCEL_PROCEDURE, {
    onCompleted(data) {
      let firstDayOfCurrentView = startOfMonth(new Date(selectedDate));
      firstDayOfCurrentView = startOfWeek(firstDayOfCurrentView);
      let lastDayOfCurrentView = endOfMonth(new Date(selectedDate));
      lastDayOfCurrentView = endOfWeek(lastDayOfCurrentView);
      const variables = getVariables(
        firstDayOfCurrentView,
        lastDayOfCurrentView,
      );
      refetch(variables);
      setToast("Delete Procedure");
      setShowToast(true);
      setProcedureId("");
      closeDialog();
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on DELETE_PROCEDURE_REQUIREMENT Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  // const procedureEmployeeConstraints = useQuery(
  //   PROCEDURE_EMPLOYEE_CONSTRAINTS,
  //   {
  //     onError(error) {
  //       console.log(error);
  //       setOpenSnackBar(true);
  //       setSnackBarMessage(
  //         "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
  //       );
  //       notifyDevelopers({
  //         variables: {
  //           message:
  //             "Error on PROCEDURE_EMPLOYEE_CONSTRAINTS Query. Environment: " +
  //             environment +
  //             ". Graphql " +
  //             error,
  //         },
  //       });
  //     },
  //   }
  // );

  const [cancelProcedureEmployeeConstraint] = useMutation(
    CANCEL_PROCEDURE_EMPLOYEE_CONSTRAINTS,
    {
      onCompleted(d) {
        let firstDayOfCurrentView = startOfMonth(new Date(selectedDate));
        firstDayOfCurrentView = startOfWeek(firstDayOfCurrentView);
        let lastDayOfCurrentView = endOfMonth(new Date(selectedDate));
        lastDayOfCurrentView = endOfWeek(lastDayOfCurrentView);
        const variables = getVariables(
          firstDayOfCurrentView,
          lastDayOfCurrentView,
        );
        getFutureShifts({ variables: variables });
        setToast("Employee Skill Requirement Deleted");
        setShowToast(true);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again.",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_DELETE_PROCEDURE_EMPLOYEE_CONSTRAINTS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  const [updateProcedures] = useMutation(UPDATE_PROCEDURE, {
    onCompleted(data) {
      // var idsToDelete = [];
      let firstDayOfCurrentView = startOfMonth(new Date(selectedDate));
      firstDayOfCurrentView = startOfWeek(firstDayOfCurrentView);
      let lastDayOfCurrentView = endOfMonth(new Date(selectedDate));
      lastDayOfCurrentView = endOfWeek(lastDayOfCurrentView);
      const variables = getVariables(
        firstDayOfCurrentView,
        lastDayOfCurrentView,
      );
      refetch(variables);
      let currentConstraints = [
        ...(procedureRequirementDetailsData.shifts &&
          procedureRequirementDetailsData.shifts[0] &&
          procedureRequirementDetailsData.shifts[0].procedure &&
          procedureRequirementDetailsData.shifts[0].procedure
            .procedureRequirement.procedureemployeeconstraintSet),
      ];

      let inputData = data.updateProcedures.procedures.map((procedure) => {
        let input = vals.shifts.map((shift) => {
          // var allIds = shift.skills.map((x) => x.id);
          let skills = shift.skills.map((skill) => {
            let existing =
              // procedureEmployeeConstraints.data.procedureEmployeeConstraints.find(
              currentConstraints.find(
                (constraint) => constraint.id === skill.constraintId,
              );
            if (existing) {
              return {
                value: parseInt(shift.value),
                start: shift.ShiftStart,
                end: shift.ShiftEnd,
                employeeRequirement: parseInt(existing.id),
              };
            } else {
              return {
                value: parseInt(shift.value),
                start: shift.ShiftStart,
                end: shift.ShiftEnd,
                skill: parseInt(skill.id),
              };
            }
          });
          return skills.flat();
        });
        return input.flat();
      });
      let input = inputData.flat();
      let createInput = input.flat().filter((e) => e.skill);
      let updateInput = input.flat().filter((e) => e.employeeRequirement);
      // .filter((e) => e.id != null && !idsToDelete.includes(e.id));
      // const allSkills = vals.shifts.map(shift => shift.skills).flat();
      let idsToDelete = currentConstraints
        .filter((constraint) => {
          const updateIds = updateInput.map((input) =>
            parseInt(input.employeeRequirement),
          );
          return !updateIds.includes(parseInt(constraint.id));
        })
        .map((constraint) => constraint.id);
      updateProcedureEmployeeConstraints({
        variables: {
          employeeRequirements: updateInput,
          procedureRequirement: parseInt(
            data.updateProcedures.procedures[0].procedureRequirement.id,
          ),
        },
      });
      createProcedureEmployeeConstraints({
        variables: {
          employeeRequirements: createInput,
          procedureRequirement: parseInt(
            data.updateProcedures.procedures[0].procedureRequirement.id,
          ),
        },
      });
      cancelProcedureEmployeeConstraint({
        variables: {
          employeeRequirements: idsToDelete,
          procedureRequirement: parseInt(
            data.updateProcedures.procedures[0].procedureRequirement.id,
          ),
        },
      });
      closeDialog();
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_PROCEDURE Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [createProcedureEmployeeConstraints] = useMutation(
    CREATE_PROCEDURE_EMPLOYEE_CONSTRAINTS,
    {
      onCompleted(data) {
        let firstDayOfCurrentView = startOfMonth(new Date(selectedDate));
        firstDayOfCurrentView = startOfWeek(firstDayOfCurrentView);
        let lastDayOfCurrentView = endOfMonth(new Date(selectedDate));
        lastDayOfCurrentView = endOfWeek(lastDayOfCurrentView);
        const variables = getVariables(
          firstDayOfCurrentView,
          lastDayOfCurrentView,
        );
        getFutureShifts({ variables: variables });
        setShowAddToast(true);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again.",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_CREATE_PROCEDURE_EMPLOYEE_CONSTRAINTS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  const [updateProcedureEmployeeConstraints] = useMutation(
    UPDATE_PROCEDURE_EMPLOYEE_CONSTRAINTS,
    {
      onCompleted(data) {
        let firstDayOfCurrentView = startOfMonth(new Date(selectedDate));
        firstDayOfCurrentView = startOfWeek(firstDayOfCurrentView);
        let lastDayOfCurrentView = endOfMonth(new Date(selectedDate));
        lastDayOfCurrentView = endOfWeek(lastDayOfCurrentView);
        const variables = getVariables(
          firstDayOfCurrentView,
          lastDayOfCurrentView,
        );
        getFutureShifts({ variables: variables });
        setShowAddToast(true);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again.",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_UPDATE_PROCEDURE_EMPLOYEE_CONSTRAINTS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  const [batchUpdateProcedureRequirements] = useMutation(
    BATCH_UPDATE_PROCEDURE_REQUIREMENTS,
    {
      onCompleted(data) {
        let procedureRequirementsData =
          procedureRequirementDetailsData &&
          procedureRequirementDetailsData.shifts &&
          procedureRequirementDetailsData.shifts[0] &&
          procedureRequirementDetailsData.shifts[0].procedure;
        const inputData =
          data.batchUpdateProcedureRequirement.procedureRequirements.map(
            (procedureReq) => {
              return {
                id: parseInt(procedureRequirementsData.id),
                procedureRequirement: parseInt(
                  procedureRequirementsData.procedureRequirement.id,
                ),
                start: vals.startDate.toISOString(),
                end: vals.endDate.toISOString(),
                notes: vals.notes,
              };
            },
          );
        updateProcedures({
          variables: {
            input: inputData,
          },
        });
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again.",
        );

        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_UPDATE_PROCEDURE_REQUIREMENTS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  const [createNewProcedureType] = useMutation(CREATE_PROCEDURE_TYPE, {
    onCompleted(data) {
      if (vals.id != "") {
        handleUpdateProcedureRequirements(
          vals,
          data.createProcedureType.procedureType,
        );
      } else {
        handleCreateProcedureRequirements(
          vals,
          data.createProcedureType.procedureType,
        );
      }
    },
  });

  const handleCreateProcedureRequirements = (values, newProcedureType) => {
    SetVals(values);
    let employeeRequirementsInput = values.shifts
      .map((e) => {
        let empInput = e.skills.map((s) => {
          return {
            start: e.ShiftStart,
            end: e.ShiftEnd,
            skill: parseInt(s.id),
            value: parseInt(e.value),
          };
        });
        return empInput.flat();
      })
      .flat();
    let inputData = [
      {
        start: values.startDate,
        end: values.endDate,
        office: parseInt(values.office),
        procedureType:
          newProcedureType != null
            ? parseInt(newProcedureType.id)
            : parseInt(values.procedureType),
        name: values.procedureName,
        notes: values.notes,
        employeeRequirements: employeeRequirementsInput,
      },
    ];
    createProcedureRequirements({
      variables: {
        procedures: inputData,
      },
    });
  };

  const handleUpdateProcedureRequirements = (values, newProcedureType) => {
    SetVals(values);
    let procedureRequirementsData =
      procedureRequirementDetailsData &&
      procedureRequirementDetailsData.shifts &&
      procedureRequirementDetailsData.shifts[0] &&
      procedureRequirementDetailsData.shifts[0].procedure;

    let inputData = [
      {
        id: parseInt(procedureRequirementsData.procedureRequirement.id),
        name: values.procedureName,
        earliestDate: format(values.startDate, "yyyy-MM-dd"),
        latestDate: format(values.endDate, "yyyy-MM-dd"),
        duration: parseInt(values.duration),
        additiveConstraint: true,
        procedureType:
          newProcedureType != null
            ? parseInt(newProcedureType.id)
            : parseInt(values.procedureType),
        office: parseInt(values.office),
      },
    ];
    batchUpdateProcedureRequirements({
      variables: {
        input: inputData,
      },
    });
  };

  if (
    getSkills.loading ||
    getTimezone.loading ||
    procedureTypes.loading ||
    childOffices.loading ||
    allOfficesOfThisLocation.loading
  ) {
    return <CircularProgress color="primary" />;
  } else {
    let formattedShifts = [];
    let procedureRequirementsData =
      procedureRequirementDetailsData &&
      procedureRequirementDetailsData.shifts &&
      procedureRequirementDetailsData.shifts[0] &&
      procedureRequirementDetailsData.shifts[0].procedure;
    if (procedureRequirementsData) {
      procedureRequirementsData.procedureRequirement &&
        procedureRequirementsData.procedureRequirement
          .procedureemployeeconstraintSet &&
        procedureRequirementsData.procedureRequirement
          .procedureemployeeconstraintSet.length > 0 &&
        procedureRequirementsData.procedureRequirement.procedureemployeeconstraintSet
          .filter((constraint) => constraint.value != 0)
          .map((constraint) => {
            const matchingShift = formattedShifts.find(
              (shift) =>
                isEqual(shift.ShiftStart, new Date(constraint.start)) &&
                isEqual(shift.ShiftEnd, new Date(constraint.end)) &&
                parseInt(shift.skills.value === constraint.value),
            );
            if (matchingShift) {
              matchingShift.skills.push({
                ...constraint.employeeCapability,
                value: constraint.value,
                constraintId: constraint.id,
              });
            } else {
              formattedShifts.push({
                id: constraint.id,
                ShiftStart: new Date(constraint.start),
                ShiftEnd: new Date(constraint.end),
                skills: [
                  {
                    ...constraint.employeeCapability,
                    value: constraint.value,
                    constraintId: constraint.id,
                  },
                ],
                value: constraint.value,
              });
            }
          });
    }

    const handleAddEmployees = () => {
      setConstraints(formattedShifts);
      setAddEmployees(true);
    };
    const handleDeleteClick = () => {
      cancelProcedure({
        variables: {
          procedureRequirement: parseInt(
            procedureRequirementsData.procedureRequirement.id,
          ),
        },
      });
      setProcedureId(procedureRequirementsData.id);
    };

    const handlecancelProcedureEmployeeConstraint = (row, remove, index) => {
      row.id === ""
        ? remove(index)
        : cancelProcedureEmployeeConstraint({
            variables: {
              employeeRequirements: [parseInt(row.id)],
              procedureRequirement:
                procedureRequirementsData.procedureRequirement.id,
            },
          });
      remove(index);
    };

    const officeTimezone =
      getTimezone && getTimezone.data
        ? getTimezone.data.offices[0].timezone
        : "";

    const momentDate = moment().tz(
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    );
    const officeTZDate = momentDate.clone().tz(officeTimezone);

    const sameTimezoneAsOffice =
      momentDate.format("z") === officeTZDate.format("z");

    const getOffsetTime = (date) => {
      const newDate = moment(date);
      const formattedToOfficeTimezone = newDate
        .clone()
        .tz(officeTimezone)
        .format("MM/DD/YYYY HH:mm z");

      return formattedToOfficeTimezone;
    };

    const handleChangeDate = (date, field, setFieldValue, values) => {
      if (date && !isNaN(date.getTime())) {
        setFieldValue(field, date);
        if (field === "startDate") {
          if (date > values.endDate) {
            setFieldValue("endDate", date);
            setFieldValue("duration", 0);
          } else {
            const duration = differenceInHours(
              procedureEndDate != null ? procedureEndDate : values.endDate,
              date,
            );
            setTimeout(() => {
              setFieldValue("duration", duration);
            }, 200);
          }
        } else if (field === "endDate") {
          if (date < values.startDate) {
            setFieldValue("startDate", date);
            setFieldValue("duration", 0);
          } else {
            const duration = differenceInHours(date, values.startDate);
            setTimeout(() => {
              setFieldValue("duration", duration);
            }, 200);
          }
        }
      }
    };

    const handleAddNewProcedureType = (values) => {
      SetVals(values);
      createNewProcedureType({
        variables: {
          input: {
            name: values.newProcedureType,
          },
        },
      });
    };

    const disableAddEmployees =
      !scheduleEndDate ||
      (scheduleEndDate &&
        procedureRequirement &&
        new Date(`${scheduleEndDate}T23:59:00`) <
          new Date(procedureRequirement.start));
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div>
            <Grid
              container
              component="form"
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Grid container item justifyContent="space-between">
                <Grid item>
                  <Typography variant="h3">Procedure Requirements</Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    color="secondary"
                    data-testid="closeProcedureRequirementForm"
                    onClick={closeDialog}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Formik
              enableReinitialize
              initialValues={{
                id: procedureRequirementsData
                  ? procedureRequirementsData.id
                  : "",
                startDate: procedureRequirementsData
                  ? new Date(procedureRequirementsData.start)
                  : selectedDate,
                endDate: procedureRequirementsData
                  ? new Date(procedureRequirementsData.end)
                  : procedureEndDate != null
                    ? procedureEndDate
                    : selectedDateEndDate,
                procedureType: procedureRequirementsData
                  ? procedureRequirementsData.procedureRequirement.procedureType
                      .id
                  : "",
                newProcedureType: "",
                office: procedureRequirementsData
                  ? procedureRequirementsData.procedureRequirement.office.id
                  : user.office.id,
                duration: procedureRequirementsData
                  ? differenceInHours(
                      new Date(procedureRequirementsData.end),
                      new Date(procedureRequirementsData.start),
                    )
                  : differenceInHours(selectedDateEndDate, selectedDate),
                notes: procedureRequirementsData
                  ? procedureRequirementsData.notes
                  : "",
                procedureName: procedureRequirementsData
                  ? procedureRequirementsData.procedureRequirement.name
                  : "",
                shifts: procedureRequirementsData
                  ? formattedShifts
                  : procedureEmployeeTemplatesData &&
                      procedureEmployeeTemplatesData.length > 0
                    ? procedureEmployeeTemplatesData.map((e, index) => {
                        return {
                          id: "",
                          ShiftStart: add(selectedDateVar(), {
                            minutes: e.offset,
                          }),
                          ShiftEnd:
                            e.duration != null
                              ? add(
                                  add(selectedDateVar(), {
                                    minutes: e.offset,
                                  }),
                                  { minutes: parseInt(e.duration) },
                                )
                              : procedureEndDate != null
                                ? procedureEndDate
                                : selectedDateEndDate,
                          value: parseInt(e.value),
                          skills: [e.employeeCapability],
                        };
                      })
                    : [
                        {
                          id: "",
                          ShiftStart: selectedDate,
                          ShiftEnd: selectedDateEndDate,
                          value: 1,
                          skills: [],
                        },
                      ],
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                SetVals(values);
                if (values.newProcedureType != "") {
                  handleAddNewProcedureType(values);
                } else {
                  if (values.id != "") {
                    handleUpdateProcedureRequirements(values);
                  } else {
                    handleCreateProcedureRequirements(values);
                  }
                }
              }}
            >
              {({
                values,
                handleChange,
                setFieldValue,
                errors,
                touched,
                isSubmitting,
                dirty,
              }) => (
                <Form>
                  <Grid container>
                    {!sameTimezoneAsOffice && (
                      <Grid item xs={12}>
                        <Typography
                          style={{ fontWeight: "500" }}
                          className={classes.error}
                        >
                          **You are in a different timezone than your office.
                          Please enter the times in your local timezone and we
                          will display the time in your office timezone below
                          each field.
                        </Typography>
                      </Grid>
                    )}
                    <Grid container item spacing={2} style={{ marginTop: 16 }}>
                      <Grid item xs={4}>
                        <Typography variant="h6">Procedure Start</Typography>
                        <div data-testid="startDate">
                          <DateTimePicker
                            // disableToolbar
                            // autoOk
                            // variant="inline"
                            inputVariant="outlined"
                            name="startDate"
                            format="MM/dd/yyyy HH:mm"
                            // id="current-date"
                            value={values.startDate}
                            onChange={(date) => {
                              selectedDateVar(date);
                              handleChangeDate(
                                date,
                                "startDate",
                                setFieldValue,
                                values,
                              );
                            }}
                            renderInput={(props) => {
                              return <TextField {...props} />;
                            }}
                            minDate={new Date()}
                            className={classes.input}
                            // ampm={false}
                            minutesStep={15}
                            //disabled={procedureRequirement ? true : false}
                          />
                        </div>
                        {!sameTimezoneAsOffice && (
                          <Typography style={{ fontWeight: "500" }}>
                            {getOffsetTime(values.startDate)}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6">Procedure End</Typography>
                        <div data-testid="endDate">
                          <DateTimePicker
                            // disableToolbar
                            // autoOk
                            // variant="inline"
                            inputVariant="outlined"
                            name="endDate"
                            format="MM/dd/yyyy HH:mm"
                            // id="current-date"
                            value={values.endDate}
                            onChange={(date) => {
                              SetProcedureEndDate(date);
                              handleChangeDate(
                                date,
                                "endDate",
                                setFieldValue,
                                values,
                              );
                            }}
                            renderInput={(props) => {
                              return <TextField {...props} />;
                            }}
                            minDate={addHours(values.startDate, 4)}
                            className={classes.input}
                            // ampm={false}
                            minutesStep={15}
                            //disabled={procedureRequirement ? true : false}
                          />
                        </div>
                        {!sameTimezoneAsOffice && (
                          <Typography style={{ fontWeight: "500" }}>
                            {getOffsetTime(values.endDate)}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {/* <Typography variant="h6">Timezone</Typography>
                      <Select 
                        variant="outlined"
                        id={'timezone'}
                        name={'timezone'}
                        value={values.timezone}
                        onChange={handleChange}
                        className={classes.input}
                        disabled={true}
                      >
                        {timezones.map(timezone => (
                          <MenuItem key={timezone} value={timezone}>
                            {timezone}
                          </MenuItem>
                        ))}
                      </Select> */}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6">
                          Select Mission Type
                        </Typography>
                        <Select
                          variant="outlined"
                          id="procedureType"
                          name="procedureType"
                          data-testid="procedureType"
                          onChange={(e) => {
                            setTimeout(() => {
                              setFieldValue("procedureType", e.target.value);
                              let procName =
                                procedureTypes.data.procedureTypes.find(
                                  (x) => x.id === e.target.value,
                                );
                              setFieldValue("duration", values.duration);
                              setFieldValue(
                                "procedureName",
                                procName
                                  ? procName.name +
                                      " " +
                                      format(values.startDate, "dd-MMM-yyyy")
                                  : format(values.startDate, "dd-MMM-yyyy"),
                              );
                            }, 1000);

                            if (e.target.value === "new") {
                              SetShowNewProcedureTypeInput(true);
                              SetShowNewProcedureTypeDialog(true);
                              setFieldValue("procedureType", "new");
                            } else {
                              setFieldValue("newProcedureType", "");
                              SetShowNewProcedureTypeInput(false);
                              procedureEmployeePackages({
                                variables: {
                                  procedureType: parseInt(e.target.value),
                                },
                              });
                            }
                          }}
                          value={values.procedureType}
                          className={classes.input}
                        >
                          {procedureTypes.data.procedureTypes.map(
                            (type, index) => (
                              <MenuItem key={index} value={type.id}>
                                {type.name}
                              </MenuItem>
                            ),
                          )}
                          <MenuItem key="new" value="new">
                            <Button
                              onClick={() => SetShowNewProcedureTypeInput(true)}
                              color="primary"
                              data-testid="addNewProcedureType"
                            >
                              Add New Procedure Type
                            </Button>
                          </MenuItem>
                        </Select>
                        {showNewProcedureTypeInput && (
                          <TextField
                            id="newProcedureType"
                            name="newProcedureType"
                            variant="outlined"
                            onChange={handleChange}
                            data-testid="newProcedureType"
                          />
                        )}
                        {errors && errors.procedureType && (
                          <Typography className="field-error" color="error">
                            {errors.procedureType}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6">Duration</Typography>
                        <TextField
                          variant="outlined"
                          name="Duration"
                          id="Duration"
                          type="number"
                          data-testid="duration"
                          value={values.duration}
                          // onChange={handleChange}
                          disabled={true}
                          className={classes.input}
                          helperText="Calculated based on start and end times"
                        />
                        {errors && errors.duration && (
                          <Typography className="field-error" color="error">
                            {errors.duration}
                          </Typography>
                        )}
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="h6">Procedure Name</Typography>
                        <TextField
                          variant="outlined"
                          name="procedureName"
                          type="text"
                          id="procedureName"
                          value={values.procedureName}
                          onChange={handleChange}
                          className={classes.input}
                          data-testid="procedureName"
                        />
                        {errors && errors.procedureName && (
                          <Typography className="field-error" color="error">
                            {errors.procedureName}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6">Office</Typography>

                        {user.isPrimaryParentOffice === true ||
                        user.isAdmin === true ? (
                          <Select
                            variant="outlined"
                            id="office"
                            name="office"
                            value={values.office}
                            onChange={handleChange}
                            className={classes.input}
                            data-testid="selectedOffice"
                          >
                            <MenuItem
                              key={user.office.id}
                              value={user.office.id}
                            >
                              {user.office.name}
                            </MenuItem>
                            {user.isAdmin &&
                              !user.isPrimaryParentOffice &&
                              allOfficesOfThisLocation.data &&
                              allOfficesOfThisLocation.data.offices.length >
                                0 &&
                              allOfficesOfThisLocation.data.offices.map(
                                (e, index) => (
                                  <MenuItem key={index} value={e.id}>
                                    {e.name}
                                  </MenuItem>
                                ),
                              )}
                            {childOffices.data &&
                              childOffices.data.getChildren.length > 0 &&
                              childOffices.data.getChildren.map((e, index) => (
                                <MenuItem key={index} value={e.id}>
                                  {e.name}
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          <Typography
                            variant="body1"
                            data-testid="selectedOffice"
                          >
                            {user.office.name}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6">Notes</Typography>
                        <TextField
                          variant="outlined"
                          name="notes"
                          id="notes"
                          value={values.notes}
                          onChange={handleChange}
                          className={classes.input}
                          multiline
                          data-testid="notes"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: 16,
                        marginBottom: 16,
                        height: 280,
                        overflowY: "auto",
                      }}
                    >
                      <FieldArray name="shifts">
                        {({ push, remove }) => (
                          <Table>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Shift Start</StyledTableCell>
                                <StyledTableCell>Shift End</StyledTableCell>
                                <StyledTableCell>Value</StyledTableCell>
                                <StyledTableCell>Skills</StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.shifts.length > 0 &&
                                values.shifts.map((row, index) => (
                                  <TableRow key={index}>
                                    <StyledTableCell
                                      style={{ display: "none" }}
                                    >
                                      <Typography
                                        id={`shifts.${index}.id`}
                                        name={`shifts.${index}.id`}
                                        value={row.id}
                                      >
                                        {row.id}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <div
                                        data-testid={`shifts.${index}.ShiftStart`}
                                      >
                                        <DateTimePicker
                                          // disableToolbar
                                          // autoOk
                                          // variant="inline"
                                          inputVariant="outlined"
                                          id={`shifts.${index}.ShiftStart`}
                                          name={`shifts.${index}.ShiftStart`}
                                          value={row.ShiftStart}
                                          format="MM/dd/yyyy HH:mm"
                                          // id="current-date"
                                          onChange={(date) => {
                                            handleChangeDate(
                                              date,
                                              `shifts.${index}.ShiftStart`,
                                              setFieldValue,
                                              values,
                                            );
                                          }}
                                          renderInput={(props) => {
                                            return <TextField {...props} />;
                                          }}
                                          minDate={values.startDate}
                                          maxDate={values.endDate}
                                          minDateMessage="Date cannot be before mission starts"
                                          maxDateMessage="Date cannot be after mission ends"
                                          className={classes.input}
                                          minutesStep={15}
                                        />
                                      </div>
                                      {errors &&
                                        errors.shifts &&
                                        errors.shifts[index] &&
                                        errors.shifts[index].startDate && (
                                          <div className="field-error">
                                            {errors.shifts[index].startDate}
                                          </div>
                                        )}
                                      {!sameTimezoneAsOffice && (
                                        <Typography
                                          style={{ fontWeight: "500" }}
                                        >
                                          {getOffsetTime(row.ShiftStart)}
                                        </Typography>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <div
                                        data-testid={`shifts.${index}.ShiftEnd`}
                                      >
                                        <DateTimePicker
                                          inputVariant="outlined"
                                          id={`shifts.${index}.ShiftEnd`}
                                          name={`shifts.${index}.ShiftEnd`}
                                          value={row.ShiftEnd}
                                          format="MM/dd/yyyy HH:mm"
                                          onChange={(date) => {
                                            handleChangeDate(
                                              date,
                                              `shifts.${index}.ShiftEnd`,
                                              setFieldValue,
                                              values,
                                            );
                                          }}
                                          renderInput={(props) => {
                                            return <TextField {...props} />;
                                          }}
                                          minDate={values.startDate}
                                          maxDate={values.endDate}
                                          minDateMessage="Date cannot be before mission starts or lesser than shift start time"
                                          maxDateMessage="Date cannot be after mission ends"
                                          className={classes.input}
                                          minutesStep={15}
                                        />
                                      </div>
                                      {!sameTimezoneAsOffice && (
                                        <Typography
                                          style={{ fontWeight: "500" }}
                                        >
                                          {getOffsetTime(row.ShiftEnd)}
                                        </Typography>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <TextField
                                        id={`shifts.${index}.value`}
                                        name={`shifts.${index}.value`}
                                        type="number"
                                        variant="outlined"
                                        onChange={(e) =>
                                          setFieldValue(
                                            `shifts.${index}.value`,
                                            e.target.value,
                                          )
                                        }
                                        value={row.value}
                                        data-testid={`shifts.${index}.value`}
                                      />
                                      {errors &&
                                        errors.shifts &&
                                        errors.shifts[index] &&
                                        errors.shifts[index].value && (
                                          <Typography
                                            className="field-error"
                                            color="error"
                                          >
                                            {errors.shifts[index].value}
                                          </Typography>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <div
                                        data-testid={`shifts.${index}.skills`}
                                      >
                                        <MultiSelect
                                          options={
                                            getSkills.data.skills &&
                                            getSkills.data.skills.length > 0
                                              ? getSkills.data.skills.filter(
                                                  (e) =>
                                                    e.variety === "JOB_TYPE" ||
                                                    e.variety === "TRAINING",
                                                )
                                              : [{ id: 0, name: "" }]
                                          }
                                          id={`shifts.${index}.skills`}
                                          name={`shifts.${index}.skills`}
                                          onChange={setFieldValue}
                                          val={row.skills}
                                          getOptionSelected={(option, value) =>
                                            option.name === value.name
                                          }
                                          getOptionLabel={(option) =>
                                            option?.name
                                          }
                                          style={{ width: 150 }}
                                          showCheckBox={true}
                                        />
                                      </div>
                                      {errors &&
                                        errors.shifts &&
                                        errors.shifts[index] &&
                                        errors.shifts[index].skills && (
                                          <Typography
                                            className="field-error"
                                            color="error"
                                          >
                                            {errors.shifts[index].skills}
                                          </Typography>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <IconButton
                                        color="secondary"
                                        data-testid="removeProcedureConstraintButton"
                                        onClick={() => {
                                          handlecancelProcedureEmployeeConstraint(
                                            row,
                                            remove,
                                            index,
                                          );
                                        }}
                                        size="large"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </StyledTableCell>
                                  </TableRow>
                                ))}
                              <TableRow>
                                <StyledTableCell>
                                  <Button
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    data-testid="addAnotherProcedureConstraintButton"
                                    onClick={() =>
                                      push({
                                        id: "",
                                        ShiftStart:
                                          values.shifts.length > 0
                                            ? values.shifts[
                                                values.shifts.length - 1
                                              ].ShiftEnd
                                            : values.startDate,
                                        ShiftEnd:
                                          values.shifts.length > 0
                                            ? add(
                                                values.shifts[
                                                  values.shifts.length - 1
                                                ].ShiftEnd,
                                                { hours: 4 },
                                              )
                                            : values.startDate,
                                        skills: [],
                                      })
                                    }
                                  >
                                    Add Another
                                  </Button>
                                </StyledTableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        )}
                      </FieldArray>
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                      {/* {procedureRequirement ? (
                      <Grid item xs={4}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleAddEmployees}
                          disabled={disableAddEmployees}
                        >
                          Add Employees
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item xs={4}></Grid>
                    )} */}
                      <Grid
                        item
                        container
                        xs={6}
                        justifyContent="flex-end"
                        spacing={2}
                      >
                        {procedureRequirementsData ? (
                          <Grid item>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={handleDeleteClick}
                              data-testid="procedureDeleteButton"
                            >
                              Delete
                            </Button>
                          </Grid>
                        ) : null}
                        <Grid item>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting === true || !dirty}
                            data-testid="procedureSubmitButton"
                          >
                            {procedureRequirement ? "Update" : "Submit"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </LocalizationProvider>
        <Snackbar
          open={showProcedureAddToast}
          autoHideDuration={2000}
          onClose={() => {
            refetch();
            setShowProcedureAddToast(false);
            closeDialog();
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            elevation={6}
            onClose={() => {
              refetch();
              setShowProcedureAddToast(false);
              closeDialog();
            }}
            severity="success"
          >
            <Typography>Procedure added!</Typography>
          </Alert>
        </Snackbar>
        <Dialog open={showNewProcedureTypeDialog}>
          <DialogContent>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography>
                  You are about to add a new Procedure Type. Make sure to add
                  the Employee Packages Template for this new Procedure Type.
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => SetShowNewProcedureTypeDialog(false)}
                >
                  OK
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  }
};

export default ProcedureRequirementForm;
