import { AbilityBuilder, Ability } from "@casl/ability";

const permExists = (userrole, abilities, permname) => {
  let exist =
    abilities &&
    abilities.length > 0 &&
    abilities.find(
      (e) => e.role.name === userrole && e.permission.name === permname,
    );
  return !exist ? false : true;
};

export default function defineAbilityFor(user) {
  const { can, cannot, build } = new AbilityBuilder(Ability);
  if (user.isAdmin) {
    can("manage", ["RolesandPermissions"]);

    if (permExists("ADMIN", user.userabilities, "calendar")) {
      can("manage", ["Calendar"]);
    }
    if (permExists("ADMIN", user.userabilities, "preferences")) {
      can("manage", ["Preferences"]);
    }
    if (permExists("ADMIN", user.userabilities, "notifications")) {
      can("manage", ["Notifications"]);
    }
    if (permExists("ADMIN", user.userabilities, "manageOffices")) {
      can("manage", ["AllOffices"]);
    }
    if (permExists("ADMIN", user.userabilities, "applicationSettings")) {
      can("manage", ["RolesandPermissions"]);
      can("manage", ["AppSettings"]);
      can("manage", ["LocationSettings"]);
    }

    if (permExists("ADMIN", user.userabilities, "generateSchedule")) {
      can("manage", ["Schedule"]);
    }
    if (permExists("ADMIN", user.userabilities, "manageUsers")) {
      can("manage", ["Users"]);
    }
    if (permExists("ADMIN", user.userabilities, "allRequests")) {
      can("manage", ["Requests"]);
    }
    if (permExists("ADMIN", user.userabilities, "usersRequests")) {
      can("manage", ["Requests"]);
    }
    can("manage", ["Profile"]);
  } else if (user.isManager) {
    if (permExists("MANAGER", user.userabilities, "calendar")) {
      can("manage", ["Calendar"]);
    }
    if (permExists("MANAGER", user.userabilities, "preferences")) {
      can("manage", ["Preferences"]);
    }
    if (permExists("MANAGER", user.userabilities, "notifications")) {
      can("manage", ["Notifications"]);
    }
    if (permExists("MANAGER", user.userabilities, "manageOffices")) {
      can("manage", ["AllOffices"]);
    }
    if (permExists("MANAGER", user.userabilities, "applicationSettings")) {
      can("manage", ["RolesandPermissions"]);
      can("manage", ["AppSettings"]);
      can("manage", ["LocationSettings"]);
    }

    if (permExists("MANAGER", user.userabilities, "generateSchedule")) {
      can("manage", ["Schedule"]);
    }
    if (permExists("MANAGER", user.userabilities, "manageUsers")) {
      can("manage", ["Users"]);
    }
    if (permExists("MANAGER", user.userabilities, "allRequests")) {
      can("manage", ["Requests"]);
    }
    if (permExists("MANAGER", user.userabilities, "usersRequests")) {
      can("manage", ["Requests"]);
    }
    can("manage", ["Profile"]);
  } else if (user.isScheduler) {
    if (permExists("SCHEDULER", user.userabilities, "calendar")) {
      can("manage", ["Calendar"]);
    }
    if (permExists("SCHEDULER", user.userabilities, "preferences")) {
      can("manage", ["Preferences"]);
    }
    if (permExists("SCHEDULER", user.userabilities, "notifications")) {
      can("manage", ["Notifications"]);
    }
    if (permExists("SCHEDULER", user.userabilities, "manageOffices")) {
      can("manage", ["AllOffices"]);
    }
    if (permExists("SCHEDULER", user.userabilities, "applicationSettings")) {
      can("manage", ["RolesandPermissions"]);
      can("manage", ["AppSettings"]);
      can("manage", ["LocationSettings"]);
    }

    if (permExists("SCHEDULER", user.userabilities, "generateSchedule")) {
      can("manage", ["Schedule"]);
    }
    if (permExists("SCHEDULER", user.userabilities, "manageUsers")) {
      can("manage", ["Users"]);
    }
    if (permExists("SCHEDULER", user.userabilities, "allRequests")) {
      can("manage", ["Requests"]);
    }
    if (permExists("SCHEDULER", user.userabilities, "usersRequests")) {
      can("manage", ["Requests"]);
    }
    can("manage", ["Profile"]);
  } else {
    if (permExists("EMPLOYEE", user.userabilities, "calendar")) {
      can("manage", ["Calendar"]);
    }
    if (permExists("EMPLOYEE", user.userabilities, "preferences")) {
      can("manage", ["Preferences"]);
    }
    if (permExists("EMPLOYEE", user.userabilities, "notifications")) {
      can("manage", ["Notifications"]);
    }
    if (permExists("EMPLOYEE", user.userabilities, "manageOffices")) {
      can("manage", ["AllOffices"]);
    }
    if (permExists("EMPLOYEE", user.userabilities, "applicationSettings")) {
      can("manage", ["RolesandPermissions"]);
      can("manage", ["AppSettings"]);
      can("manage", ["LocationSettings"]);
    }

    if (permExists("EMPLOYEE", user.userabilities, "generateSchedule")) {
      can("manage", ["Schedule"]);
    }
    if (permExists("EMPLOYEE", user.userabilities, "manageUsers")) {
      can("manage", ["Users"]);
    }
    if (permExists("EMPLOYEE", user.userabilities, "allRequests")) {
      can("manage", ["Requests"]);
    }
    if (permExists("EMPLOYEE", user.userabilities, "usersRequests")) {
      can("manage", ["Requests"]);
    }
    can("manage", ["Profile"]);
  }

  return build();
}
