import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import CalendarIcon from "@mui/icons-material/Event";
import ScheduleIcon from "@mui/icons-material/Schedule";
import EditIcon from "@mui/icons-material/Edit";
import BusinessIcon from "@mui/icons-material/Business";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DomainIcon from "@mui/icons-material/Domain";
import QueueIcon from "@mui/icons-material/Queue";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import ListAltIcon from '@mui/icons-material/ListAlt';
import { userVar, locationConfigVar } from "../../cache";
import Ability from "../../Roles/defineAbility";
import WorkIcon from "@mui/icons-material/Work";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleIcon from "@mui/icons-material/People";
import HomeIcon from "@mui/icons-material/Home";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import FolderOpen from "@mui/icons-material/FolderOpen";
import { Tooltip } from "@mui/material";
import Assignment from "@mui/icons-material/Assignment";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { PersonSearch } from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import Copyright from "../general/Copyright";

function DashBoardSideNav(props) {
  let setDrawerOpen = props.setDrawerOpen;
  let isOpen = props.isOpen;
  // const user = useSelector(state => state.user.userData);
  // const userRole = user ? user.role : '';
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const mapPathnames = {
    "/": 0,
    "/Schedule": 1,
    "/Preferences": 2,
    "/CreateSchedule": 3,
    "/ManageTeam": 4,
    "/ManageMentees": 5,
    "/Admin": 6,
    "/HospitalSettings": 7,
    "/Settings": 8,
    "/RolesandPermissions": 9,
    "/Requests": 10,
    "/FloatSettings": 11,
    "/FloatNeeds": 12,
    "/DepartmentStatus": 13,
  };

  const defaultIndex = mapPathnames[location.pathname]
    ? mapPathnames[location.pathname]
    : 0;

  const [selectedIndex, setSelectedIndex] = React.useState(defaultIndex);
  const [managerShowmore, setManageTeamShowMore] = React.useState(false);
  const [floatShowMore, setFloatShowMore] = React.useState(false);

  const [departmentShowmore, setdepartmentShowmore] = React.useState(false);
  const [analyticsShowmore, setanalyticstShowmore] = React.useState(false);
  const [allSettingsShowMore, setAllSettingsShowMore] = React.useState(false);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const user = userVar();
  const ability = Ability(user);
  const locationConfig = locationConfigVar();

  // when sidenav hides, close all submenus
  useEffect(() => {
    if (!isOpen) {
      setManageTeamShowMore(false);
      setdepartmentShowmore(false);
      setanalyticstShowmore(false);
      setAllSettingsShowMore(false);
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <Box>
          <img
            data-testid="upperNavLogo"
            src="Duality-Systems-WhiteTM.png"
            height={75}
            alt={window.LOGO.split(".")[0].split("-").join(" ").concat(" logo")}
          />
        </Box>
      )}
      {!isOpen && (
        <Box>
          <img data-testid="upperNavLogo" src="DualityDLogo.png" height={75} />
        </Box>
      )}
      <List style={{ marginLeft: 15 }}>
        {(ability.can("manage", "Calendar") ||
          ability.can("manage", "Preferences")) && (
          <>
            {(window.SHOW_CALENDAR_ON_LOAD === true ||
              window.SHOW_CALENDAR_ON_LOAD === "true") &&
            user.isEmployee === true ? (
              <>
                <ListItem
                  button
                  component={Link}
                  to="/Dashboard"
                  selected={selectedIndex === 0}
                  onClick={(event) => {
                    handleListItemClick(event, 0);
                    setDrawerOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <Tooltip title="Home" placement="right">
                      <HomeIcon
                        style={{
                          color: theme.palette.common.white,
                        }}
                        data-testid="navigationHomeIcon"
                      />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Dashboard
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/"
                  selected={selectedIndex === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                >
                  <ListItemIcon>
                    <Tooltip title="Schedule" placement="right">
                      <CalendarIcon
                        style={{
                          color: theme.palette.common.white,
                        }}
                        data-testid="navigationCalendarIcon"
                      />
                    </Tooltip>{" "}
                  </ListItemIcon>

                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Schedule
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
              </>
            ) : (
              <>
                <ListItem
                  button
                  component={Link}
                  to="/"
                  selected={selectedIndex === 0}
                  onClick={(event) => {
                    handleListItemClick(event, 0);
                    setDrawerOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <Tooltip title="Home" placement="right">
                      <HomeIcon
                        style={{ color: theme.palette.common.white }}
                        data-testid="navigationHomeIcon"
                      />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Dashboard
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/Schedule"
                  selected={selectedIndex === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                >
                  <ListItemIcon>
                    <Tooltip title="Schedule" placement="right">
                      <CalendarIcon
                        style={{ color: theme.palette.common.white }}
                        data-testid="navigationCalendarIcon"
                      />
                    </Tooltip>{" "}
                  </ListItemIcon>

                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Schedule
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
              </>
            )}

            {user.isSchedulable === true &&
              (window.HIDE_EMPLOYEE_REQUEST_FORMS === false ||
                window.HIDE_EMPLOYEE_REQUEST_FORMS === "false") && (
                <>
                  <ListItem
                    button
                    component={Link}
                    to="/Preferences"
                    selected={selectedIndex === 2}
                    onClick={(event) => handleListItemClick(event, 2)}
                  >
                    <ListItemIcon>
                      <Tooltip title="Preferences" placement="right">
                        <EditIcon
                          style={{ color: theme.palette.common.white }}
                          data-testid="navigationPreferenceIcon"
                        />
                      </Tooltip>{" "}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          color="primary.contrastText"
                        >
                          Preferences
                        </Typography>
                      }
                    ></ListItemText>
                  </ListItem>
                </>
              )}
            {ability.can("manage", "Requests") && (
              <ListItem
                button
                component={Link}
                to="/Requests"
                selected={selectedIndex === 3}
                onClick={(event) => handleListItemClick(event, 3)}
              >
                <ListItemIcon>
                  <Tooltip title="Requests" placement="right">
                    <QueueIcon
                      style={{ color: theme.palette.common.white }}
                      data-testid="navigationRequestsIcon"
                    />
                  </Tooltip>{" "}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1" color="primary.contrastText">
                      Requests
                    </Typography>
                  }
                ></ListItemText>
              </ListItem>
            )}
          </>
        )}
        {/* {ability.can("manage", "Requests") && (
        <>
          <ListItem
            button
            component={Link}
            to="/Requests"
            selected={selectedIndex === 3}
            onClick={(event) => handleListItemClick(event, 3)}
          >
            <ListItemIcon>
              <Tooltip title="Requests" placement="right">
                <QueueIcon data-testid="navigationRequestsIcon" />
              </Tooltip>{" "}
            </ListItemIcon>
            <ListItemText>Requests</ListItemText>
          </ListItem>
        </>
      )} */}
        {(ability.can("manage", "Users") ||
          ability.can("manage", "Schedule")) && (
          <ListItem
            button
            onClick={() => setManageTeamShowMore(!managerShowmore)}
          >
            <ListItemIcon>
              <Tooltip title="Team" placement="right">
                <GroupIcon
                  data-testid="navigationTeamsTabIcon"
                  style={{ color: theme.palette.common.white }}
                />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1" color="primary.contrastText">
                  Team
                </Typography>
              }
            ></ListItemText>

            <ListItemIcon>
              <Tooltip title="Team" placement="right">
                <IconButton
                  style={{ color: theme.palette.common.white }}
                  size="large"
                >
                  {" "}
                  {!managerShowmore ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </Tooltip>
            </ListItemIcon>
          </ListItem>
        )}
        {managerShowmore && (
          <Box ml={3}>
            {ability.can("manage", "Schedule") &&
              user.office.parent === null && (
                <>
                  <ListItem
                    button
                    component={Link}
                    to="/CreateSchedule"
                    selected={selectedIndex === 4}
                    onClick={(event) => handleListItemClick(event, 4)}
                    data-testid="navigationCreateScheduleIcon"
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          color="primary.contrastText"
                        >
                          Create Schedule
                        </Typography>
                      }
                    ></ListItemText>
                  </ListItem>
                </>
              )}
            {ability.can("manage", "Users") && (
              <>
                <ListItem
                  button
                  component={Link}
                  to="/ManageTeam"
                  selected={selectedIndex === 5}
                  onClick={(event) => handleListItemClick(event, 5)}
                  data-testid="navigationManageTeamIcon"
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Manage Teams
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/ManageMentees"
                  selected={selectedIndex === 6}
                  onClick={(event) => handleListItemClick(event, 6)}
                  data-testid="navigationManageMenteesIcon"
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Manage Mentors/Mentees
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/ImportData"
                  selected={selectedIndex === 7}
                  onClick={(event) => handleListItemClick(event, 7)}
                  data-testid="navigationImportTabIcon"
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Import Data
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
              </>
            )}
          </Box>
        )}
        {(ability.can("manage", "Users") ||
          ability.can("manage", "Schedule")) &&
          user.floatOffice && (
            <ListItem button>
              <Tooltip title="Manage Team" placement="right">
                <ListItemIcon>
                  <PeopleIcon
                    onClick={() => setFloatShowMore(!floatShowMore)}
                    style={{ color: theme.palette.common.white }}
                    data-testid="navigationFloatTabIcon"
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="body1" color="primary.contrastText">
                    Float
                  </Typography>
                }
              ></ListItemText>
              <ListItemIcon>
                <IconButton
                  onClick={() => setFloatShowMore(!floatShowMore)}
                  size="large"
                >
                  {!floatShowMore ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </ListItemIcon>
            </ListItem>
          )}
        {floatShowMore && user.floatOffice && (
          <Box ml={3}>
            <ListItem
              button
              component={Link}
              to="/EmployeeAllocation"
              selected={selectedIndex === 8}
              onClick={(event) => handleListItemClick(event, 8)}
            >
              <Tooltip title="Employee Allocation" placement="right">
                <ListItemIcon>
                  <WorkIcon
                    style={{ color: theme.palette.common.white }}
                    data-testid="navigationFloatEmployeeAllocationIcon"
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="body1" color="primary.contrastText">
                    Employee Allocations
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/FloatSettings"
              selected={selectedIndex === 9}
              onClick={(event) => handleListItemClick(event, 9)}
            >
              <Tooltip title="Settings" placement="right">
                <ListItemIcon>
                  <SettingsIcon
                    style={{ color: theme.palette.common.white }}
                    data-testid="navigationFloatSettingsIcon"
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="body1" color="primary.contrastText">
                    Settings
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/DepartmentStatus"
              selected={selectedIndex === 10}
              onClick={(event) => handleListItemClick(event, 10)}
            >
              <Tooltip title="Department Status" placement="right">
                <ListItemIcon>
                  <CheckCircleOutlineIcon
                    style={{ color: theme.palette.common.white }}
                    data-testid="navigationDepartmentStatusIcon"
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="body1" color="primary.contrastText">
                    Department Status
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/FloatNeeds"
              selected={selectedIndex === 11}
              onClick={(event) => handleListItemClick(event, 11)}
            >
              <Tooltip title="Float Needs" placement="right">
                <ListItemIcon>
                  <PersonAddIcon
                    style={{ color: theme.palette.common.white }}
                    data-testid="navigationFloatNeedsIcon"
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="body1" color="primary.contrastText">
                    Department Needs
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
          </Box>
        )}
        {(ability.can("manage", "AllOffices") ||
          ability.can("manage", "LocationSettings") ||
          ability.can("manage", "AppSettings") ||
          ability.can("manage", "RolesandPermissions")) && (
          <ListItem
            button
            onClick={() => setdepartmentShowmore(!departmentShowmore)}
          >
            <Tooltip title="Departments" placement="right">
              <ListItemIcon>
                <BusinessIcon
                  style={{ color: theme.palette.common.white }}
                  data-testid="navigationManageOfficeTabIcon"
                />{" "}
              </ListItemIcon>
            </Tooltip>

            <ListItemText
              primary={
                <Typography variant="body1" color="primary.contrastText">
                  Department
                </Typography>
              }
            ></ListItemText>
            <ListItemIcon>
              <IconButton
                style={{ color: theme.palette.common.white }}
                size="large"
              >
                {!departmentShowmore ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </ListItemIcon>
          </ListItem>
        )}
        {departmentShowmore && (
          <Box ml={3}>
            {ability.can("manage", "AllOffices") && (
              <>
                <ListItem
                  button
                  component={Link}
                  to="/Admin"
                  selected={selectedIndex === 12}
                  onClick={(event) => handleListItemClick(event, 12)}
                  data-testid="navigationAdminIcon"
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Departments
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
                {ability.can("manage", "LocationSettings") && (
                  <ListItem
                    button
                    component={Link}
                    to="/LocationSettings"
                    selected={selectedIndex === 26}
                    onClick={(event) => handleListItemClick(event, 26)}
                  >
                    {/* <Tooltip title="Location Settings" placement="right">
                    <ListItemIcon>
                      <LocalLibraryIcon
                        style={{ color: theme.palette.common.white }}
                        data-testid="navigationLocationSettingsIcon"
                      />
                    </ListItemIcon>
                  </Tooltip> */}

                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          color="primary.contrastText"
                        >
                          Skills
                        </Typography>
                      }
                    ></ListItemText>
                  </ListItem>
                )}
                {!locationConfig.shiftBasedDisplay && (
                  <>
                    <ListItem
                      button
                      component={Link}
                      to="/MissionDetails"
                      selected={selectedIndex === 13}
                      onClick={(event) => handleListItemClick(event, 13)}
                    >
                      <Tooltip title="Mission Details" placement="right">
                        <ListItemIcon>
                          <AssignmentIcon
                            style={{ color: theme.palette.common.white }}
                            data-testid="navigationMissionDetailsIcon"
                          />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            color="primary.contrastText"
                          >
                            Missions Details
                          </Typography>
                        }
                      ></ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/ProcedureTypeTemplate"
                      selected={selectedIndex === 23}
                      onClick={(event) => handleListItemClick(event, 23)}
                    >
                      <Tooltip
                        title="Procedure Type Template"
                        placement="right"
                      >
                        <ListItemIcon>
                          <FolderOpen
                            style={{ color: theme.palette.common.white }}
                            data-testid="navigationProcedureEmployeeTemplateIcon"
                          />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            color="primary.contrastText"
                          >
                            Procedure Type Template
                          </Typography>
                        }
                      ></ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/ProcedureTemplate"
                      selected={selectedIndex === 24}
                      onClick={(event) => handleListItemClick(event, 24)}
                    >
                      <Tooltip title="Procedure Template" placement="right">
                        <ListItemIcon>
                          <FileCopyIcon
                            style={{ color: theme.palette.common.white }}
                            data-testid="navigationProcedureTemplateofTemplates"
                          />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            color="primary.contrastText"
                          >
                            Procedure Template
                          </Typography>
                        }
                      ></ListItemText>
                    </ListItem>
                  </>
                )}
              </>
            )}
          </Box>
        )}
        {(ability.can("manage", "Users") ||
          ability.can("manage", "Schedule") ||
          user.role === "EMPLOYEE") &&
          locationConfig.analyticsTab && (
            <ListItem
              button
              onClick={() => setanalyticstShowmore(!analyticsShowmore)}
            >
              <Tooltip title="Analytics" placement="right">
                <ListItemIcon>
                  <AssessmentIcon
                    style={{ color: theme.palette.common.white }}
                    data-testid="navigationAnalyticsTabIcon"
                  />
                </ListItemIcon>
              </Tooltip>

              <ListItemText
                primary={
                  <Typography variant="body1" color="primary.contrastText">
                    Analytics
                  </Typography>
                }
              ></ListItemText>
              <Tooltip title="Analytics" placement="right">
                <ListItemIcon>
                  <IconButton
                    style={{ color: theme.palette.common.white }}
                    size="large"
                  >
                    {!analyticsShowmore ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ExpandLessIcon />
                    )}
                  </IconButton>
                </ListItemIcon>
              </Tooltip>
            </ListItem>
          )}
        {analyticsShowmore && locationConfig.analyticsTab && (
          <Box ml={3}>
            {/* <ListItem button>
            <ListItemIcon
              data-testid="navigationReportsTabIcon"
              onClick={() => setReportsShowMore(!reportsShowMore)}
            ></ListItemIcon>

            <ListItemText
              primary={
                <Typography variant="body1" color="primary.contrastText">
                  Reports
                </Typography>
              }
            ></ListItemText>
            <Tooltip title="Reports" placement="right">
              <ListItemIcon>
                <IconButton
                  style={{ color: theme.palette.common.white }}
                  onClick={() => setReportsShowMore(!reportsShowMore)}
                  size="large"
                >
                  {!reportsShowMore ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </ListItemIcon>
            </Tooltip>
          </ListItem> */}
            <ListItem
              button
              component={Link}
              to="/StaffingCoverage"
              selected={selectedIndex === 24}
              onClick={(event) => handleListItemClick(event, 24)}
            >
              <ListItemText
                primary={
                  <Typography variant="body1" color="primary.contrastText">
                    Staffing Coverage
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            {(ability.can("manage", "Schedule") ||
              ability.can("manage", "Users")) && (
              <Box>
                <ListItem
                  button
                  component={Link}
                  to="/PayrollReport"
                  selected={selectedIndex === 23}
                  onClick={(event) => handleListItemClick(event, 23)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Payroll Report
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>

                <ListItem
                  button
                  component={Link}
                  to="/StaffAvailability"
                  selected={selectedIndex === 25}
                  onClick={(event) => handleListItemClick(event, 25)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Staff Availability
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/ScheduleSummary"
                  selected={selectedIndex === 26}
                  onClick={(event) => handleListItemClick(event, 26)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Schedule Summary
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
              </Box>
            )}
            {ability.can("manage", "AllOffices") && user.floatOffice && (
              <ListItem
                button
                component={Link}
                to="/FloatAnalytics"
                selected={selectedIndex === 17}
                onClick={(event) => handleListItemClick(event, 17)}
              >
                <Tooltip title="Float Analytics" placement="right">
                  <ListItemIcon>
                    <DashboardIcon
                      style={{ color: theme.palette.common.white }}
                      data-testid="navigationFloatAnalyticsIcon"
                    />
                  </ListItemIcon>
                </Tooltip>

                <ListItemText
                  primary={
                    <Typography variant="body1" color="primary.contrastText">
                      Float Analytics
                    </Typography>
                  }
                ></ListItemText>
              </ListItem>
            )}
            {ability.can("manage", "AllOffices") && !user.floatOffice && (
              <>
                <ListItem
                  button
                  component={Link}
                  to="/ScheduleQualityAnalytics"
                  selected={selectedIndex === 18}
                  onClick={(event) => handleListItemClick(event, 18)}
                  data-testid="navigationScheduleQualityAnalyticsIcon"
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Schedule Quality
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/PreferenceAnalytics"
                  selected={selectedIndex === 19}
                  onClick={(event) => handleListItemClick(event, 19)}
                  data-testid="navigationPreferenceAnalyticsIcon"
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Preferences
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
                {/* <ListItem
                  button
                  component={Link}
                  to="/SkillSetAnalytics"
                  selected={selectedIndex === 20}
                  onClick={(event) => handleListItemClick(event, 20)}
                  data-testid="navigationSkillSetAnalyticsIcon"
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Skill Set
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem> */}
                {/* <ListItem
                  button
                  component={Link}
                  to="/TimeOffAnalytics"
                  selected={selectedIndex === 21}
                  onClick={(event) => handleListItemClick(event, 21)}
                  data-testid="navigationTimeOffAnalyticsIcon"
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Time Off
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem> */}
                {/* {user.isPrimaryParentOffice && (
                <ListItem
                  button
                  component={Link}
                  to="/LocationPreferenceAnalytics"
                  selected={selectedIndex === 22}
                  onClick={(event) => handleListItemClick(event, 22)}
                >
                  <Tooltip
                    title="Location Preference Analytics"
                    placement="right"
                  >
                    <ListItemIcon>
                      <DashboardIcon
                        style={{ color: theme.palette.common.white }}
                        data-testid="navigationLocationPreferenceAnalyticsIcon"
                      />
                    </ListItemIcon>
                  </Tooltip>

                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Location Preferences
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
              )} */}
              </>
            )}
          </Box>
        )}{" "}
        {ability.can("manage", "AppSettings") && (
          <ListItem
            button
            data-testid="navigationAdminIcon"
            onClick={() => setAllSettingsShowMore(!allSettingsShowMore)}
          >
            <Tooltip title="Settings" placement="right">
              <ListItemIcon style={{ color: theme.palette.common.white }}>
                <SettingsIcon data-testid="navigationAppSettingsIcon" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={
                <Typography variant="body1" color="primary.contrastText">
                  Settings
                </Typography>
              }
            ></ListItemText>

            <ListItemIcon>
              <IconButton
                style={{ color: theme.palette.common.white }}
                size="large"
              >
                {!allSettingsShowMore ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </ListItemIcon>
          </ListItem>
        )}
        {allSettingsShowMore && (
          <>
            <Box ml={3}>
              <ListItem
                button
                component={Link}
                to="/AppSettings"
                selected={selectedIndex === 27}
                onClick={(event) => handleListItemClick(event, 27)}
              >
                {/* <Tooltip title="App Settings" placement="right">
                    <ListItemIcon>
                      <SettingsIcon data-testid="navigationAppSettingsIcon" />
                    </ListItemIcon>
                  </Tooltip> */}
                <ListItemText
                  primary={
                    <Typography variant="body1" color="primary.contrastText">
                      All Settings
                    </Typography>
                  }
                ></ListItemText>
              </ListItem>

              {ability.can("manage", "RolesandPermissions") && (
                <ListItem
                  button
                  component={Link}
                  to="/RolesandPermissions"
                  selected={selectedIndex === 28}
                  onClick={(event) => handleListItemClick(event, 28)}
                >
                  {/* <Tooltip title="Roles and Permissions" placement="right">
                    <ListItemIcon>
                      <AccountCircleIcon
                        style={{ color: theme.palette.common.white }}
                        data-testid="navigationRolesandPermissionsIcon"
                      />
                    </ListItemIcon>
                  </Tooltip> */}

                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary.contrastText">
                        Roles and Permissions
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
              )}
            </Box>
          </>
        )}
      </List>
    </>
  );
}

export default DashBoardSideNav;
