import React, { forwardRef, useState } from "react";
import {
  Typography,
  Grid,
  IconButton,
  FormControlLabel,
  Switch,
  Button,
  InputLabel,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import { toIsoDate, getLocalIsoOffset } from "../../helpers/formatTime";
import { DateTimePaginator } from "../../helpers/DateTimePaginator";
import { userVar } from "../../cache";
import { useMutation, gql } from "@apollo/client";
import { EDIT_SOFT_REQUEST, DELETE_SOFT_REQUEST } from "../../api/gqlQueries";
import { updateSoftRequest } from "./UpdateEvents";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}
const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 138,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.primary.main,
  },
  button: {
    width: 75,
  },
}));

/*Click the event with title SOFT REQUEST to edit the soft time off request added by the logged in User.
  User can delete their soft time off requests.
*/

const EditSoftTimeOff = forwardRef((props, ref) => {
  const classes = useStyles();

  const {
    closeDialog,
    scheduleEndDate,
    softRequestToEdit,
    notifyDevelopers,
    environment,
    selectedOffice,
  } = props;

  const user = userVar();

  const [priorityChecked, setPriorityChecked] = useState(
    softRequestToEdit ? softRequestToEdit.highPriority : false,
  );
  const [dateForRequest, setDateForRequest] = useState(
    softRequestToEdit
      ? new Date(softRequestToEdit.start)
      : new Date(scheduleEndDate),
  );
  const [error, setError] = useState("");
  const minimumDate = new Date(scheduleEndDate);

  const requestISODate = toIsoDate(softRequestToEdit.start);
  console.log(requestISODate);

  const [updatePreference] = useMutation(EDIT_SOFT_REQUEST, {
    update(cache, { data: { updateOffPreference } }) {
      // This adds the node to the query associated with the new date
      // However, it does not remove it from the original query results which should be fine
      if (updateOffPreference.result.__typename !== "OffPreferenceNode") {
        return;
      }
      const paginator = new DateTimePaginator(1); // TODO: using in multiple spots need the same constructor
      const element = updateOffPreference.result;
      const left = element.date + "T00:00:00";
      const start = new Date(left + getLocalIsoOffset());
      const dates = paginator.getQueryDateRanges(start, start);
      const variables = {
        employeeId: user.id,
        pageEndDate: toIsoDate(dates[0][1]),
        pageStartDate: toIsoDate(dates[0][0]),
      };
      const strVariables = JSON.stringify(variables);
      cache.modify({
        fields: {
          offPreferences: (existing, { storeFieldName }) => {
            if (storeFieldName.includes(strVariables)) {
              const newSoftTimeOffRef = cache.writeFragment({
                data: element,
                fragment: gql`
                  fragment NewOffPreference on OffPreferenceNode {
                    id
                    date
                    value
                    employee {
                      id
                    }
                  }
                `,
              });
              return [...existing, newSoftTimeOffRef];
            } else {
              return existing;
            }
          },
        },
      });
    },
    onCompleted(d) {
      const typename = d.updateOffPreference.result.__typename;
      const result = d.updateOffPreference.result;
      toastShow("Successfully edited your soft request", "Success");
      closeDialog();
      if (typename === "OffPreferenceNode") {
        if (ref) {
          updateSoftRequest(
            ref.current,
            result,
            softRequestToEdit,
            selectedOffice.name,
          );
        }
        toastShow("Day off preference successfully updated.", "Success");
        closeDialog();
      } else if (typename === "ModelValidationError") {
        toastShow(result.message, "Error");
      } else {
        toastShow(result.message, "Error");
        closeDialog();
      }
    },
    onError(error) {
      console.log(error);
      toastShow(
        "Unable to update the request. If the issue persists, please create a help ticket.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on EDIT_SOFT_REQUEST Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [deleteSoftRequest] = useMutation(DELETE_SOFT_REQUEST, {
    update(cache, { data: { deleteOffPreference } }) {
      cache.evict({
        id: `OffPreferenceNode:${deleteOffPreference.deletedId}`,
      });
    },
    onCompleted(data) {
      if (ref) {
        updateSoftRequest(
          ref.current,
          null,
          softRequestToEdit,
          selectedOffice.name,
        );
      }
      toastShow("Day off preference successfully deleted.", "Success");
      closeDialog();
    },
    onError(error) {
      toastShow(
        "Day off preference could not be deleted. If the issue persists, please create a help ticket.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on DELETE_SOFT_REQUEST Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleSubmit = () => {
    const updatedRequest = {
      date: toIsoDate(dateForRequest),
      value: priorityChecked ? 10.0 : 5.0,
    };
    updatePreference({
      variables: {
        id: parseInt(softRequestToEdit.eventId),
        input: { ...updatedRequest },
      },
    });
  };

  const handleDelete = () => {
    deleteSoftRequest({ variables: { id: softRequestToEdit.eventId } });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="h3">Edit Soft Request</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              color="secondary"
              size="small"
              onClick={closeDialog}
              data-testid="closeEditSoftTimeOffFormShift"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            data-testid="prioritySwitchEditSoftTimeOffFormShift"
            control={
              <Switch
                checked={priorityChecked}
                onChange={(e) => setPriorityChecked(e.target.checked)}
                name="priorityChecked"
                color={priorityChecked ? "primary" : "secondary"}
              />
            }
            label="Priority Request"
          ></FormControlLabel>
        </Grid>
        <Grid item>
          <InputLabel htmlFor="date-for-request">
            <Typography variant="h6">Date:</Typography>
          </InputLabel>
          <DatePicker
            disableToolbar
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            id="date-for-request"
            minDate={minimumDate}
            value={dateForRequest}
            onChange={(date) => setDateForRequest(date)}
            className={classes.input}
            renderInput={(props) => <TextField {...props} />}
            data-testid="dateEditSoftTimeOffFormShift"
          />
        </Grid>
        <Grid item style={{ height: 40, marginTop: -15, marginBottom: 10 }}>
          {error && <Typography className={classes.error}>{error}</Typography>}
        </Grid>
        <Grid item container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleDelete}
              className={classes.button}
              data-testid="deleteSoftTimeOffFormShift"
            >
              Delete
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={
                error ||
                (requestISODate === toIsoDate(dateForRequest) &&
                  softRequestToEdit.highPriority === priorityChecked)
              }
              className={classes.button}
              data-testid="saveSoftTimeOffFormShift"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
});

export default EditSoftTimeOff;
