import {
  ColumnDirective,
  ColumnsDirective,
  ExcelExport,
  GridComponent,
  Filter,
  Inject,
  Page,
  Sort,
  CommandColumn,
  Toolbar,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import "../../AllStyles/Grid.css";
import { getComponent, L10n } from "@syncfusion/ej2-base";

const useStyles = makeStyles((theme) => ({
  underText: {
    fontWeight: "bold",
  },
  timeText: {
    paddingRight: 0,
  },
  underTimeText: {
    fontWeight: "bold",
    paddingRight: 0,
  },
  table: {
    minWidth: "auto",
  },
  icon: {
    padding: 0,
    marginRight: 5,
  },
}));

const ShiftDistribution = ({ tableData }) => {
  const toolbarOptions = ["Search", "Print", "ExcelExport"];
  const searchOptions = {
    operator: "contains",
    key: "",
  };

  useEffect(() => {
    let gridObj = getComponent(
      document.getElementById("shift-data-grid"),
      "grid",
    );
    if (gridObj) {
      gridObj.enablePersistence = false;
      gridObj.searchSettings.key = "";
      window.localStorage.setItem("gridGrid", "");
      window.localStorage.setItem("griddata-grid", "");
    }
  }, []);

  L10n.load({
    "en-US": {
      grid: {
        EmptyRecord: "No records found.",
        enablePersistence: false,
        clearFilters: true,
      },
    },
  });

  return (
    <div style={{ margin: 10, padding: 20 }}>
      <h2 style={{ textAlign: "center", marginBottom: "10px" }}>
        Shift Day Distribution
      </h2>
      <GridComponent
        id="shift-data-grid"
        name="data-grid-shift-distribution-report"
        dataSource={tableData}
        pageSettings={{
          pageSize: 10,
          pageSizes: true,
        }}
        allowSorting={true}
        enableHover={true}
        allowPaging={true}
        height="auto"
        allowExcelExport={true}
        enablePersistence={true}
        showColumnChooser={true}
        toolbar={toolbarOptions}
        searchSettings={searchOptions}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="employee.firstName"
            headerText="First Name"
            textAlign="Center"
          />
          <ColumnDirective
            field="employee.lastName"
            headerText="Last Name"
            textAlign="Center"
          />
          <ColumnDirective
            field="shiftDistribution.monday.value"
            headerText="Monday"
            textAlign="Center"
          />
          <ColumnDirective
            field="shiftDistribution.tuesday.value"
            headerText="Tuesday"
            textAlign="Center"
          />
          <ColumnDirective
            field="shiftDistribution.wednesday.value"
            headerText="Wednesday"
            textAlign="Center"
          />
          <ColumnDirective
            field="shiftDistribution.thursday.value"
            headerText="Thursday"
            textAlign="Center"
          />
          <ColumnDirective
            field="shiftDistribution.friday.value"
            headerText="Friday"
            textAlign="Center"
          />
          <ColumnDirective
            field="shiftDistribution.saturday.value"
            headerText="Saturday"
            textAlign="Center"
          />
          <ColumnDirective
            field="shiftDistribution.sunday.value"
            headerText="Sunday"
            textAlign="Center"
          />
        </ColumnsDirective>
        <Inject
          services={[
            Sort,
            Filter,
            CommandColumn,
            Toolbar,
            ExcelExport,
            ColumnChooser,
            Page,
          ]}
        />
      </GridComponent>
    </div>
  );
};

export default ShiftDistribution;
