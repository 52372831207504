import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableCell,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { GET_ASSIGNMENT } from "../../api/gqlQueries";
import { useQuery } from "@apollo/client";
import { differenceInHours } from "date-fns";

const useStyles = makeStyles((theme) => ({
  coloredAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.secondary.main,
  },
  newSubtitle: {
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  grayedOut: {
    color: theme.palette.secondary.light,
    fontStyle: "italic",
  },
  grayedOutSubtitle: {
    color: theme.palette.secondary.light,
    fontStyle: "italic",
    fontSize: 12,
  },
  added: {
    color: theme.palette.primary.main,
  },
  button: {
    width: 110,
  },
  inputWidth: {
    width: "75%",
  },
}));

// Participant Add/Delete on Edit shift form

const ParticipantDelete = (props) => {
  const classes = useStyles();

  const {
    participant,
    employeesToDelete,
    setEmployeesToDelete,
    employeesToAdd,
    setEmployeesToAdd,
    currentShiftEmployees,
    setCurrentShiftEmployees,
    newAddition,
    officeId,
    notifyDevelopers,
    environment,
    shiftEvent,
    workHoursToUpdate,
    SetWorkHoursToUpdate,
  } = props;

  const [deleted, setDeleted] = useState(false);
  const [participantWorkHours, SetParticipantWorkHours] = useState(
    participant && participant.workHours ? participant.workHours : 0,
  );

  const fullName = participant.lastName + ", " + participant.firstName;

  let matchingAssignment;
  let jobTitle =
    participant.skills &&
    participant.skills.length > 0 &&
    participant.skills.find((e) => e.variety === "JOB_TYPE");

  const handleDeleteClick = () => {
    const currentIndex = employeesToDelete.indexOf(participant);
    const newEmployeesToDelete = [...employeesToDelete];

    if (currentIndex === -1) {
      newEmployeesToDelete.push(participant);
    } else {
      newEmployeesToDelete.splice(currentIndex, 1);
    }

    setDeleted(!deleted);
    setEmployeesToDelete(newEmployeesToDelete);
  };

  const handleUndoAddClick = () => {
    const currentIndexInAdd = employeesToAdd.indexOf(participant);
    const currentIndexInShiftEmployees =
      currentShiftEmployees.indexOf(participant);
    const newEmployeesToAdd = [...employeesToAdd];
    const newCurrentShiftEmployees = [...currentShiftEmployees];

    newEmployeesToAdd.splice(currentIndexInAdd, 1);
    newCurrentShiftEmployees.splice(currentIndexInShiftEmployees, 1);
    setEmployeesToAdd(newEmployeesToAdd);
    setCurrentShiftEmployees(newCurrentShiftEmployees);
  };

  useEffect(() => {
    if (participantWorkHours) {
      setTimeout(() => {
        participant.workHours = participantWorkHours;
        // let newValue = workHoursToUpdate.length > 0 && workHoursToUpdate.find(e => e.id === participant.id || e.id === participant.shiftAssignmentId);
        // newValue && { ...newValue, workHours: participantWorkHours }
        // let allWorkHours = [...workHoursToUpdate,{ id: participant.id ? participant.id : participant.shiftAssignmentId, workHours: participantWorkHours }]
        // !newValue && SetWorkHoursToUpdate(allWorkHours)
      }, 500);
    }
  }, [participantWorkHours]);

  return (
    <Grid item container spacing={4}>
      {newAddition ? (
        <>
          <Grid item>
            <Typography variant="subtitle1" className={classes.added} noWrap>
              {fullName}
            </Typography>
            <Typography className={classes.newSubtitle}>
              {jobTitle ? jobTitle.name : ""}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              className={classes.button}
              onClick={handleUndoAddClick}
            >
              Undo Add
            </Button>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={4}>
            <Typography
              variant="subtitle1"
              className={deleted ? classes.grayedOut : null}
              noWrap
            >
              {fullName}
            </Typography>
            <Typography
              className={deleted ? classes.grayedOutSubtitle : classes.subtitle}
            >
              {jobTitle ? jobTitle.name : ""}
            </Typography>
          </Grid>
          {new Date(shiftEvent.start) < new Date() && (
            <Grid item xs={4}>
              <TextField
                id={fullName + "workHours"}
                name={fullName + "workHours"}
                placeholder="enter work hours"
                type="number"
                size="small"
                inputProps={{ step: 0.25 }}
                className={classes.inputWidth}
                value={participantWorkHours}
                InputProps={{ inputProps: { min: 0.25 } }}
                onChange={(e) => {
                  SetParticipantWorkHours(e.target.value);
                }}
                label="work hours"
                data-testid="workHoursTextBox"
              />
            </Grid>
          )}

          <Grid item xs={4}>
            {deleted && (
              <Button
                color="primary"
                className={classes.button}
                onClick={handleDeleteClick}
                data-testid="undoRemoveEmployee"
              >
                Undo Remove
              </Button>
            )}
            {!deleted && (
              <Button
                color="primary"
                variant="outlined"
                className={classes.button}
                onClick={handleDeleteClick}
                data-testid="removeEmployee"
              >
                Remove
              </Button>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

ParticipantDelete.propTypes = {
  participant: PropTypes.object,
  employeesToDelete: PropTypes.array,
  setEmployeesToDelete: PropTypes.func,
  employeesToAdd: PropTypes.array,
  setEmployeesToAdd: PropTypes.func,
  currentShiftEmployees: PropTypes.array,
  setCurrentShiftEmployees: PropTypes.func,
  newAddition: PropTypes.bool,
};

ParticipantDelete.defaultProps = {
  newAddition: false,
};

export default ParticipantDelete;
