import React, { Fragment, useEffect, useState } from "react";
import ParticipantDelete from "./ParticipantDelete";
import EventParticipant from "./EventParticipant";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  MenuItem,
  InputLabel,
  Select,
  Box,
  CircularProgress,
  Autocomplete,
  TextField,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CloseIcon from "@mui/icons-material/Close";
import StarRateIcon from "@mui/icons-material/StarRate";
import {
  GET_ALL_USERS_FOR_ADD_EDIT_SHIFT,
  GET_SHIFT_ASSIGNMENTS_BY_SHIFT,
  ADD_AND_DROP_OPTIONS_FOR_SHIFT,
  EXECUTE_OPTION,
  SKILL_REQUIREMENTS_FOR_PROCEDURES,
  BATCH_CREATE_RESCHEDULE_OPTION,
  REMOVE_SHIFT_ASSIGNMENT,
  CREATE_SHIFT_ASSIGNMENT,
  SCHEDULER_INITIATE_OPTION,
  GET_FLOAT_DETAILS,
} from "../../api/gqlQueries";
import { format, sub, add, startOfWeek, endOfWeek, isEqual } from "date-fns";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { userVar, selectedDateVar } from "../../cache";
import RescheduleOptionsKey from "../rescheduling/RescheduleOptionsKey";
import { ProductionQuantityLimitsRounded } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 273,
    textOverflow: "ellipsis",
  },
  helpfulTip: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.primary.main,
    marginTop: -15,
  },
  button: {
    width: 75,
  },
  icon: {
    padding: 0,
    marginLeft: -7,
    marginTop: 2,
  },
}));

const MangEditShift = (props) => {
  const classes = useStyles();

  const {
    shiftEvent,
    closeDialog,
    setToast,
    setShowToast,
    refetch,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
    ref,
    events,
    setShiftToEdit,
    getVariables,
    getFutureShifts,
    setScheduleData,
    scheduleData,
    addDataToCurrentScheduleData,
  } = props;

  const user = userVar();
  const selectedDate = selectedDateVar();
  const officeId = user.office.id;

  let currentShiftId;
  if (!shiftEvent.tasks) {
    currentShiftId = shiftEvent.eventId;
  }

  // get office float settings
  const { data: officeData } = useQuery(GET_FLOAT_DETAILS, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("Error Edit Shift. Graphql " + error);
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_FLOAT_DETAILS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    if (shiftEvent) {
      getEmpsForSelectedShift({
        variables: {
          shiftId: shiftEvent.eventId,
        },
      });
    }
  }, [shiftEvent]);

  const [includedShifts, setIncludedShifts] = useState();
  const [selectedShift, setSelectedShift] = useState();
  const [shiftAssignments, setShiftAssignments] = useState([]);
  const [currentShiftEmployees, setCurrentShiftEmployees] = useState(
    shiftEvent.participants,
  );
  const [selectedEmployeeToAdd, setSelectedEmployeeToAdd] = useState("");
  const [employeesToAdd, setEmployeesToAdd] = useState([]);
  const [employeesToDelete, setEmployeesToDelete] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [addOptions, setAddOptions] = useState([]);
  const [dropOptions, setDropOptions] = useState([]);
  const [shiftRequirementSelected, SetShiftRequirementSelected] = useState(0);
  const [showRequestFloats, setShowRequestFloats] = useState(false);
  const [acceptsFloats, setAcceptsFloats] = useState(false);

  useEffect(() => {
    if (officeData) {
      const currentOffice = officeData.offices.find(
        (office) => office.id === officeId,
      );
      setAcceptsFloats(currentOffice.floatStatus === false);
    }
  }, [officeData]);

  const { loading, error, data } = useQuery(GET_ALL_USERS_FOR_ADD_EDIT_SHIFT, {
    variables: {
      shiftId: parseInt(shiftEvent.eventId),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_ALL_USERS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const [
    getEmpsForSelectedShift,
    {
      loading: getEmpsForSelectedShiftLoading,
      data: getEmpsForSelectedShiftData,
    },
  ] = useLazyQuery(GET_ALL_USERS_FOR_ADD_EDIT_SHIFT, {
    onCompleted(d) {
      console.log(d);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_ALL_USERS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [getShiftAssignmentData, { data: shiftAssignmentData }] = useLazyQuery(
    GET_SHIFT_ASSIGNMENTS_BY_SHIFT,
    {
      fetchPolicy: "cache-and-network",
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on  GET_SHIFT_ASSIGNMENTS_BY_SHIFT lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  const [schedulerInitiateOption, { data: schedulerInitiateOptionData }] =
    useMutation(SCHEDULER_INITIATE_OPTION, {
      onCompleted(d) {
        console.log(d);
        refetch();
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage("Error Edit Shift. Graphql " + error);
        notifyDevelopers({
          variables: {
            message:
              "Error on SCHEDULER_INITIATE_OPTION Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    });

  const toggleShowRequestFloats = () =>
    setShowRequestFloats(!showRequestFloats);

  const [getRescheduleOptions] = useLazyQuery(ADD_AND_DROP_OPTIONS_FOR_SHIFT, {
    onCompleted(data) {
      const addOptions =
        data.rescheduleAdds.length > 0
          ? data.rescheduleAdds
              .filter((option) => option.status === "OPTION")
              .map((add) => {
                const employeeIdsAndBenefits = add.rescheduleindividualSet.map(
                  (employee) => {
                    return {
                      employeeId: employee.employee.id,
                      benefit: employee.benefit,
                      cost: employee.cost,
                      optionId: add.id,
                    };
                  },
                );
                return employeeIdsAndBenefits;
              })
              .flat()
          : [];

      const dropOptions =
        data.rescheduleDrops.length > 0
          ? data.rescheduleDrops
              .filter((option) => option.status === "OPTION")
              .map((drop) => {
                const employeeIdsAndBenefits = drop.rescheduleindividualSet.map(
                  (employee) => {
                    return {
                      employeeId: employee.employee.id,
                      benefit: employee.benefit,
                      cost: employee.cost,
                      optionId: drop.id,
                    };
                  },
                );
                return employeeIdsAndBenefits;
              })
              .flat()
          : [];
      setAddOptions(addOptions);
      setDropOptions(dropOptions);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ADD_AND_DROP_OPTIONS_FOR_SHIFT lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchCreateOptions, { data: batchCreateOptionsData }] = useMutation(
    BATCH_CREATE_RESCHEDULE_OPTION,
    {
      onCompleted(d) {
        if (
          window.MANAGER_APPROVES_EMPLOYEE_REQUESTS === true ||
          window.MANAGER_APPROVES_EMPLOYEE_REQUESTS === "true"
        ) {
          d.batchCreateRescheduleOption.options.length > 0 &&
            d.batchCreateRescheduleOption.options.map((e) => {
              schedulerInitiateOption({
                variables: {
                  option: parseInt(e.id),
                },
              });
            });
        }
        let firstDayOfCurrentView = sub(new Date(selectedDate), { days: 2 });
        firstDayOfCurrentView = startOfWeek(firstDayOfCurrentView);
        let lastDayOfCurrentView = add(new Date(selectedDate), { weeks: 1 });
        const variables = getVariables(
          firstDayOfCurrentView,
          lastDayOfCurrentView,
        );
        refetch(variables);
      },
    },
    {
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage("Error Edit Shift." + error);
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_CREATE_RESCHEDULE_OPTION Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  useEffect(() => {
    if (shiftAssignmentData) {
      const assignments = shiftAssignmentData.shiftAssignments.filter(
        (shiftAssignment) => shiftAssignment.isArchived === false,
      );

      setShiftAssignments(assignments);
    }
  }, [shiftAssignmentData]);

  useEffect(() => {
    if (shiftEvent.tasks) {
      let smallerShifts = [];
      shiftEvent.tasks.forEach((task) => {
        const exists = smallerShifts.find(
          (small) => task.start === small.start && task.end === small.end,
        );
        const title =
          format(new Date(task.start), "HH:mm") +
          " - " +
          format(new Date(task.end), "HH:mm");

        const shiftId =
          shiftEvent.eventTitle === "GHOC"
            ? task.id
            : task.shiftAssignment.shift.id;

        const procedureId =
          shiftEvent.eventTitle !== "GHOC" ? shiftEvent.eventId : null;

        const shiftAssignment = task.shiftAssignment
          ? [task.shiftAssignment]
          : task.shiftassignmentSet.filter(
              (assignment) =>
                assignment.taskSet && assignment.taskSet.length === 0,
            );

        if (!exists) {
          smallerShifts = [
            ...smallerShifts,
            {
              title: title,
              procedureId: procedureId,
              shiftId: shiftId,
              start: task.start,
              end: task.end,
              participants: [...shiftAssignment],
            },
          ];
        } else {
          exists.participants = [...exists.participants, ...shiftAssignment];
        }
      });
      setIncludedShifts(smallerShifts);
      setSelectedShift(smallerShifts[0]);
      getRescheduleOptions({
        variables: {
          shifts: [parseInt(smallerShifts[0].shiftId)],
        },
      });
    } else if (currentShiftId) {
      getShiftAssignmentData({
        variables: {
          shiftId: parseInt(currentShiftId),
        },
      });
      getRescheduleOptions({
        variables: {
          shifts: [parseInt(currentShiftId)],
        },
      });
    }
  }, []);

  const [executeOption] = useMutation(EXECUTE_OPTION, {
    onCompleted(data) {
      let firstDayOfCurrentView = sub(new Date(selectedDate), { days: 2 });
      firstDayOfCurrentView = startOfWeek(firstDayOfCurrentView);
      let lastDayOfCurrentView = add(new Date(selectedDate), { weeks: 1 });
      const variables = getVariables(
        firstDayOfCurrentView,
        lastDayOfCurrentView,
      );
      refetch(variables);
    },
    onError(error) {
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on EXECUTE_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [removeShiftAssignment] = useMutation(REMOVE_SHIFT_ASSIGNMENT, {
    onCompleted(d) {
      scheduleData.formatted = events.filter(
        (e) => e.idOfShift != d.removeShiftAssignment.shift.id,
      );
    },
  });

  const [createShiftAssignment] = useMutation(CREATE_SHIFT_ASSIGNMENT, {
    onCompleted(d) {
      let newEvents = addDataToCurrentScheduleData([
        d.createShiftAssignment.shift,
      ]);
      setScheduleData(newEvents);
    },
  });

  const [
    getShiftSkillRequirement,
    {
      loading: getShiftSkillRequirementLoading,
      data: getShiftSkillRequirementData,
    },
  ] = useLazyQuery(SKILL_REQUIREMENTS_FOR_PROCEDURES, {
    onCompleted(data) {
      console.log(data);
    },
  });

  useEffect(() => {
    getShiftSkillRequirement({
      variables: {
        rangeStart: sub(shiftEvent.start, { days: 1 }),
        rangeEnd: add(shiftEvent.end, { days: 1 }),
      },
    });
  }, []);
  // mutation to request for float employees to fill a shift
  const handleSendFloatRequest = () => {
    requestFloat({
      variables: {
        shift: currentShiftId,
        comments: floatComment,
      },
    });
  };

  let eligibleEmployees;
  let allUsers;
  if (getEmpsForSelectedShiftData) {
    allUsers =
      getEmpsForSelectedShiftData &&
      getEmpsForSelectedShiftData.manualAddEmployees &&
      getEmpsForSelectedShiftData.manualAddEmployees.length > 0
        ? getEmpsForSelectedShiftData.manualAddEmployees
        : data && data.manualAddEmployees && data.manualAddEmployees.length > 0
          ? data.manualAddEmployees
          : [];
    const allUsersIds = allUsers.map((user) => user.id);
    const currentShiftEmployeeIds = currentShiftEmployees.map(
      (user) => user.employeeId,
    );
    const eligibleEmployeeIds = allUsersIds.filter(
      (id) => !currentShiftEmployeeIds.includes(id),
    );
    eligibleEmployees = allUsers
      .filter((user) => eligibleEmployeeIds.includes(user.id))
      .sort(function (a, b) {
        if (a.lastName < b.lastName) {
          return -1;
        }
        if (a.lastName > b.lastName) {
          return 1;
        }
        return 0;
      });
  }

  const renderParticipants = () => {
    const uniqueIds = [];
    let uniqueEmployees = [];
    if (selectedShift) {
      uniqueEmployees = selectedShift.participants.map(
        (participant) => participant.employeeId,
      );
    } else {
      shiftEvent.participants.forEach((participant) => {
        !uniqueIds.includes(participant.employeeId) &&
          uniqueIds.push(participant.employeeId) &&
          uniqueEmployees.push(participant);
      });
    }
    return uniqueEmployees.map((participant, index) => {
      const drop = dropOptions.find(
        (option) => option.employeeId === participant.employeeId,
      );
      return (
        <Grid
          item
          container
          key={index}
          xs={5}
          alignItems="center"
          spacing={1}
          style={{ margin: 5, marginRight: 25 }}
        >
          <Grid item xs={2}>
            {drop && (
              <>
                {drop.benefit <= -1 && (
                  <StarRateIcon className={classes.icon} />
                )}
                {drop.benefit < 1 && drop.benefit > -1 && (
                  <>
                    <StarRateIcon className={classes.icon} />
                    <StarRateIcon className={classes.icon} />
                  </>
                )}
                {drop.benefit >= 1 && (
                  <>
                    <StarRateIcon className={classes.icon} />
                    <StarRateIcon className={classes.icon} />
                    <StarRateIcon className={classes.icon} />
                  </>
                )}
              </>
            )}
          </Grid>
          <ParticipantDelete
            participant={participant}
            employeesToDelete={employeesToDelete}
            setEmployeesToDelete={setEmployeesToDelete}
            officeId={officeId}
            shiftEvent={shiftEvent}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setSnackBarMessage}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
          />
        </Grid>
      );
    });
  };

  const renderNewAdditions = () => {
    if (employeesToAdd) {
      return employeesToAdd.map((employee) => {
        const add = addOptions.find(
          (option) => option.employeeId === employee.id,
        );

        return (
          <Grid
            item
            container
            key={employee.id}
            xs={5}
            alignItems="center"
            spacing={1}
            style={{ margin: 5, marginRight: 25 }}
          >
            <Grid item xs={2}>
              {add && (
                <>
                  {add.benefit <= -1 && (
                    <StarRateIcon className={classes.icon} />
                  )}
                  {add.benefit < 1 && add.benefit > -1 && (
                    <>
                      <StarRateIcon className={classes.icon} />
                      <StarRateIcon className={classes.icon} />
                    </>
                  )}
                  {add.benefit >= 1 && (
                    <>
                      <StarRateIcon className={classes.icon} />
                      <StarRateIcon className={classes.icon} />
                      <StarRateIcon className={classes.icon} />
                    </>
                  )}
                </>
              )}
            </Grid>
            <ParticipantDelete
              key={employee.id}
              participant={employee}
              newAddition={true}
              employeesToAdd={employeesToAdd}
              setEmployeesToAdd={setEmployeesToAdd}
              currentShiftEmployees={currentShiftEmployees}
              setCurrentShiftEmployees={setCurrentShiftEmployees}
              officeId={officeId}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </Grid>
        );
      });
    }
  };

  const renderEmployeeOptions = (employees) => {
    if (employees && employees.length > 0) {
      let adds = [];
      let others = [];
      employees.forEach((employee) => {
        const add = addOptions.find(
          (option) => option.employeeId === employee.id,
        );
        if (add) {
          adds = [...adds, { ...add, ...employee }];
        } else {
          others = [...others, { ...employee }];
        }
      });

      adds.sort((a, b) => b.benefit - a.benefit);

      return [...adds, ...others].filter(Boolean);
    } else {
      return "No Eligible Employees";
    }
  };

  const handleAddEmployee = (e) => {
    setSelectedEmployeeToAdd(e.target.value);
  };

  const temporaryAdd = () => {
    if (selectedEmployeeToAdd) {
      const employeeToAdd = allUsers.find(
        (user) => user.id === selectedEmployeeToAdd.id,
      );
      setEmployeesToAdd([...employeesToAdd, employeeToAdd]);
      setCurrentShiftEmployees([...currentShiftEmployees, employeeToAdd]);
      setSelectedEmployeeToAdd("");
      setErrorMessage("");
    } else {
      setErrorMessage("Must select employee to add");
    }
  };

  //ability to add note to the shift changes to go to notification
  const handleSubmit = () => {
    if (selectedEmployeeToAdd) {
      setErrorMessage("Click Add button to save selected employee to shift");
      return;
    } else {
      let optionsToExecute = [];

      if (employeesToDelete.length > 0) {
        let manualDrops = [];
        employeesToDelete.forEach((employee) => {
          removeShiftAssignment({
            variables: {
              employee: parseInt(employee.employeeId),
              shift: parseInt(shiftEvent.eventId),
            },
          });
        });
      }
      setTimeout(() => {
        if (employeesToAdd.length > 0) {
          let manualAdds = [];

          employeesToAdd.forEach((employee) => {
            //find if employee selected was an algorithm-generated option
            if (
              window.MANAGER_APPROVES_EMPLOYEE_REQUESTS === true ||
              window.MANAGER_APPROVES_EMPLOYEE_REQUESTS === "true"
            ) {
              const existingOption = addOptions.find(
                (option) => option.employeeId === employee.id,
              );
              if (existingOption) {
                optionsToExecute.push(existingOption.optionId);
              } else {
                manualAdds.push({
                  optionType: "ADD",
                  individuals: [
                    {
                      employee: parseInt(employee.id),
                      cost: 0,
                      benefit: 0,
                      actions: [
                        {
                          actionType: "ADD",
                          shift: parseInt(currentShiftId),
                        },
                      ],
                    },
                  ],
                  userGenerated: true,
                });
              }
            } else {
              createShiftAssignment({
                variables: {
                  employee: parseInt(employee.id),
                  shift: parseInt(shiftEvent.eventId),
                },
              });
            }
          });
          //create a reschedule option with action type ADD to add employees to any shift
          if (manualAdds.length > 0) {
            batchCreateOptions({
              variables: { options: manualAdds },
            });
          }
        }
      }, 500);

      setToast("Manager Edit Shift");
      setShowToast(true);
      closeDialog();
    }
  };

  const handleShiftChange = (e) => {
    const title = e.target.value;
    const selected = includedShifts.find((shift) => shift.title === title);
    setSelectedShift(selected);
    getRescheduleOptions({
      variables: {
        shifts: [parseInt(selected.shiftId)],
      },
    });
  };

  const eligibleToSave = Boolean(
    employeesToAdd.length > 0 || employeesToDelete.length > 0,
  );

  function groupBy(array, f) {
    var groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  }

  let allShifts = events
    .filter(
      (e) =>
        isEqual(e.start, shiftEvent.start) &&
        isEqual(e.end, shiftEvent.end) &&
        e.procedureId === shiftEvent.procedureId,
    )
    .map((x) => {
      let shifts = x.culminatedIds.map((evId) => {
        let skillReqsForShift =
          getShiftSkillRequirementData &&
          getShiftSkillRequirementData.shifts.length > 0 &&
          getShiftSkillRequirementData.shifts.find(
            (s) => parseInt(s.id) === parseInt(evId),
          );
        return {
          id: evId,
          event:
            x.eventId === evId
              ? x
              : events.find((ev) => ev.eventId === evId)
                ? events.find((ev) => ev.eventId === evId)
                : shiftEvent,
          start: format(x.start, "dd-MMM-yyyy") + " - " + x.formattedStart,
          end: format(x.end, "dd-MMM-yyyy") + " - " + x.formattedEnd,
          skillRequirements: skillReqsForShift
            ? skillReqsForShift.intervalSet[0].slackSet.map(
                (sl) => sl.skill.name,
              )
            : "",
        };
      });
      return shifts;
    })
    .flat()
    .filter(Boolean);

  const groupedRequirements = groupBy(allShifts, function (item) {
    return [item.id];
  });

  return (
    <>
      <Grid item container direction="column" spacing={2}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignContent="flex-start"
        >
          <Grid item container direction="column" spacing={1} xs={4}>
            <Grid item>
              <Typography variant="h3">
                Edit{" "}
                {format(shiftEvent.start, "HH:mm") +
                  " - " +
                  format(shiftEvent.end, "HH:mm")}
              </Typography>
              <Typography variant="h6">
                {format(new Date(shiftEvent.start), "dd MMM yyyy")}
              </Typography>
              <List>
                {groupedRequirements &&
                  groupedRequirements.length > 0 &&
                  groupedRequirements.map((e, index) => (
                    <ListItem
                      onClick={() => {
                        SetShiftRequirementSelected(index);
                        setShiftToEdit(e[0].event);
                        getEmpsForSelectedShift({
                          variables: { shiftId: parseInt(e[0].event.eventId) },
                        });
                      }}
                      selected={shiftRequirementSelected === index}
                      key={index}
                      LinkComponent={Button}
                    >
                      <Button>
                        {e[0].start + " - " + e[0].end + " "}
                        <br />
                        {"Skill Requirements: " + e[0].skillRequirements &&
                          e[0].skillRequirements.length > 0 &&
                          e[0].skillRequirements.map((x) => x)}
                      </Button>
                    </ListItem>
                  ))}
              </List>
            </Grid>
            <Grid item>
              {includedShifts && (
                <Box mt={1}>
                  <Typography variant="h6" style={{ marginBottom: 8 }}>
                    Shift Time:
                  </Typography>
                  <Select
                    variant="outlined"
                    name="selectedShift"
                    value={selectedShift.title}
                    onChange={handleShiftChange}
                    className={classes.select}
                  >
                    {includedShifts.map((shift, index) => (
                      <MenuItem key={index} value={shift.title}>
                        {shift.title}
                      </MenuItem>
                    ))}
                    {/* {shiftEvent.tasks.map((task, index) => (
                      <MenuItem key={index} value={task.id}>
                        {format(new Date(task.start), "HH:mm") +
                          "-" +
                          format(new Date(task.end), "HH:mm")}
                      </MenuItem>
                    ))} */}
                  </Select>
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            xs={6}
            //justifyContent="flex-end"
            // style={
            //   includedShifts || allShifts.length>0
            //     ? { marginBottom: -10, marginTop: -85 }
            //     : { marginBottom: -10, marginTop: -30 }
            // }
          >
            <RescheduleOptionsKey type="addDrop" />
          </Grid>

          <Grid item container justifyContent="flex-end" xs={1}>
            <Grid item>
              <IconButton color="secondary" onClick={closeDialog} size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="h6">Employees Scheduled:</Typography>
        </Grid>
        <Grid item container justifyContent="space-between" wrap="wrap">
          {renderParticipants()}
          {renderNewAdditions()}
        </Grid>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item>
            <InputLabel id="select-employee-1-label">
              <Typography variant="h6">Add Employee:</Typography>
            </InputLabel>
          </Grid>
          {getEmpsForSelectedShiftLoading ? (
            <CircularProgress />
          ) : (
            <Grid item xs={6}>
              {eligibleEmployees && eligibleEmployees.length > 0 ? (
                <Autocomplete
                  multiple={false}
                  name="employeeSearchBox"
                  value={
                    selectedEmployeeToAdd != "" ? selectedEmployeeToAdd : null
                  }
                  size="small"
                  disableClearable={true}
                  onChange={(event, newValue) => {
                    setSelectedEmployeeToAdd(newValue);
                  }}
                  options={
                    data && data.manualAddEmployees.length > 0
                      ? renderEmployeeOptions(data.manualAddEmployees)
                      : getEmpsForSelectedShiftData &&
                          getEmpsForSelectedShiftData.manualAddEmployees
                            .length > 0
                        ? renderEmployeeOptions(
                            getEmpsForSelectedShiftData.manualAddEmployees,
                          )
                        : []
                  }
                  placeholder="Search Employee"
                  getOptionSelected={(option, value) =>
                    option.name === value.lastName + " " + value.firstName
                  }
                  getOptionLabel={(option) =>
                    option.lastName + " " + option.firstName
                  }
                  renderOption={(p, option, { selected }) => (
                    <li {...p}>
                      {option.benefit <= -1 && (
                        <StarRateIcon className={classes.icon} />
                      )}
                      {option.benefit < 1 && option.benefit > -1 && (
                        <>
                          <StarRateIcon className={classes.icon} />
                          <StarRateIcon className={classes.icon} />
                        </>
                      )}
                      {option.benefit >= 1 && (
                        <>
                          <StarRateIcon className={classes.icon} />
                          <StarRateIcon className={classes.icon} />
                          <StarRateIcon className={classes.icon} />
                        </>
                      )}
                      {option.lastName + " " + option.firstName}
                      {option.skills &&
                        option.skills.length > 0 &&
                        option.skills.map((e) => e.name).join(",")}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Search Employee"
                    />
                  )}
                />
              ) : (
                // <Select
                //   labelId="select-employee-1-label"
                //   id="select-employee-1"
                //   variant="outlined"
                //   value={selectedEmployeeToAdd}
                //   onChange={handleAddEmployee}
                //   className={classes.select}
                // >

                //   {renderEmployeeOptions(eligibleEmployees)}
                // </Select>
                "No Eligible Employees"
              )}
            </Grid>
          )}
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={temporaryAdd}
              disabled={!selectedEmployeeToAdd}
            >
              Add
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              className={classes.button}
              onClick={() => setSelectedEmployeeToAdd("")}
              disabled={!selectedEmployeeToAdd}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="h6">Notes: {shiftEvent.notes}</Typography>
        </Grid>
        {errorMessage && (
          <Grid item>
            <Typography className={classes.error}>{errorMessage}</Typography>
          </Grid>
        )}
      </Grid>

      <Grid
        item
        container
        direction="column"
        alignItems="flex-end"
        justifyContent="flex-end"
        spacing={1}
        style={{ marginTop: 15 }}
      >
        {acceptsFloats ? (
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={toggleShowRequestFloats}
            >
              {showRequestFloats ? "Cancel" : "Request Float Staff"}
            </Button>
          </Grid>
        ) : (
          <Grid item></Grid>
        )}
        <Grid item>
          <Typography variant="body2" className={classes.helpfulTip}>
            Save to activate shift changes
          </Typography>
        </Grid>
        {showRequestFloats ? (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              //onClick={handleSendFloatRequest}
            >
              Send Request
            </Button>
          </Grid>
        ) : (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit}
              disabled={!eligibleToSave}
              data-testid="handleShiftBasedEditShiftSave"
            >
              Save
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MangEditShift;
