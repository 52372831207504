import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { CSVLink } from "react-csv";
import "../../AllStyles/Calendar.css";
import {
  Typography,
  Button,
  Grid,
  Paper,
  DialogContent,
  Dialog,
  Popover,
  Snackbar,
  CircularProgress,
  Badge,
  MenuItem,
  Box,
  Alert,
  TextField,
  Select,
  ButtonGroup,
  // IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TuneIcon from "@mui/icons-material/Tune";
import PrintIcon from "@mui/icons-material/Print";
import GetAppIcon from "@mui/icons-material/GetApp";
// import CloseIcon from "@mui/icons-material/Close";
import ShiftSwitchRequestForm from "../shiftSwitch/ShiftSwitchRequestForm";
import EmpCallInForm from "./EmpCallInForm";
// import ShiftEvent from "./ShiftEvent";
import SoftTimeOff from "./SoftTimeOff";
import EditSoftTimeOff from "./EditSoftTimeOff";
import TimeOffRequestForm from "./TimeOffRequestForm";
import EditTimeOffRequestForm from "./EditTimeOffRequestForm";
import ShiftSlackBySkill from "../slacks/ShiftSlackBySkill";
import Roles from "../../Roles/roles";
import MangEditShift from "./MangEditShift";
import ImportPastShifts from "./ImportPastShifts";
import {
  formatTimeOff,
  formatSoftRequests,
  orderShifts,
} from "../../helpers/formatMissionEvents";
import {
  format,
  add,
  sub,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
} from "date-fns";
import FilterMenu from "./FilterMenu";
import Scheduler from "./Scheduler";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import {
  filterListVar,
  userVar,
  selectedDateVar,
  locationConfigVar,
  selectedViewVar,
  eventsDatesVar,
} from "../../cache";
import {
  GET_EMPLOYEE_NAMES,
  AVAILABILITY_TYPES,
  GET_RELEVANT_SKILLS,
  INTERVAL_SLACKS,
  EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
  ALL_SQL_SHIFT_ASSIGNMENTS,
  GET_EMPLOYEES_OF_CHILD_OFFICES,
  GET_MISSIONS,
  SCHEDULE_QUALITY_ANALYTICS,
  GET_ALL_TIME_OFFS,
  GET_ALL_SOFT_REQUESTS,
  ALL_OFFICES_NAMES,
  OFFICE_BASED_PROCEDURE,
  GET_OFFICE_EMPLOYEES,
} from "../../api/gqlQueries";
import AddProcedure from "./AddProcedure";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Annotation,
  FormFields,
  Inject,
  Print,
  TextSelection,
  TextSearch,
} from "@syncfusion/ej2-react-pdfviewer";
import Logout from "../general/LogOutDialog";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useHistory } from "react-router-dom";
import { toIsoDate } from "../../helpers/formatTime";

const useStyles = makeStyles(() => ({
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  label: {
    fontSize: 12,
    marginTop: -7,
  },
  tabs: {
    minWidth: 100,
    width: 125,
  },
  downloadPopover: {
    textAlign: "left",
  },
}));

function EmpCalendar({
  schedulePeriods,
  refetchSchedulePeriods,
  setOpenSnackBar,
  setSnackBarMessage,
  notifyDevelopers,
  environment,
}) {
  const classes = useStyles();
  const history = useHistory();
  const user = userVar();
  const locationConfig = locationConfigVar();
  const selectedDate = useReactiveVar(selectedDateVar);
  const primaryUnit = user.office;
  const userRole = user.role;

  const managerAccess =
    userRole === Roles.MANAGER ||
    userRole === Roles.SCHEDULER ||
    userRole === Roles.LEAD ||
    userRole === Roles.ADMIN;

  const officeBasedProcedures = useQuery(OFFICE_BASED_PROCEDURE);

  useQuery(AVAILABILITY_TYPES, {
    onCompleted(data) {
      let sortedTypes = [];
      //const ordered = ["PTO", "UPTO", "Training", "Medical", "Admin", "OTHER"];
      const ordered = data.availabilityTypes.map((type) => type.name);
      ordered.forEach((name) => {
        let match = data.availabilityTypes.find((type) => type.name === name);
        if (match) {
          match = { name: match.name, id: match.id };
          sortedTypes.push(match);
          // if (match.name === "TRAINING" || match.name === "OTHER") {
          //   match.name =
          //     match.name.slice(0, 1) + match.name.slice(1).toLowerCase();
          //   sortedTypes.push(match);
          // } else {
          // }
        }
      });
      setSortedAvailabilityTypes(sortedTypes);
      const requestFilters = sortedTypes.map((type) => type.name);

      if (!managerAccess) {
        const all = [
          ...filterList.allFilters,
          ...requestFilters,
          // 'Soft Requests'
        ];
        const requests = [
          ...filterList.requestsFilters,
          ...requestFilters,
          // 'Soft Requests'
        ];
        filterListVar({
          ...filterList,
          allFilters: all,
          requestsFilters: requests,
        });
      }
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on AVAILABILITY_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useQuery(GET_RELEVANT_SKILLS, {
    variables: {
      office: parseInt(user.office.id),
    },
    onCompleted(data) {
      const jobTypes = data.requiredSkills.filter(
        (skill) => skill.variety === "JOB_TYPE",
      );
      setJobTypes(jobTypes);

      const jobTypeNames = jobTypes.map((skill) => skill.name);

      const all = ["All Missions", ...jobTypeNames, "All Job Type"];

      filterListVar({
        ...filterList,
        allFilters: all,
        jobTypeFilters: ["All Job Type", ...jobTypeNames],
      });
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const notViewable = schedulePeriods.find(
    (schedule) =>
      !schedule.status === "PUBLISHED" || !schedule.status === "MANAGER_DRAFT",
  );
  const releasedSchedules = schedulePeriods.filter(
    (schedule) =>
      schedule.status === "PUBLISHED" ||
      schedule.status === "MANAGER_DRAFT" ||
      schedule.status === "MANAGER DRAFT" ||
      schedule.status === "READY" ||
      schedule.status === "OPEN",
  );

  const allSchedules = [...schedulePeriods];

  const mostRecentReleased =
    releasedSchedules.length > 0
      ? releasedSchedules.sort(
          (a, b) => new Date(b.start) - new Date(a.start),
        )[0]
      : null;

  const mostRecentIncludingDraft =
    allSchedules.length > 0
      ? allSchedules.sort((a, b) => new Date(b.start) - new Date(a.start))[0]
      : null;

  let draftStart;
  let draftEnd;
  let openStart;
  let openEnd;
  if (mostRecentIncludingDraft) {
    if (
      mostRecentIncludingDraft.status === "MANAGER_DRAFT" ||
      mostRecentIncludingDraft.status === "MANAGER DRAFT" ||
      mostRecentIncludingDraft.status === "DRAFT"
    ) {
      draftStart = new Date(`${mostRecentIncludingDraft.start}T12:00:00`);
      draftEnd = sub(new Date(`${mostRecentIncludingDraft.end}T12:00:00`), {
        hours: 12,
      });
    } else if (notViewable && managerAccess) {
      draftStart = new Date(`${notViewable.start}T12:00:00`);
      draftEnd = sub(new Date(`${notViewable.end}T12:00:00`), { hours: 12 });
    }
  }

  //get all the schedules in open state
  const allSchedulesInOpenState =
    allSchedules.length > 0
      ? allSchedules.filter((e) => e.status === "OPEN" || e.status === "READY")
      : null;
  allSchedulesInOpenState &&
    allSchedulesInOpenState.sort(
      (a, b) => new Date(a.start) - new Date(b.start),
    );

  if (allSchedulesInOpenState && allSchedulesInOpenState.length > 0) {
    let managerManualScheduling = allSchedulesInOpenState.filter(
      (e) => e.status === "READY" || e.status === "OPEN",
    );
    managerManualScheduling.sort(
      (a, b) => new Date(a.start) - new Date(b.start),
    );
    if (managerAccess && managerManualScheduling.length > 0) {
      openStart = new Date(`${managerManualScheduling[0].start}T12:00:00`);
      openEnd = new Date(
        `${
          managerManualScheduling[parseInt(managerManualScheduling.length) - 1]
            .end
        }T12:00:00`,
      );
    }
  }

  allSchedules.sort((a, b) => new Date(b.start) - new Date(a.start));
  const scheduleEndDate = allSchedules.length > 0 ? allSchedules[0].end : null;
  function getVariables(startDate, endDate) {
    let variables =
      window.DISPLAY_ALL_OFFICES_EVENTS === "true" ||
      window.DISPLAY_ALL_OFFICES_EVENTS === true
        ? {
            rangeStart: startDate && startDate.toISOString(),
            rangeEnd: endDate && endDate.toISOString(),
          }
        : {
            office: parseInt(primaryUnit.id),
            rangeStart: startDate && startDate.toISOString(),
            rangeEnd: endDate && endDate.toISOString(),
          };
    return variables;
  }

  const today = new Date();
  let firstDayOfCurrentView = sub(startOfDay(today), { days: 1 });
  let lastDayOfCurrentView = add(endOfDay(firstDayOfCurrentView), { weeks: 1 });
  const variablesForInitialQuery = getVariables(
    firstDayOfCurrentView,
    lastDayOfCurrentView,
  );

  const { loading, error, data, refetch } = useQuery(
    window.DISPLAY_ALL_OFFICES_EVENTS === "true" ||
      window.DISPLAY_ALL_OFFICES_EVENTS === true
      ? ALL_SQL_SHIFT_ASSIGNMENTS
      : EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
    {
      variables: variablesForInitialQuery,
      onCompleted(d) {
        addDatestoTheListOfDates(firstDayOfCurrentView, lastDayOfCurrentView);
        let newScheduleData =
          window.DISPLAY_ALL_OFFICES_EVENTS === "true" ||
          window.DISPLAY_ALL_OFFICES_EVENTS === true
            ? orderShifts(d.sqlShiftAssignmentsAll)
            : orderShifts(d.sqlShiftAssignments2);
        newScheduleData.userShifts =
          window.DISPLAY_ALL_OFFICES_EVENTS === "true" ||
          window.DISPLAY_ALL_OFFICES_EVENTS === true
            ? [
                ...d.sqlShiftAssignmentsAll.filter((e) =>
                  e.shiftassignmentSet.find((x) => x.employeeId === user.id),
                ),
              ]
            : [
                ...d.sqlShiftAssignments2.filter((e) =>
                  e.shiftassignmentSet.find((x) => x.employeeId === user.id),
                ),
              ]; //get only logged in users shifts
        newScheduleData = { ...scheduleData, ...newScheduleData };
        setScheduleData(newScheduleData);
        const oneWeekAgo = sub(new Date(), { weeks: 1 });
        const oneWeekFromNow = add(new Date(), { weeks: 1 });
        const futureVariables = getVariables(
          sub(lastDayOfCurrentView, { days: 1 }),
          oneWeekFromNow,
        );
        const pastVariables = getVariables(
          oneWeekAgo,
          add(firstDayOfCurrentView, { days: 1 }),
        );
        getFutureShifts({
          variables: futureVariables,
        });
        futureTimeOffs({
          variables: {
            officeId: parseInt(user.office.id),
            startDate: format(lastDayOfCurrentView, "yyyy-MM-dd"),
            endDate: format(oneWeekFromNow, "yyyy-MM-dd"),
          },
        });
        getPastShifts({
          variables: pastVariables,
        });
        const currentAndFutureSchedules = schedulePeriods.filter(
          (schedule) => new Date(`${schedule.end}T23:00:00`) > new Date(),
        );

        currentAndFutureSchedules.sort(
          (a, b) =>
            new Date(`${a.start}T23:00:00`) - new Date(`${b.start}T23:00:00`),
        );

        if (currentAndFutureSchedules.length > 0) {
          const rangeStart = currentAndFutureSchedules[0].start + "T00:00:00";
          const rangeEnd =
            currentAndFutureSchedules[currentAndFutureSchedules.length - 1]
              .end + "T23:00:00";
          if (managerAccess) {
            getSlackIssues({
              variables: {
                rangeStart: rangeStart,
                rangeEnd: rangeEnd,
                office: parseInt(primaryUnit.id),
                issuesOnly: true,
              },
            });
            getSlack({
              variables: {
                rangeStart: rangeStart,
                rangeEnd: rangeEnd,
                office: parseInt(primaryUnit.id),
                issuesOnly: false,
              },
            });
          }
        }
      },
      onError(err) {
        console.log(err.message);
        // if (err.message.includes("Signature has expired")) {
        //   async function signout() {
        //     try {
        //       await signOut({ global: true });
        //     } catch (error) {
        //       console.log("error signing out: ", error);
        //     }
        //   };
        // }
      },
    },
  );

  const [getShiftsForAnyDateRange] = useLazyQuery(
    window.DISPLAY_ALL_OFFICES_EVENTS === "true" ||
      window.DISPLAY_ALL_OFFICES_EVENTS === true
      ? ALL_SQL_SHIFT_ASSIGNMENTS
      : EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
    {
      onCompleted(d) {
        const newScheduleData =
          window.DISPLAY_ALL_OFFICES_EVENTS === true ||
          window.DISPLAY_ALL_OFFICES_EVENTS === "true"
            ? addDataToCurrentScheduleData(d.sqlShiftAssignmentsAll)
            : addDataToCurrentScheduleData(d.sqlShiftAssignments2);
        setScheduleData(newScheduleData);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on shifts Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  const [allOffices, SetAllOffices] = useState();

  //All Office Names
  useQuery(ALL_OFFICES_NAMES, {
    variables: {
      receivesFloats:
        user.isPrimaryParentOffice === false && user.floatOffice === true
          ? true
          : false,
    },
    onCompleted(d) {
      SetAllOffices(d.offices);
    },
    onError(error) {
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  let officeResources =
    user.floatOffice === true
      ? allOffices &&
        allOffices.map((office) => {
          return {
            id: office.name,
            name: office.name,
          };
        })
      : allOffices &&
        allOffices.map((office) => {
          return {
            id: office.name,
            name: office.name,
          };
        });

  const [getFutureShifts, { data: futureData }] = useLazyQuery(
    window.DISPLAY_ALL_OFFICES_EVENTS === "true" ||
      window.DISPLAY_ALL_OFFICES_EVENTS === true
      ? ALL_SQL_SHIFT_ASSIGNMENTS
      : EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
    {
      onCompleted(data) {
        addDatestoTheListOfDates(
          lastDayOfCurrentView,
          add(lastDayOfCurrentView, { weeks: 1 }),
        );
        let rangeStart = startOfMonth(new Date(selectedDate));
        rangeStart = startOfWeek(new Date(rangeStart));
        let rangeEnd = endOfMonth(new Date(selectedDate));
        rangeEnd = endOfWeek(new Date(rangeEnd));

        getSlackIssues({
          variables: {
            rangeStart: rangeStart,
            rangeEnd: rangeEnd,
            office: parseInt(primaryUnit.id),
            issuesOnly: true,
          },
        });
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_FUTURE_SHIFTS lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  useEffect(() => {
    if (futureData) {
      const newScheduleData =
        window.DISPLAY_ALL_OFFICES_EVENTS === "true" ||
        window.DISPLAY_ALL_OFFICES_EVENTS === true
          ? addDataToCurrentScheduleData(futureData.sqlShiftAssignmentsAll)
          : addDataToCurrentScheduleData(futureData.sqlShiftAssignments2);
      setScheduleData(newScheduleData);
    }
  }, [futureData]);

  // const missionVariables = getVariables(oneYearAgo, sixMonthsFromNow);
  //const missionEnd = format(sixMonthsFromNow, "yyyy-MM-dd");

  // const { data: missionData, refetch: refetchMissions } = useQuery(
  //   GET_MISSIONS,
  //   {
  //     variables: {
  //       office: parseInt(primaryUnit.id),
  //       end: missionEnd,
  //     },
  //     onError(error) {
  //       console.log(error);
  //       setOpenSnackBar(true);
  //       setSnackBarMessage(
  //         "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
  //       );
  //       notifyDevelopers({
  //         variables: {
  //           message:
  //             "Error on GET_MISSIONS Query. Environment: " +
  //             environment +
  //             ". Graphql " +
  //             error,
  //         },
  //       });
  //     },
  //   }
  // );

  const { data: employeeData, loading: employeeDataLoading } = useQuery(
    GET_OFFICE_EMPLOYEES,
    {
      variables: {
        office: parseInt(primaryUnit.id),
        start: toIsoDate(new Date()),
        // window.DISPLAY_ALL_OFFICES_EVENTS === false ||
        // window.DISPLAY_ALL_OFFICES_EVENTS === "false"
        //   ? parseInt(primaryUnit.id)
        //   : null,
      },
      onCompleted(d) {
        addEmployeeData(d.officeEmployees);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_EMPLOYEE_NAMES Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  const { data: childOfficeEmployeeData } = useQuery(
    GET_EMPLOYEES_OF_CHILD_OFFICES,
    {
      variables: { parent: parseInt(primaryUnit.id) },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_ALL_CHILD_OFFICES Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  const [getPastShifts, { data: pastData }] = useLazyQuery(
    window.DISPLAY_ALL_OFFICES_EVENTS === "true" ||
      window.DISPLAY_ALL_OFFICES_EVENTS === true
      ? ALL_SQL_SHIFT_ASSIGNMENTS
      : EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
    {
      onCompleted() {
        addDatestoTheListOfDates(
          sub(firstDayOfCurrentView, { weeks: 1 }),
          firstDayOfCurrentView,
        );
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_PAST_SHIFTS lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  useEffect(() => {
    if (pastData) {
      const newScheduleData =
        window.DISPLAY_ALL_OFFICES_EVENTS === "true" ||
        window.DISPLAY_ALL_OFFICES_EVENTS === true
          ? addDataToCurrentScheduleData(pastData.sqlShiftAssignmentsAll)
          : addDataToCurrentScheduleData(pastData.sqlShiftAssignments2);
      setScheduleData(newScheduleData);
    }
  }, [pastData]);

  const ref = React.useRef();
  const params = useParams();

  const filterList = useReactiveVar(filterListVar);
  const allFilters = filterList.allFilters;

  const [scheduleData, setScheduleData] = useState({
    formatted: [],
    userShifts: [],
    shiftNames: [],
    missionNames: [],
  });
  const [sortedAvailabilityTypes, setSortedAvailabilityTypes] = useState([]);
  const [allSlackDates, setAllSlackDates] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [allSlacks, setAllSlacks] = useState([]);
  const [slackIssues, setSlackIssues] = useState([]);
  const [issueDates, setIssueDates] = useState([]);
  const [issueDateswithProcedureName, setIssueDateswithProcedureName] =
    useState([]);
  const [showShiftSlackBySkill, setShowShiftSlackBySkill] = useState(false);
  const [view, setView] = useState("TimelineDay");
  const [shiftSwitch, setShiftSwitch] = useState(false);
  const [callIn, setCallIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [shiftSwitchApproval, setShiftSwitchApproval] = useState(false);
  const [timeOffApproval, setTimeOffApproval] = useState(false);
  const [editShift, setEditShift] = useState(false);
  const [shiftToEdit, setShiftToEdit] = useState();
  const [shiftToChange, setShiftToChange] = useState();
  const [softRequest, setSoftRequest] = useState(false);
  const [softRequestIdToEdit, setSoftRequestIdToEdit] = useState();
  const [editSoftRequest, setEditSoftRequest] = useState(false);
  const [timeOffRequestIdToEdit, setTimeOffRequestIdToEdit] = useState();
  const [timeOffRequestForm, setTimeOffRequestForm] = useState(false);
  const [importPastEventsForm, setImportPastEventsForm] = useState(false);
  const [editTimeOffRequest, setEditTimeOffRequest] = useState(false);
  const [procedureReqForm, setprocedureReqForm] = useState(false);
  const [procedureRequirementEvent, setProcedureRequirementEvent] = useState();
  const [openDownload, setopenDownload] = useState(null);
  const [toast, setToast] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [errorToast, setErrorToast] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const openD = Boolean(openDownload);
  const anchorId = openD ? "download-popover" : undefined;
  const [openAddProcedure, setOpenAddProcedure] = useState(null);
  const openAddP = Boolean(openAddProcedure);
  const anchorIdForAddProcedure = openAddP ? "addProcedure-popover" : undefined;
  const [employeeResources, setEmployeeResources] = useState([]);
  const [allFitnesses, SetallFitnesses] = useState([]);
  const [empFitness, SetempFitness] = useState("");
  const [filterText, SetFilterText] = useState("");
  const [buttongroupOpen, setButtonGroupOpen] = useState(false);

  useQuery(SCHEDULE_QUALITY_ANALYTICS, {
    variables: {
      employee: parseInt(user.id),
    },
    onCompleted(data) {
      let fitnessFotCurrentDate = data.scheduleQualityAnalytics.find(
        (e) =>
          e.scheduleStart <= format(new Date(selectedDate), "yyyy-MM-dd") &&
          e.scheduleEnd >= format(new Date(selectedDate), "yyyy-MM-dd"),
      );
      fitnessFotCurrentDate && SetempFitness(fitnessFotCurrentDate.score);
      SetallFitnesses(data.scheduleQualityAnalytics);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on SCHEDULE_QUALITY_ANALYTICS lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [allDateTimeOffs, SetAllDateTimeOffs] = useState([]);
  const [allDateSoftRequests, SetAllDateSoftRequests] = useState([]);
  const [itemsPerPageVal, SetItemsPerPageVal] = useState("10");

  useQuery(GET_ALL_TIME_OFFS, {
    variables: {
      officeId: parseInt(user.office.id),
      startDate: format(firstDayOfCurrentView, "yyyy-MM-dd"),
      endDate: format(lastDayOfCurrentView, "yyyy-MM-dd"),
    },
    onCompleted(data) {
      let timeOffs = data.availability.filter(
        (timeOff) =>
          timeOff.deniedText === null &&
          (!timeOff.userCancelled || timeOff.userCancelled === false),
      );
      const newTimeOffs = formatTimeOff(timeOffs, user.id);
      const allTimeOffEvents = [...allDateTimeOffs, ...newTimeOffs];
      SetAllDateTimeOffs(allTimeOffEvents);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_ALL_TIME_OFFS lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [futureTimeOffs] = useLazyQuery(GET_ALL_TIME_OFFS, {
    onCompleted(d) {
      let timeOffs = d.availability.filter(
        (timeOff) =>
          timeOff.deniedText === null &&
          (!timeOff.userCancelled || timeOff.userCancelled === false),
      );
      const newTimeOffs = formatTimeOff(timeOffs, user.id);
      const allTimeOffEvents = [...allDateTimeOffs, ...newTimeOffs];
      SetAllDateTimeOffs(allTimeOffEvents);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_ALL_TIME_OFFS lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useQuery(GET_ALL_SOFT_REQUESTS, {
    variables: {
      employeeId: parseInt(user.id),
    },
    onCompleted(data) {
      //logged in users soft requests
      const newSoftRequests = formatSoftRequests(data.offPreferences, user.id);
      const userSoftRequests = [...allDateSoftRequests, ...newSoftRequests];
      SetAllDateSoftRequests(userSoftRequests);
    },
  });

  useEffect(() => {
    let fitnessFotCurrentDate = allFitnesses.find(
      (e) =>
        new Date(e.scheduleStart) <= new Date(selectedDate) &&
        new Date(e.scheduleEnd) >= new Date(selectedDate),
    );
    fitnessFotCurrentDate && SetempFitness(fitnessFotCurrentDate.score);
  }, [selectedDate]);

  const [getSlackIssues, { data: slackIssuesData }] = useLazyQuery(
    INTERVAL_SLACKS,
    {
      onCompleted(d) {
        setSlackIssues(d.intervalSlacks);
        let datesWithSlackInfo = [];
        let isseDates = [];
        let issueDatesprocedurename = [];
        d.intervalSlacks.forEach((slack) => {
          const start = format(new Date(slack.interval.start), "MM/dd/yyyy");
          !isseDates.includes(start) && isseDates.push(start);
          !datesWithSlackInfo.includes(start) && datesWithSlackInfo.push(start);
          !issueDatesprocedurename.find(
            (e) =>
              slack.interval.shifts[0].procedure != null &&
              e.procedureName ===
                slack.interval.shifts[0].procedure.procedureRequirement.name,
          ) &&
            issueDatesprocedurename.push({
              id: slack.id,
              issueDate: start,
              procedureName:
                slack.interval.shifts[0].procedure &&
                slack.interval.shifts[0].procedure.procedureRequirement.name,
            });
        });
        setIssueDates(isseDates);
        setIssueDateswithProcedureName(issueDatesprocedurename);
        setAllSlackDates(datesWithSlackInfo);
        setAllSlacks([...d.intervalSlacks]);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_SLACK lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  const [getSlack, { loading: slacksLoading }] = useLazyQuery(INTERVAL_SLACKS, {
    onCompleted(d) {
      let datesWithSlackInfo = [];
      d.intervalSlacks.forEach((slack) => {
        const start = format(new Date(slack.interval.start), "MM/dd/yyyy");
        !datesWithSlackInfo.includes(start) && datesWithSlackInfo.push(start);
      });
      setAllSlackDates(datesWithSlackInfo);
      setAllSlacks(d.intervalSlacks);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_SLACK lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  // useEffect(() => {
  //   if (
  //     employeeData &&
  //     employeeData.data &&
  //     employeeData.data.officeEmployees
  //   ) {
  //     let data = employeeData.data.officeEmployees;
  //     addEmployeeData(data);
  //   }
  // }, [employeeData]);

  // useEffect(() => {
  //   if (childOfficeEmployeeData) {
  //     let data = childOfficeEmployeeData.getChildren
  //       .map((e) => e.employeeSet)
  //       .flat();
  //     addEmployeeData(data);
  //   }
  // }, [childOfficeEmployeeData]);

  const combineGhocShifts = (events) => {
    const nonGhoc = events.filter((event) => event.eventTitle !== "GHOC");
    const ghoc = events.filter((event) => event.eventTitle === "GHOC");

    const noDuplicates = [];
    ghoc.forEach((event) => {
      const exists = noDuplicates.find(
        (newEvent) => event.eventId === newEvent.eventId,
      );
      if (exists) {
        const newIds = event.employeeIds.filter(
          (id) => !exists.employeeIds.includes(id),
        );
        exists.employeeIds = [...exists.employeeIds, ...newIds];
      } else {
        noDuplicates.push(event);
      }
    });

    return [...nonGhoc, ...noDuplicates];
  };

  const handleChangeView = (currentView) => {
    setView(currentView);
  };

  const addDataToCurrentScheduleData = (data) => {
    let currentScheduleData = { ...scheduleData };
    let userShifts = currentScheduleData.formatted
      .filter(
        (event) =>
          (event.participants &&
            event.participants.length > 0 &&
            event.participants.find((e) => e.employeeId === user.id)) ||
          (event.participant && event.participant.employeeId === user.id),
      )
      .map((event) => event);
    let shiftIds = currentScheduleData.formatted.map((event) => event.eventId);
    let userShiftIds = userShifts && userShifts.map((shift) => shift.eventId);
    let newEvents;

    newEvents = data.filter((shift) => !shiftIds.includes(shift.id));
    const newIds = newEvents.map((event) => event.id);

    shiftIds = [...shiftIds, ...newIds];

    const newData = orderShifts(newEvents);
    currentScheduleData.formatted = [
      ...currentScheduleData.formatted,
      ...newData.formatted,
    ];
    currentScheduleData.formatted.sort((a, b) => a.start - b.start);
    // currentScheduleData.formatted = combineGhocShifts(
    //   currentScheduleData.formatted
    // );
    currentScheduleData.shiftNames.sort();
    const newShiftNames = newData.shiftNames.filter(
      (shift) => !currentScheduleData.shiftNames.includes(shift),
    );

    currentScheduleData.shiftNames = [
      ...currentScheduleData.shiftNames,
      ...newShiftNames,
    ];
    currentScheduleData.shiftNames.sort();

    const newMissionNames = newData.missionNames.filter(
      (shift) => !currentScheduleData.missionNames.includes(shift),
    );
    currentScheduleData.missionNames = [
      ...currentScheduleData.missionNames,
      ...newMissionNames,
    ];
    currentScheduleData.missionNames.sort();
    const newUserShifts =
      data &&
      data.filter(
        (shift) =>
          !userShiftIds.includes(shift.id) &&
          shift.shiftassignmentSet.find((emp) => emp.employeeId === user.id),
      );
    const newUserShiftIds =
      newUserShifts && newUserShifts.map((shift) => shift.id);

    userShifts = [...userShifts, ...newUserShifts];
    userShiftIds = [...userShiftIds, ...newUserShiftIds];

    currentScheduleData.userShifts = userShifts;
    return currentScheduleData;
  };

  function groupBy(array, f) {
    var groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  }

  const addEmployeeData = (data) => {
    const resources = data
      .map((employee) => {
        return {
          id: employee.id,
          name: `${employee.lastName + ", "} ${employee.firstName}`,
          color: "#F5E1DB",
          skills: employee.skills.map((e) => e.name),
          office: user.office.name,
        };
      })
      .filter(Boolean);
    // const newResources = [...employeeResources, ...resources];
    // const newGroupedResources = groupBy(newResources, function (item) {
    //   return [item.name];
    // });
    // let reducedGroupedResources = newGroupedResources.map((e) => e[0]);
    // reducedGroupedResources
    //   .map((e) => e[0])
    //   .sort(function (a, b) {
    //     if (a.name < b.name) {
    //       return -1;
    //     }
    //     if (a.name > b.name) {
    //       return 1;
    //     }
    //     return 0;
    //   });
    setEmployeeResources(resources);
  };

  const generatePDF = () => {
    let printModel = {
      cssClass: "e-print-schedule",
      dateFormat: "dd-MMM-yyyy",
      readonly: true,
      rowAutoHeight: true,
      height: "auto",
      width: "auto",
      showWeekends: true,
      showHeader: true,
      timeScale: { enable: true },
    };
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.print(printModel);
  };

  useEffect(() => {
    const availabilityTypes = sortedAvailabilityTypes.map((type) => type.name);

    if (managerAccess && params.request) {
      // if (params.request === "time-off-calendar") {
      //   setTimeOffApproval(false);
      //   setShiftSwitchApproval(false);
      //   const pendingRequests = data
      //     ? data.timeOff.filter((request) => request.approvedby === null)
      //     : [];
      //   const earliestRequest =
      //     pendingRequests.length > 0
      //       ? pendingRequests.sort(
      //           (a, b) => new Date(a.firstday) - new Date(b.firstday)
      //         )[0]
      //       : null;
      //   const earliestDate = earliestRequest
      //     ? new Date(`${earliestRequest.firstday}T08:00:00`)
      //     : new Date();
      //   selectedDateVar(earliestDate);
      //   setView("Month");
      //   filterListVar({
      //     // allFilters: ["All Shifts", "All Pending"],
      //     allFilters: ["All Shifts", ...availabilityTypes],
      //     shiftsFilters: ["All Shifts"],
      //     missionFilters: [],
      //     requestsFilters: [...availabilityTypes],
      //     // requestsFilters: ["All Pending"],
      //   });
      // } else if (params.request === "time-off") {
      //   showTimeOffApproval();
      // } else if (params.request === "shift-switch") {
      //   showShiftSwitchApproval();
      // }
    } else if (params.date) {
      const showDate = new Date(params.date);
      selectedDateVar(showDate);
      refetchSchedulePeriods();
      if (managerAccess) {
        filterListVar({
          allFilters: ["All Shifts", "All Missions", "All Job Type"],
          shiftsFilters: ["All Shifts"],
          missionFilters: ["All Missions"],
          requestsFilters: [],
          jobTypeFilters: ["All Job Type"],
        });
      } else {
        filterListVar({
          allFilters: [
            "Personal",
            "All Missions",
            "Soft Requests",
            "Pending",
            "Approved",
            ...availabilityTypes,
            "All Requests",
            "All Job Type",
          ],
          shiftsFilters: ["Personal"],
          missionFilters: ["All Missions"],
          requestsFilters: [
            "Soft Requests",
            "Pending",
            "Approved",
            ...availabilityTypes,
            "All Requests",
          ],
          jobTypeFilters: ["All Job Type"],
        });
      }
      // getFutureShifts({
      //   variables: {
      //     office: parseInt(primaryUnit.id),
      //     rangeStart: params.date,
      //   },
      // });
    }
  }, []);

  const toggleShiftSwitchForm = () => setShiftSwitch(!shiftSwitch);
  const toggleCallInForm = () => setCallIn(!callIn);
  const toggleEditShift = () => setEditShift(!editShift);
  const toggleSoftRequest = () => setSoftRequest(!softRequest);
  const toggleTimeOffForm = () => setTimeOffRequestForm(!timeOffRequestForm);
  const togglePastEvents = () => setImportPastEventsForm(!importPastEventsForm);
  const toggleEditTimeOffForm = () =>
    setEditTimeOffRequest(!editTimeOffRequest);
  const toggleEditSoftRequestForm = () => setEditSoftRequest(!editSoftRequest);
  const toggleProcedureRequirementForm = () => {
    setprocedureReqForm(!procedureReqForm);
  };

  // const showShiftSwitchApproval = () => {
  //   // setShowDayActions(false);
  //   setShiftSwitchApproval(true);
  //   setTimeOffApproval(false);
  // };

  // const showTimeOffApproval = () => {
  //   // setShowDayActions(false);
  //   setTimeOffApproval(true);
  //   setShiftSwitchApproval(false);
  // };

  const handleEditClick = (shift) => {
    setShiftToEdit(shift);
    toggleEditShift();
  };

  const handleEditProcedureReq = (event) => {
    setProcedureRequirementEvent(event);
    toggleProcedureRequirementForm();
  };

  // const handleCallInClick = (date) => {
  //   selectedDateVar(date);
  //   toggleCallInForm();
  // };

  const toastMessage = () => {
    const toasts = {
      "Employee Shift Switch": "Shift switch request sent!",
      "Manager Shift Switch": "Shift switch successful! Employees notified.",
      "New Time Off Request": "Time off request sent for approval!",
      "Edit Time Off Request":
        "Time off request updated and sent for approval!",
      "Delete Time Off Request": "Time off request deleted!",
      "New Soft Request": "Soft request entered successfully!",
      "Edit Soft Request": "Soft request updated successfully!",
      "Delete Soft Request": "Soft request deleted!",
      "Call In": "Call in successful!",
      "Manager Edit Shift": "Shift updated and employees notified!",
      "Manager Approve Time Off Request": "Time off request approved!",
      "Manager Deny Time Off Request": "Time off request denied!",
      "Manager Approve Shift Switch": "Shift switch request approved!",
      "Manager Deny Shift Switch": "Shift switch request denied!",
      "Delete Procedure": "Procedure successfully deleted!",
    };
    return toasts[toast] ? toasts[toast] : "Success!";
  };

  const errorToastMessage = () => {
    const errorToasts = {
      "Non Eligible Shift Switch": "No eligible shifts to switch.",
      "Error Edit Shift": "Unable to edit shift. Please try again.",
      "Error Call In": "Unable to call in. Please try again.",
      "Error Approve Time Off":
        "Unable to approve time off request. Please try again.",
      "Error Deny Time Off":
        "Unable to deny time off request. Please try again.",
      "Error Approve Shift Switch":
        "Unable to approve shift switch request. Please try again.",
      "Error Deny Shift Switch":
        "Unable to deny shift switch request. Please try again.",
      "Error Delete Soft Request":
        "Unable to delete soft request. Please try again.",
      "Error Delete Time Off":
        "Unable to delete time off request. Please try again.",
    };
    return errorToasts[errorToast]
      ? errorToasts[errorToast]
      : "Error. Please try again.";
  };

  const officeProcedures =
    officeBasedProcedures.data &&
    officeBasedProcedures.data.offices.map((e) => e).flat();
  const events = [...scheduleData.formatted];
  const userEvents = events.filter(
    (event) => event.employeeIds && event.employeeIds.includes(user.id),
  );
  const shiftNames = scheduleData.shiftNames
    ? scheduleData.shiftNames.filter(Boolean)
    : [];

  let missionResources =
    scheduleData &&
    scheduleData.missionNames &&
    scheduleData.missionNames.length > 0
      ? scheduleData.missionNames.map((name) => {
          return {
            id: name,
            name: name,
          };
        })
      : [];

  const userTimeOff =
    allDateTimeOffs.length > 0 &&
    allDateTimeOffs.filter((event) => event.category === "personal");

  const userSoftRequests = formatSoftRequests(allDateSoftRequests, user.id);

  let invalidDates = [];
  userSoftRequests.forEach((request) => {
    invalidDates.push(request.start);
  });

  userTimeOff.length > 0 &&
    userTimeOff.forEach((request) => {
      const arrayOfTimeOff = eachDayOfInterval({
        start: request.start,
        end: request.end,
      });
      invalidDates = [...invalidDates, ...arrayOfTimeOff];
    });

  invalidDates = invalidDates.map((date) => format(date, "MM/dd/yyyy"));

  const maxGreyout = user.office.maxGreyoutRequests;
  const allowCallIns = user.office.allowCallIns;
  const schedulePeriodWeeks = user.office.scheduleDuration
    ? user.office.scheduleDuration
    : 4;

  const toggleEditRequest = (type, id) => {
    if (type === "softRequest") {
      const matchingRequest = userSoftRequests.find(
        (request) => parseInt(request.eventId) === parseInt(id),
      );
      if (matchingRequest) {
        setSoftRequestIdToEdit(matchingRequest.eventId);
        setEditSoftRequest(true);
      }
    } else {
      const matchingRequest = managerAccess
        ? allDateTimeOffs.find(
            (request) => parseInt(request.eventId) === parseInt(id),
          )
        : userTimeOff.find(
            (request) => parseInt(request.eventId) === parseInt(id),
          );
      if (matchingRequest) {
        setTimeOffRequestIdToEdit(matchingRequest.eventId);
        setEditTimeOffRequest(true);
      }
    }
  };

  function eventsToView() {
    let eventsToView = [];
    let requests = [];
    console.log(allFilters);
    if (allFilters) {
      if (allFilters.includes("All Shifts")) {
        eventsToView = [...events];
        if (allFilters.includes("All Requests")) {
          if (userSoftRequests.length > 0 && allDateTimeOffs.length > 0) {
            requests = [...userSoftRequests, ...allDateTimeOffs];
          } else if (userSoftRequests.length > 0) {
            requests = [...userSoftRequests];
          } else if (allDateTimeOffs && allDateTimeOffs.length > 0) {
            requests = [...allDateTimeOffs];
          } else {
            requests = [];
          }
        } else {
          if (allFilters.includes("Soft Requests")) {
            requests.push(userSoftRequests);
          }
          sortedAvailabilityTypes.forEach((type) => {
            if (filterList.requestsFilters.includes(type.name)) {
              let matchingTimeOffs =
                allDateTimeOffs &&
                allDateTimeOffs.length > 0 &&
                allDateTimeOffs.filter((timeOff) => timeOff.typeId === type.id);
              if (
                allFilters.includes("Pending") &&
                !allFilters.includes("Approved")
              ) {
                matchingTimeOffs = matchingTimeOffs.filter(
                  (timeOff) => timeOff.status === "pending",
                );
              } else if (
                !allFilters.includes("Pending") &&
                allFilters.includes("Approved")
              ) {
                matchingTimeOffs =
                  matchingTimeOffs.length > 0 &&
                  matchingTimeOffs.filter(
                    (timeOff) => timeOff.status === "approved",
                  );
              }
              requests.push(matchingTimeOffs);
            }
          });
          requests = requests.flat().filter(Boolean);
        }
      } else if (allFilters.includes("Personal")) {
        eventsToView = [...userEvents];

        if (allFilters.includes("All Requests")) {
          if (
            userSoftRequests &&
            userSoftRequests.length > 0 &&
            userTimeOff &&
            userTimeOff.length > 0
          ) {
            requests = [...userSoftRequests, ...userTimeOff];
          } else if (
            !userTimeOff &&
            userSoftRequests &&
            userSoftRequests.length > 0
          ) {
            requests = [...userSoftRequests];
          } else if (
            !userSoftRequests &&
            userTimeOff &&
            userTimeOff.length > 0
          ) {
            requests = [...userTimeOff];
          } else {
            requests = [];
          }
        } else {
          if (allFilters.includes("Soft Requests")) {
            requests.push(userSoftRequests);
          }

          sortedAvailabilityTypes.forEach((type) => {
            if (filterList.requestsFilters.includes(type.name)) {
              let matchingTimeOffs =
                userTimeOff.length > 0 &&
                userTimeOff.filter((timeOff) => timeOff.typeId === type.id);

              if (
                allFilters.includes("Pending") &&
                !allFilters.includes("Approved")
              ) {
                matchingTimeOffs = matchingTimeOffs.filter(
                  (timeOff) => !timeOff.approvedby,
                );
              } else if (
                !allFilters.includes("Pending") &&
                allFilters.includes("Approved")
              ) {
                matchingTimeOffs = matchingTimeOffs.filter(
                  (timeOff) => timeOff.approvedby,
                );
              }

              requests.push(matchingTimeOffs);
            }
          });

          requests = requests.flat();
        }
      } else if (allFilters.includes("Not Scheduled")) {
        eventsToView = events.filter(
          (event) => event.participants.length === 0,
        );
      }

      if (!allFilters.includes("All Missions")) {
        const filteredList = [];
        const missionNames = missionResources.map((mission) => mission.name);

        missionNames.forEach((name) => {
          if (allFilters.includes(name)) {
            const filtered = eventsToView.filter(
              (event) => event.missionId === name,
            );
            filteredList.push(filtered);
          }
        });
        eventsToView = filteredList.flat();
      }

      if (!allFilters.includes("All Job Type")) {
        const filteredList = [];
        const jobTypeNames = filterList.jobTypeFilters
          ? filterList.jobTypeFilters
          : [];

        eventsToView.forEach((event) => {
          let participantsWithJobType = [];
          if (event.participants) {
            participantsWithJobType = event.participants.filter((participant) =>
              participant.skillSet?.find((skill) =>
                jobTypeNames.includes(skill.name),
              ),
            );
          } else if (event.participant) {
            const hasSkill = event.participant.skills?.find((skill) =>
              jobTypeNames.includes(skill.name),
            );
            hasSkill && participantsWithJobType.push(event.participant);
          }

          if (participantsWithJobType.length > 0) {
            filteredList.push({
              ...event,
              participants: participantsWithJobType,
            });
          }
        });

        eventsToView = filteredList;
      }

      if (filterText.length > 0) {
        let keyworkFiltered = eventsToView.filter(
          (event) =>
            event.eventTitle &&
            event.eventTitle.toLowerCase().includes(filterText.toLowerCase()),
        );
        eventsToView = keyworkFiltered;
      }
    }
    let filteredText = eventsToView.filter((event) =>
      event.calendars.includes(view),
    );
    return [...events, ...requests];
  }

  const ExportToICS = () => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.exportToICalendar();
  };

  const ExportToExcel = () => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.exportToExcel();
  };

  function addDatestoTheListOfDates(firstDate, lastDate) {
    let dateRangeFound = eventsDatesVar().find(
      (e) =>
        format(new Date(e.rangeStart), "yyyy-MM-dd") ===
          format(new Date(firstDate), "yyyy-MM-dd") &&
        format(new Date(e.rangeEnd), "yyyy-MM-dd") ===
          format(new Date(lastDate), "yyyy-MM-dd"),
    );
    (!dateRangeFound || dateRangeFound === "undefined") &&
      eventsDatesVar().push({
        rangeStart: format(new Date(firstDate), "yyyy-MM-dd"),
        rangeEnd: format(new Date(lastDate), "yyyy-MM-dd"),
      });
  }

  // const ExportToExcel = () => {
  //   const scheduleObj =
  //     document.querySelector(".e-schedule").ej2_instances[0];
  //   let exportValues = {
  //     dataSource: events,
  //   };
  //   let a =
  //     allSchedules.length > 0 &&
  //     allSchedules.find(
  //       (x) =>
  //         new Date(x.start) <= new Date() && new Date(x.end) >= new Date()
  //     );
  //   scheduleObj.exportToExcel(events);
  // };

  // const hideFilterBadge = Boolean(
  //   allFilters.includes("All Shifts") &&
  //     allFilters.includes("All Missions") &&
  //     allFilters.includes("All Requests"),
  // );

  let backend = "";
  if (environment === "development") {
    backend = "https://backendtest.balancescheduler.com";
  } else {
    backend = "https://backenddemo.balancescheduler.com";
  }

  const filtertextchange = (e) => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    let empresourceData = employeeResources.filter(
      (emp) =>
        emp.name.toLowerCase().includes(e.toLowerCase()) ||
        emp.office.toLowerCase().includes(e.toLowerCase()),
    );
    scheduleObj.resources[1].dataSource = empresourceData;
    scheduleObj.dataBind();
  };

  const options = ["Add Procedure", "Add Procedures with Templates"];
  const handleButtonGroupToggle = () => {
    setButtonGroupOpen(!buttongroupOpen);
  };
  if (employeeDataLoading) {
    return <CircularProgress />;
  } else {
    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          className={classes.headerSpacing}
        >
          <Grid item xs={4}>
            <Typography variant="h3">Schedule</Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" spacing={2} xs={8}>
            <Grid item>
              {/* <Select
                id="itemsPerPage"
                name="itemsPerPage"
                value={itemsPerPageVal}
                onChange={(event) => SetItemsPerPageVal(event.target.value)}
              >
                <MenuItem key={5} value="5">5</MenuItem>
                <MenuItem key={10} value="10">10</MenuItem>
                <MenuItem key={15} value="15">15</MenuItem>
                <MenuItem key={20} value="20">20</MenuItem>
              </Select> */}
            </Grid>
            <Grid item>
              <TextField
                className="e-input"
                type="text"
                placeholder="Enter the Search text"
                value={filterText}
                data-testid="filterSearchText"
                onChange={(e) => {
                  SetFilterText(e.target.value);
                  filtertextchange(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={toggleTimeOffForm}
                style={{ width: 170 }}
                data-testid="addCalendarEvent"
              >
                Add Calendar Event
              </Button>
            </Grid>
            {managerAccess && locationConfig.shiftBasedDisplay === false && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  area-aria-describedby={anchorIdForAddProcedure}
                  onClick={(event) => setOpenAddProcedure(event.currentTarget)}
                  // onClick={toggleProcedureRequirementForm}
                  style={{ width: 170 }}
                  data-testid="addProcedureRequirements"
                >
                  Add Procedure
                </Button>
                <Popover
                  id={anchorIdForAddProcedure}
                  anchorEl={openAddProcedure}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  open={openAddP}
                  onClose={() => setOpenAddProcedure(null)}
                  style={{ overflow: "auto" }}
                >
                  <MenuItem
                    color="secondary"
                    target="_blank"
                    onClick={() => toggleProcedureRequirementForm()}
                    className={classes.downloadPopover}
                    data-testid="exportToPDF"
                  >
                    Add Procedure
                  </MenuItem>
                  <MenuItem
                    color="secondary"
                    onClick={() => history.push("/ProcedureTemplate")}
                    className={classes.downloadPopover}
                    data-testid="exportToCalendar"
                  >
                    Add Procedures from Templates
                  </MenuItem>
                </Popover>
              </Grid>
            )}
          </Grid>
        </Grid>
        <div className="col-lg-3 property-section">
          <Grid container direction="row">
            <Grid item xs={3}>
              {view === "Day" && managerAccess && (
                <Button
                  variant="outlined"
                  color="primary"
                  aria-describedby={anchorId}
                  onClick={() => setShowShiftSlackBySkill(true)}
                  style={{ width: 178, marginBottom: 8 }}
                  data-testid="staffingRequirements"
                  disabled={
                    !allSlackDates.includes(
                      format(selectedDateVar(), "MM/dd/yyyy"),
                    )
                  }
                >
                  {slacksLoading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    "View Staffing"
                  )}
                </Button>
              )}
            </Grid>
            <Grid item xs={2}>
              {loading ? (
                <>
                  <Typography variant="body2">
                    <CircularProgress color="primary" />
                  </Typography>{" "}
                </>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={3}>
              <Box
                style={{ textAlign: "center" }}
                data-testid="scheduleQualityForSchedulePeriod"
              >
                {empFitness === ""
                  ? ""
                  : "Your schedule quality for this schedule period: " +
                    empFitness * 100 +
                    "%"}
              </Box>
            </Grid>
            <Grid item container justifyContent="flex-end" xs={4}>
              <Grid item>
                <Button
                  color="secondary"
                  aria-describedby={anchorId}
                  onClick={(event) => setopenDownload(event.currentTarget)}
                  data-testid="DownloadEvents"
                >
                  <GetAppIcon style={{ marginRight: 5 }} /> Download
                </Button>
                <Popover
                  id={anchorId}
                  anchorEl={openDownload}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  open={openD}
                  onClose={() => setopenDownload(null)}
                  style={{ overflow: "auto" }}
                >
                  <MenuItem
                    color="secondary"
                    target="_blank"
                    filename="schedule.csv"
                    onClick={ExportToExcel}
                    className={classes.downloadPopover}
                    data-testid="exportToexcel"
                  >
                    Export to Excel
                  </MenuItem>
                  <MenuItem
                    color="secondary"
                    target="_blank"
                    onClick={generatePDF}
                    className={classes.downloadPopover}
                    data-testid="exportToPDF"
                  >
                    Export to PDF
                  </MenuItem>
                  <MenuItem
                    color="secondary"
                    target="_blank"
                    // href={`${backend}/scheduler/employee_calendar/${parseInt(
                    //   user.id
                    // )}`}
                    onClick={ExportToICS}
                    className={classes.downloadPopover}
                    data-testid="exportToCalendar"
                  >
                    Export to Calendar
                  </MenuItem>
                </Popover>
              </Grid>
              <Grid item>
                <Button
                  id="print"
                  color="secondary"
                  onClick={generatePDF}
                  data-testid="printIcon"
                >
                  <PrintIcon style={{ marginRight: 5 }} /> Print
                </Button>
              </Grid>
              <Grid item>
                {" "}
                <Button
                  color="secondary"
                  style={{ padding: 4, fontSize: 12 }}
                  onClick={() => window.location.reload()}
                  data-testid="refetchEvents"
                >
                  <RefreshIcon /> Refetch Events
                </Button>
              </Grid>
              {/* <Grid item>
              <Button
                color="secondary"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                data-testid="FilterMenuIcon"
              >
                <Badge
                  variant="dot"
                  color="primary"
                  style={{ marginRight: 5 }}
                  //invisible={hideFilterBadge}
                >
                  <TuneIcon />
                </Badge>
                Filter
              </Button>
              <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                style={{ overflow: "auto" }}
              >
                <Paper style={{ padding: 10 }}>
                  <FilterMenu
                    data-testid="FilterMenu"
                    missionNames={
                      missionResources &&
                      missionResources.map((mission) => mission.name)
                    }
                    availabilityTypes={sortedAvailabilityTypes}
                    jobTypes={jobTypes}
                  />
                </Paper>
              </Popover>
            </Grid> */}
            </Grid>
          </Grid>
        </div>
        <div id="Scheduler">
          <Scheduler
            events={eventsToView()}
            getFutureShifts={getFutureShifts}
            draftStart={draftStart}
            draftEnd={draftEnd}
            openStart={openStart}
            openEnd={openEnd}
            employeeResources={employeeResources}
            missionResources={missionResources}
            officeResources={officeResources}
            view={view}
            setView={setView}
            handleChangeView={handleChangeView}
            toggleEditRequest={toggleEditRequest}
            toggleCallInForm={toggleCallInForm}
            toggleShiftSwitchForm={toggleShiftSwitchForm}
            issueDates={issueDates}
            slackIssues={slackIssues}
            handleEditClick={handleEditClick}
            handleEditProcedureReq={handleEditProcedureReq}
            setShiftToChange={setShiftToChange}
            allowCallIns={allowCallIns}
            ref={ref}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setSnackBarMessage}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
            filterText={filterText}
            itemsPerPageVal={itemsPerPageVal}
            officeProcedures={officeProcedures}
            getShiftsForAnyDateRange={getShiftsForAnyDateRange}
            addDatestoTheListOfDates={addDatestoTheListOfDates}
          />
        </div>
        <Logout />

        <Dialog open={shiftSwitch} fullWidth maxWidth="sm">
          <DialogContent
            style={{
              padding: 30,
              overflowX: "hidden",
              overflowY: "auto",
              height: 675,
              position: "relative",
            }}
            data-testid="missionShiftSwitchForm"
          >
            <ShiftSwitchRequestForm
              allEvents={events}
              userEvents={userEvents}
              closeDialog={toggleShiftSwitchForm}
              shiftNames={shiftNames}
              scheduleEndDate={scheduleEndDate}
              setToast={setToast}
              setShowToast={setShowToast}
              setErrorToast={setErrorToast}
              setShowErrorToast={setShowErrorToast}
              shiftToChange={shiftToChange}
              view={view}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={callIn} fullWidth maxWidth="xs">
          <DialogContent
            style={{
              padding: 30,
              overflowX: "hidden",
              height: 460,
              position: "relative",
            }}
            data-testid="missionCallInForm"
          >
            <EmpCallInForm
              closeDialog={toggleCallInForm}
              userEvents={userEvents}
              setToast={setToast}
              setShowToast={setShowToast}
              view={view}
              shiftToChange={shiftToChange}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={editShift} fullWidth maxWidth="md">
          <DialogContent data-testid="missionEditShiftForm">
            <Grid
              container
              component={DialogContent}
              direction="column"
              wrap="nowrap"
              justifyContent="space-between"
              style={{
                padding: 30,
                overflowX: "hidden",
                overflowY: "auto",
                height: 675,
              }}
            >
              {shiftToEdit ? (
                <MangEditShift
                  shiftEvent={shiftToEdit}
                  setShiftToEdit={setShiftToEdit}
                  closeDialog={toggleEditShift}
                  setToast={setToast}
                  setShowToast={setShowToast}
                  setErrorToast={setErrorToast}
                  setShowErrorToast={setShowErrorToast}
                  refetch={refetch}
                  getVariables={getVariables}
                  getFutureShifts={getFutureShifts}
                  events={eventsToView()}
                  setOpenSnackBar={setOpenSnackBar}
                  setSnackBarMessage={setSnackBarMessage}
                  notifyDevelopers={notifyDevelopers}
                  environment={environment}
                  ref={ref}
                  setScheduleData={setScheduleData}
                  scheduleData={scheduleData}
                  addDataToCurrentScheduleData={addDataToCurrentScheduleData}
                />
              ) : null}
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog open={softRequest} fullWidth maxWidth="xs">
          <DialogContent
            style={{ padding: 30 }}
            data-testid="missionSoftRequestForm"
          >
            <SoftTimeOff
              closeDialog={toggleSoftRequest}
              scheduleEndDate={scheduleEndDate}
              invalidDates={invalidDates}
              setToast={setToast}
              setShowToast={setShowToast}
              maxGreyout={maxGreyout}
              schedulePeriodWeeks={schedulePeriodWeeks}
              softRequests={userSoftRequests}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={editSoftRequest} fullWidth maxWidth="xs">
          <DialogContent
            style={{ padding: 30 }}
            data-testid="missionEditSoftRequestForm"
          >
            {softRequestIdToEdit && (
              <EditSoftTimeOff
                closeDialog={toggleEditSoftRequestForm}
                scheduleEndDate={scheduleEndDate}
                invalidDates={invalidDates}
                setToast={setToast}
                setShowToast={setShowToast}
                maxGreyout={maxGreyout}
                schedulePeriodWeeks={schedulePeriodWeeks}
                softRequests={userSoftRequests}
                softRequestIdToEdit={softRequestIdToEdit}
                setSoftRequestIdToEdit={setSoftRequestIdToEdit}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            )}
          </DialogContent>
        </Dialog>
        <Dialog open={timeOffRequestForm} fullWidth maxWidth="md">
          <DialogContent
            style={{ padding: 30, height: 650, position: "relative" }}
            data-testid="missionTimeOffRequestForm"
          >
            <TimeOffRequestForm
              closeDialog={toggleTimeOffForm}
              invalidDates={invalidDates}
              setToast={setToast}
              setShowToast={setShowToast}
              employees={employeeResources}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
              allDateTimeOffs={allDateTimeOffs}
              SetAllDateTimeOffs={SetAllDateTimeOffs}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={editTimeOffRequest} fullWidth maxWidth="sm">
          <DialogContent
            style={{ padding: 30, height: 650, position: "relative" }}
            data-testid="missioneditTimeOffRequestForm"
          >
            {timeOffRequestIdToEdit && (
              <EditTimeOffRequestForm
                closeDialog={toggleEditTimeOffForm}
                userSoft={userSoftRequests}
                setToast={setToast}
                setShowToast={setShowToast}
                userTimeOff={userTimeOff}
                timeOffRequestIdToEdit={timeOffRequestIdToEdit}
                setTimeOffRequestIdToEdit={setTimeOffRequestIdToEdit}
                refetch={refetch}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            )}
          </DialogContent>
        </Dialog>
        <Dialog open={procedureReqForm} fullWidth maxWidth="lg">
          <DialogContent
            style={{
              padding: 30,
              overflowX: "hidden",
              overflowY: "auto",
              height: 750,
              position: "relative",
            }}
            data-testid="missionprocedureReqForm"
          >
            <AddProcedure
              closeDialog={toggleProcedureRequirementForm}
              event={procedureRequirementEvent}
              setProcedureRequirementEvent={setProcedureRequirementEvent}
              refetch={refetch}
              getShiftsForAnyDateRange={getShiftsForAnyDateRange}
              setToast={setToast}
              setShowToast={setShowToast}
              setErrorToast={setErrorToast}
              setShowErrorToast={setShowErrorToast}
              scheduleEndDate={scheduleEndDate}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
              getVariables={getVariables}
              events={eventsToView()}
              setScheduleData={setScheduleData}
              getSlackIssues={getSlackIssues}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={showShiftSlackBySkill} maxWidth={false}>
          <DialogContent
            style={{ padding: 30, overflowX: "hidden", overflowY: "auto" }}
            data-testid="missionShowShiftSlackBySkill"
          >
            <ShiftSlackBySkill
              slacks={allSlacks}
              closeDialog={() => setShowShiftSlackBySkill(false)}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={importPastEventsForm} maxWidth={false}>
          <DialogContent
            style={{ padding: 30, overflowX: "hidden", overflowY: "auto" }}
            data-testid="missionimportPastEventsForm"
          >
            <ImportPastShifts
              closeDialog={() => setImportPastEventsForm(false)}
            />
          </DialogContent>
        </Dialog>
        <Snackbar
          open={showToast}
          autoHideDuration={3000}
          onClose={() => setShowToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            elevation={6}
            onClose={() => setShowToast(false)}
            severity="success"
          >
            <Typography>{toastMessage()}</Typography>
          </Alert>
        </Snackbar>
        <Snackbar
          open={showErrorToast}
          autoHideDuration={3000}
          onClose={() => setShowErrorToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            elevation={6}
            onClose={() => setShowErrorToast(false)}
            severity="error"
          >
            <Typography>{errorToastMessage()}</Typography>
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default EmpCalendar;
