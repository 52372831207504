/*
id	The id field needs to be defined as mandatory and this field usually assigns a unique ID value to each of the events.
subject	The subject field is optional, and usually assigns the summary text to each of the events.
startTime	The startTime field defines the start time of an event and it is mandatory to provide it for any of the valid event objects.
endTime	The endTime field defines the end time of an event and it is mandatory to provide the end time for any of the valid event objects.
startTimezone	It maps the startTimezone field from the dataSource and usually accepts the valid IANA timezone names. It is assumed that the value provided for this field is taken into consideration while processing the startTime field. When this field is not mapped with any timezone names, then the events will be processed based on the timezone assigned to the Scheduler.
endTimezone	It maps the endTimezone field from the dataSource and usually accepts the valid IANA timezone names. It is assumed that the value provided for this field is taken into consideration while processing the endTime field. When this field is not mapped with any timezone names, then the events will be processed based on the timezone assigned to the Scheduler.
location	It maps the location field from the dataSource and the location text value will be displayed over the events.
description	It maps the description field from the dataSource and denotes the event description which is optional.
isAllDay	The isAllDay field is mapped from the dataSource and is used to denote whether an event is created for an entire day or for specific time alone. Usually, an event with isAllDay field set to true will be considered as an all-day event.
recurrenceID	It maps the recurrenceID field from dataSource and usually holds the ID value of the parent recurrence event. This field is applicable only for the edited occurrence events.
recurrenceRule	It maps the recurrenceRule field from dataSource and holds the recurrence rule value in a string format. Also, it uniquely identifies whether the event belongs to a recurring type or normal ones.
recurrenceException	It maps the recurrenceException field from dataSource and is used to hold the collection of exception dates, on which the recurring occurrences needs to be excluded. The recurrenceException should be specified in UTC format.
isReadonly	It maps the isReadonly field from dataSource. It is mainly used to make specific appointments as readonly when set to true.
isBlock	It maps the isBlock field from dataSource. It is used to block the particular time ranges in the Scheduler and prevents the event creation on those time slots.
*/
import {
  EMPLOYEE_TIME_OFF,
  GET_EMPLOYEE_ON_CALL,
  GET_ALL_SOFT_REQUESTS,
  INTERVAL_SLACKS,
} from "../../api/gqlQueries";
import {
  getScheduleDataTransformer,
  getTimeOffTransformer,
  getOnCallTransformer,
  getSoftRequestTransformer,
  getSlackTransformer,
} from "./Transformer";
import { toIsoDate } from "../../helpers/formatTime";
import { format } from "date-fns";

async function fetchScheduleData(client, office, first, last, query, ref) {
  // fetches, transforms, and add data to the current schedule obj
  try {
    const data = await client.query({
      query: query,
      variables: {
        office: office,
        pageStart: first.toISOString(),
        pageEnd: last.toISOString(),
      },
    });
    const events = getScheduleDataTransformer(
      ref.currentView,
      ref.viewIndex,
    )(data.data.sqlShiftAssignments2);
    return events;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function fetchTimeOffData(client, office, first, last, ref) {
  /**
  Based on given inputs, retrieves the Employee Availability objects in an array
  from the backend given those inputs as filters
  inputs:
      client:
        type: apolloClient
        content: global variable apolloClient
      office:
        type: int
        content: office PK representing the user's office
      first:
        type: Date
        content: datetime object representing the start of the query
      last:
        type: Date
        content: datetime object representing the end of the query
      ref:
        content: reference to the Calendar object
  return:
    outputArray: array of shiftAssignments with the below assignments for each input record
  */
  try {
    const data = await client.query({
      query: EMPLOYEE_TIME_OFF,
      variables: {
        officeId: office,
        start: first.toISOString(),
        end: last.toISOString(),
        status: ["APPROVED", "REQUESTED"],
      },
    });
    const events = getTimeOffTransformer(
      ref.currentView,
      ref.viewIndex,
    )(data.data.employeeTimeOff);
    return events;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function fetchOnCallData(client, office, first, last, ref) {
  try {
    const data = await client.query({
      query: GET_EMPLOYEE_ON_CALL,
      variables: { officeId: office, pageStart: first, pageEnd: last },
    });
    const events = getOnCallTransformer(
      ref.currentView,
      ref.viewIndex,
    )(data.data.employeeOnCall);
    return events;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function fetchSoftRequestData(client, employee, first, last, ref) {
  try {
    const data = await client.query({
      query: GET_ALL_SOFT_REQUESTS,
      variables: {
        employeeId: employee,
        pageStartDate: toIsoDate(first),
        pageEndDate: toIsoDate(last),
      },
    });
    const events = getSoftRequestTransformer(ref.currentView, ref.viewIndex)(
      data.data.offPreferences,
      employee,
    );
    return events;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function fetchSlackData(client, office, first, last, ref) {
  try {
    // TODO [BSD-443]: pass in env vars rather than relying on window access
    if (window.TENANT_NAME === "evarahealth") {
      return [];
    }
    const data = await client.query({
      query: INTERVAL_SLACKS,
      variables: {
        pageStart: first,
        pageEnd: last,
        office: office,
        issuesOnly: true,
      },
    });

    const formattedSlack = getSlackTransformer(
      ref.currentView,
      ref.viewIndex,
    )(data.data.intervalSlacks);
    return formattedSlack;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const useData = (client, shiftQuery, paginator, office, employee) => {
  // const [getScheduleData, scheduleDataResult] = useLazyQuery(shiftQuery)
  // const [getCallData, callDataResult] = useLazyQuery(GET_EMPLOYEE_ON_CALL)
  // const [getSoftRequestData, softRequestDataResult] = useLazyQuery(GET_ALL_SOFT_REQUESTS)
  const getData = async (ref) => {
    // ref.deleteEvent(ref.getEvents());
    const dates = ref.getCurrentViewDates(); // TODO: returns Date[]
    const end = dates.length > 1 ? dates[dates.length - 1] : dates[0];
    const queryRanges = paginator.getQueryDateRanges(dates[0], end);

    const schedulePromises = queryRanges.map(([first, last]) => {
      return fetchScheduleData(client, office, first, last, shiftQuery, ref);
    });
    const timeOffPromises = queryRanges.map(([first, last]) => {
      return fetchTimeOffData(client, office, first, last, ref);
    });
    const callPromises = queryRanges.map(([first, last]) => {
      return fetchOnCallData(client, office, first, last, ref);
    });
    const offPromises = queryRanges.map(([first, last]) => {
      return fetchSoftRequestData(client, employee, first, last, ref);
    });
    const slackPromises = queryRanges.map(([first, last]) => {
      return fetchSlackData(client, office, first, last, ref);
    });
    // return Promise.all([...schedulePromises]) // promise that rejects if any one promise rejects
    const results = {
      scheduleData: await Promise.allSettled([
        ...schedulePromises,
        ...callPromises,
        ...offPromises,
      ]),
      timeOffData: await Promise.allSettled([...timeOffPromises]),
      slackData: await Promise.allSettled([...slackPromises]),
    };
    return results;
  };
  return getData;
};
