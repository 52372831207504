import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@mui/material";
import { useQuery } from "@apollo/client";
import ShiftDistribution from "../reportTables/ShiftDistribution";
import ShiftTypeDistribution from "../reportTables/ShiftTypeDistribution";
import EmployeeStint from "../reportTables/EmployeeStint";
import DailyHours from "../reportTables/DailyHours";
import { GET_EMPLOYEE_ANALYTICS } from "../../api/gqlQueries";
import SchedulePeriodPicker from "./SchedulePeriodPicker";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}
export const extractHeader = (str) => {
  if (str == "employee.firstName") {
    return "First Name";
  } else if (str == "employee.lastName") {
    return "Last Name";
  }
  const parts = str.split(".");
  let header = parts[1];

  return header.charAt(0).toUpperCase() + header.slice(1);
};

export const transformSummaryData = (data) => {
  //an array of objects is passed in
  let dataArr = [];
  for (let obj of data) {
    let transformedObj = {};
    let parsedData = JSON.parse(obj.data);

    //get employee info
    let empDetails = {
      firstName: obj.employee.firstName,
      lastName: obj.employee.lastName,
    };

    //create stint key with object value
    let stintDetails = {};
    parsedData.employeeStints?.forEach((stint) => {
      if (stint.type == "on") {
        const key = `${stint.days}-day-${stint.type}`;
        stintDetails[key] = {
          type: stint.type,
          value: stint.value,
          employee: stint.employee,
        };
      }
    });

    //create daily hours object
    let dailyHoursDetails = {};
    parsedData.employeeDailyHours?.forEach((hours) => {
      const key = `${Math.floor(hours.hours)}-hours`;
      dailyHoursDetails[key] = {
        value: hours.value,
        employee: hours.employee,
      };
    });

    //create shift distribution key with object value
    const daysOfWeek = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    let shiftDetails = {};
    let shiftTypeDetails = {};
    parsedData.employeeShiftDistribution?.forEach((shift) => {
      const key = daysOfWeek[shift.weekday];
      if (shiftDetails[key]) {
        shiftDetails[key].value += shift.value;
      } else {
        shiftDetails[key] = {
          value: shift.value,
          employee: shift.employee,
        };
      }
      if (shiftTypeDetails[shift.shiftType]) {
        shiftTypeDetails[shift.shiftType].value += shift.value;
      } else {
        shiftTypeDetails[shift.shiftType] = {
          value: shift.value,
          employee: shift.employee,
        };
      }
    });

    transformedObj = {
      employee: empDetails,
      stints: stintDetails,
      dailyHours: dailyHoursDetails,
      shiftDistribution: shiftDetails,
      shiftTypeDistribution: shiftTypeDetails,
    };

    dataArr.push(transformedObj);
  }
  return dataArr;
};

const ScheduleSummaryReport = ({ selectedOffice, schedulePeriod }) => {
  const [transformedData, setTransformedData] = useState(null);

  const { data, loading, error } = useQuery(GET_EMPLOYEE_ANALYTICS, {
    variables: {
      period: schedulePeriod,
    },
    onCompleted(data) {
      setTransformedData(transformSummaryData(data.employeeAnalyticsData));
    },
    onError(e) {
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again. " +
          error.message,
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_EMPLOYEE_ANALYTICS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
      console.log("ERROR", e);
    },
  });

  if (loading || !transformedData) {
    return <CircularProgress color="primary" />;
  } else {
    let shiftData = transformedData?.map(({ employee, shiftDistribution }) => ({
      employee,
      shiftDistribution,
    }));
    let shiftTypeData = transformedData?.map(
      ({ employee, shiftTypeDistribution }) => ({
        employee,
        shiftTypeDistribution,
      }),
    );
    let stintData = transformedData?.map(({ employee, stints }) => ({
      employee,
      stints,
    }));
    let hoursData = transformedData?.map(({ employee, dailyHours }) => ({
      employee,
      dailyHours,
    }));

    return (
      <Grid container>
        <ShiftDistribution tableData={shiftData} />
        <ShiftTypeDistribution tableData={shiftTypeData} />
        <EmployeeStint tableData={stintData} />
        <DailyHours tableData={hoursData} />
      </Grid>
    );
  }
};

export default ScheduleSummaryReport;
