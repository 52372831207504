import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Typography } from "@mui/material";

import SchedulePeriodPicker from "./SchedulePeriodPicker";
import ScheduleSummaryReport from "./ScheduleSummaryReport";

const ScheduleSummaryPage = ({ selectedOffice }) => {
  const [schedulePeriod, setSchedulerPeriod] = useState(null);

  if (schedulePeriod) {
    return (
      <Grid>
        <SchedulePeriodPicker
          selectedOffice={selectedOffice}
          setSchedulePeriod={setSchedulerPeriod}
          schedulePeriod={schedulePeriod}
        />
        <ScheduleSummaryReport
          selectedOffice={selectedOffice}
          schedulePeriod={schedulePeriod}
        />
      </Grid>
    );
  } else {
    return (
      <Grid>
        <SchedulePeriodPicker
          selectedOffice={selectedOffice}
          setSchedulePeriod={setSchedulerPeriod}
        />
      </Grid>
    );
  }
};

export default ScheduleSummaryPage;
