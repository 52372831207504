import React from "react";
import { Grid, Typography } from "@mui/material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckIcon from "@mui/icons-material/Check";
import { format } from "date-fns";

const WeekLabel = ({ data, skills, procedureColor }) => {
  if (data.participants) {
    return (
      <Grid
        container
        direction="column"
        style={{ backgroundColor: procedureColor }}
      >
        <Grid item>
          <Typography variant="body1">{data.eventTitle}</Typography>
        </Grid>
      </Grid>
    );
  } else if (data.type === "softRequest") {
    return (
      <>
        {data.highPriority ? (
          <Grid container wrap="nowrap">
            <Grid item>
              <PriorityHighIcon style={{ marginBottom: -2, marginRight: 5 }} />
            </Grid>
            <Grid item>
              <Typography variant="body1">{data.eventTitle}</Typography>
              <Typography variant="body2">{skills}</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container wrap="nowrap">
            <Grid item>
              <Typography variant="body1">{data.eventTitle}</Typography>
              <Typography variant="body2">{skills}</Typography>
            </Grid>
          </Grid>
        )}
      </>
    );
  } else if (data.status === "approved") {
    return (
      <Grid container wrap="nowrap">
        <Grid item>
          <CheckIcon style={{ marginBottom: -2, marginRight: 5 }} />
        </Grid>
        <Grid item>
          <Typography variant="body1">{data.eventTitle}</Typography>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container wrap="nowrap" style={{ backgroundColor: procedureColor }}>
        <Grid item>
          <Typography variant="body1">{data.eventTitle}</Typography>
        </Grid>
      </Grid>
    );
  }
};

export default WeekLabel;
