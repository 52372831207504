import React, { useState } from "react";
import { userVar } from "../../cache";
import ManagerDashboard from "./ManagerDashboard";
import EmployeeDashboard from "./EmployeeDashboard";
import { CircularProgress } from "@mui/material";

const Dashboard = ({ selectedOffice }) => {
  const user = userVar();
  if (selectedOffice) {
    if (user.isEmployee) {
      return <EmployeeDashboard selectedOffice={selectedOffice} />;
    } else {
      return <ManagerDashboard selectedOffice={selectedOffice} />;
    }
  } else {
    return <CircularProgress></CircularProgress>;
  }
};

export default Dashboard;
