import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { isLoggedInVar } from "../../cache";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SAMLLogin = () => {
  const query = useQuery();
  const history = useHistory();
  const token = query.get("token");

  useEffect(() => {
    if (token) {
      sessionStorage.setItem("jwt", token);
      isLoggedInVar(true);
      history.push("/");
    } else {
      isLoggedInVar(false);
      history.push("/");
    }
  }, [token]);

  return (
    <CircularProgress
      color="primary"
      style={{ marginTop: 50, marginBottom: 50 }}
    />
  );
};

export default SAMLLogin;
