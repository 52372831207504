import React from "react";
import { Link } from "@mui/material";

function Copyright() {
  return (
    <div style={{ fontSize: "10px" }} align="center">
      <Link
        style={{ color: "#f2f2f2" }}
        href="https://www.dualitysys.com"
        target="blank"
      >
        {"Copyright © "} Duality Systems {new Date().getFullYear()}
      </Link>
    </div>
  );
}

export default Copyright;
