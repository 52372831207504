import React, { useEffect, useState } from "react";
import AuthenticatedApp from "./AuthenticatedApp";
import UnauthenticatedApp from "./UnauthenticatedApp";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { isLoggedInVar, appsettingsVar } from "../../cache";
import { useQuery, useReactiveVar } from "@apollo/client";
import { PUBLICLOCATION } from "../../api/gqlQueries";
import defaultTheme from "../../themes/default-theme.json";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import { ErrorBoundary } from "react-error-boundary";

function App() {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const publicLocationSettings = useQuery(PUBLICLOCATION, {
    onError(error) {
      console.log(error);
    },
  });
  const appvar = useReactiveVar(appsettingsVar);
  appvar.color =
    !publicLocationSettings.loading &&
    publicLocationSettings.data != null &&
    publicLocationSettings.data.locationPublicSettings[0].hexcolor;
  const [clr, setclr] = useState(appvar.color);

  const myTheme = {
    typography: {
      ...defaultTheme.typography,
      h2: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "1.5rem",
        color: "#333333",
      },
      h3: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "1.2rem",
        color: "#333333",
      },
      h4: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "1rem",
        color: "#333333",
      },
      h5: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "0.9rem",
        color: "#333333",
      },
      h6: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "0.85rem",
        color: "#333333",
      },
      body: {
        fontStyle: "normal",
        fontSize: "18px",
        color: "#333333",
      },
      body1: {
        fontStyle: "normal",
        fontSize: "15px",
        color: "#333333",
      },
      body2: {
        fontSize: "12px",
      },
      subtitle1: {
        fontSize: "15px",
        fontWeight: "500",
      },
      subtitle2: {
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    components: {
      ...defaultTheme.overrides,
      MuiAppBar: {
        root: {
          boxShadow: "none",
          zIndex: "theme.zIndex.drawer + 1",
        },
      },
      MuiToolBar: {
        paddingRight: "24",
        zIndex: "1",
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "12px",
            lineHeight: "20px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            boxSizing: "border-box",
            fontSize: "12px",
          },
          MuiOutlinedInput: {
            root: {
              padding: 5,
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            root: {
              fontSize: "13px",
            },
            outlined: {
              borderRadius: "6px",
            },
          },
        },
      },
      MuiDialog: {
        paperWidthSm: {
          maxWidth: "640px",
        },
      },
      MuiSvgIcon: {
        fontSizeSmall: {
          fontSize: "0.9rem",
        },
      },
      MuiTab: {
        root: {
          maxWidth: "300px",
        },
      },
      MuiAccordion: {
        root: {
          margin: "8px 0",
        },
      },
      MuiStepLabel: {
        label: {
          fontStyle: "normal",
          color: "#808080",
          fontSize: "15px",
          lineHeight: "18px",
        },
        active: {
          fontStyle: "normal",
          fontWeight: "bold",
          color: "#000000",
          fontSize: "20px",
          lineHeight: "23px",
        },
      },
    },
    palette: {
      ...defaultTheme.palette,
      primary: { main: clr ? clr : defaultTheme.palette.primary.main },
    },
  };

  const theme = createTheme(myTheme);

  useEffect(() => {
    setclr(appvar.color);
  }, [appvar.color]);

  return (
    <BrowserRouter>
      <Route>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ErrorBoundary
              fallbackRender={(props) => <UnauthenticatedApp errored={true} />}
            >
              <Switch>
                {isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
              </Switch>
            </ErrorBoundary>
          </ThemeProvider>
        </StyledEngineProvider>
      </Route>
    </BrowserRouter>
  );
}

export default App;
