import {
  getScheduleDataTransformer,
  getTimeOffTransformer,
  getSoftRequestTransformer,
} from "./Transformer";
import {
  getLocalStorageItem,
  getSessionStorageItem,
} from "../../hooks/storage/useStorage";
import { applyOfficeFilters } from "./filter/FilterEvents";

export function createSplitShiftEvent(ref, data, selectedOfficeName) {
  /**
   * Updates the Syncfusion scheduler object after a split shift.
   *
   * Updates the Syncfusion scheduler object after a shift is split which means
   * transforming the new data into events for the appropriate view while using the
   * parent shift's shiftResource attribute before adding them to the scheduler object.
   *
   * @param {ScheduleComponent} ref     the Syncfusion scheduler object
   * @param {Object[]} data             array of EfficientShiftNodes returned from the db
   * @param {String} selectedOfficeName the current office context to apply filters
   */
  const events = getScheduleDataTransformer(
    ref.currentView,
    ref.viewIndex,
  )(data);
  events.forEach((e) => {
    ref.allScheduleDataObject[e.id] = e;
  });
  const displayedEvents = applyOfficeFilters(selectedOfficeName, events);
  ref.addEvent(displayedEvents);
}

export function updateScheduleEvent(ref, data, oldEvent, selectedOfficeName) {
  /**
   * Updates the Syncfusion scheduler object after a schedule change.
   *
   * Updates the Syncfusion schedule object after a schedule change which means deleting
   * all current events and transforming the new data into events ready to be added to
   * the scheduler object.
   *
   * @param {ScheduleComponent} ref the Syncfusion scheduler object
   * @param {Object} data EfficientShiftNode returned from mutation
   * @param {Object} oldEvent the current event data for the shift/shift assignment being edited.
   * @param {String} selectedOfficeName the current office context to apply filters
   */

  ref.deleteEvent(`SHIFT:${oldEvent.shiftId}`);
  delete ref.allScheduleDataObject[`SHIFT:${oldEvent.shiftId}`];
  oldEvent.participants.forEach((sa) => {
    ref.deleteEvent(`SHIFTASSIGNMENT:${sa.id}`);
    delete ref.allScheduleDataObject[`SHIFTASSIGNMENT:${sa.id}`];
  });
  const events = getScheduleDataTransformer(
    ref.currentView,
    ref.viewIndex,
  )([data]);
  events.forEach((event) => {
    ref.allScheduleDataObject[event.id] = event;
  });

  const displayedEvents = applyOfficeFilters(selectedOfficeName, events);
  ref.addEvent(displayedEvents);
}

export function updateRecurrenceScheduleEvent(ref, data, selectedOfficeName) {
  /**
   * Updates the Syncfusion scheduler object after a schedule change with recurrence.
   *
   * Updates the Syncfusion schedule object after a schedule change which means deleting
   * all current events that are referenced within the return data and transforming the
   * new data into events ready to be added to the scheduler object.
   *
   * @param {ScheduleComponent} ref the Syncfusion scheduler object
   * @param {Array[Object]} data Array of EfficientShiftNodes returned from mutation
   * @param {String} selectedOfficeName the current office context to apply filters
   */

  data.forEach((shiftNode) => {
    const oldEvents =
      ref.eventsData.length > 0 &&
      ref.eventsData.filter((e) => e.shiftId === shiftNode.id);
    ref.deleteEvent(`SHIFT:${shiftNode.id}`);
    delete ref.allScheduleDataObject[`SHIFT:${shiftNode.id}`];
    oldEvents.forEach((sa) => {
      ref.deleteEvent(sa.id);
      delete ref.allScheduleDataObject[sa.id];
    });
  });

  const events = getScheduleDataTransformer(
    ref.currentView,
    ref.viewIndex,
  )(data);

  const displayedEvents = applyOfficeFilters(selectedOfficeName, events);
  ref.addEvent(displayedEvents);
  events.forEach((event) => {
    ref.allScheduleDataObject[event.id] = event;
  });
  ref.hideSpinner();
}

export function updateScheduleFromOption(ref, data, selectedOfficeName) {
  /**
   * Updates the Syncfusion scheduler object after a manager approves/executes an option.
   *
   * Updates the events bound to the scheduler object which entails deleting any SHIFT or
   * SHIFTASSIGNMENT events for the shifts involved.
   *
   * @param {ScheduleComponent} ref the Syncfusion scheduler object
   * @param {Array[Object]} data Array of EfficientShiftNodes returned from the mutation
   * @param {String} selectedOfficeName the name for the office that this change is for
   */
  const shiftIds = new Set(data.map((shift) => shift.id));

  const oldEvents =
    ref.eventsData.length > 0 &&
    ref.eventsData.filter((e) => shiftIds.has(e.shiftId));

  oldEvents.forEach((e) => {
    ref.deleteEvent(e.id);
    delete ref.allScheduleDataObject[e.id];
  });

  const events = getScheduleDataTransformer(
    ref.currentView,
    ref.viewIndex,
  )(data);
  events.forEach((event) => {
    ref.allScheduleDataObject[event.id] = event;
  });
  const displayedEvents = applyOfficeFilters(selectedOfficeName, events);
  ref.addEvent(displayedEvents);
}

export function updateUnavailabilityEvent(
  ref,
  data,
  oldEvent,
  selectedOfficeName,
) {
  /**
   * Updates the Syncfusion scheduler object after a PTO add or change.
   *
   * Updates the Syncfusion schedule object after a PTO add or change; includes creating
   * a new event and possibly deleting the old event if a request was previously
   * pending.
   *
   * @param {ScheduleComponent} ref the Syncfusion scheduler object
   * @param {Object | Object[]} data EmployeeAvailabilityNode or an array of them returned from mutation
   * @param {Object} oldEvent optional, the previous event data for the PTO request being updated.
   * @param {String} selectedOfficeName the name for the office that this change is for
   */
  if (oldEvent != null) {
    ref.deleteEvent(oldEvent.id);
    delete ref.allScheduleDataObject[oldEvent.id];
  }
  // TODO: check if data is an array
  if (data.userCancelled) {
    return;
  }
  const events = getTimeOffTransformer(ref.currentView, ref.viewIndex)([data]);
  events.forEach((event) => {
    ref.allScheduleDataObject[event.id] = event;
  });

  let displayedEvents = applyOfficeFilters(selectedOfficeName, events);
  ref.addEvent(displayedEvents);
}

export function updateTimeOffEvent(ref, data, oldEvent, selectedOfficeName) {
  if (oldEvent != null) {
    ref.deleteEvent(oldEvent.id);
    delete ref.allScheduleDataObject[oldEvent.id];
  }
  if (data.status === "CANCELED") {
    return;
  }
  const events = getTimeOffTransformer(ref.currentView, ref.viewIndex)([data]);
  events.forEach((event) => {
    ref.allScheduleDataObject[event.id] = event;
  });
  let displayedEvents = applyOfficeFilters(selectedOfficeName, events);
  ref.addEvent(displayedEvents);
}

export function updateSoftRequest(ref, data, oldEvent, selectedOfficeName) {
  /**
   * Updates the Syncfusion scheduler object after a soft request event add or change.
   *
   * Updates the Syncfusion schedule object after a soft request add, change, or delete;
   * includes creating a new event and possibly deleting the old event if it was an
   * update.
   *
   * @param {ScheduleComponent} ref the Syncfusion scheduler object
   * @param {Object} data optional, OffPreferenceNode returned from mutation
   * @param {Object} oldEvent optional, the current event data for the request being updated.
   * @param {String} selectedOfficeName the current office context to apply filters
   */
  if (oldEvent != null) {
    ref.deleteEvent(oldEvent.id);
    delete ref.allScheduleDataObject[oldEvent.id];
  }
  if (data != null) {
    const events = getSoftRequestTransformer(ref.currentView, ref.viewIndex)(
      [data],
      parseInt(data.employee.id),
    );
    events.forEach((event) => {
      ref.allScheduleDataObject[event.id] = event;
    });
    let displayedEvents = applyOfficeFilters(selectedOfficeName, events);
    ref.addEvent(displayedEvents);
  }
}

export function updateOnCallEvent(ref, data, oldEvent) {
  /**
   * Updates the Syncfusion scheduler object after an On Call change.
   *
   * Updates the Syncfusion schedule object after an on call schedule change; includes
   * deleting the current event and replacing it with a new event.
   *
   * @param {ScheduleComponent} ref the Syncfusion scheduler object
   * @param {Object} data EfficientShiftNode returned from saveScheduleChange mutation
   * @param {Object} oldEvent the current event data for the shift/shift assignment being edited.
   */
}
