import React, { forwardRef, useState } from "react";
import { userVar, selectedDateVar, locationConfigVar } from "../../cache";
import roles from "../../Roles/roles";
import MangShiftSwitchRequestForm from "./MangShiftSwitchRequestForm";
import EmpShiftSwitchRequestForm from "./EmpShiftSwitchRequestForm";
import MissionMangShiftSwitchRequestForm from "./MissionMangShiftSwitchRequestForm";
import MissionEmpShiftSwitchRequestForm from "./MissionEmpShiftSwitchRequestForm";
import {
  Grid,
  Typography,
  IconButton,
  FormControlLabel,
  Switch,
  CircularProgress,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RescheduleOptionsKey from "../rescheduling/RescheduleOptionsKey";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

const ShiftSwitchRequestForm = forwardRef((props, ref) => {
  const {
    closeDialog,
    scheduleEndDate,
    notifyDevelopers,
    environment,
    selectedOffice,
    selectedShift,
  } = props;

  const user = userVar();
  const date = selectedDateVar();
  const locationConfig = locationConfigVar();

  const shiftBased = locationConfig.shiftBasedDisplay;

  const managerAccess =
    user.role === roles.MANAGER ||
    user.role === roles.SCHEDULER ||
    user.role === roles.ADMIN;

  const [personalShiftSwitch, setPersonalShiftSwitch] = useState(false);

  // check if loggedin user is employee or manager and display the shift switch form based on the employee role

  //The number of days required between shift and a shift switch request for that shift.
  const shiftSwitchBuffer = selectedOffice.shiftSwitchBufferDays;

  return (
    <Grid
      container
      component="form"
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
    >
      <Grid container item justifyContent="space-between">
        <Grid item>
          <Typography variant="h3">Switch Shifts</Typography>
        </Grid>
        <Grid item>
          <IconButton
            color="secondary"
            onClick={closeDialog}
            size="large"
            data-testid="closeButtonshiftSwitchForm"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      {user.role === "EMPLOYEE" || personalShiftSwitch === true ? (
        shiftBased === true ? (
          <EmpShiftSwitchRequestForm
            closeDialog={closeDialog}
            shiftSwitchBuffer={shiftSwitchBuffer}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
            selectedOffice={selectedOffice}
            selectedShift={selectedShift}
            ref={ref}
          />
        ) : (
          <MissionEmpShiftSwitchRequestForm
            allEvents={[]}
            userEvents={[]}
            closeDialog={closeDialog}
            date={date}
            shiftToChange={shiftToChange}
            view={view}
            shiftSwitchBuffer={shiftSwitchBuffer}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
          />
        )
      ) : shiftBased ? (
        <MangShiftSwitchRequestForm
          closeDialog={closeDialog}
          date={date}
          scheduleEndDate={scheduleEndDate}
          shiftSwitchBuffer={shiftSwitchBuffer}
          notifyDevelopers={notifyDevelopers}
          environment={environment}
          selectedOffice={selectedOffice}
          selectedShift={selectedShift}
          ref={ref}
        />
      ) : (
        <MissionMangShiftSwitchRequestForm
          allEvents={[]}
          shiftNames={[]}
          closeDialog={closeDialog}
          date={date}
          scheduleEndDate={scheduleEndDate}
          refetch={refetch}
          shiftSwitchBuffer={shiftSwitchBuffer}
          notifyDevelopers={notifyDevelopers}
          environment={environment}
        />
      )}
    </Grid>
  );
});

export default ShiftSwitchRequestForm;
