import React from "react";
import { Typography, Box, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  GET_SCHEDULE_INFO,
  GET_INCOMING_TO_REQUESTS,
  EMPLOYEE_GET_INCOMING_SS_REQUESTS,
} from "../../api/gqlQueries";
import { useQuery } from "@apollo/client";
import { format, sub } from "date-fns";
import { userVar } from "../../cache";

const now = new Date();

function UpcomingTasks(props) {
  const user = userVar();
  const history = useHistory();
  const scheduleInfo = useQuery(GET_SCHEDULE_INFO, {
    variables: {
      id: parseInt(props.selectedOffice.id),
    },
    onError(err) {
      console.error(err);
    },
  });
  const getManagerRequests = useQuery(GET_INCOMING_TO_REQUESTS, {
    variables: {
      officeId: parseInt(props.selectedOffice.id),
      start: now.toISOString(),
      end: "2029-12-31T00:00:00",
      status: ["REQUESTED"],
    },
    onError(err) {
      console.error(err);
    },
    onCompleted(d) {
      console.log(d);
    },
  });

  const getEmployeeRequests = useQuery(EMPLOYEE_GET_INCOMING_SS_REQUESTS, {
    variables: {
      employee: parseInt(user.id),
    },
    onError(err) {
      console.error(err);
    },
  });

  if (
    scheduleInfo.loading ||
    getManagerRequests.loading ||
    getEmployeeRequests.loading ||
    props.schedulePeriods.loading
  ) {
    return <CircularProgress color="primary" />;
  } else {
    let mostRecentSchedule = props.schedulePeriods.data.getOfficePeriodStatus
      .nextPeriodToCreateStart
      ? props.schedulePeriods.data.getOfficePeriodStatus.nextPeriodToCreateStart
      : new Date();

    let dayspreferencesDueBeforeScheduleDate =
      scheduleInfo.data.offices &&
      parseInt(scheduleInfo.data.offices[0].numberDaysBeforePreferencesDue);

    // mostRecentSchedule &&
    //   mostRecentSchedule.length > 0 &&
    //   mostRecentSchedule.sort((a, b) => new Date(b.start) - new Date(a.start));

    let weeksBeforeScheduleGeneration =
      scheduleInfo.data.offices &&
      parseInt(scheduleInfo.data.offices[0].schedulePublishedBufferWeeks);
    let reviewWindowDays =
      scheduleInfo.data.offices &&
      parseInt(scheduleInfo.data.offices[0].reviewWindowDays);
    let generateNextScheduleBefore = mostRecentSchedule
      ? format(
          sub(new Date(mostRecentSchedule), {
            weeks: weeksBeforeScheduleGeneration,
          }),
          "MMM-dd-yyyy",
        )
      : format(new Date(), "MMM-dd-yyyy");

    generateNextScheduleBefore = format(
      sub(new Date(generateNextScheduleBefore), {
        days: reviewWindowDays,
      }),
      "MMM-dd-yyyy",
    );

    let preferencesDueBeforeScheduleDate = format(
      sub(new Date(generateNextScheduleBefore), {
        days: dayspreferencesDueBeforeScheduleDate,
      }),
      "MMM-dd-yyyy",
    );
    if (!user) {
      return <CircularProgress color="primary" />;
    } else {
      return (
        <div>
          <div>
            <Box m={2}>
              <Typography variant="h3">UPCOMING TASKS</Typography>
            </Box>
          </div>
          <div
            style={{
              margin: 10,
              background: props.lightBackground,
              border: "1px solid" + props.borderColor,
              padding: 8,
              borderRadius: 4,
              cursor: "pointer",
            }}
            onClick={() => history.push("/Requests")}
          >
            {" "}
            {
              <Typography variant="body1">
                Requests to Approve:{" "}
                {user.isEmployee === true
                  ? ""
                  : ` ${getManagerRequests.data.employeeTimeOff.length}`}
              </Typography>
            }
          </div>
          <div
            style={{
              margin: 10,
              background: props.lightBackground,
              border: "1px solid" + props.borderColor,
              padding: 8,
              borderRadius: 4,
              cursor: "pointer",
            }}
            onClick={() => history.push("/Preferences")}
          >
            {" "}
            <Typography variant="body1">
              Preference Due: {preferencesDueBeforeScheduleDate}
            </Typography>
          </div>
          <div
            style={{
              margin: 10,
              background: props.lightBackground,
              border: "1px solid" + props.borderColor,
              padding: 8,
              borderRadius: 4,
              cursor: "pointer",
            }}
            onClick={() =>
              !user.isEmployee
                ? history.push("/CreateSchedule")
                : (window.SHOW_CALENDAR_ON_LOAD === true ||
                      window.SHOW_CALENDAR_ON_LOAD === "true") &&
                    userVar().isEmployee === true
                  ? history.push("/")
                  : history.push("/Schedule")
            }
          >
            {" "}
            <Typography variant="body1">
              {" "}
              Publish Next Schedule Before:
            </Typography>
            <Typography variant="body1">
              {generateNextScheduleBefore}
            </Typography>
          </div>
        </div>
      );
    }
  }
}

export default UpcomingTasks;
