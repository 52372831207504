import React from "react";
import { TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const EmployeeSearchBox = (props) => {
  const setValue = props.setEmployee;
  const [inputValue, setInputValue] = React.useState("");
  console.log(props);
  return (
    <div>
      <Autocomplete
        value={props.val}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        placeholder="Enter Employee Name"
        id={props.id}
        name={props.name}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={props.options}
        getOptionLabel={(option, index) => option.name}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Enter Employee" />
        )}
      />
    </div>
  );
};

export default EmployeeSearchBox;
